import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {PrizesSenetProService} from "../../../service/prizes/prizesSenetPro.service";
import {PrizesSenetproHistoryModel} from "../prizes-senetpro-history.model";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";
import {WITHDRAW_TRANSACTION_TYPES} from "../../../config/withdraw/transactionsTypes.config";

@Component({
    selector: 'app-senetpro-transactions',
    templateUrl: './senetpro-transactions.component.html',
    styleUrls: ['./senetpro-transactions.component.scss']
})
export class SenetproTransactionsComponent implements OnInit {
    @Input() items = [];
    @Input() isStickyHeader = true;

    WITHDRAW_TRANSACTION_TYPES = WITHDRAW_TRANSACTION_TYPES;
    constructor() {}

    ngOnInit() {}

    isTransaction(cellType: number, dataType: number): boolean {
        return cellType === dataType || dataType === this.WITHDRAW_TRANSACTION_TYPES.bonuses;
    }

    getDate(date) {
        if(date == null){
            return '';
        }
        return date.replace(/\s/g, 'T');
    }
}
