import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PRIZES_SENETPRO_METHODS} from "../../pages/prizes-senetpro/prizesSenetPro.config";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {PrizesSenetProService} from "../../service/prizes/prizesSenetPro.service";
import {PopupComponent} from "../popup/popup.component";

@Component({
    selector: 'app-popup-prizes-senetpro-confirm',
    templateUrl: './popup-prizes-senetpro-confirm.component.html',
    styleUrls: ['./popup-prizes-senetpro-confirm.component.scss']
})
export class PopupPrizesSenetproConfirmComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() changeHandle: EventEmitter<{type: string, handle: string}> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    moreThenAvailablePayout = false;
    selectedMethod;

    PRIZES_METHODS = PRIZES_SENETPRO_METHODS;
    isTotalAmountCorrect = false;
    totalAmount = 0;
    phoneMask;
    sendToFriendStep = 1;
    sendFriendInfo = {
        firstName: '',
        lastName: ''
    }
    constructor(private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private popupComponent: PopupComponent,
                private prizesSenetProService: PrizesSenetProService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            handle: [null],
            phone: [null],
            phoneVenmo: [{value: null, disabled: true}],
            amount: [null, Validators.required],
            quantity: [null]
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            if (this.popupInfo.providers) {
                this.selectedMethod = this.popupInfo.providers[0];
                this.form.controls.handle.setValue(this.selectedMethod);
            }
           if (this.popupInfo.type === this.PRIZES_METHODS.venmo) {
                this.form.controls.phoneVenmo.setValue(this.popupInfo.handle);
           }
        } else {
            if (this.form) {
                this.form.reset();
            }
            this.isTotalAmountCorrect = false;
            this.totalAmount = 0;

            this.resetSendToFriend();
            this.moreThenAvailablePayout = false;
        }
    }

    setPaypalVerified($event) {
        this.popupInfo.paypalAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    setVenmoVerified($event) {
        this.popupInfo.venmoAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    updateHandle(type, handle) {
        this.form.controls.handle.setValue(handle);
        this.changeHandle.emit({type: type, handle: handle});
    }

    closePopup() {
        this.close.emit(true);
    }

    onSubmitForm() {
        if (this.popupInfo.type === this.PRIZES_METHODS.games) {
            //this.updateHandle(this.PRIZES_METHODS.games, this.form.controls.handle.value)
        }
        if (this.isOneOfGiftCards()) {
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type, qty: this.form.controls.quantity.value});
        } else {
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type});
        }
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onInputQuantity() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        this.totalAmount = this.form.controls.amount.value * +this.form.controls.quantity.value;
        this.isTotalAmountCorrect = this.totalAmount <= avaliable;
    }

    isOneOfGiftCards(): boolean {
        return this.popupInfo.type === this.PRIZES_METHODS.giftCardVisa || this.popupInfo.type === this.PRIZES_METHODS.giftCardAmazon
    }

    selectAmount(item) {
        this.form.controls.amount.setValue(item);
        // this.onInputAmount();
        this.onInputQuantity();
    }

    isSendToFriendPopup():boolean {
        if (this.popupInfo.type) {
            return this.popupInfo.type === this.PRIZES_METHODS.sendToFriend;
        }
        return false;
    }

    onSubmitSentToFriendPhone() {
        const phone = this.form.controls.phone.value;
        this.prizesSenetProService.sendFriendPhone(phone).subscribe(data => {
            try {
                if (data.status) {
                    this.sendToFriendStep = 2;
                    this.sendFriendInfo.firstName = data.firstName;
                    this.sendFriendInfo.lastName = data.lastName;
                    this.form.controls.phone.disable();
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onSubmitSentToFriendForm() {
        const amount = this.form.controls.amount.value;
        const phone = this.form.controls.phone.value;
        this.prizesSenetProService.sendToFrined(phone, amount).subscribe(data => {
            try {
                if (data.status) {
                    this.closePopup();
                    this.confirm.emit(this.popupInfo);
                    this.popupComponent.showPopupSuccess({text: data.message});
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    goToFirstStepSendFriend() {
        this.resetSendToFriend();
    }

    resetSendToFriend() {
        this.sendToFriendStep = 1;
        this.sendFriendInfo.firstName = '';
        this.sendFriendInfo.lastName = '';
        if (this.form) {
          this.form.controls.phone.enable();
        }
    }
}
