import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {PaypalService} from "../../../../service/settings/paypal.service";
import {AddressesService} from "../../../../service/settings/addresses.service";
import {ServerResponse} from "../../../../models/responses/response.model";
import {RouterPaths} from "../../../../config/testing/router.paths.model";
import {StylingService} from "../../../../service/styling.service";


@Component({
    selector: 'app-add-address',
    templateUrl: './add-address.component.html',
    styleUrls: ['./add-address.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class AddAddressComponent implements OnInit {
    newAddressForm: FormGroup;

    routerPaths = RouterPaths;
    constructor(private formBuilder: FormBuilder,
                private addressesService: AddressesService,
                private loaderService: LoaderService,
                private router: Router,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newAddressForm = this.formBuilder.group({
            address: ['', Validators.required],
        });
    }

    // onSubmit() {
    //     this.popupComponent.showPopupAgreement({text: `Add address ${address} to available addresses${saveText}?`}, (status) => {
    //         if (status) {
    //             this.submit();
    //         }
    //     });
    // }

    onSubmit() {
        const address = this.getAddress();

        this.addressesService.addAddress(address).subscribe(data => {
            this.addAddressResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    addAddressResult(responce: ServerResponse) {
        if(responce.status === true){
            this.popupComponent.showPopupSuccess({text: responce.message});
            this.goBack();
        } else if(responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getAddress() {
        return this.newAddressForm.controls.address.value;
    }

    goBack() {
        this.newAddressForm.controls.address.setValue('');
        this.router.navigate([this.routerPaths.addresses.main]);
    }

    onInput($event) {
        this.newAddressForm.get('address').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
