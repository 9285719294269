import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {EXISTING_USER_CONFIG, USER_BALANCE_CONFIG} from "../../config/api";

@Injectable({
  providedIn: 'root'
})
export class UserBalanceService extends SettingsLoaderService {
  private config = USER_BALANCE_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  getBalance() {
    this.showLoader();
    return this.http.post<any>(this.config.balance, {});
  }

  increaseBalance(amount) {
    this.showLoader();
    return this.http.post<any>(this.config.increaseBalance, {amount});
  }
}
