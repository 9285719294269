<div class="promo" [class.is-popup-open]="popupIsOpen">
    <div class="promo__inner">
        <img style="width: 80%" class="promo__bg" src="../../../../assets/images/promo/777/promo_777_bg.jpg" alt="">
        <h1 class="promo__title">Sweepstakes that pays you commission</h1>
        <form [formGroup]="form" (ngSubmit)="promoStep === 1 ? onPhoneSubmit() : promoStep === 2 ? onVerifyCashappSubmit() : onPlayNow()" class="promo-form">

            <h1 *ngIf="promoStep === 1" class="promo-form__title">Register to get immediate free play</h1>
            <h1 *ngIf="promoStep === 2" class="promo-form__title">Congrats! You Got <span class="color-third">${{benefits.register}}</span> in free play</h1>
            <h1 *ngIf="promoStep === 3" class="promo-form__title">Congrats! You Got <span class="color-third">${{benefits.register + benefits.cashapp}}</span> in free play</h1>

            <div class="promo-form__steps">
                <div class="promo-form__step" [class.is-done]="promoStep !== 1">
                    <h2>
                        <span class="color-primary">Register & </span>
                        <span class="color-secondary">Get free ${{benefits.register}} play</span>
                    </h2>
                    <img class="promo-form__step-result" src="../../../../assets/images/promo/777/checkmark.fw.png" alt="">
                </div>

                <div class="promo-form__step" [class.is-done]="promoStep !== 1 && promoStep !== 2">
                    <h2>
                        <span class="color-primary">Enter cashapp & </span>
                        <span class="color-secondary">Get free ${{benefits.cashapp}} play</span>
                    </h2>
                    <img class="promo-form__step-result" src="../../../../assets/images/promo/777/checkmark.fw.png" alt="">
                </div>

                <div class="promo-form__step">
                    <h2>
                        <span class="color-primary">Deposit & </span>
                        <span class="color-secondary">Get free ${{benefits.deposit}} play</span>
                    </h2>
                    <div class="promo-form__step-addition" *ngIf="promoStep === 3">
                        <p>send only $1.00 & get ${{benefits.deposit}}.00 more deposited on your account</p>
                        <div class="promo-form__step-addition-wrapper">
                            <h2>
                                <span class="color-primary">send to: </span>
                                <span class="color-secondary">$iriskeysolutions</span>
                            </h2>
                            <h2>
                                <span class="">AMOUNT: </span>
                                <span class="">$1.00</span>
                            </h2>
                        </div>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="promoStep === 1">
                <div class="promo-form__items">
                    <div class="promo-form__item">
                        <label class="promo-form__label">
                            First Name
                        </label>
                        <input formControlName="firstName"
                               [type]="'text'"
                               class="promo-form__input"
                               autocomplete="off">
                    </div>

                    <div class="promo-form__item">
                        <label class="promo-form__label">
                            Last Name
                        </label>
                        <input formControlName="lastName"
                               [type]="'text'"
                               class="promo-form__input"
                               autocomplete="off">
                    </div>

                    <div class="promo-form__item">
                        <label class="promo-form__label">
                            Phone
                        </label>
                        <input [mask]="phoneMask"
                               formControlName="phone"
                               [type]="'tel'"
                               class="promo-form__input"
                               autocomplete="off">

                    </div>

                    <div class="promo-form__item">
                        <label class="promo-form__label">
                            Email
                        </label>
                        <input formControlName="email"
                               [type]="'text'"
                               email="true"
                               class="promo-form__input"
                               autocomplete="off">
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="promoStep === 2">
                <div class="promo-form__items">
                    <div class="promo-form__item">
                        <label class="promo-form__label">
                            CASHAPP TAG
                        </label>
                        <input formControlName="cashappTag"
                               [type]="'text'"
                               #cashappTag
                               class="promo-form__input"
                               autocomplete="off">
                    </div>
                </div>
            </ng-container>

<!--            <ng-container *ngIf="promoStep === 3">-->
<!--                <div class="promo-form__items">-->
<!--                    <div class="promo-form__item">-->
<!--                        <label class="promo-form__label">-->
<!--                            DEPOSIT-->
<!--                        </label>-->
<!--                        <input mask="separator"-->
<!--                               thousandSeparator=","-->
<!--                               [prefix]="'$'"-->
<!--                               formControlName="deposit"-->
<!--                               [type]="'text'"-->
<!--                               class="promo-form__input"-->
<!--                               disabled="true"-->
<!--                               autocomplete="off">-->
<!--                    </div>-->
<!--                </div>-->
<!--            </ng-container>-->

            <ng-container *ngIf="promoStep === 3">
                <div class="skip-step">
                    <input formControlName="skip"
                           [type]="'checkbox'"
                           id="skip-step"
                           class="promo-checkbox"
                           [checked]="true"
                           (change)="onSkipChange($event)"
                           autocomplete="off">
                    <label for="skip-step">
                        I don't want additional ${{benefits.deposit}} in free play
                    </label>
                    <button class="skip-step__btn">Skip this step</button>
                </div>

            </ng-container>


            <div class="promo-form__btns" *ngIf="promoStep === 1">
                <button class="promo-form__btn" [disabled]="form.controls.firstName.invalid || form.controls.lastName.invalid || form.controls.email.invalid || form.controls.phone.invalid">
                    Register now
                </button>
            </div>

            <div class="promo-form__btns" *ngIf="promoStep === 2">
                <button class="promo-form__btn" [disabled]="form.controls.cashappTag.invalid">
                    Verify Cashapp Tag
                </button>
            </div>

            <div class="promo-form__btns" *ngIf="promoStep === 3">
                <button class="promo-form__btn" type="button" (click)="onTextCashTag()">
                    Text me cash tag
                </button>

                <button class="promo-form__btn">
                    Play now
                </button>
            </div>

            <app-promo-popup [active]="popupIsOpen && popupType === 'pin'" (close)="onPopupClose()">
                <p class="small">We sent a verification text to</p>
                <p>{{form.controls.phone.value | mask: phoneMask}}</p>
                <form [formGroup]="pinForm" class="login-page__form">
                    <div class="login__items" formArrayName="digits">
                        <input (keydown.enter)="onPinSubmit()"
                               *ngFor="let field of pinForm.get('digits')['controls'];
                               let i = index" #inputs [maxLength]="1" [formControlName]="i"
                               (keydown)="check(i, field, $event)"
                               mask="0"
                               class="login-input-small" type="tel">
                    </div>

                    <div class="btns-wrapper">
                        <button (click)="resendSms()" type="button" class="button">Resend</button>

                        <button type="submit" (click)="onPinSubmit()" class="button" [disabled]="!pinForm.valid">
                            VERIFY
                        </button>
                    </div>
                </form>
            </app-promo-popup>

            <app-promo-popup [active]="popupIsOpen && popupType === 'textCashTag'" (close)="onPopupClose()">
                <p class="small">We sent cashtag</p>
                <p><span class="color-primary">$IRISKEYSOLUTIONS</span></p>
                <p class="small">to</p>
                <p>{{form.controls.phone.value | mask: phoneMask}}</p>

                <div class="btns-wrapper">
                    <button (click)="onTextCashTag()" type="button" class="button">Re-send</button>

                    <button type="submit" (click)="onLogin()" class="button">
                        Login
                    </button>
                </div>
            </app-promo-popup>

<!--            <app-promo-popup [active]="popupIsOpen && popupType === 'tag' || popupIsOpen && popupType === 'tagRetype'" (close)="onPopupClose()">-->
            <app-promo-popup [active]="popupIsOpen && popupType === 'tagRetype'" (close)="onPopupClose()">
                <form [formGroup]="resendForm" class="login-page__form">
                    <ng-container *ngIf="popupType === 'tagRetype'">
                        <p class="small">Retype the tag. All your winnings will be sent to this cashapp address</p>
                        <div class="promo-form__item" style="margin-top: auto">
                            <input formControlName="cashappTagRetype"
                                   #cashappTagRetype
                                   [type]="'text'"
                                   class="promo-form__input"
                                   autocomplete="off">
                        </div>
                        <div class="btns-wrapper">
                            <button type="submit"
                                    (click)="onTagRetypeSubmit()"
                                    class="button"
                                    [disabled]="form.controls.cashappTag.value !== resendForm.controls.cashappTagRetype.value">
                                RETYPE
                            </button>
                        </div>
                        <p class="small-note">Contact support to change cashapp address in the futeere.</p>
                    </ng-container>

                    <!--                    <ng-container *ngIf="popupType === 'tag'">-->
                    <!--                        <p class="small">This cashapp is already in usee please enter a cashapp tag that's unique to you</p>-->
                    <!--                        <div class="promo-form__item" style="margin-top: auto">-->
                    <!--                            <input formControlName="cashappTag"-->
                    <!--                                   [type]="'text'"-->
                    <!--                                   class="promo-form__input"-->
                    <!--                                   autocomplete="off">-->
                    <!--                        </div>-->
                    <!--                        <div class="btns-wrapper">-->
                    <!--                            <button type="submit" (click)="onTagResendSubmit()" class="button" [disabled]="resendForm.controls.cashappTag.invalid">-->
                    <!--                                VERIFY-->
                    <!--                            </button>-->
                    <!--                        </div>-->
                    <!--                    </ng-container>-->
                </form>
            </app-promo-popup>
        </form>
    </div>
</div>
<app-rotate-popup></app-rotate-popup>
