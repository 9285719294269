import {Injectable} from '@angular/core';
import {KioskPaymentService} from "./kiosk-payment.service";
import {BehaviorSubject} from "rxjs/index";
import {KIOSK_ACCESSOR_COMMANDS,KIOSK_ACCESSOR_PIRAMID_COMMANDS,KIOSK_ACCESSORS} from "./kiosk-accessor-commands";
import {KIOSK_ACCESSOR_STATUSES} from "./kiosk-accessor-statuses";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../loader.service";
import {ConnectionService} from "ng-connection-service";
import {KioskService} from "../kiosk.service";
import {PROJECT_CONFIG} from "../../config/project";
import {KIOSK_ACCESSOR_ERRORS_MESSAGES} from "./kiosk-accessor-errors-messages";
import {UsersPointService} from "../users/users-point.service";
import {PartnerService} from "../partner.service";
import {ScreenSaverService} from "../screen-saver.service";
import {NavigationEnd, Router} from "@angular/router";


declare var CefSharp: any;
declare var AccessorService: any;

@Injectable({
    providedIn: 'root'
})
export class KioskAccessorService {
    private _paymentToken;
    private _transactionStart = false;
    private _paymentType = null;
    private _billingPage = false;
    private _userLogged = false;

    public paymentAllowed = new BehaviorSubject(false);
    public paymentAmountChanged = new BehaviorSubject(0);
    public accessorStatusChanged = new BehaviorSubject(null);
    public paymentComplete = new BehaviorSubject(null);
    public needLogout = new BehaviorSubject(false);


    private accessorCommands = KIOSK_ACCESSOR_COMMANDS;
    private accessorPiramideCommands = KIOSK_ACCESSOR_PIRAMID_COMMANDS;
    private accessors = KIOSK_ACCESSORS;
    private accessorStatuses = KIOSK_ACCESSOR_STATUSES;
    private accessorErrorsMessages = KIOSK_ACCESSOR_ERRORS_MESSAGES;
    private projectConfig = PROJECT_CONFIG;
    private paymentTypes = PROJECT_CONFIG.terminalPaymentType;

    private billValidatorName;

    constructor(private popupComponent: PopupComponent,
                private kioskPaymentService: KioskPaymentService,
                private kioskService: KioskService,
                private loaderService: LoaderService,
                private connectionService: ConnectionService,
                private usersPointService: UsersPointService,
                private partnerService: PartnerService,
                private screenSaverService: ScreenSaverService,
                private router: Router,
                private authenticationService: AuthenticationService) {

        let statusError = null;
        (window as any).getCmd = (status,error,amount,qty,billValidator) => {
            var status:any = parseFloat(status);
            var error:any = parseFloat(error);
            var amount:any = parseFloat(amount);
            var qty:any = parseFloat(qty);
            var billValidator:any = billValidator;

               //console.log('sssss');
               //console.log(status);
               //console.log(error);
               //console.log(billValidator);
            //console.log(amount);

            if(error != 0 && statusError == null){
                statusError = error;

                this.returnBill();
                this.deactivationBill();

                return false;
            }

            if(error == 0 && statusError !== 0 && statusError != null){
                statusError = null;

                this.activateBill();
            }

            //this.returnBill();

            let billValidatorArr = billValidator.split('_');
            if(billValidatorArr[3]){
                this.billValidatorName = billValidatorArr[3];
                this.billValidatorName = this.billValidatorName.replace('name.','');
            }

            if(!this.transactionStart &&
                ((status == this.accessorStatuses.billAccepted && this.billValidatorName == this.accessors.cashcode) ||
                    (status == this.accessorStatuses.billAcceptedPiramid && this.billValidatorName == this.accessors.piramide)) &&
                amount > 0){
                this.transactionStart = true;

                this.amountChanged(amount);
            }else if(this.transactionStart && (
                (status != this.accessorStatuses.billAccepted &&  this.billValidatorName == this.accessors.cashcode) ||
                (status != this.accessorStatuses.billAcceptedPiramid &&  this.billValidatorName == this.accessors.piramide)
            )){
                this.transactionStart = false;
            }
        };

        let $this = this;
        setTimeout(function(){
            $this.activateBill();
        },3000);

        //this.activateBill();

        // TERMINAL V1
        this.authenticationService.userLoggedStatusChanged.subscribe((value: any) => this.userLoggedStatusChanged(value));
        // TERMINAL V2
        this.kioskService.billingPage.subscribe((value: any) => this.billingPageVisibilityChanged(value));

        this.connectionService.monitor().subscribe(isConnected => {
            if (!isConnected) {
                this.deactivationBill();
            }else{
                this.activateBill();
            }
        });

        this.kioskService.paymentType.subscribe((value: any) => this.savePaymentType(value));
    }

    public amountChanged(amount){
        // if(this.paymentType === this.paymentTypes.crypto){
        //     if(amount < this.projectConfig.terminalMinAmount){
        //         this.popupComponent.showPopupError(
        //             {
        //                 text: "To purchase crypto, the minimum accepted bill is "+this.projectConfig.terminalMinAmount+" USD. Please insert 10, 20, 50, or 100 dollar bills.",
        //                 confirmButtonText: "Ok"
        //             }
        //         );
        //
        //         this.returnBill();
        //
        //         return true;
        //     }
        //
        // }


        const canAccept = this.checkAcceptBilling();

        if (canAccept) {
            this.newAccessorStatus();

            this.loaderService.show();
            const typeShortName = this.partnerService.storageGetTerminalShortName();
            this.kioskPaymentService.create(amount, typeShortName).subscribe(data => {
                this.loaderService.hide();

                if (data.status) {
                    this.accepteBill();

                    this.paymentAmountChanged.next(data.result);
                }else{
                    this.popupComponent.showPopupError({text: data.message}, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');

                    this.returnBill();
                }
            }, (error) => {
                this.returnBill();
            });
        }
    }

    checkAcceptBilling() {
        if (this._userLogged) {
            if (this._billingPage) {
                return true;
            } else {
                if (this.partnerService.isMascotSingleTerminal()) {
                    const isPopupOpen = document.querySelector('.mascot-popup');
                    if (isPopupOpen) {
                        this.popupComponent.showPopupError({text: this.accessorErrorsMessages.closeGameFirst},this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
                    } else {
                        return true;
                    }
                } else {
                    this.popupComponent.showPopupError({text: this.accessorErrorsMessages.productNotSelected}, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
                }
            }
        } else {
            let $this = this;
            this.popupComponent.showPopupErrorReturnEvent({text: this.accessorErrorsMessages.notLogged},function(status){
                $this.screenSaverService.hideScreenSaver();
            }, this.partnerService.isMascotSingleTerminal() ? 'btn-ok' : '');
            //this.popupComponent.showPopupError({text: this.accessorErrorsMessages.notLogged});
        }
        this.returnBill();
        return false;
    }

    savePaymentType(type){
        this.paymentType = type;
    }

    // TERMINAL V1
    public userLoggedStatusChanged(status) {
        this._userLogged = !!status;
    }

    // TERMINAL V2
    public billingPageVisibilityChanged(status){
        this._billingPage = !!status;
    }

    // public complete(){
    //     this.loaderService.show();
    //     this.kioskPaymentService.complete().subscribe(data => {
    //         this.loaderService.hide();
    //
    //         this.newAccessorStatus();
    //
    //         if (data.status) {
    //             this.popupComponent.showPopupSuccess({text: data.message});
    //
    //             this.paymentAmountChanged.next(0);
    //             this.paymentCompleted();
    //         }else{
    //             this.popupComponent.showPopupError({text: data.message});
    //         }
    //     }, () => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    public completeCryptoBuy(currencyIdTo, successHandler?){
        this.kioskPaymentService.buyCrypto(currencyIdTo).subscribe(data => {
            if (successHandler) {
                this.checkBuyResult(data, successHandler);
            } else {
                this.checkBuyResult(data);
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    public completeSweepsBuy(successHandler?){
        this.kioskPaymentService.buySweeps().subscribe(data => {
            if (successHandler) {
                this.checkBuyResult(data, successHandler);
            } else {
                this.checkBuyResult(data);
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    private checkBuyResult(data, successHandler?) {
        this.newAccessorStatus();
        if (data.status) {
            if (successHandler) {
                successHandler();
            }

            this.popupComponent.showPopupSuccess({text: data.message}, this.logoutAfterPayment.bind(this));

            this.paymentAmountChanged.next(0);
            this.paymentCompleted();
        }else{
            this.popupComponent.showPopupError({text: data.message});
        }
    }

    logoutAfterPayment() {
        if (this.partnerService.isRiverPayTerminal()) {
            this.needLogout.next(true);
        }
    }

    public cmd(cmd:number){
        (async function() {
             await CefSharp.BindObjectAsync("AccessorService", "bound");

             AccessorService.cmd(cmd);
        })();
    }

    public reload(){
        (async function() {
            await CefSharp.BindObjectAsync("AccessorService", "bound");

            AccessorService.reload();
        })();
    }

    paymentCompleted(){
        this.paymentComplete.next(Math.round(+new Date()/1000)+Math.random());
    }

    newAccessorStatus(){
        this.accessorStatusChanged.next(Math.round(+new Date()/1000)+Math.random());
    }

    accepteBill(){
        if(this.billValidatorName == this.accessors.piramide){
            this.cmd(this.accessorPiramideCommands.acceptBill);
        }else{
            this.cmd(this.accessorCommands.acceptBill);
        }
    }

    returnBill(){
        if(this.billValidatorName == this.accessors.piramide){
            this.cmd(this.accessorPiramideCommands.returnBill);
        }else{
            this.cmd(this.accessorCommands.returnBill);
        }

        this.newAccessorStatus();
    }
    deactivationBill(){
        if(this.billValidatorName){
            if(this.billValidatorName == this.accessors.piramide){
                this.cmd(this.accessorPiramideCommands.deactivationBill);
            }else{
                this.cmd(this.accessorCommands.deactivationBill);
            }
        }else{
            let $this = this;
            setTimeout(function(){
                $this.deactivationBill();
            },500)
        }

    }
    activateBill(){
        if(this.billValidatorName){
            if(this.billValidatorName == this.accessors.piramide){
                this.cmd(this.accessorPiramideCommands.acceptanceConfirmationMode);
            }else{
                this.cmd(this.accessorCommands.acceptanceConfirmationMode);
            }
        }else{
            let $this = this;
            setTimeout(function(){
                $this.activateBill();
            },500)
        }
    }

    get transactionStart(): boolean {
        return this._transactionStart;
    }

    set transactionStart(value: boolean) {
        this._transactionStart = value;
    }

    get paymentToken() {
        return this._paymentToken;
    }

    set paymentToken(value) {
        this._paymentToken = value;
    }

    get paymentType(): any {
        return this._paymentType;
    }

    set paymentType(value: any) {
        this._paymentType = value;
    }
}
