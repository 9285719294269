import {Component, HostListener, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {PartnerService} from "../../service/partner.service";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {KeyboardService} from "../../service/keyboard.service";

@Component({
    selector: 'app-keyboard',
    templateUrl: './keyboard.component.html',
    styleUrls: ['./keyboard.component.scss']
})
export class KeyboardComponent implements OnInit {
    capitalLetters;
    logoutTimer;
    isUserLogout = true;
    keyboardVisible;
    isApp = +localStorage.getItem('isApp');
    isMascotSingle: boolean;

    constructor(private keyboardService: KeyboardService,
                private auth: AuthenticationService,
                private partnerService: PartnerService,
                private router: Router,) {
        this.keyboardService.capsLock.subscribe(value => {
            this.capsLockChanged(value);
        });
        this.keyboardService.activityTimer.subscribe(value => {
            this.logoutTimerChanged(value);
        });
        this.keyboardService.keyboardVisible.subscribe(value => {
            this.keyboardVisibleChanged(value);
        });

        this.partnerService.currentPartnerTemplate.subscribe(val => {
            this.isMascotSingle = this.partnerService.isMascotSingle();
            if (this.isApp || this.isMascotSingle) {
                this.keyboardService.init();
            }
        });

        //TERMINAL V2
        this.auth.userLoggedStatusChanged.subscribe(val => {
            if (this.isApp) {
                this.isUserLogout = !val;
                this.keyboardService.setTimeout();
            }
        });

        this.isUserLogout = !this.auth.isUserLogged();

    }


    ngOnInit() {
        this.isMascotSingle = this.partnerService.isMascotSingle();
        if (this.isApp || this.isMascotSingle) {
            this.keyboardService.init();
        }

    }

    clickHandle(e) {
        const value = e.currentTarget.title;
        this.keyboardService.onInput(value);

    }

    capsLockChanged(value) {
        this.capitalLetters = value;
    }

    logoutTimerChanged(value) {
        this.logoutTimer = value;
    }

    logout() {
        this.keyboardService.logout();

    }

    keyboardVisibleChanged(value) {
        this.keyboardVisible = value;
    }
}
