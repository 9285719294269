import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {KioskService} from "../../../service/kiosk.service";
import {Router} from "@angular/router";


@Component({
    selector: 'app-terminal-connecting',
    templateUrl: './terminal-connecting.component.html',
    styleUrls: ['./terminal-connecting.component.scss']
})
export class TerminalConnectingComponent implements OnInit {
    @Output() close = new EventEmitter();
    showPopup = true;

    terminalForm: FormGroup;
    isApp: number;
    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private authenticationService: AuthenticationService,
                private kioskService: KioskService,
                private router: Router,
                private formBuilder: FormBuilder,) {}

    ngOnInit() {
        this.isApp = this.kioskService.isKiosk();
        this.terminalForm = this.formBuilder.group({
            user_name: [null, Validators.required],
            password: [null, Validators.required]
        });
    }

    goBack(){
        this.showPopup = false;
        setTimeout(() => {
            console.log("test_test: 0");
            this.close.emit();
        });
    }


    onSubmitTerminalForm() {
        const login = this.terminalForm.controls.user_name.value;
        const password = this.terminalForm.controls.password.value;

        this.authenticationService.registerTerminal(login, password).subscribe(data => {
            this.checkSubmitTerminalForm(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitTerminalForm(data) {
        try {
            if(data.status === true){
                if (this.isApp) {
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.terminalForm.reset();
                } else {
                    this.popupComponent.showPopupSuccess({text:data.message});
                    this.goBack();
                }

            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    reloadPage() {
        console.log('reloadPage');

        this.router.navigate(['/']);
    }
}

