export const PRIZES_SENETPRO_METHODS = {
  games: 'games',
  senet: 'senet',
  mascot: 'mascot',
  river: 'river',
  goldenDragon: 'golden_dragon',
  topUpBalance: 'topUpBalance',
  sendToFriend: 'friend',
  giftCardVisa: 'giftCardVisa',
  giftCardAmazon: 'giftCardAmazon',
  paypal: 'paypal',
  venmo: 'venmo',
  card: 'debitCard'
};

export const DEBIT_CARD_STATUS = {
  default: 1,
  pending: 2,
  error: 3,
  selected: 4,
  errorCard: 5,
};
