import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PRIZES_METHODS} from "../../pages/prizes/prizes.config";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {PrizesService} from "../../service/prizes/prizes.service";
import {PopupComponent} from "../popup/popup.component";

@Component({
    selector: 'app-popup-mobile-topup',
    templateUrl: './popup-mobile-topup.component.html',
    styleUrls: ['./popup-mobile-topup.component.scss']
})
export class PopupMobileTopupComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() changeHandle: EventEmitter<{type: string, handle: string}> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    moreThenAvailablePayout = false;
    selectedMethod;

    currentStep = 1;
    isMyPhoneSelected = false;
    mobileOperatorsList = [];
    selectedOperator = null;
    constructor(private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private phoneMaskService: PhoneMaskService,
                private prizesService: PrizesService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            phone: [null, Validators.required],
            amount: [null, Validators.required]
        });

    }

    ngOnChanges(changes) {
        // if (!changes.popupInfo.currentValue.show && this.form) {
        //     this.form.controls.amount.setValue(null);
        //     this.form.controls.handle.setValue(null);
        // }
        if (changes.popupInfo.currentValue.show) {
            this.currentStep = 1;
        }
    }

    updateHandle(type, handle) {
        // this.form.controls.handle.setValue(handle);
        // this.changeHandle.emit({type: type, handle: handle});
    }

    closePopup() {
        this.close.emit(true);
    }

    onSubmitForm() {
        this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type});
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    goToStep(num) {
        switch (num) {
            case 1:
                this.currentStep = 1;
                break;
            case 2:
                this.currentStep = 2;
                break;
            case 3:
                this.currentStep = 3;
                this.getOperatorsList();
                break;
            case 4:
                this.currentStep = 4;
                break;

        }
    }

    selectMyPhone() {
        this.goToStep(3);
        this.isMyPhoneSelected = true;
    }

    selectAnotherPhone() {
        this.goToStep(2);
        this.isMyPhoneSelected = false;
    }

    getOperatorsList() {
        this.prizesService.getMobileTopupOperatorsList().subscribe((data) => {
            this.setMobileOperators(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide()
        })
    }

    setMobileOperators(data) {
        try {
            this.mobileOperatorsList = [];
            if (data.status) {
                if (data.result && data.result.length) {
                    this.mobileOperatorsList = data.result;
                } else {
                    this.loaderService.hideAndShowTryAgain();
                }
            } else {
                this.popupComponent.showPopupError({text: data.message})
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    selectOperator(item) {
        this.selectedOperator = item;
        this.goToStep(4);
    }

    selectAmount(item) {
        this.form.controls.amount.setValue(item);
    }

    getPhoneMask(): string {
        return this.phoneMaskService.setPhoneMask();
    }
}
