export const CRYPTO_SETTINGS = {
    btc: {
        icon: 'btc',
        color: '#F7931A',
    },
    bch: {
        icon: 'bch',
        color: '#8DC351',
    },
    ltc: {
        icon: 'ltc',
        color: '#BEBEBE',
    },
    eth: {
        icon: 'eth',
        color: '#627EEA',
    },
    default: {
        icon: 'btc',
        color: '#F7931A',
    }
}

export const CRYPTO_AMOUNT_MASK = '1.0-10';
