export const KIOSK_ACCESSORS = {
    piramide: 'piramide',
    cashcode: 'cashcode'
};

export const KIOSK_ACCESSOR_COMMANDS = {
    reload: 0,
    deactivationBill: 1,
    acceptanceConfirmationMode: 2,
    acceptanceAutoMode: 3,
    acceptBill: 4,
    returnBill: 5
};

export const KIOSK_ACCESSOR_PIRAMID_COMMANDS = {
    reload: 1,
    deactivationBill: 0,
    acceptanceConfirmationMode: 2,
    acceptBill: 4,
    returnBill: 5
};
