import {Injectable} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";

@Injectable({
  providedIn: 'root'
})
export class CopyToBufferService {

  constructor(private popupComponent: PopupComponent) {
  }

  copyToBuffer(value, needMessage?, messageText?) {
    let tempInput = document.createElement('input');
    tempInput.value = value;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
    if (needMessage) {
      const popupMessage = messageText ? messageText : 'Code has been successfully copied!';
      this.popupComponent.showPopupSuccess({text: popupMessage});
    }
  }
}
