import {
  Component,
  OnInit,
 HostBinding
} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LoginService} from "../../../service/login.service";

import {PopupComponent} from "../../../shared/popup/popup.component";
import {MenuService} from "../../../service/menu.service";
import {LoaderService} from "../../../service/loader.service";
import {PartnerService} from "../../../service/partner.service";
import {PARTNERS} from "../../../config/partners_settings";
import {UsersPointService} from "../../../service/users/users-point.service";
import {KeyboardService} from "../../../service/keyboard.service";
import {AppPromptService} from "../../../service/app/appPrompt.service";
import {KioskService} from "../../../service/kiosk.service";
import {ScreenSaverService} from "../../../service/screen-saver.service";
import {environment} from "../../../../environments/environment";
import {TerminalService} from "../../../service/terminal/terminal.service";

@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.component.html',
  styleUrls: ['./kiosk.component.scss',
    '../../../../../node_modules/simple-keyboard/build/css/index.css'],
})
export class KioskComponent implements OnInit {
  pointId;

  riverPayAccountId = '';
  accountEnteringPopup = false;
  terminalConnectingPopup = false;

  showGamesSelection = false;
  showBuyMenu = true;

  isApp = +localStorage.getItem('isApp');
  isRiverPay: boolean;

  @HostBinding('class') isAppClass = '';

  PARTNERS = PARTNERS;

  constructor(
    private formBuilder: FormBuilder,
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private usersPointService: UsersPointService,
    private loginService: LoginService,
    private menuService: MenuService,
    private loaderService: LoaderService,
    private partnerService: PartnerService,
    private router: Router,
    private route: ActivatedRoute,
    private auth:AuthenticationService,
    private keyboardService: KeyboardService,
    private appPromptService: AppPromptService,
    private kioskService: KioskService,
    private screenSaverService: ScreenSaverService,
    private terminalService: TerminalService,
    private popupComponent: PopupComponent) {
    this.partnerService.isRiverPayTerminalPartner.subscribe(val => {
      this.isRiverPay = val;
    });
  }

  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.pointId = params.pointId;
      // console.log(this.pointId)
    });
    this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';
  }

  getPartnerName() {
    return this.terminalService.getTitle();
  }

  getPartnerDescription() {
    return this.terminalService.getDescription();
  }

  getPartnerLogo() {
    return this.terminalService.getLogo();
  }

  logout(){
    this.auth.logout();
    //this.router.navigate(['/']);
    this.showGamesSelection = false;
    this.popupComponent.closePopup();
  }

  getGamesForPointId(pointId) {
    this.pointId = pointId;
    this.showGamesSelection = true;
  }

  savePointId(pointId) {
    this.pointId = pointId;
    //this.showGamesSelection = true;
  }

  openGamesSelection() {
    this.showGamesSelection = true;
  }

  openBuyMenu() {
    this.showBuyMenu = true;
  }

  gameProviderWasChecked() {
    this.showGamesSelection = true;
  }
}
