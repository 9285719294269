import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SENETPRO_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class SenetproService extends SettingsLoaderService{
    private config = SENETPRO_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    playNow(token) {
      this.loaderService.show();
      return this.http.post<any>(this.config.playNow, {token});
    }
}
