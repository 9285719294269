export const RouterPaths = {
  transactionProcessing: '/threedsecure',
  promo777: '/promo777',
  massSms: '/mass-sms',
  senetpro: '/senetpro',
  login: '/login',
  rewards: {
    main: '/home/rewards',
    form: '/home/rewards/form',
    support: '/home/rewards/support',
    commission: '/home/rewards/commission',
  },
  investments: {
    main: '/home/investments',
  },
  prizes: {
    main: '/home/prizes',
    paypal: '/home/prizes/paypal',
    mailCheck: '/home/prizes/mail-check',
    crypto: '/home/prizes/crypto',
    venmo: '/home/prizes/venmo',
    cashapp: '/home/prizes/cashapp',
    games: '/home/prizes/games'
  },
  settings: {
    home: '/home/settings',
    help: '/home/settings/help',
    cashapp: {
      main: '/home/settings/cashapp',
      edit: '/home/settings/cashapp/edit',
      menu: '/home/settings/cashapp/card-menu',
      add: '/home/settings/cashapp/new-card',
      verify: '/home/settings/cashapp/verify',
    },
    card: {
      main: '/home/settings/card',
      edit: '/home/settings/card/edit',
      verify: '/home/settings/card/verify',
      menu: '/home/settings/card/card-menu',
      add: '/home/settings/card/new-card'
    },
    prepaid: {
      main: '/home/settings/prepaid',
          edit: '/home/settings/prepaid/edit',
          verify: '/home/settings/prepaid/verify',
          menu: '/home/settings/prepaid/card-menu',
          add: '/home/settings/prepaid/new-card'
    },
    paypal: {
      main: '/home/settings/paypal',
      edit: '/home/settings/paypal/edit',
      add: '/home/settings/paypal/add',
    },
    addresses: {
      main: '/home/settings/addresses',
      edit: '/home/settings/addresses/edit',
      add: '/home/settings/addresses/add',
    },
    contacts: '/home/settings/contacts',
    funds: '/home/settings/funds'
  },
  termsAndConditions: {
    main: '/home/terms-and-conditions'
  },
  mascot: {
    main: '/mascot'
  },
  mascotCard: {
    main: '/mascot-card'
  },
    games: {
      yeti: {
            main: '/yeti'
        }
  }
}
