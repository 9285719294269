<div class="investments-popup investments-popup--pay investments-popup--mobile-topup" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->

        <div class="investments-popup__title-pay">
            <p *ngIf="currentStep === 1">
                The balance of which phone do you want to top up
            </p>

            <p *ngIf="currentStep === 2">
                Please enter the phone you want to top up
            </p>

            <p *ngIf="currentStep === 3">
                Select Operator which you want to use
            </p>
        </div>

        <div class="operators-list" *ngIf="currentStep === 3">
            <button class="operators-list__item" *ngFor="let item of mobileOperatorsList" (click)="selectOperator(item)">
                <div class="operators-list__item-name">{{item.name}}</div>
                <button class="operators-list__item-btn">select</button>
            </button>
        </div>

        <form [formGroup]="form"
              (ngSubmit)="onSubmitForm()"
              class="form-simple form-simple--shrink form-simple--primary">
            <div class="form-simple__item"  *ngIf="currentStep === 2">
                <input formControlName="phone"
                       placeholder="Phone"
                       [mask]="getPhoneMask()"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item form-simple__item--amount"  *ngIf="currentStep === 4 && selectedOperator && (!selectedOperator.variants ||  !selectedOperator.variants.length)">
                <input mask="separator.0"
                       thousandSeparator=","
                       (input)="onInputAmount()"
                       formControlName="amount"
                       placeholder="Amount"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
                <span>.00</span>
                <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                    Available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                </div>
            </div>

            <div class="amount-variants" *ngIf="currentStep === 4 && selectedOperator && selectedOperator.variants && selectedOperator.variants.length">
                <button class="amount-variants__item" [class.is-active]="form.controls.amount.value === item" (click)="selectAmount(item)" *ngFor="let item of selectedOperator.variants">{{item}}</button>
            </div>

            <div class="form-simple__btns">
                <div class="form-simple__btns-row" *ngIf="currentStep === 1">
                    <button class="button snake-btn is-notouched"
                            (click)="selectMyPhone()">
                        My phone
                        <svg><rect></rect></svg>
                    </button>
                    <button class="button snake-btn is-notouched"
                            (click)="selectAnotherPhone()">
                        Another phone
                        <svg><rect></rect></svg>
                    </button>
                </div>
                <div class="form-simple__btns-row" *ngIf="currentStep !== 1">
                    <button class="button snake-btn is-notouched"
                            *ngIf="currentStep !== 1 && currentStep !== 3"
                            [disabled]="(currentStep === 2 && form.controls.phone.invalid) || (currentStep === 4 && form.controls.amount.invalid)"
                            (click)="currentStep === 2 ? goToStep(3) : goToStep(5)">
                        Continue
                        <svg><rect></rect></svg>
                    </button>

                    <button class="button snake-btn is-notouched"
                            *ngIf="currentStep !== 1"
                            (click)="currentStep === 2 ? goToStep(1) : currentStep === 3 && isMyPhoneSelected ? goToStep(1) : currentStep === 3 && !isMyPhoneSelected ? goToStep(2) : currentStep === 4 ? goToStep(3) : goToStep(4)">
                        Back
                        <svg><rect></rect></svg>
                    </button>
                </div>
<!--                <button class="button snake-btn is-notouched"-->
<!--                        [disabled]="+form.controls.amount.value <= 0">-->
<!--                    Transfer-->
<!--                    <svg><rect></rect></svg>-->
<!--                </button>-->
            </div>
        </form>
    </div>
</div>
