import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {USER_CARD_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class UserCardService extends SettingsLoaderService {
    private config = USER_CARD_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getCards(type) {
        this.showLoader();
        return this.http.post<any>(this.config.cards, {type});
    }

    getCardInfo(id) {
        this.showLoader();
        return this.http.post<any>(this.config.card, {id});
    }

    deleteCard(id) {
        this.showLoader();
        return this.http.post<any>(this.config.delete, {id});
    }

    resendCode(id) {
        this.showLoader();
        return this.http.post<any>(this.config.resendCode, {id});
    }

    checkVerify(id) {
        this.showLoader();
        return this.http.post<any>(this.config.checkVerify, {id});
    }

    // verifyCard(code, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.verify, {code, id});
    // }

    verifyCard(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.verify, {amount, id});
    }

    addCard(type, card, month,year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        this.showLoader();
        return this.http.post<any>(this.config.add, {type, card, month,year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId});
    }

    saveCard(id, month,year, name, billingAddress, billingZip, city, stateId) {
        this.showLoader();
        return this.http.post<any>(this.config.save, {id, month,year, name, billingAddress, billingZip, city, stateId});
    }

    addCashAppCard(card, month,year, cvv, name) {
        this.showLoader();
        return this.http.post<any>(this.config.addCashAppCard, {card, month,year, cvv, name});
    }
    addPrepaidCard(card, month,year, cvv, name) {
        this.showLoader();
        return this.http.post<any>(this.config.addPrepaidCard, {card, month,year, cvv, name});
    }
}
