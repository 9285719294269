import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    OnInit,
    Output,
    QueryList, ViewChild,
    ViewChildren
} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {KEYBOARD_SETTINGS} from "../../config/keyboard_settings";
import {KioskService} from "../../service/kiosk.service";
import {ScreenSaverService} from "../../service/screen-saver.service";
import {PartnerService} from "../../service/partner.service";
import {Router} from "@angular/router";
import {RIVERPAY_SLIDER_LINK} from "../../config/project";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-screen-saver',
    templateUrl: './screen-saver.component.html',
    styleUrls: ['./screen-saver.component.scss'],
})
export class ScreenSaverComponent implements OnInit {
    showSlider = false;

    sliderInterval;
    slidesDurationSec = 30;

    riverPaySliderLink = window.location.href.includes('river.dev.studionovi.co') ?
                        this.getLink(RIVERPAY_SLIDER_LINK.dev) : this.getLink(RIVERPAY_SLIDER_LINK.prod);
    isApp = this.kioskService.isKiosk();
    isRiverPay: boolean;

    CLASSES_NAMES = {
        active: 'is-active',
        prev: 'is-prev'
    };

    videoLink = "https://river.dev.studionovi.co/assets/videos/videoplayback.webm";
    videoLinkRes = this.videoLink+"?n="+this.random();

    @ViewChildren('img') slidesDOM: QueryList<ElementRef>;
    @ViewChild('video', {static: false}) video: ElementRef;
    @ViewChild('videoSource', {static: false}) videoSource: ElementRef;

    constructor(private kioskService: KioskService,
                private partnerService: PartnerService,
                private router: Router,
                public sanitizer: DomSanitizer,
                private screenSaverService: ScreenSaverService) {

        this.isRiverPay = this.partnerService.isRiverPayTerminal();

        this.screenSaverService.showing.subscribe((showing) => {
            // let media: HTMLVideoElement;
            // if (this.video) {
            //     media = this.video.nativeElement;
            // }

            if (showing) {
                if (this.isRiverPay) {
                    this.playVideo();
                    // window.location.href = this.riverPaySliderLink;
                } else {
                    this.initSlider();
                }
            } else {
                if (this.isRiverPay) {
                   this.stopVideo();
                } else {
                    this.stopSlider();
                }
            }
        });
    }

    loadVideo(){
        return false;

        let $this = this;
        setTimeout(function () {
            console.log("test_test: 14");
            if($this.isRiverPay){
                $this.showSlider = true;
            }

        },5000);

        setTimeout(function () {
            console.log("test_test: 15");
            if($this.isRiverPay) {
                const media: HTMLVideoElement = $this.video.nativeElement;
                const videoSource: HTMLVideoElement = $this.videoSource.nativeElement;

                // var video;
                // try {
                //     video = new Video();
                // } catch(e) {
                //     video = $this.video.nativeElement;;
                // }
                // video.src = $this.videoLink + "?n=" + $this.random();
                // bindOnce(video, 'canplaythrough', function() {alert("Loaded");});
                // video.onerror = function(e){alert("Error");};
                // video.load();
                // video.play();

                media.src = $this.videoLink + "?n=" + $this.random();
                //videoSource.setAttribute('src', $this.videoLink + "?n=" + $this.random());
                media.loop = true;
                media.load();
                //media.play();
            }
        },6000);
    }

    random(){
        return Math.round(+new Date()/1000)+Math.random();
    }

    // currentTime(event){
    //     const media: HTMLVideoElement = this.video.nativeElement;
    //     const videoSource: HTMLVideoElement = this.videoSource.nativeElement;
    //
    //     let $this = this;
    //     media.onerror = function() {
    //         console.log("Error " + media.error.code + "; details: " + media.error.message);
    //
    //         setTimeout(function () {
    //             media.pause();
    //
    //             $this.loadVideo();
    //         },5000);
    //     }
    //
    //
    //     //if(event.target.currentTime > (event.target.duration - 5)){
    //     // if(event.target.currentTime > 20){
    //     //     media.currentTime = 0;
    //     // }
    //     console.log(event.target.currentTime+"-"+this.videoLinkRes);
    //
    //     //console.log(event.target.duration)
    // }

    ngAfterViewInit() {
        //this.loadVideo();
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    ngOnInit() {

    }

    videoLoad() {
        console.log("videoLoad");

        const media: HTMLVideoElement = this.video.nativeElement;

        media.volume = 0;
        media.loop = true;
        media.play();
    }

    playVideo() {
        //alert('k')

        // let riverVideo:HTMLElement = (<HTMLElement>document.getElementById('riverVideo'));
        // riverVideo.style.zIndex = '99999999';
        // riverVideo.style.position = 'fixed';

        //const riverVideo1: HTMLAudioElement = this.video.nativeElement;
        // let riverVideo1:HTMLVideoElement = (<HTMLVideoElement>document.getElementById('riverVideo1'));
        // riverVideo1.loop = true;
        // riverVideo1.play();

        this.showSlider = true;
        // video.currentTime = 10;
        // video.volume = 1;


    }

    stopVideo() {
        //video.pause();
        // video.volume = 0;
        // video.currentTime = 0;

        this.showSlider = false;
    }

    initSlider() {
        this.showSlider = true;
        const slidesLength = this.slidesDOM.length;

        this.resetClasses();
        this.slidesDOM.forEach((el) => {
            if (+this.getOrder(el) === 1) {
                this.setClass(el, this.CLASSES_NAMES.active);
            }
        });

        this.sliderInterval = setInterval(() => {
            const activeSlide = this.slidesDOM.find(el => el.nativeElement.classList.contains(this.CLASSES_NAMES.active));
            const order = this.getOrder(activeSlide)

            this.resetClasses();

                this.slidesDOM.forEach(el => {
                    const elOrder = +this.getOrder(el);
                    if (order < slidesLength) {
                        switch (elOrder) {
                            case +order + 1: {
                                this.setClass(el, this.CLASSES_NAMES.active);
                                break;
                            }
                            case +order: {
                                this.setClass(el, this.CLASSES_NAMES.prev)
                                break;
                            }
                        }
                    } else {
                        switch (elOrder) {
                            case 1: {
                                this.setClass(el, this.CLASSES_NAMES.active);
                                break;
                            }
                            case slidesLength: {
                                this.setClass(el, this.CLASSES_NAMES.prev)
                                break;
                            }
                        }
                    }
                });
        }, this.slidesDurationSec * 1000);
    }

    stopSlider() {
        this.resetClasses();
        this.showSlider = false;
        clearInterval(this.sliderInterval);
    }

    getOrder(el) {
        return el.nativeElement.getAttribute('data-order');
    }

    setClass(el, className) {
        el.nativeElement.classList.add(className);
    }

    resetClasses() {
        if (this.slidesDOM) {
            this.slidesDOM.forEach(el => el.nativeElement.classList.remove(this.CLASSES_NAMES.active));
            this.slidesDOM.forEach(el => el.nativeElement.classList.remove(this.CLASSES_NAMES.prev));
        }
    }

    getPartnerName() {
        return this.partnerService.getName();
        //return this.currentPartner.name;
    }

    getPartnerLogo() {
        return this.partnerService.getLogo();
    }

}

