import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import {SnekapekInvestmentsComponent} from "./snekapek-investments/snekapek-investments.component";


@NgModule({
    declarations: [
        SnekapekInvestmentsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
    ],
    exports: [
    ],
    entryComponents: [

    ]
})
export class SnekapekModule { }
