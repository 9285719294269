import {Inject, Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PROJECT_CONFIG, PROVIDERS_TEMPLATES} from "../config/project";
import {LoaderService} from "./loader.service";
import {HttpClient} from "@angular/common/http";
import {PARTNERS_CONFIG} from "../config/api";
import {PARTNERS} from "../config/partners_settings";
import {BehaviorSubject} from "rxjs";
import {TERMINAL_RESPONSE_KEYS, TERMINAL_RESPONSE_KEYS_MODEL} from "../config/terminal/response_keys.config";
import {TerminalService} from "./terminal/terminal.service";
import {KioskService} from "./kiosk.service";
import {TERMINAL_PARTNERS} from "../config/terminal/terminal_partners.config";
import {environment} from "../../environments/environment";
import {FrontendTestSettingsService} from "./testing/frontendTestSettings.service";
import {PARTNER_TYPE} from "../pages/partners/partner.config";

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    private config = PARTNERS_CONFIG;
    private partners = PARTNERS;
    currentPartner = '';
    currentTerminalPartner = '';
    private storage = sessionStorage;
    private currentPartnerShortName = new BehaviorSubject('');
    currentPartnerTemplate = new BehaviorSubject('');
    isRiverPayPartner = new BehaviorSubject(false);
    isMascotPartner = new BehaviorSubject(false);

    // TERMINAL
    terminalPartnerShortName = new BehaviorSubject('');
    isRiverPayTerminalPartner = new BehaviorSubject(false);
    isMascotTerminalPartner = new BehaviorSubject(false);
    isMascotStationTerminalPartner = new BehaviorSubject(false);

    isApp: number;
    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private terminalService: TerminalService,
                private kioskService: KioskService,
                private http: HttpClient,
                private frontendTestSettingsService: FrontendTestSettingsService,) {
         this.isApp = this.kioskService.isKiosk();
        //
        // if (this.isApp) {
        //     this.terminalPartnerShortName.subscribe(val => {
        //         this.isRiverPayTerminalProvider(val);
        //         this.isMascotTerminalProvider(val);
        //         this.isMascotStationTerminalProvider(val);
        //     });
        //     this.isRiverPayTerminalProvider(this.getShortName());
        //     this.isMascotTerminalProvider(this.getShortName());
        //     this.isMascotStationTerminalProvider(this.getShortName());
        // }
        //
        //
        // this.currentPartnerShortName.subscribe(val => {
        //     this.isRiverPayProvider(val);
        //     this.isMascotProvider(val);
        // });
        // this.isRiverPayProvider(this.getShortName());
        // this.isMascotProvider(this.getShortName());



    }

    getPartner(){
        const domain = window.location.hostname;

        return this.http.post<any>(this.config.name, {domain:domain});
    }

    savePartner(data){
        if (this.frontendTestSettingsService.isPartnerTypeDefault()) {
            data.result.type = 0;
            console.log('d')
        }
        if (this.frontendTestSettingsService.isPartnerTypeSingle()) {
            data.result.type = 1;
            console.log('d2')
        }

        if (this.frontendTestSettingsService.isMascotSinglePage()) {
            data.result.short_name = 'mascot';
            data.result.template = 'mascotSingle';
        }

        if (this.frontendTestSettingsService.isTerminalStationMascot()) {
            data.result.terminalShortName = 'stationMascot';
        }

        if (this.frontendTestSettingsService.isNoTerminal()) {
            data.result = [];
        }

        if (this.frontendTestSettingsService.isPartnerAdditionInfo()) {
            data.result.businessName = 'Oksana Test';
            data.result.domain = 'http://google.com';
            data.result.logo_background = './assets/partners/snekapek/icons/icon-144.png';
        }

        if(data.result){
            this.storageSetData(data.result);

            this.storageSetShortName('');
            if(data.result.short_name){
                this.storageSetShortName(data.result.short_name);
            }

            this.storageSetTemplate('');
            if(data.result.template){
                this.storageSetTemplate(data.result.template);
            }

            this.storageSetTerminalShortName('');
            if(data.result.terminalShortName){
                this.storageSetTerminalShortName(data.result.terminalShortName);
            }

            this.storageSetCurrentPartnerBusinessName('');
            if(data.result.businessName){
                this.storageSetCurrentPartnerBusinessName(data.result.businessName);
            }

            this.storageSetCurrentPartnerDomain('');
            if(data.result.domain){
                this.storageSetCurrentPartnerDomain(data.result.domain);
            }

            this.storageSetCurrentPartnerLogoBackground('');
            if(data.result.logo_background){
                this.storageSetCurrentPartnerLogoBackground(data.result.logo_background);
            }

            this.storageSetCashapTag('');
            if(data.result.cashapp_tag){
                this.storageSetCashapTag(data.result.cashapp_tag);
            }

            this.storageSetPartnerType('');
            if(data.result.type || data.result.type === 0){
                this.storageSetPartnerType(data.result.type);
            }

            this.setTerminalInfo(data.result);

            if (this.getShortName() === PARTNERS.senet.short_name || this.getShortName() === PARTNERS.senetLabel.short_name) {
                this.changeThumbnailIcons(PARTNERS.senet.short_name);
                document.querySelector('title').innerHTML = this.getBusinessName();
            }
        }
    }

    storageSetCurrentPartnerBusinessName(data){
        this.storage.setItem('currentPartnerBusinessName', data);
    }

    storageSetCurrentPartnerDomain(data){
        this.storage.setItem('currentPartnerDomain', data);
    }

    storageSetCurrentPartnerLogoBackground(data){
        this.storage.setItem('currentPartnerLogoBackground', data);
    }

    storageSetTerminalShortName(data){
        this.storage.setItem('currentPartnerTerminalShortName', data);
    }

    storageSetData(data){
        this.storage.setItem('currentPartner', JSON.stringify(data));
    }

    storageSetTemplate(data){
        this.storage.setItem('currentPartnerTemplate', data);
    }

    storageSetShortName(data){
        this.storage.setItem('currentPartnerShortName', data);
    }

    storageSetCashapTag(data){
        this.storage.setItem('currentPartnerCashappTag', data);
    }

    storageSetPartnerType(data){
        this.storage.setItem('currentPartnerType', data);
    }

    storageGetTemplate(){
        return this.storage.getItem('currentPartnerTemplate');
    }

    storageGetTerminalShortName(){
        return this.storage.getItem('currentPartnerTerminalShortName');
    }

    storageGetCashapTag(){
        return this.storage.getItem('currentPartnerCashappTag');
    }

    storageGetPartnerLogo(){
        if (this.storage.getItem('currentPartnerLogoBackground')) {
            return this.storage.getItem('currentPartnerLogoBackground');
        } else {
            return '';
        }

    }

    storageGetCurrentPartner(){
        if(this.storage.getItem('currentPartner')){
            if(this.storage.getItem('currentPartner') != ''){
                return this.storage.getItem('currentPartner');
            }
        }

        return false;
    }

    detectPartner() {
        this.resetPartner();
        const domain = window.location.hostname;
        const defaultDomain = PROJECT_CONFIG.defaultDomain;

        this.currentPartner =  'default';

        this.resetTerminalInfo();
        //if (domain !== defaultDomain) {
            this.loaderService.show();
            this.http.post<any>(this.config.name, {domain:domain})
                .subscribe(data => {
                    this.loaderService.hide();

                    if (data.status) {
                        // data.result.logo = '../../../assets/images/activate_yourself.png'
                        // data.result.short_name = 'mascot';
                        // data.result.template = 'mascotSingle';
                        // data.result.name = 'riverpay';
                        // this.storage.setItem('currentPartnerShortName', 'snekapek');
                        // data.result[TERMINAL_RESPONSE_KEYS.shortName] = 'stationMascot';

                        this.storage.setItem('currentPartnerShortName', data.result.short_name);
                        this.storage.setItem('currentPartner', JSON.stringify(data.result));

                        this.storage.setItem('currentPartnerTemplate', data.result.template);

                        this.currentPartnerShortName.next(data.result.short_name);
                        this.currentPartnerTemplate.next(data.result.template);

                        this.terminalPartnerShortName.next(data.result[TERMINAL_RESPONSE_KEYS.shortName]);


                        console.log("RouterPaths.mascot.main setTerminalInfo");

                        this.setTerminalInfo(data.result);

                        // data.result.terminalAnimate = 'v2';
                        // this.screenSaverService.animation.next(data.result.terminalAnimate);
                    }else{

                    }
            });
        //}
    }

    resetPartner() {
        this.storage.removeItem('currentPartner');
        this.storage.removeItem('currentPartnerType');
    }

    resetTerminalInfo() {
        this.storage.removeItem("terminalInfo");
        // this.terminalService.terminalInfoUpdating.next(Date.parse(new Date().toISOString()));
    }

    setTerminalInfo(result) {
        if (this.frontendTestSettingsService.isTerminalExist()) {
            result[TERMINAL_RESPONSE_KEYS.title] = 'Test';
            result[TERMINAL_RESPONSE_KEYS.description] = 'Test';
            if (this.frontendTestSettingsService.isTerminalStationMascot()) {
                result[TERMINAL_RESPONSE_KEYS.shortName] = TERMINAL_PARTNERS.stationMascot.shortName;
            } else {
                result[TERMINAL_RESPONSE_KEYS.shortName] = TERMINAL_PARTNERS.riverpay.shortName;
            }
            result[TERMINAL_RESPONSE_KEYS.logo] = 'http://192.168.0.101:8888/ebox-api/images/partners/activate_yourself.png';

            result[TERMINAL_RESPONSE_KEYS.isCrypto] = 0;
            result[TERMINAL_RESPONSE_KEYS.isGames] = 0;
            if (this.frontendTestSettingsService.isTerminalDisplayGames()) {
                result[TERMINAL_RESPONSE_KEYS.isGames] = 1;
            }
            if (this.frontendTestSettingsService.isTerminalDisplayCrypto()) {
                result[TERMINAL_RESPONSE_KEYS.isCrypto] = 1;
            }

        }

        const terminalInfo = {
            [TERMINAL_RESPONSE_KEYS.title]: result[TERMINAL_RESPONSE_KEYS.title],
            [TERMINAL_RESPONSE_KEYS.description]: result[TERMINAL_RESPONSE_KEYS.description],
            [TERMINAL_RESPONSE_KEYS.logo]: result[TERMINAL_RESPONSE_KEYS.logo],
            [TERMINAL_RESPONSE_KEYS.shortName]: result[TERMINAL_RESPONSE_KEYS.shortName],
            [TERMINAL_RESPONSE_KEYS.isCrypto]: result[TERMINAL_RESPONSE_KEYS.isCrypto],
            [TERMINAL_RESPONSE_KEYS.isGames]: result[TERMINAL_RESPONSE_KEYS.isGames],
        };

        this.storage.setItem("terminalInfo", JSON.stringify(terminalInfo));
        //this.terminalService.terminalInfoUpdating.next(Date.parse(new Date().toISOString()));
    }

    getShortName(){
        if (this.storage.getItem('currentPartnerShortName')) {
            return this.storage.getItem('currentPartnerShortName');
        }

        return 'default';
    }

    getBusinessName(){
        if (this.storage.getItem('currentPartnerBusinessName')) {
            return this.storage.getItem('currentPartnerBusinessName');
        }
        return '';
    }

    getPartnerDomain(){
        if (this.storage.getItem('currentPartnerDomain')) {
            return this.storage.getItem('currentPartnerDomain');
        }
        return '';
    }

    getPartnerType(){
        if (this.storage.getItem('currentPartnerType')) {
            return this.storage.getItem('currentPartnerType');
        }
        return '';
    }

    isPartnerTypeSingleBalance(): boolean {
        return this.getPartnerType() === PARTNER_TYPE.singleBalance;
    }

    getLoginText(){
        if (this.storage.getItem('currentPartner')) {
            try {
                const currentPartner = JSON.parse(this.storage.getItem('currentPartner'));

                return currentPartner.login_text;
            }catch (e) {

            }
        }

        return '';
    }

    getName(){
        if (this.storage.getItem('currentPartner')) {
            try {
                const currentPartner = JSON.parse(this.storage.getItem('currentPartner'));

                return currentPartner.name;
            }catch (e) {

            }
        }

        return this.partners.default.name;
    }

    getLogo() {
        // if (!environment.production) {
        //     return '../../../assets/images/activate_yourself.png'
        // }

        if (this.storage.getItem('currentPartner')) {
            try {
                const currentPartner = JSON.parse(this.storage.getItem('currentPartner'));

                return currentPartner.logo;
            }catch (e) {
                return this.partners.default.logo;
            }
        }

        // return this.partners.default.logo;
    }

    isRiverPayProvider() {
        if (this.getShortName() === PARTNERS.riverpay.short_name) {
            return true;
        } else {
            return false;
        }
    }

    // isRiverPayProvider(val) {
    //     if (val === PARTNERS.riverpay.short_name) {
    //         this.isRiverPayPartner.next(true);
    //     } else {
    //         this.isRiverPayPartner.next(false);
    //     }
    // }

    isMascotProvider(){
        if(this.getShortName() === PARTNERS.mascot.short_name) {
            return true;
        } else {
            return false;
        }
    }

    // isMascotProvider(val) {
    //     if (val === PARTNERS.mascot.short_name) {
    //         this.isMascotPartner.next(true);
    //     } else {
    //         this.isMascotPartner.next(false);
    //     }
    // }

    isRiverPayTerminalProvider(val) {
        if (val === PARTNERS.riverpay.short_name) {
            this.isRiverPayTerminalPartner.next(true);
        } else {
            this.isRiverPayTerminalPartner.next(false);
        }
    }

    isMascotTerminalProvider(val) {
        if (val === PARTNERS.mascot.short_name) {
            this.isMascotTerminalPartner.next(true);
        } else {
            this.isMascotTerminalPartner.next(false);
        }
    }

    isMascotStationTerminalProvider(val) {
        if (val === TERMINAL_PARTNERS.stationMascot.shortName) {
            this.isMascotStationTerminalPartner.next(true);
        } else {
            this.isMascotStationTerminalPartner.next(false);
        }
    }

    isRiverPayTerminal(){
        if(this.isApp && this.storageGetTerminalShortName() === PARTNERS.riverpay.short_name){
            return true;
        }else{
            return false;
        }
    }

    isMascotSingleTerminal(){
        if(this.isApp && this.storageGetTerminalShortName() === TERMINAL_PARTNERS.stationMascot.shortName){
            return true;
        }else{
            return false;
        }
    }

    isMascotSingle(){
        if(this.storageGetTemplate() == PROVIDERS_TEMPLATES.mascotSingle){
            return true;
        }

        return false;
    }

    changeThumbnailIcon(selector, icon, partner) {
        const defaultIcon = document.querySelector(selector).getAttribute('href');
        const newIcon = defaultIcon.split('/icons')[0] + `/partners/${partner}/icons/${icon}`;
        document.querySelector(selector).setAttribute('href', newIcon);
    }

    changeThumbnailIcons(partner) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', partner);
    }
}
