import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TERMINAL_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class KioskPaymentService extends SettingsLoaderService{
    private config = TERMINAL_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    create(amount:number, typeShortName) {
        return this.http.post<any>(this.config.createPayment, {amount, typeShortName});
    }

    complete() {
        return this.http.post<any>(this.config.complete, {});
    }

    getBalance() {
        this.showLoader();
        return this.http.post<any>(this.config.balance, {});
    }

    getCrypto() {
        return this.http.post<any>(this.config.getCrypto, {});
    }

    buyCrypto(currencyIdTo) {
        this.showLoader();
        return this.http.post<any>(this.config.buyCrypto, {currencyIdTo});
    }

    buySweeps() {
        this.showLoader();
        return this.http.post<any>(this.config.buySweeps, {});
    }
}
