<div class="page-wrapper">
<!--    <div class="page-wrapper__left" [class.child&#45;&#45;blured]="menu">-->
<!--        <app-hero-v2></app-hero-v2>-->
<!--    </div>-->
    <div class="page-wrapper__right">
        <div class="child" [class.child--blured]="menu">
            <router-outlet></router-outlet>
        </div>

        <div class="menu" [class.is-disabled]="disableMenu()" [ngStyle]="{'border-color': getActiveColor()}">
            <a id="menu_rewards" routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/rewards']">
                <svg width="35" height="30">
                    <use xlink:href="../../../../assets/images/sprite.svg#home"></use>
                </svg>
            </a>
            <a id="menu_prizes" routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/prizes']">
                <svg width="29" height="33">
                    <use xlink:href="../../../../assets/images/sprite.svg#prizes"></use>
                </svg>
            </a>
            <a id="menu_settings" routerLinkActive="is-active" class="menu__link" [routerLink]="['/home/settings']">
                <svg width="30" height="30">
                    <use xlink:href="../../../../assets/images/sprite.svg#settings"></use>
                </svg>
            </a>
            <a id="menu_investments" routerLinkActive="is-active" class="menu__link" [routerLink]="getInvestmentURL()">

<!--                <svg *ngIf="getPartner() === 'snekapek'" width="41" height="48">-->
<!--                    <use xlink:href="../../../../assets/images/sprite.svg#sounds"></use>-->
<!--                </svg>-->

                <svg width="34" height="30">
                    <use xlink:href="../../../../assets/images/sprite.svg#crypto-icon"></use>
                </svg>

            </a>

            <button id="show_menu_btn" (click)="menu = true" class="menu__btn">
                <svg width="29" height="7">
                    <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                </svg>
            </button>
        </div>
        <div class="addition-menu" [class.is-active]="menu">
            <ul class="addition-menu__list">
                <li (click)="goToTermsAndConditions()" class="addition-menu__item">Terms and Conditions</li>
                <li *ngIf="!isApp" (click)="upgradeApp()" class="addition-menu__item">Upgrade APP</li>
                <li *ngIf="needInstallOnAndroid()" (click)="installApp()" class="addition-menu__item">Install</li>
                <li id="logout_btn" (click)="logout()" class="addition-menu__item">Logout</li>
            </ul>
            <button (click)="menu = false" class="addition-menu__btn">Cancel</button>
        </div>

        <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
            <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
            <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
            <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
            <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
        </svg>
    </div>
</div>


