import {Component, OnInit} from '@angular/core';
import {PartnerService} from "../../service/partner.service";

@Component({
  selector: 'app-prizes-parent',
  templateUrl: './prizes-parent.component.html',
  styleUrls: ['./prizes-parent.component.scss']
})
export class PrizesParentComponent implements OnInit {
  constructor(private partnerService: PartnerService) {

  }

  ngOnInit(): void {

  }

  isPartnerTypeSingleBalance(): boolean {
    return this.partnerService.isPartnerTypeSingleBalance();
  }

}
