import { NgZone } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { PROJECT_CONFIG, PROVIDERS_TEMPLATES } from "../config/project";
import { PARTNERS_CONFIG } from "../config/api";
import { PARTNERS } from "../config/partners_settings";
import { BehaviorSubject } from "rxjs";
import { TERMINAL_RESPONSE_KEYS } from "../config/terminal/response_keys.config";
import { TERMINAL_PARTNERS } from "../config/terminal/terminal_partners.config";
import { PARTNER_TYPE } from "../pages/partners/partner.config";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./loader.service";
import * as i3 from "./terminal/terminal.service";
import * as i4 from "./kiosk.service";
import * as i5 from "@angular/common/http";
import * as i6 from "./testing/frontendTestSettings.service";
var PartnerService = /** @class */ (function () {
    function PartnerService(ngZone, route, loaderService, terminalService, kioskService, http, frontendTestSettingsService) {
        this.ngZone = ngZone;
        this.route = route;
        this.loaderService = loaderService;
        this.terminalService = terminalService;
        this.kioskService = kioskService;
        this.http = http;
        this.frontendTestSettingsService = frontendTestSettingsService;
        this.config = PARTNERS_CONFIG;
        this.partners = PARTNERS;
        this.currentPartner = '';
        this.currentTerminalPartner = '';
        this.storage = sessionStorage;
        this.currentPartnerShortName = new BehaviorSubject('');
        this.currentPartnerTemplate = new BehaviorSubject('');
        this.isRiverPayPartner = new BehaviorSubject(false);
        this.isMascotPartner = new BehaviorSubject(false);
        // TERMINAL
        this.terminalPartnerShortName = new BehaviorSubject('');
        this.isRiverPayTerminalPartner = new BehaviorSubject(false);
        this.isMascotTerminalPartner = new BehaviorSubject(false);
        this.isMascotStationTerminalPartner = new BehaviorSubject(false);
        this.isApp = this.kioskService.isKiosk();
        //
        // if (this.isApp) {
        //     this.terminalPartnerShortName.subscribe(val => {
        //         this.isRiverPayTerminalProvider(val);
        //         this.isMascotTerminalProvider(val);
        //         this.isMascotStationTerminalProvider(val);
        //     });
        //     this.isRiverPayTerminalProvider(this.getShortName());
        //     this.isMascotTerminalProvider(this.getShortName());
        //     this.isMascotStationTerminalProvider(this.getShortName());
        // }
        //
        //
        // this.currentPartnerShortName.subscribe(val => {
        //     this.isRiverPayProvider(val);
        //     this.isMascotProvider(val);
        // });
        // this.isRiverPayProvider(this.getShortName());
        // this.isMascotProvider(this.getShortName());
    }
    PartnerService.prototype.getPartner = function () {
        var domain = window.location.hostname;
        return this.http.post(this.config.name, { domain: domain });
    };
    PartnerService.prototype.savePartner = function (data) {
        if (this.frontendTestSettingsService.isPartnerTypeDefault()) {
            data.result.type = 0;
            console.log('d');
        }
        if (this.frontendTestSettingsService.isPartnerTypeSingle()) {
            data.result.type = 1;
            console.log('d2');
        }
        if (this.frontendTestSettingsService.isMascotSinglePage()) {
            data.result.short_name = 'mascot';
            data.result.template = 'mascotSingle';
        }
        if (this.frontendTestSettingsService.isTerminalStationMascot()) {
            data.result.terminalShortName = 'stationMascot';
        }
        if (this.frontendTestSettingsService.isNoTerminal()) {
            data.result = [];
        }
        if (this.frontendTestSettingsService.isPartnerAdditionInfo()) {
            data.result.businessName = 'Oksana Test';
            data.result.domain = 'http://google.com';
            data.result.logo_background = './assets/partners/snekapek/icons/icon-144.png';
        }
        if (data.result) {
            this.storageSetData(data.result);
            this.storageSetShortName('');
            if (data.result.short_name) {
                this.storageSetShortName(data.result.short_name);
            }
            this.storageSetTemplate('');
            if (data.result.template) {
                this.storageSetTemplate(data.result.template);
            }
            this.storageSetTerminalShortName('');
            if (data.result.terminalShortName) {
                this.storageSetTerminalShortName(data.result.terminalShortName);
            }
            this.storageSetCurrentPartnerBusinessName('');
            if (data.result.businessName) {
                this.storageSetCurrentPartnerBusinessName(data.result.businessName);
            }
            this.storageSetCurrentPartnerDomain('');
            if (data.result.domain) {
                this.storageSetCurrentPartnerDomain(data.result.domain);
            }
            this.storageSetCurrentPartnerLogoBackground('');
            if (data.result.logo_background) {
                this.storageSetCurrentPartnerLogoBackground(data.result.logo_background);
            }
            this.storageSetCashapTag('');
            if (data.result.cashapp_tag) {
                this.storageSetCashapTag(data.result.cashapp_tag);
            }
            this.storageSetPartnerType('');
            if (data.result.type || data.result.type === 0) {
                this.storageSetPartnerType(data.result.type);
            }
            this.setTerminalInfo(data.result);
            if (this.getShortName() === PARTNERS.senet.short_name || this.getShortName() === PARTNERS.senetLabel.short_name) {
                this.changeThumbnailIcons(PARTNERS.senet.short_name);
                document.querySelector('title').innerHTML = this.getBusinessName();
            }
        }
    };
    PartnerService.prototype.storageSetCurrentPartnerBusinessName = function (data) {
        this.storage.setItem('currentPartnerBusinessName', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerDomain = function (data) {
        this.storage.setItem('currentPartnerDomain', data);
    };
    PartnerService.prototype.storageSetCurrentPartnerLogoBackground = function (data) {
        this.storage.setItem('currentPartnerLogoBackground', data);
    };
    PartnerService.prototype.storageSetTerminalShortName = function (data) {
        this.storage.setItem('currentPartnerTerminalShortName', data);
    };
    PartnerService.prototype.storageSetData = function (data) {
        this.storage.setItem('currentPartner', JSON.stringify(data));
    };
    PartnerService.prototype.storageSetTemplate = function (data) {
        this.storage.setItem('currentPartnerTemplate', data);
    };
    PartnerService.prototype.storageSetShortName = function (data) {
        this.storage.setItem('currentPartnerShortName', data);
    };
    PartnerService.prototype.storageSetCashapTag = function (data) {
        this.storage.setItem('currentPartnerCashappTag', data);
    };
    PartnerService.prototype.storageSetPartnerType = function (data) {
        this.storage.setItem('currentPartnerType', data);
    };
    PartnerService.prototype.storageGetTemplate = function () {
        return this.storage.getItem('currentPartnerTemplate');
    };
    PartnerService.prototype.storageGetTerminalShortName = function () {
        return this.storage.getItem('currentPartnerTerminalShortName');
    };
    PartnerService.prototype.storageGetCashapTag = function () {
        return this.storage.getItem('currentPartnerCashappTag');
    };
    PartnerService.prototype.storageGetPartnerLogo = function () {
        if (this.storage.getItem('currentPartnerLogoBackground')) {
            return this.storage.getItem('currentPartnerLogoBackground');
        }
        else {
            return '';
        }
    };
    PartnerService.prototype.storageGetCurrentPartner = function () {
        if (this.storage.getItem('currentPartner')) {
            if (this.storage.getItem('currentPartner') != '') {
                return this.storage.getItem('currentPartner');
            }
        }
        return false;
    };
    PartnerService.prototype.detectPartner = function () {
        var _this = this;
        this.resetPartner();
        var domain = window.location.hostname;
        var defaultDomain = PROJECT_CONFIG.defaultDomain;
        this.currentPartner = 'default';
        this.resetTerminalInfo();
        //if (domain !== defaultDomain) {
        this.loaderService.show();
        this.http.post(this.config.name, { domain: domain })
            .subscribe(function (data) {
            _this.loaderService.hide();
            if (data.status) {
                // data.result.logo = '../../../assets/images/activate_yourself.png'
                // data.result.short_name = 'mascot';
                // data.result.template = 'mascotSingle';
                // data.result.name = 'riverpay';
                // this.storage.setItem('currentPartnerShortName', 'snekapek');
                // data.result[TERMINAL_RESPONSE_KEYS.shortName] = 'stationMascot';
                _this.storage.setItem('currentPartnerShortName', data.result.short_name);
                _this.storage.setItem('currentPartner', JSON.stringify(data.result));
                _this.storage.setItem('currentPartnerTemplate', data.result.template);
                _this.currentPartnerShortName.next(data.result.short_name);
                _this.currentPartnerTemplate.next(data.result.template);
                _this.terminalPartnerShortName.next(data.result[TERMINAL_RESPONSE_KEYS.shortName]);
                console.log("RouterPaths.mascot.main setTerminalInfo");
                _this.setTerminalInfo(data.result);
                // data.result.terminalAnimate = 'v2';
                // this.screenSaverService.animation.next(data.result.terminalAnimate);
            }
            else {
            }
        });
        //}
    };
    PartnerService.prototype.resetPartner = function () {
        this.storage.removeItem('currentPartner');
        this.storage.removeItem('currentPartnerType');
    };
    PartnerService.prototype.resetTerminalInfo = function () {
        this.storage.removeItem("terminalInfo");
        // this.terminalService.terminalInfoUpdating.next(Date.parse(new Date().toISOString()));
    };
    PartnerService.prototype.setTerminalInfo = function (result) {
        var _a;
        if (this.frontendTestSettingsService.isTerminalExist()) {
            result[TERMINAL_RESPONSE_KEYS.title] = 'Test';
            result[TERMINAL_RESPONSE_KEYS.description] = 'Test';
            if (this.frontendTestSettingsService.isTerminalStationMascot()) {
                result[TERMINAL_RESPONSE_KEYS.shortName] = TERMINAL_PARTNERS.stationMascot.shortName;
            }
            else {
                result[TERMINAL_RESPONSE_KEYS.shortName] = TERMINAL_PARTNERS.riverpay.shortName;
            }
            result[TERMINAL_RESPONSE_KEYS.logo] = 'http://192.168.0.101:8888/ebox-api/images/partners/activate_yourself.png';
            result[TERMINAL_RESPONSE_KEYS.isCrypto] = 0;
            result[TERMINAL_RESPONSE_KEYS.isGames] = 0;
            if (this.frontendTestSettingsService.isTerminalDisplayGames()) {
                result[TERMINAL_RESPONSE_KEYS.isGames] = 1;
            }
            if (this.frontendTestSettingsService.isTerminalDisplayCrypto()) {
                result[TERMINAL_RESPONSE_KEYS.isCrypto] = 1;
            }
        }
        var terminalInfo = (_a = {},
            _a[TERMINAL_RESPONSE_KEYS.title] = result[TERMINAL_RESPONSE_KEYS.title],
            _a[TERMINAL_RESPONSE_KEYS.description] = result[TERMINAL_RESPONSE_KEYS.description],
            _a[TERMINAL_RESPONSE_KEYS.logo] = result[TERMINAL_RESPONSE_KEYS.logo],
            _a[TERMINAL_RESPONSE_KEYS.shortName] = result[TERMINAL_RESPONSE_KEYS.shortName],
            _a[TERMINAL_RESPONSE_KEYS.isCrypto] = result[TERMINAL_RESPONSE_KEYS.isCrypto],
            _a[TERMINAL_RESPONSE_KEYS.isGames] = result[TERMINAL_RESPONSE_KEYS.isGames],
            _a);
        this.storage.setItem("terminalInfo", JSON.stringify(terminalInfo));
        //this.terminalService.terminalInfoUpdating.next(Date.parse(new Date().toISOString()));
    };
    PartnerService.prototype.getShortName = function () {
        if (this.storage.getItem('currentPartnerShortName')) {
            return this.storage.getItem('currentPartnerShortName');
        }
        return 'default';
    };
    PartnerService.prototype.getBusinessName = function () {
        if (this.storage.getItem('currentPartnerBusinessName')) {
            return this.storage.getItem('currentPartnerBusinessName');
        }
        return '';
    };
    PartnerService.prototype.getPartnerDomain = function () {
        if (this.storage.getItem('currentPartnerDomain')) {
            return this.storage.getItem('currentPartnerDomain');
        }
        return '';
    };
    PartnerService.prototype.getPartnerType = function () {
        if (this.storage.getItem('currentPartnerType')) {
            return this.storage.getItem('currentPartnerType');
        }
        return '';
    };
    PartnerService.prototype.isPartnerTypeSingleBalance = function () {
        return this.getPartnerType() === PARTNER_TYPE.singleBalance;
    };
    PartnerService.prototype.getLoginText = function () {
        if (this.storage.getItem('currentPartner')) {
            try {
                var currentPartner = JSON.parse(this.storage.getItem('currentPartner'));
                return currentPartner.login_text;
            }
            catch (e) {
            }
        }
        return '';
    };
    PartnerService.prototype.getName = function () {
        if (this.storage.getItem('currentPartner')) {
            try {
                var currentPartner = JSON.parse(this.storage.getItem('currentPartner'));
                return currentPartner.name;
            }
            catch (e) {
            }
        }
        return this.partners.default.name;
    };
    PartnerService.prototype.getLogo = function () {
        // if (!environment.production) {
        //     return '../../../assets/images/activate_yourself.png'
        // }
        if (this.storage.getItem('currentPartner')) {
            try {
                var currentPartner = JSON.parse(this.storage.getItem('currentPartner'));
                return currentPartner.logo;
            }
            catch (e) {
                return this.partners.default.logo;
            }
        }
        // return this.partners.default.logo;
    };
    PartnerService.prototype.isRiverPayProvider = function () {
        if (this.getShortName() === PARTNERS.riverpay.short_name) {
            return true;
        }
        else {
            return false;
        }
    };
    // isRiverPayProvider(val) {
    //     if (val === PARTNERS.riverpay.short_name) {
    //         this.isRiverPayPartner.next(true);
    //     } else {
    //         this.isRiverPayPartner.next(false);
    //     }
    // }
    PartnerService.prototype.isMascotProvider = function () {
        if (this.getShortName() === PARTNERS.mascot.short_name) {
            return true;
        }
        else {
            return false;
        }
    };
    // isMascotProvider(val) {
    //     if (val === PARTNERS.mascot.short_name) {
    //         this.isMascotPartner.next(true);
    //     } else {
    //         this.isMascotPartner.next(false);
    //     }
    // }
    PartnerService.prototype.isRiverPayTerminalProvider = function (val) {
        if (val === PARTNERS.riverpay.short_name) {
            this.isRiverPayTerminalPartner.next(true);
        }
        else {
            this.isRiverPayTerminalPartner.next(false);
        }
    };
    PartnerService.prototype.isMascotTerminalProvider = function (val) {
        if (val === PARTNERS.mascot.short_name) {
            this.isMascotTerminalPartner.next(true);
        }
        else {
            this.isMascotTerminalPartner.next(false);
        }
    };
    PartnerService.prototype.isMascotStationTerminalProvider = function (val) {
        if (val === TERMINAL_PARTNERS.stationMascot.shortName) {
            this.isMascotStationTerminalPartner.next(true);
        }
        else {
            this.isMascotStationTerminalPartner.next(false);
        }
    };
    PartnerService.prototype.isRiverPayTerminal = function () {
        if (this.isApp && this.storageGetTerminalShortName() === PARTNERS.riverpay.short_name) {
            return true;
        }
        else {
            return false;
        }
    };
    PartnerService.prototype.isMascotSingleTerminal = function () {
        if (this.isApp && this.storageGetTerminalShortName() === TERMINAL_PARTNERS.stationMascot.shortName) {
            return true;
        }
        else {
            return false;
        }
    };
    PartnerService.prototype.isMascotSingle = function () {
        if (this.storageGetTemplate() == PROVIDERS_TEMPLATES.mascotSingle) {
            return true;
        }
        return false;
    };
    PartnerService.prototype.changeThumbnailIcon = function (selector, icon, partner) {
        var defaultIcon = document.querySelector(selector).getAttribute('href');
        var newIcon = defaultIcon.split('/icons')[0] + ("/partners/" + partner + "/icons/" + icon);
        document.querySelector(selector).setAttribute('href', newIcon);
    };
    PartnerService.prototype.changeThumbnailIcons = function (partner) {
        this.changeThumbnailIcon('link[rel="icon"]', 'icon-72.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="180x180"]', 'icon-180.png', partner);
        this.changeThumbnailIcon('link[rel="apple-touch-icon"][sizes="120x120"]', 'icon-120.png', partner);
    };
    PartnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerService_Factory() { return new PartnerService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.TerminalService), i0.ɵɵinject(i4.KioskService), i0.ɵɵinject(i5.HttpClient), i0.ɵɵinject(i6.FrontendTestSettingsService)); }, token: PartnerService, providedIn: "root" });
    return PartnerService;
}());
export { PartnerService };
