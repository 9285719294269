/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./games-payment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../investments/investments-nft-popup/investments-nft-popup.component.ngfactory";
import * as i6 from "../../investments/investments-nft-popup/investments-nft-popup.component";
import * as i7 from "../../../service/investments/investments.service";
import * as i8 from "../../../service/cryptoTransactions.service";
import * as i9 from "../../../service/loader.service";
import * as i10 from "../../../service/fitTextService.service";
import * as i11 from "../../../service/nft/nft.service";
import * as i12 from "../../../service/cashapp-pay.service";
import * as i13 from "../../../service/partner.service";
import * as i14 from "../../../shared/popup/popup.component";
import * as i15 from "../../../shared/iframe-popup/iframe-popup.component.ngfactory";
import * as i16 from "../../../shared/iframe-popup/iframe-popup.component";
import * as i17 from "@angular/platform-browser";
import * as i18 from "../../../service/transactions/transactions.service";
import * as i19 from "./games-payment.component";
import * as i20 from "../../../service/games/scratchOff.service";
import * as i21 from "../../../service/scrollTo.service";
import * as i22 from "../../../service/settings/funds.service";
var styles_GamesPaymentComponent = [i0.styles];
var RenderType_GamesPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GamesPaymentComponent, data: {} });
export { RenderType_GamesPaymentComponent as RenderType_GamesPaymentComponent };
function View_GamesPaymentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "form-simple__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["autocomplete", "off"], ["class", "form-simple__radio"], ["formControlName", "paymentMethod"], ["name", "email"]], [[8, "id", 0], [8, "type", 0], [8, "value", 0], [1, "data-id", 0], [8, "checked", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (_co.changeMethod(_v.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var currVal_12 = "paymentMethod"; _ck(_v, 4, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("card-" + _co.getPaymentMethod(_v.context.$implicit)) + _co.getMethodId(_v.context.$implicit)); var currVal_1 = "radio"; var currVal_2 = _v.context.$implicit; var currVal_3 = _co.getMethodId(_v.context.$implicit); var currVal_4 = (_co.activeMethod === _v.context.$implicit); var currVal_5 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 6).ngClassValid; var currVal_10 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); var currVal_13 = (("card-" + _co.getPaymentMethod(_v.context.$implicit)) + _co.getMethodId(_v.context.$implicit)); _ck(_v, 7, 0, currVal_13); var currVal_14 = _co.getPaymentName(_v.context.$implicit); _ck(_v, 8, 0, currVal_14); }); }
function View_GamesPaymentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "games-payment mascot-popup"]], [[2, "is-active", null], [2, "is-blur", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "button", [["class", "mascot-popup__close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "button", [["class", "games-payment__btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.buyGame() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 17, "div", [["class", "games-payment__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "page__centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["class", "button snake-btn is-notouched"], ["id", "add_credit_card"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, [" ADD NEW CARD "])), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 10, "form", [["class", "form-simple form-simple--shrink form-simple--primary"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(12, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(14, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "saved"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "saved__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SAVED PAYMENT METHODS"])), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "saved__list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GamesPaymentComponent_2)), i1.ɵdid(20, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.getAddLink(); _ck(_v, 6, 0, currVal_4); var currVal_12 = _co.buyForm; _ck(_v, 12, 0, currVal_12); var currVal_13 = _co.data.result; _ck(_v, 20, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPopup; var currVal_1 = _co.showNFTPopup; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 6).target; var currVal_3 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 14).ngClassUntouched; var currVal_6 = i1.ɵnov(_v, 14).ngClassTouched; var currVal_7 = i1.ɵnov(_v, 14).ngClassPristine; var currVal_8 = i1.ɵnov(_v, 14).ngClassDirty; var currVal_9 = i1.ɵnov(_v, 14).ngClassValid; var currVal_10 = i1.ɵnov(_v, 14).ngClassInvalid; var currVal_11 = i1.ɵnov(_v, 14).ngClassPending; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }); }
function View_GamesPaymentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investments-nft-popup", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.onPopupNFTClose($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_InvestmentsNftPopupComponent_0, i5.RenderType_InvestmentsNftPopupComponent)), i1.ɵdid(1, 638976, null, 0, i6.InvestmentsNftPopupComponent, [i7.InvestmentsService, i8.CryptoTransactionsService, i9.LoaderService, i10.FitTextService, i11.NFTService, i12.CashappPayService, i13.PartnerService, i14.PopupComponent], { popupData: [0, "popupData"], popupType: [1, "popupType"], isMascot: [2, "isMascot"] }, { close: "close" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.NFTPopupData.data; var currVal_1 = _co.NFTPopupData.type; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_GamesPaymentComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iframe-popup", [], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.hideIframePopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_IframePopupComponent_0, i15.RenderType_IframePopupComponent)), i1.ɵdid(1, 4308992, null, 0, i16.IframePopupComponent, [i17.DomSanitizer, i9.LoaderService, i14.PopupComponent, i18.TransactionsService], { url: [0, "url"], isMascot: [1, "isMascot"] }, { emitClose: "onClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iframePopup.url; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_GamesPaymentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GamesPaymentComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GamesPaymentComponent_3)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GamesPaymentComponent_4)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPopup; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showNFTPopup && _co.NFTPopupData); _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.iframePopup.show; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_GamesPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-games-payment", [], null, null, null, View_GamesPaymentComponent_0, RenderType_GamesPaymentComponent)), i1.ɵdid(1, 245760, null, 0, i19.GamesPaymentComponent, [i20.ScratchOffService, i2.FormBuilder, i21.ScrollToService, i14.PopupComponent, i22.FundsService, i9.LoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GamesPaymentComponentNgFactory = i1.ɵccf("app-games-payment", i19.GamesPaymentComponent, View_GamesPaymentComponent_Host_0, { data: "data", showPopup: "showPopup" }, { isPaymentSuccess: "isPaymentSuccess", closePopup: "closePopup" }, []);
export { GamesPaymentComponentNgFactory as GamesPaymentComponentNgFactory };
