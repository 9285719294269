import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "./settingsLoader.service";
import {LoaderService} from "./loader.service";
import {CRYPTO_TRANSACTIONS_CONFIG} from "../config/api";

@Injectable({
    providedIn: 'root'
})
export class CryptoTransactionsService extends SettingsLoaderService {
    private config = CRYPTO_TRANSACTIONS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    buyCryptoFromFounds(id, method, amount, cryptoId) {
        this.showLoader();
        return this.http.post<any>(this.config.founds, {id, method, amount, cryptoId});
    }

    sellCrypto(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.sellCrypto, {amount, id});
    }

    buyCrypto(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.buyCrypto, {amount, id});
    }

    confirmRedeemPayout(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.redeem, {amount, id});
    }

    confirmInvestmentSellCrypto(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.investmentSellCrypto, {amount, id});
    }

    confirmInvestmentBuyCrypto(amount, id) {
        this.showLoader();
        return this.http.post<any>(this.config.investmentBuyCrypto, {amount, id});
    }
}
