import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {PrizesSenetProService} from "../../../service/prizes/prizesSenetPro.service";
import {PrizesSenetproHistoryModel} from "../prizes-senetpro-history.model";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";

@Component({
    selector: 'app-popup-senetpro-transactions',
    templateUrl: './popup-senetpro-transactions.component.html',
    styleUrls: ['./popup-senetpro-transactions.component.scss']
})
export class PopupSenetproTransactionsComponent implements OnInit, OnChanges {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    prizesData: PaginationDataModel = new PaginationData('prizes', '.js-prizes-item', '.js-prizes-parent', 'history');

    constructor(private loaderService: LoaderService,
                private prizesSenetProService: PrizesSenetProService,
                private paginationScrollService: PaginationScrollService,
                private popupComponent: PopupComponent) {}

    ngOnInit() {
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            this.getPrizesInfo();
        } else {
            this.paginationScrollService.resetPagination(this.prizesData);
        }
    }

    getPrizesInfo() {
        this.prizesSenetProService.getPageInfo(1, true).subscribe(data => {
              this.setPrizesInfo(data);
          },
          () => { this.loaderService.hideAndShowTryAgain(); },
          () => { this.loaderService.hide(); });
    }

    setPrizesInfo(data: {history: [PrizesSenetproHistoryModel?], limit: number, total: number }): void {
        this.setTransactionsList(data.history, data.limit, data.total);
    }

    setTransactionsList(history: [PrizesSenetproHistoryModel?], limit: number, total: number): void {
        this.paginationScrollService.resetPagination(this.prizesData);
        document.querySelector('.js-prizes-parent').scrollTop = 0;
        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: history,
            scrollLimit: limit,
            total: total
        };
        this.initScrolledData(this.prizesData, prizesDataSettings);
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////
    // DETECTING SCROLL
    scroll(e, settings: PaginationDataModel) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this), true);
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page: number, step = 'end', settings: PaginationDataModel): void {
        if (settings.name ===  'prizes') {
            this.prizesSenetProService.getPageInfo(page, true).subscribe(data => {
                  this.paginationScrollService.dataAddingLogic(page, step, data, settings, true)
              },
              () => { this.loaderService.hideAndShowTryAgain() },
              () => { this.loaderService.hide() }
            );
        }
    }

    closePopup() {
        this.close.emit(true);
        // this.formNew.reset();
        // this.cards = [];
        // this.activeCard = null;
        // this.setCheckedCard(null);
    }

    // scrollToTopOfPopup(smooth = true) {
    //     setTimeout(() => {
    //         document.querySelector('#scrolled-wrapper').scroll({
    //             top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'
    //         });
    //     }, smooth ? 500 : 0);
    // }
}
