import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {InvestmentsService} from "../../../service/investments/investments.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FitTextService} from "../../../service/fitTextService.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {CryptoTransactionsService} from "../../../service/cryptoTransactions.service";

@Component({
    selector: 'app-investments-popup-confirm',
    templateUrl: './investments-popup-confirm.component.html',
    styleUrls: ['./investments-popup-confirm.component.scss']
})
export class InvestmentsPopupConfirmComponent implements OnInit {
    @Input() popupData;
    @Input() confirmType;
    @Input() currency = 'usd';

    @Output() close = new EventEmitter();

    needShowFullBtn = false;
    showFullAddress = false;

    constructor(private investmentsService: InvestmentsService,
                private cryptoTransactionsService: CryptoTransactionsService,
                private loaderService: LoaderService,
                private fitTextService: FitTextService,
                private popupComponent: PopupComponent,) {

    }


    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        setTimeout(() => {
            if (document.body.querySelectorAll('.js-check-address-size').length) {
                const elem = document.body.querySelector('.js-check-address-size');
                const elemHasScroll = elem.clientWidth < elem.scrollWidth;

                if (elemHasScroll) {
                    elem.classList.add('cut-overflow');
                    this.needShowFullBtn = true;
                } else {
                    elem.classList.remove('cut-overflow');
                    this.needShowFullBtn = false;
                }
            }
        });
    }

    closePopup(all?) {
        if (all) {
            this.close.emit('all');
        } else {
            this.close.emit();
        }

    }

    onConfirmCryptoSpend() {
        const amount = this.popupData.amount;
        const address = this.popupData.address;
        const note = this.popupData.note;
        const currencyId = this.popupData.currencyId;

        if (address && currencyId && amount) {
            this.investmentsService.spendCrypto(amount, address, currencyId, note).subscribe(data => {
                this.checkConfirm(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onConfirmTrade() {
        const from = this.popupData.currencyIdFrom;
        const to = this.popupData.currencyIdTo;

        const amount = this.popupData.amount;

        if (from && to && amount) {
            this.investmentsService.confirmTradeResult(from, to, amount).subscribe(data => {
                this.checkConfirm(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    onConfirmPrizes() {
        // const currencyId = this.popupData.currencyId;
        // const amount = this.popupData.amount;
        //
        // if (amount && currencyId) {
        //     if (this.isCryptoBuy()) {
        //         this.cryptoTransactionsService.confirmRedeemPayout(amount, currencyId).subscribe(data => {
        //             this.checkConfirm(data);
        //         }, (error) => {
        //             this.loaderService.hideAndShowTryAgain();
        //         }, () => {
        //             this.loaderService.hide();
        //         });
        //     } else {
        //         // this.prizesService.confirmCryptoSellPayout(amount, currencyId).subscribe(data => {
        //         //     this.checkConfirm(data);
        //         // }, (error) => {
        //         //     this.loaderService.hideAndShowTryAgain();
        //         // }, () => {
        //         //     this.loaderService.hide();
        //         // });
        //     }
        // } else {
        //     this.loaderService.hideAndShowTryAgain();
        // }
    }

    isCryptoBuy() {
        return this.currency === 'usd';
    }

    checkConfirm(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});

                this.closePopup('all');
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    hasScroll(addressText) {
        const elem = addressText;
        const elemHasScroll = elem.clientWidth < elem.scrollWidth;

        if (elemHasScroll) {
            return true
        }
        return false;
    }

    showMoreAddress(el) {
        const elemLeftScroll = el.scrollLeft;

        el.scrollIntoView({behavior: 'smooth'});
        el.scroll({
            left: elemLeftScroll + 300,
            behavior: 'smooth'
        });
    }
}
