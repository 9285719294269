import {HostListener, Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthenticationService} from "../modules/auth/_services/authentication.service";
import {KioskAccessorService} from "./kiosk/kiosk-accessor.service";
import {KEYBOARD_SETTINGS} from "../config/keyboard_settings";
import {PartnerService} from "./partner.service";

@Injectable({
    providedIn: 'root'
})
export class KeyboardService {
    inputs = document.querySelectorAll('input[type=text], input[type=tel], input[type=number]');
    activeInput;
    capsLock = new BehaviorSubject(false);
    capsLockReset = false;
    activityTimer = new BehaviorSubject(0);
    logoutTimer;
    wasLogout = new BehaviorSubject(false);

    keyboardVisible = new BehaviorSubject(false);
    isApp = +localStorage.getItem('isApp');
    isStationMascot: boolean;
    timer = 90;
    smallInputValueChanged = new BehaviorSubject({order: 0, value: null});


    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private kioskAccessorService: KioskAccessorService,
                private partnerService: PartnerService,
                private auth:AuthenticationService) {

        this.kioskAccessorService.needLogout.subscribe(value => {
            if (value) {
                this.logout();
                this.kioskAccessorService.needLogout.next(false);
            }
        });


        this.setTerminalSettings();
    }

    setTerminalSettings() {
        this.isStationMascot = this.partnerService.isMascotSingle();
        if(this.isStationMascot){
            clearInterval(this.logoutTimer);
        }else{
            if (this.auth.isUserLogged()) {
                this.setTimeout();
            }
        }


        // this.partnerService.terminalPartnerShortName.subscribe(val => {
        //     console.log("setTerminalSettings");
        //     alert(this.partnerService.isMascotStationTerminalPartner.value)
        //     if (val) {
        //
        //         if (this.partnerService.isMascotStationTerminalPartner.value) {
        //             this.isStationMascot = true;
        //             clearInterval(this.logoutTimer);
        //         } else {
        //             if (this.auth.isUserLogged()) {
        //                 this.setTimeout();
        //             }
        //         }
        //     }
        // });

        if(this.isApp){
            this.kioskAccessorService.accessorStatusChanged.subscribe(value => {
                this.restartTimer();
            });
        }
    }

    setTimeout() {
        console.log("test_test: 4");
        if(this.isApp && !this.partnerService.isMascotSingle()){
            clearInterval(this.logoutTimer);
            this.activityTimer.next(this.timer);
            this.logoutTimer = setInterval(() => {
                if (this.activityTimer.value) {
                    this.activityTimer.next(this.activityTimer.value - 1);
                } else {
                    console.log("setTimeout logout");

                    this.logout();
                }
            }, 1000);
        } else {
            clearInterval(this.logoutTimer);
        }
    }

    restartTimer() {
        this.activityTimer.next(this.timer);
    }

    logout() {
        clearInterval(this.logoutTimer);
        this.wasLogout.next(true);
        this.auth.logout();

        console.log("KeyboardService: logout");
        //this.router.navigate(['/']);
    }


    init() {
        setTimeout(() => {
            console.log("test_test: 5");
            this.setActiveInput();
        }, 1000)
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                setTimeout(() => {
                    console.log("test_test: 6");
                    this.setActiveInput();
                }, 1000)
            }
        });
    }

    setActiveInput() {
        this.inputs = document.querySelectorAll(KEYBOARD_SETTINGS.excludedInputs);

        if (this.inputs.length) {
            this.activeInput = (<HTMLInputElement>this.inputs[0]);

            this.inputs.forEach(el => {
                el.addEventListener('focus', () => {
                    this.activeInput = el;
                });

                el.scrollLeft = el.scrollWidth;
            });

            if (!this.wasLogout.value || this.partnerService.isMascotSingle()) {
                this.activeInput.focus();

                this.showKeyboard();
            }
        }
    }

    showKeyboard() {
        if (this.partnerService.getName()) {
            this.keyboardVisible.next(true);
        }
    }

    onInput(value) {
        let val = this.capsLock.value ? value.toUpperCase() : value.toLowerCase();
        let cursorPosition = this.activeInput.selectionStart ? this.activeInput.selectionStart : 0;
        this.activeInput.focus();

        if (this.capsLockReset && value !== 'up') {
            this.capsLock.next(false);
            this.capsLockReset = false;
        }

        if (value === 'up') {
            this.capsLock.next(!this.capsLock.value);
            this.capsLockReset = true;
            return
        }


        if (this.activeInput.classList.contains('login-input-small')) {
            let inputOrder = +(<HTMLInputElement>document.activeElement).getAttribute('data-order');
            const countAllInputs = document.querySelectorAll('.login-input-small').length - 1;

            if (value === 'cancel') {
                (<HTMLInputElement>document.activeElement).value = '';
                this.smallInputValueChanged.next({order: +inputOrder, value: null});
                if (+inputOrder !== 0) {
                    this.setPrevSmallInput(inputOrder);
                }

            } else if (Number(value) || value === "0") {
                if ((<HTMLInputElement>document.activeElement).value) {
                    if (+inputOrder !== countAllInputs) {
                        this.setNextSmallInput(inputOrder);
                        inputOrder += 1;
                    }
                }

                (<HTMLInputElement>document.activeElement).value = value;
                this.smallInputValueChanged.next({order: inputOrder, value: value});
                if (+inputOrder !== countAllInputs) {
                    this.setNextSmallInput(inputOrder);
                }

            }
            return;
        }

        if (value === 'cancel') {
            cursorPosition -= 1;
            let str = (<HTMLInputElement>document.activeElement).value;
            (<HTMLInputElement>document.activeElement).value = this.removeCharacter(str, cursorPosition);
            (<HTMLInputElement>document.activeElement).dispatchEvent(new Event('input'));
            // cursorPosition -= 1;

            this.activeInput.setSelectionRange(cursorPosition, cursorPosition);
            return
        }

        let oldValue = (<HTMLInputElement>document.activeElement).value;
        let newValue = oldValue.slice(0, cursorPosition) + val + oldValue.slice(cursorPosition);

        (<HTMLInputElement>document.activeElement).value = newValue;
        (<HTMLInputElement>document.activeElement).dispatchEvent(new Event('input', { bubbles: true }));

        let index = this.activeInput.value.indexOf(val, [cursorPosition]);

        if (index > -1) {
            cursorPosition = index+1;
        }

        let inputType = this.activeInput.getAttribute('type');

        if (inputType === 'email') {
            this.activeInput.setAttribute('type', 'text');
        }
        this.activeInput.setSelectionRange(cursorPosition, cursorPosition);


    }

    setPrevSmallInput(inputOrder) {
        let prevInput;
        this.inputs.forEach(el => {
            if (el.getAttribute('data-order') === String(+inputOrder - 1)) {
                prevInput = el;
            }
        });
        this.activeInput = prevInput;
        this.activeInput.focus();
    }

    setNextSmallInput(inputOrder) {
        let nextInput;
        this.inputs.forEach(el => {
            if (el.getAttribute('data-order') === String(+inputOrder + 1)) {
                nextInput = el;
            }
        });
        this.activeInput = nextInput;
        this.activeInput.focus();
    }

    onFocusChanged(el) {
        this.activeInput = el;
        this.activeInput.focus();
    }

    removeCharacter(str, char_pos) {
        const part1 = str.substring(0, char_pos);
        const part2 = str.substring(char_pos + 1, str.length);
        return (part1 + part2);
    }

}
