import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {PROJECT_MESSAGES} from "../../../../config/project";
import {RouterPaths} from "../../../../config/testing/router.paths.model";
import {StylingService} from "../../../../service/styling.service";

@Component({
    selector: 'app-new-email',
    templateUrl: './new-email.component.html',
    styleUrls: ['./new-email.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewEmailComponent implements OnInit {
    newEmailForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private prizesService: PrizesService,
                private loaderService: LoaderService,
                private router: Router,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newEmailForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]],
            save: [true]
        });
    }

    onSubmit() {
        const email = this.getInput('email');
        const save = this.getInput('save');
        const saveText = save ? ' and save it for future use' : '';

        if (email) {
            this.popupComponent.showPopupAgreement({text: `Add email ${email} to available emails${saveText}?`}, (status) => {
                if (status) {
                    this.submit();
                }
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    submit() {
        const email = this.getInput('email');
        const save = this.getInput('save');

        if (!email) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
            return;
        }

        if (save !== 'false' && save !== false) {
            this.prizesService.paypalAddEmail(email).subscribe(data => {
                this.submitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.prizesService.setTemporaryEmail(email);
            this.goBack();
        }
    }

    getInput(field) {
        if (this.newEmailForm.controls[field]) {
            return this.newEmailForm.controls[field].value;
        }
    }

    submitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.goBack();
        } else if(data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    goBack() {
        this.newEmailForm.controls.email.setValue('');
        this.newEmailForm.controls.save.setValue(true);

        this.router.navigate([RouterPaths.prizes.paypal]);
    }

    onSaveEmail(e) {
        const checked = e.target.checked;
        this.newEmailForm.controls.save.setValue(!!checked);
    }

    onInput($event) {
        this.newEmailForm.get('email').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

}
