import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {PaypalService} from "../../../../service/settings/paypal.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {StylingService} from "../../../../service/styling.service";

@Component({
    selector: 'app-add-account',
    templateUrl: './add-account.component.html',
    styleUrls: ['./add-account.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class AddAccountComponent implements OnInit {
    newAccountForm: FormGroup;
    constructor(private formBuilder: FormBuilder,
                private paypalService: PaypalService,
                private loaderService: LoaderService,
                private router: Router,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newAccountForm = this.formBuilder.group({
            email: new FormControl('', [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]),
        });
    }

    onSubmit() {
        const email = this.getAccount();

        if (email) {
            this.paypalService.addAccount(email).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkSubmitResult(data) {
        if (data.status === true) {
            this.popupComponent.showPopupSuccess({text:data.message});
            this.goBack();
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onInput($event) {
        this.newAccountForm.get('email').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    goBack() {
        this.newAccountForm.controls.email.setValue('');
        this.router.navigate([RouterPaths.settings.paypal.main]);
    }

    getAccount() {
        return this.newAccountForm.controls.email.value;
    }

    getBackLink() {
        return RouterPaths.settings.paypal.main;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
