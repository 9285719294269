import {DOMAIN_API} from "./local_settings";
import {
    DOMAIN_DEMO_NAME, DOMAIN_DEMO_API, DOMAIN_DEMO1_NAME,
    DOMAIN_DEMO2_NAME, DOMAIN_DEMO3_NAME,
    DOMAIN_DEMO4_NAME, DOMAIN_DEMO5_NAME,
    DOMAIN_DEMO6_NAME, DOMAIN_DEMO7_NAME,
    DOMAIN_DEMO8_NAME, DOMAIN_DEMO9_NAME,
    DOMAIN_INTERIM_NAME, DOMAIN_INTERIM_API,
    DOMAIN_INTERIM_NAME_MASCOT, DOMAIN_INTERIM_NAME_TERMINAL,
    DOMAIN_INTERIM_NAME_MASCOT1
} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {Stripe} from "../models/stripe";
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {Prizes} from "../models/prizes";
import {UserStatus} from "../models/user-status";
import {UserCard} from "../models/user-card";
import {Paypal} from "../models/paypal";
import {Addresses} from "../models/addresses";
import {Funds} from "../models/funds";
import {UserContact} from "../models/user-contact";
import {Partners} from "../models/partners";
import {AppVersion} from "../models/appVersion";
import {Point} from "../models/point";
import {Kiosk} from "../models/kiosk";
import {UserBalance} from "../models/user-balance";
import {CoinsTrade} from "../models/coins/coins-trade";
import {Lending} from "../models/lending";
import {Games} from "../models/games";
import {Terminal} from "../models/terminal";
import {GamesProvider} from "../models/gamesProvider";
import {Mascot} from "../models/mascot";
import {ScratchOffModel} from "../models/games/scratch-off.model";
import {SpinnerGameModel} from "../models/games/spinner-game.model";
import {NFTRequestsModel} from "../models/nft/nftRequests";
import {MassSms} from "../models/mass-sms";
import {YetiResponsesModel} from "../models/games/yeti-responses.model";
import {CommissionRequestModel} from "../models/commission/commissionRequestModel";
import {SupportModel} from "../models/support/support.model";
import {ReportsModel} from "../models/reports/reportsModel";
import {SlotsResponsesModel} from "../models/games/slots-responses.model";
import {PrizesSenetProRequestsModel} from "../models/prizes/prizesSenetProRequests.model";
import {ContactUsRequestsModel} from "../models/contact-us/contact-us_requests.model";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_INTERIM_NAME || window.location.hostname == DOMAIN_INTERIM_NAME_MASCOT ||
    window.location.hostname == DOMAIN_INTERIM_NAME_TERMINAL || window.location.hostname == DOMAIN_INTERIM_NAME_MASCOT1){
    domainApiUrl = DOMAIN_INTERIM_API;
}

let API = domainApiUrl + '/client';
let TERMINAL = domainApiUrl + '/terminal';
let GAMES_LINK = domainApiUrl + '/games';
let ADS = domainApiUrl + '/ads';
let pxLink = domainApiUrl + '/images/px.jpg';
if(window.location.hostname == DOMAIN_DEMO_NAME || window.location.hostname == DOMAIN_DEMO1_NAME ||
    window.location.hostname == DOMAIN_DEMO2_NAME || window.location.hostname == DOMAIN_DEMO3_NAME ||
    window.location.hostname == DOMAIN_DEMO4_NAME ||window.location.hostname == DOMAIN_DEMO5_NAME ||
    window.location.hostname == DOMAIN_DEMO6_NAME ||window.location.hostname == DOMAIN_DEMO7_NAME ||
    window.location.hostname == DOMAIN_DEMO8_NAME ||window.location.hostname == DOMAIN_DEMO9_NAME){
    API = DOMAIN_DEMO_API + '/client';
    TERMINAL = DOMAIN_DEMO_API + '/terminal';
    GAMES_LINK = DOMAIN_DEMO_API + '/games';
    ADS = DOMAIN_DEMO_API + '/ads';

    pxLink = DOMAIN_DEMO_API + '/images/px.jpg';
}


export const NETWORK_PX = pxLink;


const AUTH = API + '/users/session';
const USERS = API + '/users';
const GAMES = API + '/games';
const GAMES_MASCOT = GAMES + '/mascot';
const GAMES_SCRATCH_OFF = GAMES_LINK + '/scratchoff';
const GAMES_WHEEL = GAMES_LINK + '/wheel';
const GAMES_EBOX = GAMES_LINK + '/ebox';
const GAMES_YETI = GAMES_EBOX + '/yeti';

const NFT = API + '/nft';

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: AUTH + '/login',
    logout: AUTH + '/logout',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    checkPhoneAndSendPin: USERS + '/phone/check',
    newCustomer: USERS + '/phone/newCustomer',
    newCustomerVirtual: USERS + '/phone/newCustomerVirtual',
    findByAccountId: USERS + '/user/findByAccountId',
    registerTerminal: API + '/terminal/register',
};

export const POINT_CONFIG: Point = {
    findByHashAndPartnerShortName: USERS + '/app/point/findByHash'
};

export const PRIZES_CONFIG: Prizes = {
    data: API + '/balance/history',
    paypalEmails: API+'/paypal/emails',
    checkAddresses: API+'/check/address',
    mailCheckCreatePayout: API+'/balance/payout/check',
    paypalCreatePayout: API+'/balance/payout/paypal',
    checkAddAddress: API+'/check/address/add',
    paypalAddEmail: API+'/paypal/emails/add',

    usdcBalance: API+'/balance/prizes/available',
    usdcCreatePayout: API+'/transfers/games/usdc',

    cryptoList: API + '/payout/crypto/currencies',

    redeemViaCashApp: API + '/balance/payout/cashapp',
    redeemViaVenmo: API + '/balance/payout/shortname',
    redeemViaDebitCard: API + '/balance/payout/debitcard',
    redeemViaZelle: API + '/balance/payout/zelle',
    cashAppBalance: API + '/cashapp/payout',
    venmoBalance: API + '/venmo/payout',

    gamesBalance: API + '/games/payout',
    redeemViaGames: API + '/games/payout/create',
    redeemViaVenmoTag: API + '/balance/payout/venmo',
    getDebitCards: API + '/payout/debitcard',
    changeActiveCard: API + '/payout/debitcard/savedefault',
    addDebitCard: API + '/payout/debitcard/create',
    removeDebitCard: API + '/payout/debitcard/delete',
    addDebitCardAdditionInfo: API + '/payout/legalinfo/save',
    getDebitCardAdditionInfo: API + '/payout/legalinfo',

    redeemViaVisaGiftCard: API + '/balance/payout/visa',
    redeemViaAmazonGiftCard: API + '/balance/payout/amazon',
    getMobileTopupOperatorsList: API + '/balance/payout/giftCardAmazon',
    getGiftCardInfo: API + '/payout/giftcard',

    // redeemViaVenmo: API + '/jsons/find/file?name=success',
    // redeemViaCashApp: API + '/jsons/find/file?name=success',
    // cashAppBalance: API + '/jsons/find/file?name=cashAppBalance'
    // gamesBalance: API + '/jsons/find/file?name=prizesGamesBalance'
    // data: API + '/jsons/find/file?name=prizesHistory',
    // redeemViaDebitCard: API + '/jsons/find/file?name=success',
    // getDebitCards: API + '/jsons/find/file?name=getDebitCards',
    // changeActiveCard: API + '/jsons/find/file?name=success',
    // addDebitCard: API + '/jsons/find/file?name=addDebitCard',
    // addDebitCardAdditionInfo: API + '/jsons/find/file?name=success',
    // getDebitCardAdditionInfo: API + '/jsons/find/file?name=getDebitCardAdditionInfo',
    // redeemViaVisaGiftCard: API + '/jsons/find/file?name=success',
    // redeemViaAmazonGiftCard: API + '/jsons/find/file?name=error',
    // getMobileTopupOperatorsList: API + '/jsons/find/file?name=getMobileOperators',
    // getGiftCardInfo: API + '/jsons/find/file?name=getGiftCardInfo'
    // removeDebitCard: API + '/jsons/find/file?name=success',
    // redeemViaZelle: API + '/jsons/find/file?name=success',
};

export const USER_CONTACT_CONFIG: UserContact = {
    info: API+'/users/user/detail',
    save: API+'/users/contact/save',
    create: API+'/users/contact/create'
}

export const USER_CONFIG: UserStatus = {
    status: API+'/users/user/detail',
    freeze: API+'/users/user/freeze',
    activate: API+'/users/user/activate',
    delete: API+'/users/user/delete',
    settings: API+ '/users/settings'
    // settings: API + '/jsons/find/file?name=settings',
}

export const USER_CARD_CONFIG: UserCard = {
    cards: API+'/cards/list',
    card: API+'/cards/card',
    delete: API+'/cards/card/delete',
    verify: API+'/cards/card/verify',
    add: API+'/cards/card/add',
    addCashAppCard: API+'/cashapp/card/add',
    addPrepaidCard: API+'/prepaid/card/add',
    save: API+'/cards/card/save',
    checkVerify: API+'/cards/card/checkVerify',
    resendCode: API+'/cards/card/sendCode',
    states: API + '/cards/card/notification',


    // states: API + '/jsons/find/file?name=statesList',
}

export const PAYPAL_CONFIG: Paypal = {
    accounts: API+'/paypal/emails/lists',
    delete: API+'/paypal/emails/delete',
    add: API+'/paypal/emails/add',
    paymentSuccess: API+'/payments/paypal/paymentSuccess',
    paymentUnsuccess: API+'/payments/paypal/paymentFail',
    edit: API+'/paypal/emails/edit',
    find: API+'/paypal/emails/find',
}

export const ADDRESSES_CONFIG: Addresses = {
    addresses: API+'/check/address',
    list: API+'/check/address/lists',
    delete: API+'/check/address/delete',
    add: API+'/check/address/add',
    edit: API+'/check/address/edit',
    find: API+'/check/address/find',
}

export const FUNDS_CONFIG: Funds = {
    payments: API+'/payments/method/available',
    add: API+'/payments/create',
    getDepositInfo: API + '/payments/nft/card',
    getCashAppInfo: API + '/payments/nft/cashapp',

    // getDepositInfo: API + '/jsons/find/file?name=getDepositInfo',
    // getCashAppInfo: API + '/jsons/find/file?name=getCashAppPayInfo',
}

export const NEW_USER_CONFIG: NewUser = {
    save: API + '/users/user/createCustomer',
};

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: API + '/users/users',
    userInfo: API + '/users/user/paymentHistory',
    sell: API + '/users/user/deposit',
    freeze: API + '/users/user/freeze',
    delete: API + '/users/user/delete',
};

export const PARTNERS_CONFIG: Partners = {
    name: API+'/partners/partner',
};

export const APP_VERSION_CONFIG: AppVersion = {
    version: API+'/app/version',
};

export const STRIPE_CONFIG: Stripe = {
    getPublishableKey: API+'/app/stripe/public',
};

export const KIOSK_CONFIG: Kiosk = {
    sweepsSendLink: API+'/kiosk/sweeps/sendLink',
};

export const USER_BALANCE_CONFIG: UserBalance = {
    balance: API + '/users/user/balance',
    increaseBalance: API + '/games/game/deposit',
}

export const COINS_TRADE_CONFIG: CoinsTrade = {
    info: API + '/client/cryptocurrency/markets'
    // info: API + '/jsons/find/file?name=coinsTrade',
}

export const LENDING_CONFIG: Lending = {
    info: API+'/lending/info',
    usdcList: API+'/lending/usdc',
    usdList: API+'/lending/usd',
    cryptoList: API+'/lending/crypto',
    cryptoCart: API+'/lending/crypto/cart',
    cryptoSpend: API+'/lending/crypto/spend',

    // tradingList: API + '/lending/trading', // OLD with Pairs
    tradingList: API + '/lending/trading/available',
    tradingCart: API + '/lending/trading/trade',
    tradingGetInfo: API + '/lending/trading/cart',
    tradingConfirm: API + '/lending/trading/exchange',

    investmentList: API + '/lending/trading/investment',
}

export const GAMES_CONFIG: Games = {
    getGameUrl: API + '/games/game',
    buyGame: API + '/jsons/find/file?name=success',
}

export const GAMES_PROVIDER_CONFIG: GamesProvider = {
    chooseGame: API + '/games/provider/connect',
    gamesList: API + '/games/providers/lists',
    connectRiver: API + '/games/provider/connectRiver',
    getSenetSession: GAMES_LINK + '/senet/session/create',

    // connectRiver: API + '/jsons/find/file?name=connectRiverPay',
    // gamesList: API + '/jsons/find/file?name=gamesSelection',
    // chooseGame: API + '/jsons/find/file?name=connectRiverPay',
    // getSenetSession: API + '/jsons/find/file?name=getSenetSession',
}

export const TERMINAL_CONFIG: Terminal = {
    createPayment: TERMINAL + '/billacceptor/payment/create',
    complete: TERMINAL + '/billacceptor/payment/complete',
    balance: TERMINAL + '/billacceptor/balance',
    // balance: API + '/jsons/find/file?name=balance',
    getCrypto: TERMINAL + '/crypto/currency',
    // getCrypto: API + '/jsons/find/file?name=cryptoInfo',
    buyCrypto: TERMINAL + '/billacceptor/payment/crypto',
    buySweeps: TERMINAL + '/billacceptor/payment/game',
};

export const MASCOT_CONFIG: Mascot = {
    getIcons: GAMES_MASCOT + '/icons',
    getBalance: GAMES_LINK + '/mascot/balance',
    updatesLast: GAMES_LINK + '/mascot/updates/last',

    // updatesLast: API + '/jsons/find/file?name=updates',
    // // getIcons: API + '/jsons/find/file?name=mascotIconsCard',
    // getIcons: API + '/jsons/find/file?name=mascotIcons',
    // getBalance: API + '/jsons/find/file?name=mascotBalance',
};


export const SCRATCH_OFF_CONFIG: ScratchOffModel = {
    createRound:  GAMES_SCRATCH_OFF + '/rounds/create',
    chooseItem:  GAMES_SCRATCH_OFF + '/rounds/play',

    getGamePaymentInfo: GAMES_SCRATCH_OFF + '/pig/create',
    buyGame: GAMES_SCRATCH_OFF + '/pig/buy',
    // createRound:  API + '/jsons/find/file?name=mascotGameFindPrizeList',
    // // createRound:  API + '/jsons/find/file?name=error',
    // chooseItem: API + '/jsons/find/file?name=mascotGameFindPrizeItem',
    // getGamePaymentInfo: API + '/jsons/find/file?name=buyGameResult',
    // buyGame: API + '/jsons/find/file?name=threeDSPayment',
};

export const SPINNER_GAME_CONFIG: SpinnerGameModel = {
    init: GAMES_WHEEL + '/play/free',

    // init: API + '/jsons/find/file?name=initSpinner',
};


export const CRYPTO_TRANSACTIONS_CONFIG = {
    redeem: API + '/payout/crypto/redeem',
    founds:API+'/payments/crypto',
    buyCrypto: API + '/cryptocurrency/trade/buy',
    sellCrypto: API + '/cryptocurrency/trade/sell',

    investmentSellCrypto: API + '/cryptocurrency/investment/sell',
    investmentBuyCrypto: API + '/cryptocurrency/investment/buy',

    // redeem: API + '/jsons/find/file?name=success',
    // buyCrypto: API + '/jsons/find/file?name=success',
    // sellCrypto: API + '/jsons/find/file?name=success',
    //
    // investmentSellCrypto: API + '/jsons/find/file?name=success',
    // investmentBuyCrypto: API + '/jsons/find/file?name=success',
};

export const NFT_CONFIG: NFTRequestsModel = {
    generateWallet: NFT + '/wallets/generate',
    mintNFT: NFT + '/library/mint',
};

export const MASS_SMS_CONFIG: MassSms = {
    submit: API + '/mailing/sms/games',
}

export const YETI_RESPONSES_CONFIG: YetiResponsesModel = {
    initGame: GAMES_YETI + '/create',
    play: GAMES_YETI + '/play',
    redeem: GAMES_YETI + '/redeem',

    // initGame: API + '/jsons/find/file?name=yetiInit',
    // play: API + '/jsons/find/file?name=yetiPlay',
    // redeem: API + '/jsons/find/file?name=success',
}

export const COMMISSION_CONFIG: CommissionRequestModel = {
    data: API + '/resellers/reseller',
    refer: API + '/resellers/reseller/refer',
    payout: API + '/resellers/reseller/payout',

    // data: API + '/jsons/find/file?name=commissionData',
    // refer: API + '/jsons/find/file?name=success',
    // payout: API + '/jsons/find/file?name=success',
};

export const SUPPORT_CONFIG: SupportModel = {
    // request: API + '/supports/support',
    session: API + '/chat/session/create',

    // request: API + '/jsons/find/file?name=success',
    // session: API + '/jsons/find/file?name=getChatSession',
};

export const REPORTS_CONFIG: ReportsModel = {
    weekly: API + '/mascot/reports',
    senetReport: API + '/senet/reports'

    // weekly: API + '/jsons/find/file?name=weeklyReport'
};

export const SLOTS_RESPONSES_CONFIG: SlotsResponsesModel = {
  initGame: API + '/jsons/find/file?name=slotsInit',
  // play: API + '/jsons/find/file?name=yetiPlay',
  // redeem: API + '/jsons/find/file?name=success',
}

export const SETTINGS_CONFIG = {
    textMe: API + '/payments/cashapp/sendAddress',
    verifyCashapp: API + '/payments/cashapp/add',
    verifyPaypal: API + '/users/paypal/add',
    verifyVenmo: API + '/users/venmo/add',
    verifyZelle: API + '/users/zelle/add',
    // verifyVenmo: API + '/jsons/find/file?name=success',
    // verifyZelle: API + '/jsons/find/file?name=success',
}

export const PROMO_777_CONFIG = {
    register: ADS + '/promo777/register',
    verify: ADS + '/promo777/register/verify',
    cashapp: ADS + '/promo777/register/cashapp',
    textCashTag: ADS + '/promo777/register/message',
    playNow: ADS + '/promo777/register/create',

    // register: API + '/jsons/find/file?name=success',
    // verify: API + '/jsons/find/file?name=success',
}

export const TRANSACTIONS_CONFIG = {
    confirmation: API + '/payments/threedsecure',
    // confirmation: API + '/jsons/find/file?name=threedsecureSuccess',
}


export const SENETPRO_CONFIG = {
    playNow: ADS + '/senetpro/register/auth',
}

export const PRIZES_SENETPRO_CONFIG: PrizesSenetProRequestsModel = {
    info: API + '/payouts/balance',
    redeem: API + '/payout/redeem/create',
    withdraw: API + '/payout/withdraw/shortname',
    sendFriendPhone: API + '/payout/friend',
    sendToFriend: API + '/payout/friend/create',
    getPaypalInfo: API + '/payout/paypal',
    getVenmoInfo: API + '/payout/venmo',
    getDebitCardInfo: API + '/payout/debitcard/info',


    // info: API + '/jsons/find/file?name=prizesHistorySenetPro',
    // redeem: API + '/jsons/find/file?name=success',
    // withdraw: API + '/jsons/find/file?name=success',
    // sendFriendPhone: API + '/jsons/find/file?name=sendFriendPhone',
    // sendToFriend: API + '/jsons/find/file?name=success',
    // getPaypalInfo: API + '/jsons/find/file?name=getPaypalInfo',
    // getVenmoInfo: API + '/jsons/find/file?name=getVenmoInfo',
    // getDebitCardInfo: API + '/jsons/find/file?name=getDebitCardInfo',
};

export const CASH_APP_CONFIG = {
    payment: API + '/payments/cashapp/create'

    // payment: API + '/jsons/find/file?name=success'
};

export const CONTACT_US_CONFIG: ContactUsRequestsModel = {
    send: API + '/contacts/contact/send',
};
