import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class MenuService {
    public menuChanged = new BehaviorSubject(false);
    public totalNotificationsAmount = new BehaviorSubject(null);

    constructor(private route: ActivatedRoute,
                private router: Router) {

    }

    show() {
        this.menuChanged.next(true);
        //this.searchService.hide();
        document.body.classList.add('is-hidden');

    }

    hide() {
        this.menuChanged.next(false);
        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {},
                //queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
        document.body.classList.remove('is-hidden');
    }

}
