import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {ADDRESSES_CONFIG, FUNDS_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class FundsService extends SettingsLoaderService {
    private config = FUNDS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getPayments() {
        this.showLoader();
        return this.http.post<any>(this.config.payments, {});
    }

    addFunds(id,method, amount, usdc_amount) {
        this.showLoader();
        return this.http.post<any>(this.config.add, {id,method, amount, usdc_amount});
    }

    getDepositInfo(amount) {
        this.showLoader();
        return this.http.post<any>(this.config.getDepositInfo, {amount});
    }

    getCashAppInfo(amount) {
        this.showLoader();
        return this.http.post<any>(this.config.getCashAppInfo, {amount});
    }
}
