import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { PRIZES_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var PrizesService = /** @class */ (function (_super) {
    tslib_1.__extends(PrizesService, _super);
    function PrizesService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = PRIZES_CONFIG;
        _this.temporaryEmail = new BehaviorSubject('');
        _this.temporaryAddress = new BehaviorSubject('');
        return _this;
    }
    PrizesService.prototype.getPrizesInfo = function (page) {
        //this.showLoader();
        var post = this.http.post(this.config.data, { page: page });
        return post;
    };
    PrizesService.prototype.getCryptoList = function () {
        //this.showLoader();
        var post = this.http.post(this.config.cryptoList, {});
        return post;
    };
    // getEmails() {
    //     return this.http.post<any>(this.config.emails, {});
    // }
    PrizesService.prototype.getPaypalEmails = function () {
        this.showLoader();
        var post = this.http.post(this.config.paypalEmails, {});
        //this.hideLoader();
        return post;
    };
    PrizesService.prototype.getCheckAddresses = function () {
        this.showLoader();
        return this.http.post(this.config.checkAddresses, {});
    };
    PrizesService.prototype.getUSDCoinsBalance = function () {
        this.showLoader();
        return this.http.post(this.config.usdcBalance, {});
    };
    PrizesService.prototype.setTemporaryEmail = function (email) {
        this.temporaryEmail.next(email);
    };
    PrizesService.prototype.getTemporaryEmail = function () {
        return this.temporaryEmail.value;
    };
    PrizesService.prototype.setTemporaryAddress = function (address) {
        this.temporaryAddress.next(address);
    };
    PrizesService.prototype.getTemporaryAddress = function () {
        return this.temporaryAddress.value;
    };
    PrizesService.prototype.paypalCreatePayout = function (id, email, amount) {
        this.showLoader();
        var post = this.http.post(this.config.paypalCreatePayout, { id: id, email: email, amount: amount });
        // this.hideLoader();
        return post;
    };
    PrizesService.prototype.mailCheckCreatePayout = function (id, address, amount) {
        this.showLoader();
        return this.http.post(this.config.mailCheckCreatePayout, { id: id, address: address, amount: amount });
    };
    PrizesService.prototype.usdcCreatePayout = function (amount) {
        this.showLoader();
        return this.http.post(this.config.usdcCreatePayout, { amount: amount });
    };
    // confirmCryptoPayout(amount, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.confirmCryptoPayout, {amount,id});
    // }
    // confirmCryptoSellPayout(amount, id) {
    //     this.showLoader();
    //     return this.http.post<any>(this.config.confirmCryptoSellPayout, {amount,id});
    // }
    PrizesService.prototype.paypalAddEmail = function (email) {
        this.loaderService.show();
        return this.http.post(this.config.paypalAddEmail, { email: email });
    };
    PrizesService.prototype.checkAddAddress = function (address) {
        this.loaderService.show();
        return this.http.post(this.config.checkAddAddress, { address: address });
    };
    PrizesService.prototype.redeemViaCashApp = function (amount, handle) {
        this.showLoader();
        return this.http.post(this.config.redeemViaCashApp, { amount: amount, handle: handle });
    };
    PrizesService.prototype.redeemViaVenmoTag = function (amount, handle) {
        this.showLoader();
        return this.http.post(this.config.redeemViaVenmoTag, { amount: amount, handle: handle });
    };
    PrizesService.prototype.redeemViaDebitCard = function (amount) {
        this.showLoader();
        return this.http.post(this.config.redeemViaDebitCard, { amount: amount });
    };
    PrizesService.prototype.redeemViaZelle = function (amount, type) {
        this.showLoader();
        return this.http.post(this.config.redeemViaZelle, { amount: amount, type: type });
    };
    PrizesService.prototype.redeemViaVenmo = function (amount, handle, shortName) {
        this.showLoader();
        return this.http.post(this.config.redeemViaVenmo, { amount: amount, handle: handle, shortName: shortName });
    };
    PrizesService.prototype.redeemViaVisaGiftCard = function (amount, qty) {
        this.showLoader();
        return this.http.post(this.config.redeemViaVisaGiftCard, { amount: amount, qty: qty });
    };
    PrizesService.prototype.redeemViaAmazonGiftCard = function (amount, qty) {
        this.showLoader();
        return this.http.post(this.config.redeemViaAmazonGiftCard, { amount: amount, qty: qty });
    };
    PrizesService.prototype.cashAppBalance = function () {
        this.showLoader();
        return this.http.post(this.config.cashAppBalance, {});
    };
    PrizesService.prototype.venmoBalance = function () {
        this.showLoader();
        return this.http.post(this.config.venmoBalance, {});
    };
    PrizesService.prototype.getGamesBalance = function () {
        this.showLoader();
        return this.http.post(this.config.gamesBalance, {});
    };
    PrizesService.prototype.redeemViaGames = function (methodShortName, amount) {
        this.showLoader();
        return this.http.post(this.config.redeemViaGames, { methodShortName: methodShortName, amount: amount });
    };
    PrizesService.prototype.getDebitCards = function () {
        this.showLoader();
        return this.http.post(this.config.getDebitCards, {});
    };
    PrizesService.prototype.changeActiveCard = function (uuid) {
        this.showLoader();
        return this.http.post(this.config.changeActiveCard, { uuid: uuid });
    };
    PrizesService.prototype.removeDebitCard = function (uuid) {
        this.showLoader();
        return this.http.post(this.config.removeDebitCard, { uuid: uuid });
    };
    PrizesService.prototype.addDebitCard = function (card, month, year, cvv, name, firstName, lastName, billingAddress, billingZip, city, stateId) {
        this.showLoader();
        return this.http.post(this.config.addDebitCard, { card: card, month: month, year: year, cvv: cvv, name: name, firstName: firstName, lastName: lastName, billingAddress: billingAddress, billingZip: billingZip, city: city, stateId: stateId });
    };
    PrizesService.prototype.addDebitCardAdditionInfo = function (firstName, lastName, address, address2, city, zip, stateId, dob, lastFour) {
        this.showLoader();
        return this.http.post(this.config.addDebitCardAdditionInfo, { firstName: firstName, lastName: lastName, address: address, address2: address2, city: city, zip: zip, stateId: stateId, dob: dob, lastFour: lastFour });
    };
    PrizesService.prototype.getDebitCardAdditionInfo = function () {
        this.showLoader();
        return this.http.post(this.config.getDebitCardAdditionInfo, {});
    };
    PrizesService.prototype.getMobileTopupOperatorsList = function () {
        this.showLoader();
        return this.http.post(this.config.getMobileTopupOperatorsList, {});
    };
    PrizesService.prototype.getGiftCardInfo = function (type) {
        this.showLoader();
        return this.http.post(this.config.getGiftCardInfo, { type: type });
    };
    PrizesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrizesService_Factory() { return new PrizesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PrizesService, providedIn: "root" });
    return PrizesService;
}(SettingsLoaderService));
export { PrizesService };
