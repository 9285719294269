import { ElementRef, OnInit } from '@angular/core';
import { CRYPTO_AMOUNT_MASK, CRYPTO_SETTINGS } from "../../config/crypto_settings";
import { PROJECT_CONFIG } from "../../config/project";
import { ActivatedRoute } from "@angular/router";
import { CONFIRM_REQUEST, POPUP_TYPES } from "./investments-popup-form/investments-popup-form.config";
import { TRADE_ACTIONS } from "../../shared/trading/trading.config";
import { NFT_POPUP_TYPE } from "./investments-nft-popup/investments-nft-popup.config";
var InvestmentsComponent = /** @class */ (function () {
    function InvestmentsComponent(stylingService, loaderService, popupComponent, fitTextService, copyToBufferService, keyboardService, activateRoute, partnerService, kioskAccessorService, kioskService, kioskScrollService, investmentsService, NFTService) {
        var _this = this;
        this.stylingService = stylingService;
        this.loaderService = loaderService;
        this.popupComponent = popupComponent;
        this.fitTextService = fitTextService;
        this.copyToBufferService = copyToBufferService;
        this.keyboardService = keyboardService;
        this.activateRoute = activateRoute;
        this.partnerService = partnerService;
        this.kioskAccessorService = kioskAccessorService;
        this.kioskService = kioskService;
        this.kioskScrollService = kioskScrollService;
        this.investmentsService = investmentsService;
        this.NFTService = NFTService;
        this.headerData = {
            totalAmount: 0,
            currentAmount: 0,
            step: 0,
            nftPercentToToken: 0,
            price: 0,
        };
        this.CRYPTO_SETTINGS = CRYPTO_SETTINGS;
        this.DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
        this.showPopup = 'hide';
        // tradingData = {
        //     items: [],
        //     loading: false
        // };
        this.investmentData = {
            items: [],
            loading: false
        };
        this.cryptoData = {
            items: [],
            loading: false
        };
        // usdcData = {
        //     items: [],
        //     loading: false
        // };
        this.nftData = {
            wallet: '',
            qrCode: '',
            contractAddress: '',
            library: []
        };
        this.nftResponseKeys = {
            wallet: 'wallet',
            qrCode: 'walletQrcodeLink',
            contractAddress: 'contractAddress',
            library: 'library'
        };
        this.showNFTPopup = false;
        this.NFTLibraryMaxSlides = 3;
        this.nftPrice = 0;
        this.investmentListResponseKeys = {
            id: 'id',
            fee: 'fee',
            size: 'size',
            icon: 'icon',
            name: 'name',
            rate: 'rate',
            available: 'available',
            interest: 'interest',
            invested: 'invested'
        };
        this.maxPageScrollPosition = 0;
        // openTradeOnInit = false;
        this.CRYPTO_AMOUNT_MASK = CRYPTO_AMOUNT_MASK;
        this.isAppClass = '';
        this.kioskAccessorService.paymentComplete.subscribe(function (val) {
            if (val !== null) {
                _this.getData();
                // this.getUSDCList();
                // this.getCrypto();
                // this.getTrading();
                // this.getInvestmentList();
            }
        });
        this.activateRoute.queryParams.subscribe(function (queryParams) {
            var openTradeOnInit = _this.activateRoute.snapshot.queryParams['openTrade'];
            var item = _this.investmentsService.checkedItem.value;
            _this.onPopupFormClose();
            if (openTradeOnInit === TRADE_ACTIONS.buy && item) {
                _this.openTradeBuyPopup(item);
            }
            else if (openTradeOnInit === TRADE_ACTIONS.sell && item) {
                _this.openTradeSellPopup(item);
            }
        });
    }
    InvestmentsComponent.prototype.ngOnInit = function () {
        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';
        this.getData();
        // this.getCrypto();
        this.handleLibrarySlidesClick();
    };
    InvestmentsComponent.prototype.handleLibrarySlidesClick = function () {
        var _this = this;
        document.body.addEventListener('click', function (event) {
            if (event.target.classList.contains('js-library-slide')) {
                var id_1 = event.target.getAttribute('data-id');
                var slideInfo = _this.nftData.library.find(function (el) { return el.uuid === id_1; });
                if (slideInfo) {
                    _this.onShowNFTPopup(slideInfo);
                }
            }
        });
    };
    InvestmentsComponent.prototype.getData = function () {
        var _this = this;
        this.investmentsService.getInfo().subscribe(function (data) {
            _this.setInfo(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    InvestmentsComponent.prototype.setInfo = function (data) {
        try {
            this.headerData = {
                totalAmount: data.stepAmount ? data.stepAmount : 0,
                currentAmount: data.currentAmount ? data.currentAmount : 0,
                step: data.step ? data.step : 1,
                nftPercentToToken: data.nftPercentToToken ? data.nftPercentToToken : 0,
                price: data.price ? data.price : 0,
            };
            this.setNFTData(data);
            this.nftPrice = data.price ? data.price : 0;
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsComponent.prototype.setNFTData = function (data) {
        var _this = this;
        if (data.nft) {
            var nftData_1 = data.nft;
            this.nftData.wallet = nftData_1[this.nftResponseKeys.wallet] ? nftData_1[this.nftResponseKeys.wallet] : '';
            this.nftData.qrCode = nftData_1[this.nftResponseKeys.qrCode] ? nftData_1[this.nftResponseKeys.qrCode] : '';
            this.nftData.contractAddress = nftData_1[this.nftResponseKeys.contractAddress] ? nftData_1[this.nftResponseKeys.contractAddress] : '';
            this.nftData.library = [];
            setTimeout(function () {
                _this.nftData.library = nftData_1[_this.nftResponseKeys.library] ? nftData_1[_this.nftResponseKeys.library] : [];
            });
        }
    };
    InvestmentsComponent.prototype.getActiveColor = function () {
        return this.stylingService.getPageColor();
    };
    InvestmentsComponent.prototype.toggleDropdown = function (e) {
        var parent = e.target.parentElement;
        var hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
        document.body.querySelectorAll('.js-dropdown').forEach(function (el) {
            el.classList.add('is-hidden');
        });
        if (hidden) {
            e.target.offsetParent.classList.remove('is-hidden');
            if (parent.classList.contains('js-usdc-parent')) {
                //if (!this.usdcData.items.length) {
                //     this.getUSDCList();
                //}
            }
            else if (parent.classList.contains('js-crypto-parent')) {
                //if (!this.cryptoData.items.length) {
                this.getCrypto();
                //}
            }
            else if (parent.classList.contains('js-trading-parent')) {
                //if (!this.tradingData.items.length) {
                //     this.getTrading();
                // }
            }
            else if (parent.classList.contains('js-investment-parent')) {
                // this.getInvestmentList();
            }
        }
    };
    // getUSDCList() {
    //     this.usdcData.loading = true;
    //     this.investmentsService.getUsd(1).subscribe(data => {
    //         this.getServerResponse(data, this.setUSDCList.bind(this));
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //         this.usdcData.loading = false;
    //     });
    // }
    //
    // setUSDCList(data) {
    //     this.usdcData.items = data.result;
    // }
    InvestmentsComponent.prototype.getCrypto = function () {
        var _this = this;
        this.cryptoData.loading = true;
        this.investmentsService.getCrypto().subscribe(function (data) {
            _this.getServerResponse(data, _this.setCrypto.bind(_this));
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.cryptoData.loading = false;
        });
    };
    InvestmentsComponent.prototype.setCrypto = function (data) {
        this.cryptoData.items = data.result;
        //this.updateTextSize();
    };
    // getTrading() {
    //     this.tradingData.loading = true;
    //     this.investmentsService.getTrading().subscribe(data => {
    //         this.getServerResponse(data, this.setTrading.bind(this));
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.tradingData.loading = false;
    //     });
    // }
    // setTrading(data)  {
    //     this.tradingData.items = data.result;
    // }
    InvestmentsComponent.prototype.getInvestmentList = function () {
        var _this = this;
        this.investmentData.loading = true;
        this.investmentsService.getInvestment().subscribe(function (data) {
            _this.getServerResponse(data, _this.setInvestment.bind(_this));
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.investmentData.loading = false;
        });
    };
    InvestmentsComponent.prototype.setInvestment = function (data) {
        this.investmentData.items = data.result;
    };
    InvestmentsComponent.getUSDCTotal = function (amount) {
        return +amount.toFixed(2);
    };
    InvestmentsComponent.prototype.getPayoutIcon = function (icon) {
        return icon;
    };
    InvestmentsComponent.prototype.getCryptoIcon = function (icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].icon;
        }
        else {
            return this.CRYPTO_SETTINGS.default.icon;
        }
    };
    InvestmentsComponent.prototype.getCryptoColor = function (icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].color;
        }
        else {
            return this.CRYPTO_SETTINGS.default.color;
        }
    };
    InvestmentsComponent.prototype.getDate = function (item) {
        return item.created;
    };
    InvestmentsComponent.prototype.getAmount = function (item) {
        return Math.abs(item.amount);
    };
    InvestmentsComponent.prototype.getAmountSign = function (item) {
        return item.amount > 0 ? '+' : '-';
    };
    InvestmentsComponent.prototype.openSendPopup = function (item) {
        this.showPopup = 'show';
        this.popupFormType = 'crypto';
        this.popupFormData = item;
        var $this = this;
        setTimeout(function () {
            document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0].focus();
            $this.keyboardService.setActiveInput();
        }, 0);
    };
    InvestmentsComponent.prototype.onPopupFormClose = function (type) {
        this.showPopup = 'hide';
        var activeDropdown = document.querySelector('.dropdown:not(.is-hidden)');
        if (type && type.status) {
            this.getData();
            if (activeDropdown.classList.contains('js-usdc-parent')) {
                // this.getUSDCList();
            }
            else if (activeDropdown.classList.contains('js-crypto-parent')) {
                this.getCrypto();
            }
            else if (activeDropdown.classList.contains('js-trading-parent')) {
                // this.getTrading();
            }
            else if (activeDropdown.classList.contains('js-investment-parent')) {
                // this.getInvestmentList();
            }
        }
    };
    InvestmentsComponent.prototype.onPopupNFTClose = function (needReload) {
        this.showNFTPopup = false;
        if (needReload) {
            this.getData();
        }
    };
    // OLD with Pairs
    // openTradePopup(item) {
    //     const currencyIdFrom = item.currencyIdFrom;
    //     const currencyIdTo = item.currencyIdTo;
    //     const currencyFromName = item.nameFrom;
    //     const currencyToName = item.nameTo;
    //
    //     this.investmentsService.getTradingInfo(currencyIdFrom, currencyIdTo).subscribe(data => {
    //         this.getServerResponse(data, this.getTradeInfo.bind(this),
    //           {idFrom: currencyIdFrom, idTo: currencyIdTo, fromName: currencyFromName, toName: currencyToName});
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }
    InvestmentsComponent.prototype.getTradeInfo = function (data, additionalParams) {
        this.popupFormData = data.result;
        this.popupFormData.checkedCurrency = {
            from: additionalParams.idFrom,
            to: additionalParams.idTo,
            fromName: additionalParams.fromName,
            toName: additionalParams.toName
        };
        this.showTradePopup();
        var $this = this;
        setTimeout(function () {
            document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0].focus();
            $this.keyboardService.setActiveInput();
        }, 0);
    };
    InvestmentsComponent.prototype.showTradePopup = function () {
        this.showPopup = 'show';
        this.popupFormType = 'trade';
    };
    InvestmentsComponent.prototype.getServerResponse = function (data, successHandler, additionalParams) {
        try {
            if (data.status === true) {
                successHandler(data, additionalParams);
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsComponent.prototype.loadDefaultCurrencyIcon = function ($event) {
        var el = $event.target;
        el.src = './assets/images/currencies/' + this.DEFAULT_CRYPTO_ICON;
    };
    InvestmentsComponent.prototype.canScrollTop = function () {
        return this.kioskScrollService.canScrollTop(this.scrolledEl);
    };
    InvestmentsComponent.prototype.canScrollBottom = function () {
        return this.kioskScrollService.canScrollBottom(this.scrolledEl);
    };
    InvestmentsComponent.prototype.scrollTable = function (top) {
        this.kioskScrollService.scrollTable('.page__body', top);
    };
    InvestmentsComponent.prototype.openTradeSellPopup = function (item) {
        var available = item.available;
        this.openTradePopup(item, available, POPUP_TYPES.sell, CONFIRM_REQUEST.tradeSell);
    };
    InvestmentsComponent.prototype.openTradeBuyPopup = function (item) {
        // const available = this.headerData.usdTotal;
        // // const available = 1031.98;
        //
        // this.openTradePopup(item, available, POPUP_TYPES.buy, CONFIRM_REQUEST.tradeBuy);
    };
    InvestmentsComponent.prototype.openInvestmentSellPopup = function (item) {
        var available = item.available;
        this.openTradePopup(item, available, POPUP_TYPES.sell, CONFIRM_REQUEST.investmentSell);
    };
    InvestmentsComponent.prototype.openInvestmentBuyPopup = function (item) {
        // const available = this.headerData.usdTotal;
        // this.openTradePopup(item, available, POPUP_TYPES.buy, CONFIRM_REQUEST.investmentBuy);
    };
    InvestmentsComponent.prototype.openTradePopup = function (item, available, type, confirmRequest) {
        this.showPopup = 'show';
        this.popupFormType = type;
        this.popupFormData = item;
        this.popupConfirmRequest = confirmRequest;
        this.popupFormData.availableAmount = available;
        // this.popupFormData.availableAmount = 700000000;
        var $this = this;
        setTimeout(function () {
            document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0].focus();
            $this.keyboardService.setActiveInput();
        }, 0);
    };
    InvestmentsComponent.prototype.generateWallet = function () {
        var _this = this;
        this.NFTService.generateWallet().subscribe(function (data) {
            _this.updateWallet(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    InvestmentsComponent.prototype.updateWallet = function (data) {
        var _a;
        try {
            if (data.status === true) {
                var result = {
                    nft: (_a = {},
                        _a[this.nftResponseKeys.wallet] = data[this.nftResponseKeys.wallet],
                        _a[this.nftResponseKeys.qrCode] = data[this.nftResponseKeys.qrCode],
                        _a[this.nftResponseKeys.contractAddress] = data[this.nftResponseKeys.contractAddress],
                        _a)
                };
                this.setNFTData(result);
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsComponent.prototype.copyToBuffer = function () {
        var value = this.nftData.wallet;
        var message = 'Wallet address has been successfully copied!';
        this.copyToBufferService.copyToBuffer(value, true, message);
    };
    InvestmentsComponent.prototype.onShowNFTPopup = function (slide) {
        console.log('gggg');
        console.log(slide);
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        };
    };
    // openMintPopup() {
    //     this.showNFTPopup = true;
    //     this.NFTPopupData = {
    //         type: NFT_POPUP_TYPE.mint,
    //         data: {
    //             contractAddress: this.nftData.contractAddress
    //         }
    //     }
    // }
    InvestmentsComponent.prototype.isPartnerTypeSingleBalance = function () {
        return this.partnerService.isPartnerTypeSingleBalance();
    };
    return InvestmentsComponent;
}());
export { InvestmentsComponent };
