<div class="page page--quaternary long-content" *ngIf="!frame" [class.page--blured]="showBuyCryptoPopup !== 'hide' || showNFTPopup || iframePopup.show">
    <div class="page__inner">
        <a [routerLink]="getBackLink()" class="page__back page__back--mobile page__back page__back--mobile-text">
            <span class="visually-hidden">Back</span>
        </a>
        <app-logo-bg></app-logo-bg>
        <h1 class="page__title">ADD FUNDS</h1>


        <div class="page__form">
<!--            <form [formGroup]="sellForm" class="form-simple form-simple&#45;&#45;shrink form-simple&#45;&#45;primary">-->

<!--                <div class="saved">-->

<!--                    <div class="saved__title">SAVED PAYMENT METHODS</div>-->

<!--                    <div class="saved__list" #scrolledEl>-->
<!--                        <div *ngFor="let method of methods" class="form-simple__item">-->
<!--                            <input *ngIf="isCard(getPaymentMethod(method))" formControlName="method"-->
<!--                                   [id]="'card-'+getPaymentMethod(method)+getMethodId(method)"-->
<!--                                   [type]="'radio'"-->
<!--                                   class="form-simple__radio"-->
<!--                                   [value]="method"-->
<!--                                   [attr.data-id]="getMethodId(method)"-->
<!--                                   name="email"-->
<!--                                   [checked]="activeMethod === method"-->
<!--                                   (change)="changeMethod(method)"-->
<!--                                   autocomplete="off">-->

<!--                            <label *ngIf="isCard(getPaymentMethod(method))" [for]="'card-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>-->

<!--                            <input *ngIf="isPaypal(getPaymentMethod(method))" formControlName="method"-->
<!--                                   [id]="'email-'+getPaymentMethod(method)+getMethodId(method)"-->
<!--                                   [type]="'radio'"-->
<!--                                   class="form-simple__radio"-->
<!--                                   [value]="method"-->
<!--                                   [attr.data-id]="getMethodId(method)"-->
<!--                                   name="email"-->
<!--                                   [checked]="activeMethod === method"-->
<!--                                   (change)="changeMethod(method)"-->
<!--                                   autocomplete="off">-->
<!--                            <label *ngIf="isPaypal(getPaymentMethod(method))" [for]="'email-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>-->
<!--                        </div>-->

<!--                    </div>-->

<!--                    <div class="scroll-btns scroll-btns&#45;&#45;small">-->
<!--                        <svg [class.is-active]="canScrollTop()"-->
<!--                             (click)="scrollTable(true)"-->
<!--                             class="scroll-btns__arrow scroll-btns__arrow&#45;&#45;top">-->
<!--                            <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>-->
<!--                        </svg>-->
<!--                        <svg [class.is-active]="canScrollBottom()"-->
<!--                             (click)="scrollTable()"-->
<!--                             class="scroll-btns__arrow scroll-btns__arrow&#45;&#45;bottom">-->
<!--                            <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>-->
<!--                        </svg>-->
<!--                    </div>-->

<!--                </div>-->

<!--                <div class="small-text" *ngIf="goldenDragonView">Before recharging, please logout from Golden Dragon Member Portal.</div>-->



<!--                <div class="form-simple__item form-simple__item&#45;&#45;amount">-->
<!--                    <input mask="separator.0"-->
<!--                           thousandSeparator=","-->
<!--                           formControlName="amount"-->
<!--                           placeholder="Amount"-->
<!--                           [type]="'tel'"-->
<!--                           class="form-simple__input"-->
<!--                           autocomplete="off">-->
<!--                    <span>.00</span>-->
<!--                </div>-->

<!--                <div class="form-simple__btns form-simple__btns&#45;&#45;middle">-->
<!--                    <button id="funds_add_sweeps" class="js-submit-btn button snake-btn is-notouched"-->
<!--                            (click)="onSubmit('sweeps')"-->
<!--                            [disabled]="canSubmitSweeps()">-->
<!--                        Deposit-->
<!--                        <svg><rect></rect></svg>-->
<!--                    </button>-->
<!--                </div>-->

<!--&lt;!&ndash;                <div class="form-simple__item form-simple__item&#45;&#45;separator">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <input mask="separator.0"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           thousandSeparator=","&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           formControlName="usdc_amount"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           placeholder="BTC Amount"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           [type]="'tel'"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           class="form-simple__input"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                           autocomplete="off">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                    <span>.00</span>&ndash;&gt;&ndash;&gt;-->

<!--&lt;!&ndash;                    <mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                        <mat-label>GET</mat-label>&ndash;&gt;-->
<!--&lt;!&ndash;                        <mat-select #crypto formControlName="crypto" [(value)]="selectedCrypto" [disabled]="!cryptoList">&ndash;&gt;-->
<!--&lt;!&ndash;                            <mat-select-trigger>&ndash;&gt;-->
<!--&lt;!&ndash;                                <img *ngIf="crypto?.value" class="investments-form__icon js-currency-icon"&ndash;&gt;-->
<!--&lt;!&ndash;                                     src="../../../../assets/images/currencies/{{crypto?.value?.icon}}.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                     alt="{{crypto?.value?.name}}"&ndash;&gt;-->
<!--&lt;!&ndash;                                     (error)="loadDefaultCurrencyIcon($event)"&ndash;&gt;-->
<!--&lt;!&ndash;                                >&ndash;&gt;-->
<!--&lt;!&ndash;                                {{crypto?.value?.name}}&ndash;&gt;-->
<!--&lt;!&ndash;                            </mat-select-trigger>&ndash;&gt;-->
<!--&lt;!&ndash;                            <mat-option *ngFor="let option of cryptoList" [value]="option">&ndash;&gt;-->
<!--&lt;!&ndash;                                <img *ngIf="option" class="investments-form__icon"&ndash;&gt;-->
<!--&lt;!&ndash;                                     src="../../../../assets/images/currencies/{{option?.icon}}.png"&ndash;&gt;-->
<!--&lt;!&ndash;                                     alt="{{option.name}}"&ndash;&gt;-->
<!--&lt;!&ndash;                                     (error)="loadDefaultCurrencyIcon($event)">&ndash;&gt;-->

<!--&lt;!&ndash;                                {{option.name}}&ndash;&gt;-->
<!--&lt;!&ndash;                            </mat-option>&ndash;&gt;-->
<!--&lt;!&ndash;                        </mat-select>&ndash;&gt;-->
<!--&lt;!&ndash;                    </mat-form-field>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="form-simple__item transaction-limit">Crypto up to {{cryptoLimit | currency : 'USD' : 'symbol'}} per day.</div>&ndash;&gt;-->

<!--&lt;!&ndash;                <div class="form-simple__btns">&ndash;&gt;-->
<!--&lt;!&ndash;                    <button id="funds_add"&ndash;&gt;-->
<!--&lt;!&ndash;                            class="js-submit-btn button snake-btn is-notouched"&ndash;&gt;-->
<!--&lt;!&ndash;                            (click)="onSubmit('crypto')"&ndash;&gt;-->
<!--&lt;!&ndash;                            [disabled]="canSubmitCrypto()">&ndash;&gt;-->
<!--&lt;!&ndash;                        Add Crypto&ndash;&gt;-->
<!--&lt;!&ndash;                        <svg><rect></rect></svg>&ndash;&gt;-->
<!--&lt;!&ndash;                    </button>&ndash;&gt;-->
<!--&lt;!&ndash;                </div>&ndash;&gt;-->
<!--            </form>-->
            <app-funds-form (openPopup)="openNFTPopup($event)"
                            [resetForm]="resetNftForm"
                            (emitResetForm)="nftFormIsReseted()"
            ></app-funds-form>
        </div>
        <a [routerLink]="getBackLink()" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>
    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>


<!--<div *ngIf="frame" class="frame">-->
<!--    <iframe [src]="frameUrl" #iframe></iframe>-->
<!--</div>-->

<!--<button *ngIf="frame" (click)="closeFrame()" class="close">-->
<!--    <span class="visually-hidden">Close</span>-->
<!--    <svg class="close__icon" width="34" height="34">-->
<!--        <use xlink:href="../../../../assets/images/sprite.svg#close"></use>-->
<!--    </svg>-->
<!--</button>-->

<!-- <app-investments-popup-form [showPopupForm]="showBuyCryptoPopup" -->
<!--                            [data]="popupFormData" -->
<!--                            [formType]="popupFormType" -->
<!--                            [fromPage]="'founds'" -->
<!--                            (close)="onBuyCryptoPopupClose()"></app-investments-popup-form> -->

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>

<app-iframe-popup *ngIf="iframePopup.show"
                  [url]="iframePopup.url"
                  (onClose)="hideIframePopup($event)"></app-iframe-popup>
