import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KioskScrollService {

    constructor() {
    }

    canScrollTop(viewChild) {
        if (viewChild && viewChild.nativeElement) {
            return !!viewChild.nativeElement.scrollTop;
        }
        return false;
    }

    canScrollBottom(viewChild) {
        if (viewChild && viewChild.nativeElement) {
            const scrollHeight = viewChild.nativeElement.scrollHeight;
            const scrollTop = viewChild.nativeElement.scrollTop;
            const elementHeight = viewChild.nativeElement.offsetHeight;

            return scrollHeight > elementHeight + scrollTop;
        }
        return false;
    }

    scrollTable(scrollableSelector, top?) {
        let el;
        if (typeof scrollableSelector === 'string') {
            el = document.body.querySelector(scrollableSelector);
        } else {
            el  = scrollableSelector;
        }

        // const el = document.body.querySelector(scrollableSelector);
        // const el  = scrollableSelector;
        const elemTopScroll = el.scrollTop;
        const newTopScroll = top ? elemTopScroll - 200 : elemTopScroll + 200;

        el.scrollIntoView({behavior: 'smooth'});
        el.scroll({
            top: newTopScroll,
            behavior: 'smooth'
        });
    }
}
