import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Promo777Service} from "../../../service/promo/promo777.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {UsersPointService} from "../../../service/users/users-point.service";
import {GamesProviderService} from "../../../service/games/gamesProvider.service";
import {PartnerService} from "../../../service/partner.service";
import {PartnerComponent} from "../../partners/partner.component";
import {SenetproService} from "../../../service/promo/senetpro.service";
import {RouterPaths} from "../../../config/router.paths.model";

@Component({
  selector: 'app-senetpro',
  templateUrl: './senetpro.component.html'
})
export class SenetproComponent implements OnInit {
  userInfo;
  token;
  constructor(private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private auth: AuthenticationService,
              private route: Router,
              private activatedRoute: ActivatedRoute,
              private usersPointService: UsersPointService,
              private gamesProviderService: GamesProviderService,
              private partnerComponent: PartnerComponent,
              private senetproService: SenetproService) { }

  ngOnInit() {
      this.auth.clearStorage();

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.token = params.token;
      this.onPlayNow(this.token);
    });
  }

  onPlayNow(token) {
    this.senetproService.playNow(token).subscribe(data => {
      this.checkPlayNowSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkPlayNowSubmit(data) {
    try {
      if (data.status) {
          // this.popupComponent.showPopupErrorReturnEvent({text: data.message}, () => {
          this.userInfo = data;
          this.partnerComponent.detectPartner(this.loginUser.bind(this));
        // });
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  loginUser() {
    const userAccount = this.userInfo.accounts[0];

    this.auth.setUserLogged(this.userInfo.result);
    this.usersPointService.setAccount(userAccount);
    this.usersPointService.setGroupId(userAccount.id);
    this.gamesProviderService.loginUser(userAccount.point_id, userAccount.point_players_earn_income, userAccount.point_name);
    if (!this.userInfo.result.email || !this.userInfo.result.last_name || !this.userInfo.result.first_name) {
      this.route.navigate([RouterPaths.rewards.form]);
    } else {
      this.route.navigate([RouterPaths.rewards.main]);
    }
  }
}
