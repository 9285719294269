import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MASS_SMS_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";


@Injectable({
    providedIn: 'root'
})
export class MassSmsService extends SettingsLoaderService{
    private config = MASS_SMS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService)
    }

    submitSms(text: string) {
        this.loaderService.show();
        return this.http.post<any>(this.config.submit, {text});
    }
}
