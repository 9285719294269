import {Component, OnInit,ViewChild,ElementRef} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {StripeService} from "../../../../service/stripe/stripe.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {PROJECT_MESSAGES} from "../../../../config/project";
import {CashAppService} from "../../../../service/settings/cashApp.service";
import {StylingService} from "../../../../service/styling.service";
import {StatesModel} from "../../../../models/states/states.model";

@Component({
    selector: 'app-edit-card',
    templateUrl: './edit-card.component.html',
    styleUrls: ['./edit-card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class EditCardComponent implements OnInit {
    newCardForm: FormGroup;
    card;
    cardId;
    cardPlaceholder = "Card Number";
    isCardVerified = false;
    isCashApp = false;
    isPrepaid = false;

    selectedState;
    states: Array<StatesModel> = [];
    constructor(private formBuilder: FormBuilder,
                private userCardService: UserCardService,
                private loaderService: LoaderService,
                private route: ActivatedRoute,
                private router: Router,
                private stripeService:StripeService,
                private cashAppService: CashAppService,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }


    ngOnInit() {
        this.newCardForm = this.formBuilder.group({
            card_number: [null],
            exp: [null, Validators.required],
            cvv: [null],
            city: [null, Validators.required],
            state: [null, Validators.required],
            billing_address: [null, Validators.required],
            billing_zip: [null, Validators.required],
            card_nickname: [null, Validators.required]
        });

        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo(this.cardId);
        });

        this.isCardVerified = this.route.snapshot.queryParams.verify;
        this.isCashApp = this.cashAppService.checkIfCashApp();
        this.isPrepaid = this.cashAppService.isPrepaid();
    }

    getCardInfo(id) {
        if (id) {
            this.userCardService.getCardInfo(id).subscribe(data => {
                this.getCardInfoResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    getCardInfoResult(data) {
        try {
            if (data.status) {
                this.card = data.result;
                const stateId = data.result.stateId;

                if (data.states.length) {
                    this.states = data.states;
                    this.selectedState = this.states.find(state => state.id === stateId);
                    this.newCardForm.controls.state.setValue(this.selectedState);
                }

                if(this.card.last_four != ''){
                    this.cardPlaceholder = '**** **** **** '+this.card.last_four;
                }
                this.newCardForm.controls.exp.setValue(this.card.month+'/'+this.card.year);
                this.newCardForm.controls.card_nickname.setValue(this.card.description);
                this.newCardForm.controls.billing_address.setValue(this.card.billing_address);
                this.newCardForm.controls.billing_zip.setValue(this.card.billing_zip);
                this.newCardForm.controls.city.setValue(this.card.city);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message})
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        const exp = this.getInputValue('exp');
        const nickname = this.getInputValue('card_nickname');
        const billingAddress = this.getInputValue('billing_address');
        const billingZip = this.getInputValue('billing_zip');
        const city = this.getInputValue('city');
        const stateId = this.newCardForm.controls.state.value.id;
        const id = this.cardId;

        let expMonth = exp.substr(0, 2);
        let expYear = exp.substr(2, 4);

        if (id) {
            if (expMonth != '' && expYear != '' && Number(expMonth) && Number(expYear)) {
                let $this = this;
                this.userCardService.saveCard(id, expMonth, expYear, nickname, billingAddress, billingZip, city, stateId).subscribe(data => {
                    $this.onSubmitResult(data);
                }, (error) => {
                    $this.loaderService.hideAndShowTryAgain();
                }, () => {
                    $this.loaderService.hide();
                });
            } else {
                this.popupComponent.showPopupError({text: PROJECT_MESSAGES.incorrectDataFormat});
            }
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.newCardForm.reset();
                this.goBack();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    goBack() {
        if (this.isCashApp) {
            this.router.navigate([RouterPaths.settings.cashapp.menu, this.cardId]);
        } else if (this.isPrepaid) {
            this.router.navigate([RouterPaths.settings.prepaid.menu, this.cardId]);
        } else if (this.isCardVerified) {
            this.router.navigate([RouterPaths.settings.card.main]);
        } else {
            this.router.navigate([RouterPaths.settings.card.menu, this.cardId]);
        }
    }

    // getCardName() {
    //     return this.card.description;
    // }

    onInput($event) {
        this.newCardForm.get('card_nickname').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getInputValue(field) {
        if (this.newCardForm.controls[field]) {
            return this.newCardForm.controls[field].value
        }
        return null;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
