<div (click)="cmd(0)">
    init
</div>

<div (click)="cmd(1)">
    cmd 1
</div>

<div (click)="cmd(2)">
    cmd 2
</div>

<div (click)="cmd(3)">
    cmd 3
</div>

<div (click)="cmd(4)">
    cmd 4
</div>

<div (click)="cmd(5)">
    cmd 5
</div>

<div (click)="cmd(6)">
    cmd 6
</div>

<div (click)="cmd(7)">
    cmd 7
</div>

<div (click)="cmd(8)">
    cmd 8
</div>

<br/>
<br/>
<br/>
<br/>
<div (click)="pay()">
    pay
</div>
<br/>
<br/>
<br/>
<div>
    pay token {{payToken}}
</div>

<div>
    pay amount {{payAmount}}
</div>
