import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from "@angular/forms";
import { SCRATCH_OFF_POPUP_TYPE, SCRATCH_OFF_RULES, SCRATCH_OFF_WELCOME_RULES } from "./scratch-off-rules.config";
var ScratchOffRulesComponent = /** @class */ (function () {
    function ScratchOffRulesComponent(scratchOffService, formBuilder, popupComponent, loaderService) {
        this.scratchOffService = scratchOffService;
        this.formBuilder = formBuilder;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.isElementReady = new EventEmitter();
        this.isPaymentForbidden = new EventEmitter();
        this.isPaymentSuccess = new EventEmitter();
        this.imageLoaded = false;
        this.popupVisible = false;
    }
    Object.defineProperty(ScratchOffRulesComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            this._showPopup = value;
            if (value && !this.popupVisible) {
                this.popupVisible = true;
                this.getInfo();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScratchOffRulesComponent.prototype.ngOnInit = function () { };
    ScratchOffRulesComponent.prototype.ngOnDestroy = function () {
        this.popupVisible = false;
    };
    ScratchOffRulesComponent.prototype.getInfo = function () {
        var _this = this;
        this.backgroundImage = './assets/images/scratchOff/game-placeholder.jpeg';
        setTimeout(function () {
            _this.rules = _this.isWelcomePopup() ? SCRATCH_OFF_WELCOME_RULES : SCRATCH_OFF_RULES;
        });
    };
    ScratchOffRulesComponent.prototype.isWelcomePopup = function () {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    };
    ScratchOffRulesComponent.prototype.isScratchOffRulesPopup = function () {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.pigRules;
    };
    ScratchOffRulesComponent.prototype.onActionBtnClick = function () {
        if (this.isScratchOffRulesPopup()) {
            this.buyGameRequest();
        }
        else if (this.isWelcomePopup()) {
            this.isPaymentSuccess.emit();
        }
    };
    ScratchOffRulesComponent.prototype.buyGameRequest = function () {
        var _this = this;
        this.scratchOffService.getGamePaymentInfo().subscribe(function (data) {
            _this.buyGameResult(data);
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    ScratchOffRulesComponent.prototype.buyGameResult = function (response) {
        if (response.status === true) {
            if (response.payment) {
                this.isPaymentForbidden.emit(response);
            }
            else {
                this.isPaymentSuccess.emit(response);
            }
        }
        else if (response.status === false) {
            this.popupComponent.showPopupError({ text: response.message }, 'btn-ok');
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    ScratchOffRulesComponent.prototype.onBgLoaded = function () {
        this.imageLoaded = true;
        this.isElementReady.emit(true);
    };
    return ScratchOffRulesComponent;
}());
export { ScratchOffRulesComponent };
