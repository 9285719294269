<div class="mascot mascot-card" [class.is-iphone]="isIphone()" [class.is-disabled]="showPopup" [class.is-blurred]="showPopup" (dblclick)="fullScreen()">

    <div class="mascot-card__games">
        <div class="mascot-card-games">
            <button (click)="openGame(game.id)"
                    [ngStyle]="{backgroundImage: 'url(' + game.image360 + ')'}"
                    *ngFor="let game of games"
                    class="mascot-card-games__item">
            </button>
        </div>
    </div>

<!--    <div class="balance-wrapper balance-wrapper&#45;&#45;rounds">-->
<!--        {{balance.freeRounds | number: '.2'}}-->
<!--    </div>-->

    <div class="balance-wrapper balance-wrapper--win">
        {{balance.win | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--balance">
        {{balance.balance | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--jackpot">
        <span style="visibility: hidden" #textData>{{balance.jackpot | number: '.2'}}</span>

        <svg _ngcontent-trq-c4="" viewBox="0 0 70 70" width="100%">
            <path _ngcontent-trq-c4="" d="M 15,35m-35,60a23,23 0 1,1 110,0a23,23 0 1,1 -46,0" fill="transparent" id="tophalf"></path>
            <text _ngcontent-trq-c4="" style="text-align: center; font-size: 6px;" width="100%" fill="#f7c837">
                <textPath #text _ngcontent-trq-c4="" xlink:href="#tophalf" text-anchor="middle" startOffset="35.5%"></textPath>
            </text>
        </svg>
    </div>

    <button (click)="onLogout()" class="mascot__btn-settings">
        <img class="mascot__img-settings" src="./assets/images/mascot-slider/logout.png" alt="">
    </button>

<!--    <app-spinner [data]="spinnerData"></app-spinner>-->
</div>

<div [class.is-visible]='showPopup'
     [class.is-iphone]="isIphone()"
     [class.is-ipad]="isIpad()"
     [class.is-ipad-iframe]="isIpad() && popupGameURL"
     *ngIf="showPopup"
     class="mascot-popup"
     [ngStyle]="{backgroundImage: popupType === POPUP_TYPE.play ? 'none' : 'url(' + popupGameImage + ')'}">
    <button (click)="closeGame()"
            class="mascot-popup__close"></button>
    <button (click)="playGame(popupGameId)"
            *ngIf="!popupGameURL && popupType === POPUP_TYPE.play"
            class="mascot-popup__btn mascot-popup__btn--play"></button>

    <img *ngIf="popupGameImage" class="mascot-popup__img" [src]="popupGameImage" (load)="imageLoaded()" alt="">
    <iframe *ngIf="popupGameURL" width="100%" height="100%" [src]="popupGameURL" frameborder="0"></iframe>

    <div class="loading" *ngIf="popupLoading">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
        <div class="bar bar4"></div>
        <div class="bar bar5"></div>
        <div class="bar bar6"></div>
        <div class="bar bar7"></div>
        <div class="bar bar8"></div>
    </div>
</div>

<app-rotate-popup></app-rotate-popup>
