import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../../animations/router.animations";
import {formatNumber} from "@angular/common";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {UserCardService} from "../../../service/settings/userCard.service";
import {USER_STATUS} from "../../../config/user_status";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {RouterPaths} from "../../../config/router.paths.model";
import {CashAppService} from "../../../service/settings/cashApp.service";
import {CARD_TYPE} from "../../../config/card_type";
import {StylingService} from "../../../service/styling.service";
import {KioskService} from "../../../service/kiosk.service";
import {KioskScrollService} from "../../../service/kiosk-scroll.service";

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CardComponent implements OnInit {
    isCashApp = false;
    isPrepaid = false;
    cards;

    @HostBinding('class') isAppClass = '';
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private cashAppService: CashAppService,
                private stylingService: StylingService,
                private kioskService: KioskService,
                private kioskScrollService: KioskScrollService,
                private userCardService: UserCardService) {

    }


    ngOnInit() {
        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';
        this.isCashApp = this.cashAppService.checkIfCashApp();
        this.isPrepaid = this.cashAppService.isPrepaid();
        this.getCardList();

    }

    getCardList() {
        let type = this.isCashApp ? CARD_TYPE.cashapp : CARD_TYPE.card;
        if(this.isPrepaid){
            type = CARD_TYPE.prepaid;
        }

        this.userCardService.getCards(type).subscribe(data => {
            this.checkGetCardsResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain()
        }, () => {
            this.loaderService.hide();
        });
    }

    checkGetCardsResult(data) {
        try {
            this.cards = [];
            if(data.status){
                this.cards = data.result;
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askDelete(card) {
        if (card && card.uuid) {
            const message = !card.last_four ? `<b class="js-popup-title">Please confirm deleting card</b>` : card.cardholder && card.cardholder.trim() ?
                `<b class="js-popup-title">Please confirm deleting</b> <br><br> <span class="js-popup-subtitle">${card.cardholder} - ${card.lastFour}</span>` :
                `<b class="js-popup-title">Please confirm deleting card</b> <br><br> <span class="js-popup-subtitle">ending in ${card.lastFour}</span>`;

            this.popupComponent.showPopupAgreement({text: message}, (status) => {
                if (status) {
                    this.deleteCard(card.uuid);
                }
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    deleteCard(id) {
        if (id && +id) {
            this.userCardService.deleteCard(id).subscribe(data => {
                this.deleteCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    deleteCardResult(data) {
        try {
            if(data.status === true){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.getCardList();
            }  else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    viewCardInfo(card) {
        if (this.isCashApp) {
            this.router.navigate([RouterPaths.settings.cashapp.menu, card.uuid]);
        } else {
            this.router.navigate([RouterPaths.settings.card.menu, card.uuid]);
        }

        // if (this.isCashApp) {
        //     this.router.navigate([RouterPaths.settings.cashapp.menu, card.id]);
        //     return;
        // }
        //
        // if (this.isPrepaid) {
        //     this.router.navigate([RouterPaths.settings.prepaid.menu, card.id]);
        //     return;
        // }
        //
        // if(!card.verify){
        //     this.router.navigate([RouterPaths.settings.card.menu, card.id]);
        // }else{
        //     this.router.navigate([RouterPaths.settings.card.edit, card.id], {queryParams: {verify: true}});
        // }

        //return false;
    }

    editCardInfo(card) {
        if (this.isCashApp) {
            this.router.navigate([RouterPaths.settings.cashapp.edit, card.id], {queryParams: {verify: true}});
            return;

            RouterPaths
        }

        if(card.verify){
            this.router.navigate([RouterPaths.settings.card.edit, card.id], {queryParams: {verify: true}});
        }

        return false;
    }

    showDelete(card) {
        return card.verify || this.isCashApp || this.isPrepaid;
    }

    getAddLink() {
        if (this.isCashApp) {
            return RouterPaths.settings.cashapp.add;
        } else if (this.isPrepaid) {
            return RouterPaths.settings.prepaid.add;
        } else {
            return RouterPaths.settings.card.add;
        }
    }

    getTitle() {
        if (this.isCashApp) {
            return 'Cash App';
        }else if (this.isPrepaid) {
            return 'Prepaid';
        } else {
            return 'CREDIT/DEBIT CARD'
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable('.page__body', top);
    }
}
