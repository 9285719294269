import {Inject, Injectable, NgZone} from '@angular/core';


// @Injectable({
//   providedIn: 'root'
// })
export class ParticleService {
//   // === CONFIGURATION ===
// // Higher number increases rate firework accelerates over time.
//   FIREWORK_ACCELERATION = 1.05;
// // Minimum firework brightness.
//   FIREWORK_BRIGHTNESS_MIN = 50;
// // Maximum firework brightness.
//   FIREWORK_BRIGHTNESS_MAX = 70;
// // Base speed of fireworks.
//   FIREWORK_SPEED = 5;
// // Base length of firework trails.
//   FIREWORK_TRAIL_LENGTH = 3;
// // Determine if target position indicator is enabled.
//   FIREWORK_TARGET_INDICATOR_ENABLED = true;
//


  // Minimum particle brightness.
  PARTICLE_BRIGHTNESS_MIN = 50;
// Maximum particle brightness.
  PARTICLE_BRIGHTNESS_MAX = 80;
// Minimum particle decay rate.
  PARTICLE_DECAY_MIN = 0.015;
// Maximum particle decay rate.
  PARTICLE_DECAY_MAX = 0.03;
// Base particle friction.
// Slows the speed of particles over time.
  PARTICLE_FRICTION = 0.95;
// Base particle gravity.
// How quickly particles move toward a downward trajectory.
  PARTICLE_GRAVITY = 0.7;
// Variance in particle coloration.
  PARTICLE_HUE_VARIANCE = 20;
// Base particle transparency.
  PARTICLE_TRANSPARENCY = 1;
// Minimum particle speed.
  PARTICLE_SPEED_MIN = 1;
// Maximum particle speed.
  PARTICLE_SPEED_MAX = 10;
// Base length of explosion particle trails.
  PARTICLE_TRAIL_LENGTH = 5;

  x;
  y;
  friction;
  gravity;
  trail;
  trailLength;
  angle;
  speed;
  hue;
  decay;
  transparency;
  brightness;

  constructor(x, y) {
    this.x = x;
    this.y = y;
    // To better simulate a firework, set the angle of travel to random value in any direction.
    this.angle = this.random(0, Math.PI * 2);
    // Set friction.
    this.friction = this.PARTICLE_FRICTION;
    // Set gravity.
    this.gravity = this.PARTICLE_GRAVITY;
    // Set the hue to somewhat randomized number.
    // This gives the particles within a firework explosion an appealing variance.
    this.hue = this.random(this.hue - this.PARTICLE_HUE_VARIANCE, this.hue + this.PARTICLE_HUE_VARIANCE);
    // Set brightness.
    this.brightness = this.random(this.PARTICLE_BRIGHTNESS_MIN, this.PARTICLE_BRIGHTNESS_MAX);
    // Set decay.
    this.decay = this.random(this.PARTICLE_DECAY_MIN, this.PARTICLE_DECAY_MAX);
    // Set speed.
    this.speed = this.random(this.PARTICLE_SPEED_MIN, this.PARTICLE_SPEED_MAX);
    // Create an array to track current trail particles.
    this.trail = [];
    // Trail length determines how many trailing particles are active at once.
    this.trailLength = this.PARTICLE_TRAIL_LENGTH;
    // While the trail length remains, add current point to trail list.
    while(this.trailLength--) {
      this.trail.push([this.x, this.y]);
    }
    // Set transparency.
    this.transparency = this.PARTICLE_TRANSPARENCY;
  }

  update(index, particles) {
    // Remove the oldest trail particle.
    this.trail.pop();
    // Add the current position to the start of trail.
    this.trail.unshift([this.x, this.y]);

    // Decrease speed based on friction rate.
    this.speed *= this.friction;
    // Calculate current position based on angle, speed, and gravity (for y-axis only).
    this.x += Math.cos(this.angle) * this.speed;
    this.y += Math.sin(this.angle) * this.speed + this.gravity;

    // Apply transparency based on decay.
    this.transparency -= this.decay;
    // Use decay rate to determine if particle should be destroyed.
    if(this.transparency <= this.decay) {
      // Destroy particle once transparency level is below decay.
      particles.splice(index, 1);
    }
  }

  draw(context) {
    // Begin a new path for particle trail.
    context.beginPath();
    // Get the coordinates for the oldest trail position.
    let trailEndX = this.trail[this.trail.length - 1][0];
    let trailEndY = this.trail[this.trail.length - 1][1];
    // Create a trail stroke from trail end position to current particle position.
    context.moveTo(trailEndX, trailEndY);
    context.lineTo(this.x, this.y);

    // context.lineWidth = 5;

    // Set stroke coloration and style.
    // Use hue, brightness, and transparency instead of RGBA.
    context.strokeStyle = `hsla(${this.hue}, 100%, ${this.brightness}%, ${this.transparency})`;
    context.stroke();
  }


  random(min, max) {
    return Math.random() * (max - min) + min;
  }
}
