import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {ActivatedRoute} from "@angular/router";
import {TransactionsService} from "../../../service/transactions/transactions.service";

@Component({
    selector: 'app-transaction-processing',
    templateUrl: './transaction-processing.component.html',
    styleUrls: ['./transaction-processing.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class TransactionProcessingComponent implements OnInit {
    message = 'Transaction in processing';

    constructor(private popupComponent: PopupComponent,
                private activatedRoute: ActivatedRoute,
                private transactionsService: TransactionsService,
                private loaderService: LoaderService) {
        // this.activatedRoute.queryParams.subscribe(params => {
        //     const paymentIntent = params.payment_intent;
        //     if (paymentIntent) {
        //         this.transactionsService.confirmation(paymentIntent).subscribe(data => {
        //             try {
        //                 const status = data.status;
        //                 const message = data.message;
        //
        //                 if ((status || status === false) && message) {
        //                     this.transactionsService.onCloseProcessingPopup({status: status, message: message});
        //                 } else {
        //                     this.transactionsService.onCloseProcessingPopup('error');
        //                 }
        //             } catch (e) {
        //                 this.transactionsService.onCloseProcessingPopup('error');
        //             }
        //         }, () => {
        //             this.transactionsService.onCloseProcessingPopup('error');
        //         }, () => {
        //             this.loaderService.hide();
        //         })
        //     } else {
        //         this.transactionsService.onCloseProcessingPopup('error');
        //     }
        // });
    }

    ngOnInit() {
    }
}

