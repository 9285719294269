import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PRIZES_METHODS} from "../../pages/prizes/prizes.config";
import {PhoneMaskService} from "../../service/phoneMask.service";

@Component({
    selector: 'app-popup-prizes-confirm',
    templateUrl: './popup-prizes-confirm.component.html',
    styleUrls: ['./popup-prizes-confirm.component.scss']
})
export class PopupPrizesConfirmComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() changeHandle: EventEmitter<{type: string, handle: string, zelleType?: string}> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    moreThenAvailablePayout = false;
    selectedMethod;
    selectedZelleTagType;

    PRIZES_METHODS = PRIZES_METHODS;
    isTotalAmountCorrect = false;
    totalAmount = 0;

    step = 1;
    zelleTagTypeList = [{name: 'Email', value: 1}, {name: 'Phone', value: 2}];
    phoneMask;
    constructor(private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            handle: [null],
            amount: [null, Validators.required],
            quantity: [null],
            zelleTagType: [null],
        });
        this.phoneMask = this.phoneMaskService.setPhoneMask();

    }

    ngOnChanges(changes) {
        if (!changes.popupInfo.currentValue.show && this.form) {
            this.form.controls.amount.setValue(null);
            this.form.controls.handle.setValue(null);
        }
        if (changes.popupInfo.currentValue.show) {
            if (this.popupInfo.providers) {
                this.selectedMethod = this.popupInfo.providers[0];
                this.form.controls.handle.setValue(this.selectedMethod);
            }
            if (this.popupInfo.type === this.PRIZES_METHODS.zelle) {
                if (this.popupInfo.zelleAddress) {
                    this.form.controls.handle.setValue(this.popupInfo.handle);
                } else {
                    this.selectedZelleTagType = this.zelleTagTypeList[0];
                    this.form.controls.zelleTagType.setValue(this.selectedZelleTagType);
                    this.onZelleTypeChange(this.selectedZelleTagType);
                }
            }
        } else {
            if (this.form) {
                this.form.reset();
            }
            this.isTotalAmountCorrect = false;
            this.totalAmount = 0;
            this.step = 1;
        }
    }

    setCashappTagVerified($event) {
        this.popupInfo.cashappVerified = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    setPaypalVerified($event) {
        this.popupInfo.paypalAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    setVenmoVerified($event) {
        this.popupInfo.venmoAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
        this.form.controls.handle.setValue($event);
    }

    setZelleVerified($event) {
        this.popupInfo.zelleAddress = true;
        this.updateHandle(this.popupInfo.type, $event, this.popupInfo.zelleType);
    }

    setZelleType(zelleType) {
        this.changeHandle.emit({type: this.popupInfo.type, handle: this.popupInfo.handle, zelleType});
    }

    updateHandle(type, handle, zelleType?) {
        this.form.controls.handle.setValue(handle);
        this.changeHandle.emit({type: type, handle: handle, zelleType});
    }

    closePopup() {
        this.close.emit(true);
    }

    onSubmitForm() {
        if (this.popupInfo.type === this.PRIZES_METHODS.games) {
            this.updateHandle(this.PRIZES_METHODS.games, this.form.controls.handle.value)
        }
        if (this.isOneOfGiftCards()) {
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type, qty: this.form.controls.quantity.value});
        } else {
            this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type});
        }
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onInputQuantity() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        this.totalAmount = this.form.controls.amount.value * +this.form.controls.quantity.value;
        this.isTotalAmountCorrect = this.totalAmount <= avaliable;
    }

    isOneOfGiftCards(): boolean {
        return this.popupInfo.type === PRIZES_METHODS.giftCardVisa || this.popupInfo.type === PRIZES_METHODS.giftCardAmazon
    }

    selectAmount(item) {
        this.form.controls.amount.setValue(item);
        this.onInputQuantity();
    }

    isZellePhone():boolean {
        return this.popupInfo.zelleType === 2;
    }

    zelleTagType():string {
        return this.isZellePhone() ? 'phone' : 'email';
    }

    goToZelleStepTwo() {
        setTimeout(() => {
            this.step = 2;
        });
    }

    onZelleTypeChange($event) {
        this.setZelleType($event.value);
        this.popupInfo.zelleType = $event.value;
    }
}
