import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {PROJECT_CONFIG} from "../config/project";
import {HERO_VERSIONS} from "../config/hero_versions.model";

@Injectable({
    providedIn: 'root'
})
export class HeroService {

    constructor(private router: Router,) {
    }

    isCryptoVersion() {
        return PROJECT_CONFIG.heroVersion === HERO_VERSIONS.crypto;
    }

    isWatchVersion() {
        return PROJECT_CONFIG.heroVersion === HERO_VERSIONS.watch;
    }
}
