import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {GLOBAL_CLASSES} from "../../config/globalClasses";
import {KioskService} from "../kiosk.service";

@Injectable({
  providedIn: 'root'
})
export class DOMManipulationsService {
  private GLOBAL_CLASSES = GLOBAL_CLASSES;

  constructor(private kioskService: KioskService) {
  }

  setPageLongContent() {
    if (this.kioskService.isKiosk()) {
      document.body.querySelector('.page').classList.add(this.GLOBAL_CLASSES.pageLongContent);
    }
  }
}
