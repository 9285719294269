<div class="block container">
    <ul data-current="0">
        <li *ngFor="let slide of slides" class="swiper-slide">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                <img [src]="slide" alt="image" class="slide">
            </div>
        </li>
    </ul>
</div>

<div class="block container">
    <ul data-current="0">
        <li *ngFor="let slide of slidesDynamic" class="swiper-slide">
            <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
                <img [src]="slide.image360" alt="image" class="slide">
            </div>
        </li>
    </ul>
</div>
