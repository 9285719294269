import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthenticationService} from "../modules/auth/_services/authentication.service";
import {PopupComponent} from "../shared/popup/popup.component";
import {Router} from "@angular/router";

declare global {
    interface Window {
        myPhone: any;
        webkit?: any;
        androidFunction?: any;
    }
}

declare var window: Window;
declare var AndroidFunction;

@Injectable({
    providedIn: 'root'
})
export class PhoneService {
    token;

    public postUploded = new BehaviorSubject(null);
    public postAvatarUploded = new BehaviorSubject(null);
    public userAvatarUploded = new BehaviorSubject(null);

    constructor(private ngZone: NgZone,
                private router: Router,
                private authenticationService:AuthenticationService,
                private popupComponent: PopupComponent) {


    }

    init() {
        window.myPhone = window.myPhone || {};
        window.myPhone.namespace = window.myPhone.namespace || {};
        window.myPhone.namespace.publicFunc = this.publicFunc.bind(this);
    }

    destroy(blockId: number) {
        window.myPhone.namespace.publicFunc = null;
    }

    publicFunc(json) {
        //this.popupComponent.showPopupError({text: json});

        if (typeof json === 'string'){
            json = json.replace('SyntaxError: Unexpected end of JSON input','');
            try {
                json = JSON.parse(json);
            }catch (e) {
                this.popupComponent.showPopupError({text: json});
                //this.popupComponent.showPopupError({text: e});
            }
        }




        if(json.status == 'true' || json.status === true){
            let command = json.command;
            if (command == 'postUpload') {
                this.postUploded.next({id:json.id});//Math.round(+new Date()/1000)+Math.random()
            }

            if (command == 'uploadAvatarToContent') {
                this.postAvatarUploded.next({avatar_link:json.avatar_link});
            }

            if (command == 'uploadAvatarToUser') {
                this.userAvatarUploded.next({avatar_link:json.avatar_link});
            }
        }
    }

    setFilesList(){
        let token = this.authenticationService.getToken();
        let json = '{"command":"filesList","token":"'+token+'"}';

        this.sendCommand(json);
    }

    setTakePhoto(){
        let token = this.authenticationService.getToken();
        let json = '{"command":"takePhoto","token":"'+token+'"}';

        this.sendCommand(json);
    }

    setTakeVideo(){
        let token = this.authenticationService.getToken();
        let json = '{"command":"takeVideo","token":"'+token+'"}';

        this.sendCommand(json);
    }

    uploadAvatarToContent(id){
        let token = this.authenticationService.getToken();
        let json = '{"command":"uploadAvatarToContent","token":"'+token+'","id":"'+id+'"}';

        this.sendCommand(json);
    }

    uploadAvatarToUser(id){
        let token = this.authenticationService.getToken();
        let json = '{"command":"uploadAvatarToUser","token":"'+token+'","id":"'+id+'"}';

        this.sendCommand(json);
    }

    sendCommand(json){
        if(window.webkit){
            window.webkit.messageHandlers.phoneListener.postMessage(json);
        }

        //if(AndroidFunction) {
        AndroidFunction.getCommand(json);
        //}
    }
}
