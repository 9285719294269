import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ThermometerLoaderComponent} from "./thermometer-loader/thermometer-loader.component";
import {InfoPopupComponent} from "./info-popup/info-popup.component";

@NgModule({
    declarations: [
        ThermometerLoaderComponent,
        InfoPopupComponent

    ],
    imports: [
        CommonModule,
    ],
    exports: [
        ThermometerLoaderComponent,
        InfoPopupComponent
    ]
})
export class SharedGamesModule { }
