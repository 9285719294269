import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    AfterViewInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren, HostListener
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../modules/auth/_services/authentication.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {LoginService} from "../../../../service/login.service";

import {PopupComponent} from "../../../../shared/popup/popup.component";
import {MenuService} from "../../../../service/menu.service";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {LoaderService} from "../../../../service/loader.service";
import {formatCurrency} from "@angular/common";
import {slideToLeft} from "../../../../animations/router.animations";
import {StylingService} from "../../../../service/styling.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {KeyboardService} from "../../../../service/keyboard.service";
import {CashAppService} from "../../../../service/settings/cashApp.service";


@Component({
    selector: 'app-verify-card',
    templateUrl: './verify-card.component.html',
    styleUrls: ['./verify-card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class VerifyCardComponent implements OnInit {
    pinForm: FormGroup;
    amountsForm: FormGroup;

    numOfDigits = 4;
    card;
    cardId;
    infoPopupShown = false;
    isApp = +localStorage.getItem('isApp');
    clearSmallInput = false;
    @ViewChildren('inputs') inputs: QueryList<ElementRef>;
    @ViewChild('infoPopup', {static: false}) infoPopup: ElementRef;

    RouterPaths = RouterPaths;

    // @HostListener('click', ['$event'])
    // clickInside(event) {
    //     if  (!(<HTMLElement>this.infoPopup.nativeElement).contains(event.target)) {
    //         this.infoPopupShown = false;
    //     };
    // }

    isCashApp = false;
    amount1Prefix = '';
    amount2Prefix = '';
    isCardVerified = false;
    amount1 = null;
    amount2 = null;
    verifyDate;

    constructor(
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private loginService: LoginService,
        private menuService: MenuService,
        private router: Router,
        private userCardService: UserCardService,
        private loaderService: LoaderService,
        private route: ActivatedRoute,
        private auth:AuthenticationService,
        private cashAppService: CashAppService,
        private keyboardService: KeyboardService,
        private stylingService: StylingService,
        private popupComponent: PopupComponent) {

        // this.pinForm = this.formBuilder.group({
        //     digits: this.formBuilder.array([])
        // });
        this.amountsForm = this.formBuilder.group({
            amount1: [null, Validators.required],
            amount2: [null, Validators.required],
            amount3: [null],
            amount4: [null],
        });
    }
    ngOnInit() {
        this.isCashApp = this.cashAppService.checkIfCashApp();

        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo();
        });
    }

    resendCode() {
        this.userCardService.resendCode(this.cardId).subscribe(data => {
            this.checkResendCodeResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkResendCodeResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getCardInfo() {
        this.userCardService.getCardInfo(this.cardId).subscribe(data => {
            this.checkGetCardInfoResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkGetCardInfoResult(data) {
        // data.result.verify = true;
        // data.result.verify_first_amount = .67;
        // data.result.verify_second_amount = .23;
        // data.result.verified_date = '03/22/2022 14:36:05';
        try {
            if(data.status === true){
                this.card = data.result;

                this.isCardVerified = data.result.verify;
                if (this.isCardVerified) {
                    this.amountsForm.controls.amount3.setValue(Number.parseFloat(data.result.verify_first_amount));
                    this.amountsForm.controls.amount4.setValue(Number.parseFloat(data.result.verify_second_amount));

                    this.verifyDate = data.result.verified_date;
                }

            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    // onSubmit() {
    //     if (this.pinForm.invalid) {
    //         return;
    //     }
    //
    //     let pin = this.pinForm.controls.digits.value;
    //     pin = pin.join('');
    //
    //     this.userCardService.verifyCard(pin, this.cardId).subscribe(data => {
    //         this.checkOnSubmitResult(data);
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    onSubmitAmountsForm() {
        const amount1 = +this.amountsForm.controls.amount1.value;

        this.userCardService.verifyCard(amount1, this.cardId).subscribe(data => {
            this.checkOnSubmitResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkOnSubmitResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.amountsForm.reset();
                this.router.navigate(this.getBackLink());
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }
        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        }
        else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    getBackLink() {
        if (this.isCashApp) {
            return [RouterPaths.settings.cashapp.menu, this.cardId];
        } else {
            return [RouterPaths.settings.card.menu, this.cardId];
        }
    }

    // getCardName() {
    //     return this.card && this.card.description ? this.card.description : '';
    // }
    //
    // getVerifyCode() {
    //     return this.card && this.card.verify_code ? this.card.verify_code : '';
    // }

    // getTransactionPrice() {
    //     const price = this.card && this.card.transaction_price ? this.card.transaction_price : 0.5;
    //     return formatCurrency(price, 'en','$')
    // }

    onInputAmount(ev, controlNum: number) {
        const isFirstAmountActive = controlNum === 1;
        const activeField = isFirstAmountActive ? 'amount1' : 'amount2';
        const filledPrefix = '$0.';
        const emptyPrefix = '';

        if (this.amountsForm.controls[activeField].value) {
            if (isFirstAmountActive) {
                this.amount1Prefix = filledPrefix;
            } else {
                this.amount2Prefix = filledPrefix;
            }
        } else {
            if (isFirstAmountActive) {
                this.amount1Prefix = emptyPrefix;
            } else {
                this.amount2Prefix = emptyPrefix;
            }
            this.amountsForm.controls[activeField].setValue(null);
        }
    }
}

