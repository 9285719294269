export class FundsModel {
    private _status: boolean;
    private _result: Array<FundsResultItem>;

    get status(): boolean {
        return this._status;
    }

    set status(value: boolean) {
        this._status = value;
    }

    get result(): Array<FundsResultItem> {
        return this._result;
    }

    set result(value: Array<FundsResultItem>) {
        this._result = value;
    }
}

export class FundsResultItem {
    private _email: string;
    private _created: string;
    private _id: number;
    private _user_id: number;
    private _payment_method: number;

    constructor(data) {
        this._email = data.email;
        this._created = data.created;
        this._id = data.id;
        this._user_id = data.user_id;
        this._payment_method = data.payment_method;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get created(): string {
        return this._created;
    }

    set created(value: string) {
        this._created = value;
    }

    get id(): number {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get user_id(): number {
        return this._user_id;
    }

    set user_id(value: number) {
        this._user_id = value;
    }

    get payment_method(): number {
        return this._payment_method;
    }

    set payment_method(value: number) {
        this._payment_method = value;
    }
}

