import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {RouterPaths} from "../../../config/router.paths.model";
import {PROJECT_MESSAGES} from "../../../config/project";
import {UserBalanceService} from "../../../service/users/userBalance.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {
    GAME_VENDORS_GOLDENDRAGON,
    GAME_VENDORS_RIVER
} from "../../../config/gameVendors";
import {PopupRewardsGameInfoModel} from "./popup-rewards-game-form.config";

@Component({
    selector: 'app-popup-rewards-game-form',
    templateUrl: './popup-rewards-game-form.component.html',
    styleUrls: ['./popup-rewards-game-form.component.scss']
})
export class PopupRewardsGameFormComponent implements OnInit {
    @Input() popupInfo: PopupRewardsGameInfoModel;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() updateBalance: EventEmitter<any> = new EventEmitter();
    @Output() onGameSweep: EventEmitter<any> = new EventEmitter();

    balanceForm: FormGroup;
    moreThenAvailablePayout = false;

    ROUTER_PATHS = RouterPaths;
    GAME_VENDORS_RIVER = GAME_VENDORS_RIVER;
    GAME_VENDORS_GOLDENDRAGON = GAME_VENDORS_GOLDENDRAGON;
    constructor(private loaderService: LoaderService,
                private userBalanceService: UserBalanceService,
                private popupComponent: PopupComponent,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.balanceForm = this.formBuilder.group({
            amount: [null, Validators.required]
        });

    }

    closePopup() {
        this.close.emit(true);
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.availableBalance === 'number' ? this.popupInfo.availableBalance : Number.parseFloat(this.popupInfo.availableBalance);
        const entered = Number.parseFloat(this.balanceForm.controls.amount.value);

        if (avaliable < entered) {
            this.balanceForm.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }

    onSubmitBalanceForm() {
        const amount = this.balanceForm.controls.amount.value;
        if (!amount) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            // const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            // this.popupComponent.showPopupConfirmCancel({text}, (status) => {
            //     if (status) {
            //
            //     }
            // });
            this.userBalanceService.increaseBalance(amount).subscribe(data => {
                this.displaySubmitBalanceResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        }
    }

    displaySubmitBalanceResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.balanceForm.controls.amount.setValue(null);
                this.moreThenAvailablePayout = false;
                this.updateBalance.emit();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    sweepGame() {
        this.onGameSweep.emit();
    }
}
