import {Injectable} from "@angular/core";
import {REPORTS_CONFIG, USER_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
  providedIn: 'root'
})
export class WeeklyReportService extends SettingsLoaderService {
  private config = REPORTS_CONFIG;

  constructor(private http: HttpClient, public loaderService: LoaderService) {
    super(loaderService);
  }

  getReport(period?: string) {
    this.showLoader();
    if (period) {
      return this.http.post<any>(this.config.weekly, {period});
    } else {
      return this.http.post<any>(this.config.weekly, {});
    }
  }

  getSenetReport(period?: string) {
    this.showLoader();
    if (period) {
      return this.http.post<any>(this.config.senetReport, {period});
    } else {
      return this.http.post<any>(this.config.senetReport, {});
    }
  }
}
