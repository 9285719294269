import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {PRIZES_SENETPRO_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";


@Injectable({
    providedIn: 'root'
})
export class PrizesSenetProService extends SettingsLoaderService {
    private config = PRIZES_SENETPRO_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getPageInfo(page, singleHistoryPage) {
        this.showLoader();
        return this.http.post<any>(this.config.info, {page, singleHistoryPage});
    }

    redeem(amount) {
        this.showLoader();
        return this.http.post<any>(this.config.redeem, {amount});
    }

    withdraw(shortname,amount) {
        this.showLoader();
        return this.http.post<any>(this.config.withdraw, {shortname,amount});
    }

    sendFriendPhone(phone) {
        this.showLoader();
        return this.http.post<any>(this.config.sendFriendPhone, {phone});
    }

    sendToFrined(phone,amount) {
        this.showLoader();
        return this.http.post<any>(this.config.sendToFriend, {phone,amount});
    }

    getPaypalInfo() {
        this.showLoader();
        return this.http.post<any>(this.config.getPaypalInfo, {});
    }

    getVenmoInfo() {
        this.showLoader();
        return this.http.post<any>(this.config.getVenmoInfo, {});
    }

    getDebitCardInfo() {
        this.showLoader();
        return this.http.post<any>(this.config.getDebitCardInfo, {});
    }
}
