<script src="../../../config/crypto_settings.ts"></script>
<div class="page page--tertiary">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">Mascot</h1>

        <div class="page__head">
            <h2 class="page__subtitle">{{period ? 'Monthly' : 'Weekly'}} Revenue Report</h2>
            <!--<p *ngIf="reportData">{{reportData.date | date: 'EEEE, MMMM d, y'}}</p>-->
            <div class="report-controls">
                <button class="report-controls__control"
                        (click)="getReport(REPORT_PERIOD.weekly)"
                        [disabled]="!period">Weekly</button>
                <button class="report-controls__control"
                        (click)="getReport(REPORT_PERIOD.monthly)"
                        [disabled]="period">Monthly</button>
            </div>
        </div>

        <div class="page__scrolled">


            <div class="page__body">
                <table class="report-table" *ngIf="reportWeeksList.length">
                    <thead>
                    <tr class="accent-darken centered">
                        <th>Date</th>
                        <th>Type</th>
                        <th>Inflows</th>
                        <th>Free Play</th>
                        <th>Outflows</th>
                        <th>NET</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let week of reportWeeksList">

                        <ng-container *ngFor="let item of week.details; let l = index">
                            <tr>
                                <td *ngIf="l == 0" rowspan="4">{{week.date | date: 'M/d/yy'}}</td>
                                <td>{{item.name}}</td>
                                <td>
                                    <div class="report-table__cell-inner">
                                        <app-financial-info-item [showZeroValue]="true"
                                                                 [value]="item.in"></app-financial-info-item>
                                    </div>
                                </td>
                                <td>
                                    <div class="report-table__cell-inner">
                                        <app-financial-info-item [showZeroValue]="true"
                                                                 [value]="item.free"></app-financial-info-item>
                                    </div>
                                </td>
                                <td>
                                    <div class="report-table__cell-inner">
                                        <app-financial-info-item [showZeroValue]="true"
                                                                 [value]="item.out"></app-financial-info-item>
                                    </div>
                                </td>
                                <td>
                                    <div class="report-table__cell-inner">
                                        <app-financial-info-item [showZeroValue]="true"
                                                                 [value]="item.net"></app-financial-info-item>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>

                        <tr class="accent-lighten bold">
                            <td>TOTALS</td>
                            <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="week.in"></app-financial-info-item></div></td>
                            <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="week.free"></app-financial-info-item></div></td>
                            <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="week.out"></app-financial-info-item></div></td>
                            <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="week.net"></app-financial-info-item></div></td>
                        </tr>
                        <tr class="bold">
                            <td colspan="4" style="border-left-color: transparent;"></td>
                            <td class="accent">Due ({{week.percent}}%)</td>
                            <td class="accent"><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="week.due"></app-financial-info-item></div></td>
                        </tr>
                    </ng-container>

                    <tr class="empty">
                        <th colspan="6">empty</th>
                    </tr>
                    <tr class="accent-darken centered">
                        <th>Date</th>
                        <th>Inflows</th>
                        <th>Free Play</th>
                        <th>Outflows</th>
                        <th>NET</th>
                        <th>Due</th>
                    </tr>

                    <tr *ngFor="let week of reportWeeksList">
                        <td>{{week.date | date: 'M/d/yy'}}</td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="getTotal(week, REPORT_DATA_COLS.inflows)"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="getTotal(week, REPORT_DATA_COLS.freePlay)"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="getTotal(week, REPORT_DATA_COLS.outflows)"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="getTotal(week, REPORT_DATA_COLS.net)"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><span><app-financial-info-item [showZeroValue]="true" [value]="getTotal(week, REPORT_DATA_COLS.due)"></app-financial-info-item></span></div></td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr class="accent-lighten bold">
                        <td class="accent">{{reportTotal.rtp}} GGR</td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="reportTotal.in"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="reportTotal.free"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="reportTotal.out"></app-financial-info-item></div></td>
                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="reportTotal.net"></app-financial-info-item></div></td>

                        <td><div class="report-table__cell-inner"><app-financial-info-item [showZeroValue]="true" [value]="reportTotal.due"></app-financial-info-item></div></td>
                    </tr>
                    </tfoot>
                </table>
            </div>

            <canvas *ngIf="barChartData"
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [chartType]="barChartType">
            </canvas>

        </div>

    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>
