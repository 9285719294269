import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {PaypalService} from "../../../../service/paypal/paypal.service";
import {LoaderService} from "../../../../service/loader.service";
import {PartnerService} from "../../../../service/partner.service";

@Component({
    selector: 'app-paypal-payments-unsuccess',
    templateUrl: './paypal-payments-unsuccess.component.html',
    styleUrls: ['./paypal-payments-unsuccess.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalPaymentsUnsuccessComponent implements OnInit {
    partnerBusinessName = '';

    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private partnerService: PartnerService,
                private paypalService: PaypalService) {

    }

    ngOnInit() {
        this.partnerBusinessName = this.partnerService.getBusinessName();
        var url = new URL(window.location.href);

        try {
            var paymentId = url.searchParams.get("paymentId");

            if(paymentId){
                this.loaderService.show();
                this.paypalService.paymentUnsuccess(paymentId).subscribe(data => {
                    this.loaderService.hide();


                });
            }
        }catch (e) {

        }
    }

    closeWindow(){
        window.open('','_self').close();
    }
}
