import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {PAGE_COLORS} from "../config/page_colors";
import {RouterPaths} from "../config/router.paths.model";

@Injectable({
    providedIn: 'root'
})
export class StylingService {
    pageColors = PAGE_COLORS;

    constructor(private router: Router,) {
    }

    getPageColor() {
        if (this.router.isActive(RouterPaths.rewards.main, false)) {
            return this.pageColors.rewards;
        } else if (this.router.isActive(RouterPaths.prizes.main, false)) {
            return this.pageColors.prizes;
        } else if (this.router.isActive(RouterPaths.settings.home, false)) {
            return this.pageColors.settings;
        } else {
            return this.pageColors.default;
        }
    }
}
