<div class="page page--quaternary" [class.is-settings]="isPartnerTypeSingleBalance()"
                                   [class.page--blured]="menu || showSupportForm || showNFTPopup || iframePopup.show">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">SETTINGS</h1>

        <div class="page__scrolled">
            <div class="page__head">
                <div class="page__left">
                    <div *ngIf="userStatus === USER_STATUS.active" class="page__status gl-title">ACCOUNT ACTIVE</div>
                    <div *ngIf="userStatus !== USER_STATUS.active" class="page__status gl-title">ACCOUNT FROZEN</div>
                </div>
                <div class="page__right">
                    <button (click)="menu = true" class="page__options js-open-menu">
                        <svg viewBox="0 0 29 7" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                        </svg>
                    </button>
                </div>
                <div *ngIf="userStatus === USER_STATUS.frozen" class="page__status-info">User Frozen</div>
                <div *ngIf="userStatus === USER_STATUS.blocked" class="page__status-info">Admin Frozen</div>

<!--                <div class="page__status-info page__status-info&#45;&#45;left">Limit three (3) CC purchases per 24 hours</div>-->
                <div *ngIf="!isPartnerTypeSingleBalance()" class="page__status-info page__status-info--left gl-subtitle">You are depositing for {{gameVendorName}}</div>
                <div *ngIf="isPartnerTypeSingleBalance()" class="page__status-info page__status-info--left gl-subtitle">All deposits with credit and debit cards as well as all the deposits received via Cash App will be applied to your {{getPartnerBusinessName()}} Balance. You can withdraw from the balance and deposit on any available gaming system</div>

            </div>

            <div class="page__body">
                <div id="addFunds" class="page__funds-form" *ngIf="isPartnerTypeSingleBalance()">
                    <app-funds-form (openPopup)="openNFTPopup($event)"
                                    [resetForm]="resetNftForm"
                                    (emitResetForm)="nftFormIsReseted()"
                                    (emitAddNewCard)="scrollTo('addCard')"
                                    [page]="'settings'"
                    ></app-funds-form>
                </div>


                <app-dropdown-item [title]="'CASH APP PAY'"
                                   [noBodyLeftPadding]="true"
                                   [headTwoColumns]="true"
                                   [dropdownClosed]="true"
                                   [hasPagination]="true"
                                   *ngIf="!isPartnerTypeSingleBalance()">
                    <div class="dropdown__body"  #dropBody>
                        <app-group-bordered>
                            <!--<h2>Temporarily Unavailable</h2>-->
                            <!--<h4>CashApp Payment Temporarily Unavailable</h4>-->
                            <h2>CASH APP PAY</h2>
                            <h4 *ngIf="!showCashApp">{{showCashAppMessage}}</h4>
                            <h4 *ngIf="showCashApp">{{subjectLimitationTxt}}</h4>

                            <div class="page__form group-bordered-inner" *ngIf="showCashApp">
<!--                                <ng-container *ngIf="!cashappVerified">-->
<!--                                    <app-verify-cashapp-tag [verifyStatus]="cashappVerified" (onVerify)="setCashappVerified($event)"></app-verify-cashapp-tag>-->
<!--                                </ng-container>-->

                                <div class="info-block">
<!--                                    <h2>SEND FROM YOUR CASH APP-->
<!--                                        <span class="color-secondary">{{userCashappTag}}</span>-->
<!--                                    </h2>-->
<!--                                    <h2>SEND TO CASH APP-->
<!--                                        <span class="color-secondary">{{cashappTag}}-->
<!--                                            <app-copy-to-buffer-button [value]="cashappTag"></app-copy-to-buffer-button>-->
<!--                                        </span>-->
<!--                                    </h2>-->
<!--                                    <ul>-->
<!--                                        <li>enter the amount you wish</li>-->
<!--                                        <li>add comment <span class="color-secondary">{{gameVendorShortName}}</span>-->
<!--                                        </li>-->
<!--                                        <li>send to <span class="color-secondary">{{cashappTag}}</span>-->
<!--                                            <app-copy-to-buffer-button [value]="cashappTag"></app-copy-to-buffer-button>-->
<!--                                        </li>-->
<!--                                    </ul>-->
                                    <form [formGroup]="cashAppPayForm" class="form-simple form-simple--shrink form-simple--primary">
                                        <div class="form-simple__item form-simple__item--amount">
                                            <input mask="separator.0"
                                                   thousandSeparator=","
                                                   formControlName="amount"
                                                   placeholder="Amount"
                                                   [type]="'tel'"
                                                   class="form-simple__input"
                                                   autocomplete="off">
                                            <span>.00</span>
                                        </div>

                                        <div class="form-simple__btns form-simple__btns--middle" style="margin-bottom: 1rem">
                                            <button class="js-submit-btn button snake-btn is-notouched"
                                                    (click)="onCashAppPayFormSubmit()"
                                                    [disabled]="!+cashAppPayForm.controls.amount.value">
                                                Deposit
                                                <svg><rect></rect></svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
<!--                                <div class="navigation">-->
<!--                                    <a *ngFor="let item of navigationNoLimits"-->
<!--                                       id="{{item.id}}"-->
<!--                                       [hidden]="item.hidden"-->
<!--                                       (click)="item.action()"-->
<!--                                       class="navigation__button button snake-btn is-notouched {{background(item.id)}}"-->
<!--                                       style="cursor: pointer;">-->
<!--                                        {{item.title}}-->
<!--                                        <svg><rect></rect></svg>-->
<!--                                    </a>-->
<!--                                </div>-->
                            </div>
                        </app-group-bordered>
                    </div>
                </app-dropdown-item>

                <app-dropdown-item [title]="'Subject to limitations'"
                                   [noBodyLeftPadding]="true"
                                   [headTwoColumns]="true"
                                   [dropdownClosed]="true"
                                   [hasPagination]="true"
                                   *ngIf="!isPartnerTypeSingleBalance()">
                    <div class="dropdown__body" #dropBody>
                        <app-group-bordered>
                            <h2>SUBJECT TO LIMITATIONS</h2>
                            <h4>{{subjectLimitationTxt}}</h4>
                            <div class="page__form group-bordered-inner">
                                <div class="info-block">
                                    <h2></h2>
                                </div>
                                <div class="navigation">
                                    <a id="{{item.id}}"
                                       *ngFor="let item of navigation"
                                       [hidden]="item.hidden"
                                       (click)="isAvailble(item.id,item.link)"
                                       class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                                       style="cursor: pointer;">
                                        {{item.title}}
                                        <svg><rect></rect></svg>
                                    </a>
                                </div>
                            </div>
                        </app-group-bordered>
                    </div>
                </app-dropdown-item>

                <div class="settings-wrapper"  *ngIf="isPartnerTypeSingleBalance()">
                    <h1 class="page__status gl-title">CASH APP DEPOSITS</h1>
                    <div *ngIf="!showCashApp" class="page__status-info page__status-info--left gl-subtitle">{{showCashAppMessage}}</div>

                    <ng-container *ngIf="showCashApp">
                        <div class="page__status-info page__status-info--left gl-subtitle">Cash App deposits are direct transfers to {{getPartnerBusinessName()}} Cash App account. All deposits are automated. You have to register your Cash App here in order for transfers to work. Cash App deposits have no limitation.</div>
                        <ng-container *ngIf="!cashappVerified">
                            <app-verify-cashapp-tag [verifyStatus]="cashappVerified" (onVerify)="setCashappVerified($event)"></app-verify-cashapp-tag>
                        </ng-container>

                        <div class="info-block" *ngIf="cashappVerified">
                            <div class="info-block__row">
                                <div>Send to</div>
                                <div class="color-secondary">{{cashappTag}}</div>
                            </div>
                            <div class="info-block__row">
                                <div>Send from</div>
                                <div class="color-secondary">{{userCashappTag}}</div>
                            </div>
                        </div>
                        <div class="navigation">
                            <a id="{{item.id}}"
                               *ngFor="let item of navigationNoLimits"
                               [hidden]="item.hidden"
                               (click)="item.action()"
                               class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                               style="cursor: pointer;">
                                {{item.title}}
                                <svg><rect></rect></svg>
                            </a>
                        </div>
                    </ng-container>
                </div>
                <div class="settings-wrapper" id="addCard" *ngIf="isPartnerTypeSingleBalance()">
                    <h1 class="page__status gl-title">CARD DEPOSITS</h1>
<!--                    <div class="page__status-info page__status-info&#45;&#45;left gl-subtitle">Card deposits are limited to five (5) transactions per 24 hours and each cannot exceed $500.</div>-->
                    <div class="page__status-info page__status-info--left gl-subtitle">{{subjectLimitationTxt}}</div>

                    <div class="navigation">
                        <a id="{{item.id}}"
                           *ngFor="let item of navigation"
                           [hidden]="item.hidden"
                           (click)="isAvailble(item.id,item.link)"
                           class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                           style="cursor: pointer;">
                            {{item.title}}
                            <svg><rect></rect></svg>
                        </a>
                    </div>
                </div>

                <div class="navigation">
                    <div class="navigation__col" [hidden]="isRiverPay">
                        <a *ngIf="!isPartnerTypeSingleBalance()" id="funds_btn" [routerLink]="getLinkToFunds()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke="#DCBCFF" stroke-width="2"/>
                            </svg>
                        </a>

                        <button *ngIf="isPartnerTypeSingleBalance()" (click)="scrollTo('funds')" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke="#DCBCFF" stroke-width="2"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

<div class="addition-menu addition-menu--top" [class.is-active]="menu">
    <ul class="addition-menu__list">
<!--        <li class="addition-menu__item">-->
<!--            <button class="js-freeze-account" *ngIf="userStatus === USER_STATUS.active" (click)="askFreezeUser()">Freeze Account</button>-->
<!--            <button class="js-unfreeze-account" *ngIf="userStatus !== USER_STATUS.active" (click)="askUnFreezeUser()">Unfreeze Account</button>-->
<!--        </li>-->
        <li class="addition-menu__item">
            <button class="js-delete-account" (click)="askDeleteUser()">Delete</button>
        </li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn js-close-menu">Cancel</button>
</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>

<app-iframe-popup *ngIf="iframePopup.show"
                  [url]="iframePopup.url"
                  (onClose)="hideIframePopup($event)"></app-iframe-popup>
