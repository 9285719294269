import {Inject, Injectable, NgZone} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class CashAppService {

    constructor(private router: Router,) {

    }

    checkIfCashApp() {
        return this.router.url.includes('cashapp');
    }

    isPrepaid(){
        return this.router.url.includes('prepaid');
    }
}
