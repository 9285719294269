<div class="page page--quaternary">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">Help</h1>

        <div class="page__scrolled">
            <div class="page__head">
                <div class="page__left">
                    <div class="page__status gl-title">SENT TO {{cashappTag}}</div>
                </div>
                <div class="page__right">
                </div>
                <div class="page__status-info page__status-info--left gl-subtitle">Make sure you add word {{gameVendorShortName}} to comments</div>
            </div>

            <div class="page__body">
                <div class="help-screen">
                    <img class="help-screen__img" src="../../../../assets/images/help-screen.jpg" alt="Help Screenshort">
                </div>

                <a [routerLink]="getAddLink()" [queryParams]="{'back': true}" class="button snake-btn is-notouched">
                    Back
                    <svg><rect></rect></svg>
                </a>
            </div>
        </div>
    </div>
</div>
