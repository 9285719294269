<div class="page page--secondary long-content prizes" [class.page--blured]="popupConfirmInfo.show || popupDebitCardInfo.show || popupMobileTopupInfo.show">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>
        <h1 class="page__title">PRIZES</h1>

        <div class="page__scrolled">
            <div class="prizes__col">
                <div class="prizes-heading">
                    <div class="prizes__row">
                        <div class="gl-title">Total being processed</div>
                        <div class="gl-text c-accent">{{dailyInfo.total | currency : 'USD' : 'symbol'}}</div>
                    </div>
                    <div class="gl-subtitle">Below are rewards that you requested today</div>
                    <ng-container *ngIf="dailyInfo.total > 0">
                        <div class="prizes__row" *ngFor="let item of dailyInfo.list">
                            <div class="gl-text">{{item.name}}</div>
                            <div class="gl-text">{{item.amount | currency : 'USD' : 'symbol'}}</div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="page__body">
                <div class="prizes__row">
                    <div class="gl-title">{{gameVendorName}}</div>
                    <div class="gl-text c-accent">{{prizesInfo?.totalDebit | currency : 'USD' : 'symbol'}}</div>
                </div>
                <div class="gl-subtitle">You are viewing rewards for {{gameVendorName}} Sweeps promotions. Change below to see rewards in other available systems.</div>

                <div class="prizes__transfer-block">
                    <form [formGroup]="form" (ngSubmit)="showPopupConfirm(PRIZES_METHODS.games)" class="form-simple form-simple--primary">
                        <div class="prizes__transfer-block">
                            <div class="form-simple__item">
                                <mat-form-field>
                                    <mat-select #methodsSelect
                                                formControlName="method"
                                                (valueChange)="onProviderChange($event)"
                                                [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                                        <mat-select-trigger>
                                            {{methodsSelect?.value?.name}}
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let method of games" [value]="method">
                                            {{method.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="gl-page-title c-accent">
                            INSTANT TRANSFERS
                        </div>
                        <div class="gl-subtitle">
                            Available 24/7. Instant transfer. Allows you Transfering Prizes between the systems.
                        </div>

                        <div class="form-simple__btns">
                            <button class="button snake-btn is-notouched">
                                Select
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div class="prizes__transfer-block" *ngIf="isDisplayMethod(PRIZES_METHODS.giftCardVisa) || isDisplayMethod(PRIZES_METHODS.giftCardAmazon)">
                    <form class="form-simple form-simple--shrink form-simple--primary">
                        <div class="gl-page-title c-accent">
                            GIFT CARDS
                        </div>
                        <div class="gl-subtitle gl-subtitle--opacity" [innerHTML]="giftCards.description"></div>


                        <div class="form-simple__btns form-simple__btns--column">
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDisplayMethod(PRIZES_METHODS.giftCardVisa)"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.giftCardVisa)">
                                Visa
                                <svg><rect></rect></svg>
                            </button>
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDisplayMethod(PRIZES_METHODS.giftCardAmazon)"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.giftCardAmazon)">
                                Amazon
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div class="prizes__transfer-block"
                     *ngIf="isDisplayMethod(PRIZES_METHODS.paypal) || isDisplayMethod(PRIZES_METHODS.zelle) || isDisplayMethod(PRIZES_METHODS.venmo)">
                    <form class="form-simple form-simple--shrink form-simple--primary">
                        <div class="gl-page-title c-accent">
                            DAILY TRANSFERS
                        </div>
                        <div class="gl-subtitle">
                            Available 24/7. Batched & sent out at 5pm daily. Allows you claiming prizes at any time. Prizes are aggregated and sent out once per day 5pm, everyday
                        </div>

                        <div class="form-simple__btns form-simple__btns--column">
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDisplayMethod(PRIZES_METHODS.paypal)"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.paypal)">
                                PayPal
                                <svg><rect></rect></svg>
                            </button>
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDisplayMethod(PRIZES_METHODS.venmo)"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.venmo)">
                                Venmo
                                <svg><rect></rect></svg>
                            </button>
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDisplayMethod(PRIZES_METHODS.zelle)"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.zelle)">
                                Zelle
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

<!--                <div class="prizes__transfer-block" *ngIf="isDisplayMethod(PRIZES_METHODS.mobileTopup)">-->
<!--                    <form class="form-simple form-simple&#45;&#45;shrink form-simple&#45;&#45;primary">-->
<!--                        <div class="gl-page-title c-accent">-->
<!--                            MOBILE TOPUP-->
<!--                        </div>-->
<!--                        <div class="gl-subtitle">-->
<!--                            {{mobileTopup.description}}-->
<!--                        </div>-->

<!--                        <div class="form-simple__btns">-->
<!--                            <button class="button snake-btn is-notouched"-->
<!--                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.mobileTopup)">-->
<!--                                Phone-->
<!--                                <svg><rect></rect></svg>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </form>-->
<!--                </div>-->

                <div class="prizes__transfer-block" *ngIf="isDisplayMethod(PRIZES_METHODS.card)">
                    <form class="form-simple form-simple--shrink form-simple--primary">
                        <div class="gl-page-title c-accent">
                            DEBIT CARD TRANSFERS
                        </div>
                        <div class="gl-subtitle">
                            {{debitCardDescription}}
                        </div>

                        <div *ngIf="isDebitCardStatusPending()" class="gl-subtitle c-accent" style="opacity: 1; font-style: italic">
                            {{debitCardPendingText}}
                        </div>

                        <div *ngIf="isDebitCardStatusError() || isDebitCardStatusErrorCard()" class="gl-subtitle"  style="opacity: 1">
                            <span class="c-accent">Debit Card Setup Failed. Error: </span>
                            <span class="gl-subtitle">{{debitCardErrorMessage}}</span>
                        </div>

                        <div *ngIf="isDebitCardStatusSelected()" class="gl-subtitle c-accent"  style="opacity: 1; display: flex; justify-content: space-between">
                            <div>{{debitCardSelectedName}}</div>
                            <button class="edit-card-btn" (click)="showDebitCardPopup()">
                                edit
                                <svg>
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                                </svg>
                            </button>
                        </div>

                        <div class="form-simple__btns">
                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDebitCardStatusDefault()"
                                    (click)="showDebitCardPopup()">
                                Select
                                <svg><rect></rect></svg>
                            </button>

                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDebitCardStatusPending()"
                                    disabled>
                                PENDING
                                <svg><rect></rect></svg>
                            </button>

                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDebitCardStatusError()"
                                    (click)="showDebitCardAdditionInfo()">
                                CORRECT
                                <svg><rect></rect></svg>
                            </button>

                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDebitCardStatusErrorCard()"
                                    (click)="showDebitCardPopup()">
                                CORRECT
                                <svg><rect></rect></svg>
                            </button>

                            <button class="button snake-btn is-notouched"
                                    *ngIf="isDebitCardStatusSelected()"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.card)">
                                Select
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div class="prizes__transfer-block" *ngIf="isDisplayMethod(PRIZES_METHODS.cashapp)">
                    <form class="form-simple form-simple--shrink form-simple--primary">
                        <div class="gl-page-title c-accent">
                            BANKING HOURS TRANSFERS
                        </div>
                        <div class="gl-subtitle">
                            Available Monday thru Friday 9am to 5pm.
                        </div>

                        <div class="form-simple__btns">
                            <button class="button snake-btn is-notouched"
                                    (click)="showWorkingTimeMessage($event, PRIZES_METHODS.cashapp)">
                                CashApp
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>
                <div class="dropdown js-prizes-parent" [class.is-hidden]="dropdownClosed">
                    <div class="dropdown__head dropdown__head--two-columns"
                         (click)="toggleDropdown()">
                        <div class="dropdown__title">
                            Prizes Sent
                        </div>
                        <div>
                            <div id="prizes_total" class="dropdown__title">{{prizesInfo?.totalCredit | currency : 'USD' : 'symbol'}}</div>
                            <svg class="dropdown__arrow" width="21" height="12">
                                <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                            </svg>
                        </div>
                    </div>
                    <div class="dropdown__body dropdown__body--prizes" #scrolledEl (scroll)="scroll($event, prizesData)" #dropBody>
                        <table id="prizes_transactions_table" class="transactions">
                            <tr class="transactions__head">
                                <th class="transactions__type">TYPE</th>
                                <th class="transactions__date">DATE</th>
                                <th class="transactions__amount">AMOUNT</th>
                            </tr>
                            <tr *ngFor="let action of prizesInfo?.credit" class="transactions__item js-prizes-item">
                                <td class="transactions__type">
                                    <div class="transactions__icon-wrapper">
                                        <svg *ngIf="getPaymentIcon(action.type) !== 'usd_coin' && getPaymentIcon(action.type) !== 'referrals'" class="transactions__icon">
                                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPaymentIcon(action.type)"></use>
                                        </svg>

                                        <svg *ngIf="getPaymentIcon(action.type) === 'usd_coin' && getPaymentIcon(action.type) !== 'referrals'" class="transactions__icon transactions__icon--usdc">
                                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPaymentIcon(action.type)"></use>
                                        </svg>

                                        <img src="../../../assets/images/referrals_program.png" *ngIf="getPaymentIcon(action.type) === 'referrals'" class="transactions__icon">

                                    </div>
                                </td>
                                <td class="transactions__date">{{getDate(action.created) | date: 'MM/dd/yyyy'}}</td>
                                <td class="transactions__amount">{{action.amount | currency : 'USD' : 'symbol'}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>

<app-investments-popup-form [showPopupForm]="showPopup"
                            [data]="popupFormData"
                            [formType]="popupFormType"
                            [fromPage]="'prizes'"
                            (close)="onPopupFormClose($event)"></app-investments-popup-form>

<app-popup-prizes-confirm [popupInfo]="popupConfirmInfo"
                          (changeHandle)="changeHandlePopupConfirm($event)"
                          (confirm)="submitPopupConfirm($event)"
                          (close)="closePopupConfirm()">
</app-popup-prizes-confirm>

<app-popup-prizes-debit-card [popupInfo]="popupDebitCardInfo"
                             (close)="closeDebitCardPopup()">
</app-popup-prizes-debit-card>

<app-popup-mobile-topup [popupInfo]="popupMobileTopupInfo"
                        (close)="closePopupMobileTopup()">
</app-popup-mobile-topup>
