import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ScratchOffService} from "../../../service/games/scratchOff.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ScrollToService} from "../../../service/scrollTo.service";
import {PROJECT_MESSAGES} from "../../../config/project";
import {FundsService} from "../../../service/settings/funds.service";
import {NFT_POPUP_TYPE} from "../../investments/investments-nft-popup/investments-nft-popup.config";
import {RouterPaths} from "../../../config/router.paths.model";


@Component({
    selector: 'app-games-payment',
    templateUrl: './games-payment.component.html',
    styleUrls: ['./games-payment.component.scss']
})

export class GamesPaymentComponent implements OnInit {
    // @Output() isPaymentForbidden = new EventEmitter();
    @Output() isPaymentSuccess = new EventEmitter();
    @Output() closePopup = new EventEmitter();

    buyForm: FormGroup;
    activeMethod;

    showNFTPopup = false;
    NFTPopupData;

    private popupVisible = false;
    @Input() data: any;
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
      this._showPopup = value;
      if (value) {
          this.popupVisible = true;
          setTimeout(() => {
              this.setCheckedMethod();
          });
      }
    }

    get showPopup() : boolean  {
      return this._showPopup;
    }

    iframePopup = {
        show: false,
        url: null
    }

    constructor(private scratchOffService: ScratchOffService,
                private formBuilder: FormBuilder,
                private scrollToService: ScrollToService,
                private popupComponent: PopupComponent,
                private fundsService: FundsService,
                private loaderService: LoaderService) {

    }

    ngOnInit() {
        this.buyForm = this.formBuilder.group({
            paymentMethod: [null, Validators.required],
        });
    }

    ngOnDestroy() {
        this.popupVisible = false;
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.remove('is-blur');
        }
        document.body.classList.remove('hide-menu');
    }

    buyGame() {
        let method = this.buyForm.controls.paymentMethod;

        if (!this.data.result.length) {
            this.popupComponent.showPopupError({text: 'Please enter your payment method'}, 'btn-ok');
        } else {
            if (this.data.result.length > 1 && method === null) {
                this.popupComponent.showPopupError({text: 'Please select the payment method to use'}, 'btn-ok');
            } else {
                if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
                    this.showNoVerifyErrorMessage();
                } else {
                    this.showConfirmMessage();
                }
            }
        }
    }

    showConfirmMessage() {
        const amount = this.data.amount;

        this.fundsService.getDepositInfo(amount).subscribe(data => {
            try {
                if (data.status) {
                    this.onShowNFTPopup(data);
                } else {
                    this.popupComponent.showPopupError({
                        text: data.message,
                        confirmButtonText:'OK'
                    }, 'btn-ok');
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShowNFTPopup(data) {
        const description = this.activeMethod.description;
        const number = this.activeMethod.last_four;

        data.cardInfo = {
            description,
            number
        }

        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.funds,
            data: {
                element: data
            }
        }
    }

    showNoVerifyErrorMessage() {
        if (this.activeMethod && this.activeMethod.last_four) {
            const message = this.activeMethod.description ?
              `Please verify ${this.activeMethod.description} - ${this.activeMethod.last_four} first. Go to the Card settings page.` :
              `Please verify card ending in ${this.activeMethod.last_four}.`;

            this.popupComponent.showPopupError({text: message}, 'btn-ok');
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError}, 'btn-ok');
        }
        return;
    }

    getPaymentMethod(method) {
        return method.payment_method;
    }

    getMethodId(method) {
        return method.id;
    }

    getPaymentName(data){
        if (data) {
            return data.description+' - '+data.last_four;
        }
        return '';
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    setCheckedMethod() {
        if (!this.data.result.length) {
            this.buyForm.controls.paymentMethod.setValue(null);
            this.activeMethod = null;
        } else {
            let method = this.data.result[this.data.result.length-1];
            this.buyForm.controls.paymentMethod.setValue(method);
            this.activeMethod = method;
        }
        this.scrollToLastMethod();
    }
    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

    onPopupNFTClose(result) {
        this.closePopup.emit();
        this.showNFTPopup = false;
        document.getElementById('mascot-popup-pig').classList.add('is-blurred');
        if (result) {
            const cardId = this.activeMethod.id;
            this.scratchOffService.buyGame(cardId).subscribe(data => {
                if (data.status) {
                    if (data.threeDSecure) {
                        if (data.threeDSecureUrl) {
                            this.showIframePopup(data.threeDSecureUrl);
                        } else {
                            this.loaderService.hideAndShowTryAgain();
                        }
                    } else {
                        this.isPaymentSuccess.emit(data);
                        this.showNFTPopup = false;
                        document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        } else {
            this.showNFTPopup = false;
            document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
        }
    }

    onClose() {
        this.closePopup.emit();
    }

    getAddLink() {
        return RouterPaths.settings.card.add;
    }

    showIframePopup(url) {
        document.body.classList.add('hide-menu');
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.add('is-blur');
        }
        this.iframePopup = {
            show: true,
            url: url
        }
    }

    hideIframePopup(result) {
        document.body.classList.remove('hide-menu');
        document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.remove('is-blur');
        }
        this.iframePopup = {
            show: false,
            url: null
        }
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            } else {
                if (result.status) {
                    this.isPaymentSuccess.emit(result);
                    this.showNFTPopup = false;
                } else {
                    this.popupComponent.showPopupError({text: result.message}, 'btn-ok');
                }
            }
        }
    }
}
