import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { AuthConfig } from './auth-config';
import { AUTH_CONFIG } from '../../../config/api';
import { BehaviorSubject } from 'rxjs';
import { SettingsLoaderService } from "../../../service/settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "./auth-config";
import * as i2 from "@angular/common/http";
import * as i3 from "../user-idle/user-idle.service";
import * as i4 from "../../../service/users/users-point.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../service/loader.service";
var AuthenticationService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthenticationService, _super);
    function AuthenticationService(authConfig, http, userIdle, usersPointService, router, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.authConfig = authConfig;
        _this.http = http;
        _this.userIdle = userIdle;
        _this.usersPointService = usersPointService;
        _this.router = router;
        _this.loaderService = loaderService;
        _this.config = AUTH_CONFIG;
        _this.storage = sessionStorage;
        _this.rolesObservable = new EventEmitter();
        _this.userLoggedIn = new EventEmitter();
        _this.userLoggedStatusChanged = new BehaviorSubject(_this.isUserLogged());
        return _this;
    }
    AuthenticationService.prototype.isUserLogged = function () {
        return !!this.storage.getItem('currentUser');
    };
    AuthenticationService.prototype.sendPin = function (phone) {
        return this.http.post(this.config.sendPin, { phone: phone });
    };
    AuthenticationService.prototype.checkUser = function (phone, userName) {
        return this.http.post(this.config.checkUser, { phone: phone, userName: userName });
    };
    AuthenticationService.prototype.checkPhoneAndSendPin = function (phone, country) {
        this.showLoader();
        var pointHash = this.usersPointService.getHash();
        return this.http.post(this.config.checkPhoneAndSendPin, { phone: phone, country: country, pointHash: pointHash });
    };
    AuthenticationService.prototype.registerTerminal = function (login, password) {
        this.showLoader();
        return this.http.post(this.config.registerTerminal, { login: login, password: password });
    };
    // register(name: string, email: string, phone: string, zip: string, requests: string){
    //     return this.http.post<any>(this.config.register, {name:name, email:email, phone:phone, zip:zip,requests:requests}).pipe(map((res: any) => {
    //         if (res.status) {
    //             //this.setData(res.result);
    //         }
    //
    //         return res;
    //     }));
    // }
    AuthenticationService.prototype.register = function (phone, pin, country, accountId) {
        if (accountId === void 0) { accountId = ''; }
        var pointHash = this.usersPointService.getHash();
        return this.http.post(this.config.register, { phone: phone, pin: pin, country: country, pointHash: pointHash, accountId: accountId });
    };
    AuthenticationService.prototype.newCustomer = function (phone, country) {
        return this.http.post(this.config.newCustomer, { phone: phone, country: country });
    };
    AuthenticationService.prototype.newCustomerVirtual = function (phone, country) {
        return this.http.post(this.config.newCustomerVirtual, { phone: phone, country: country });
    };
    AuthenticationService.prototype.findByAccountId = function (accountId, phone, country) {
        var pointHash = this.usersPointService.getHash();
        return this.http.post(this.config.findByAccountId, { accountId: accountId, pointHash: pointHash, phone: phone, country: country });
    };
    AuthenticationService.prototype.logoutRequest = function () {
        return this.http.post(this.config.logout, {});
    };
    AuthenticationService.prototype.login = function (phone, pin, country) {
        var _this = this;
        this.showLoader();
        this.storage = sessionStorage;
        return this.http.post(this.config.login, { phone: phone, pin: pin, country: country })
            .pipe(map(function (res) {
            //console.log(res)
            if (res.status) {
                _this.setUserLogged(res.result);
            }
            return res;
        }));
    };
    AuthenticationService.prototype.setUserLogged = function (info) {
        this.setData(info);
        this.userLoggedIn.emit(true);
    };
    AuthenticationService.prototype.setData = function (result) {
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify(result));
        //this.roles = [];
        //this.roles = [result.role];
        //this.rolesObservable.next(this.roles);
    };
    AuthenticationService.prototype.logout = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.storage.getItem('currentUser')) {
                            this.logoutRequest().toPromise();
                        }
                        this.clearStorage();
                        this.userLoggedIn.emit(false);
                        console.log('userLoggedStatusChanged 4');
                        this.userLoggedStatusChanged.next(false);
                        return [4 /*yield*/, this.checkSession()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AuthenticationService.prototype.clearStorage = function () {
        this.storage.removeItem('currentUser');
        this.storage.removeItem('userPoint');
        this.storage.removeItem('userPointId');
    };
    AuthenticationService.prototype.setStamp = function () {
        var now = Date.now();
        this.authStamp = now;
        //console.log(this.authStamp+'=1')
        var $this = this;
        setTimeout(function () {
            $this.setStamp();
        }, 1000);
    };
    AuthenticationService.prototype.checkStamp = function () {
        if (this.storage.getItem('currentUser')) {
            var now = Date.now();
            if (this.authStamp) {
                if (now > (this.authStamp + 3000000)) {
                    //if(now > (this.authStamp+60000)){
                    this.logout();
                    return;
                }
                else {
                    this.refreshTokenNow();
                }
            }
            else {
                this.refreshTokenNow();
            }
        }
    };
    AuthenticationService.prototype.refreshTokenNow = function () {
        var _this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post(this.config.refresh, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    console.log('refreshTokenNow');
                    _this.router.navigate(['/']);
                }
                return res;
            });
        }
        return false;
    };
    AuthenticationService.prototype.refreshToken = function () {
        var _this = this;
        var $this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post(this.config.refresh, {}).subscribe(function (res) {
                if (res.status) {
                    _this.setData(res.result);
                }
                else {
                    console.log('refreshToken');
                    _this.router.navigate(['/']);
                }
                setTimeout(function () {
                    console.log("test_test: 2");
                    $this.refreshToken();
                }, 60 * 1 * 1000);
            });
        }
        else {
            setTimeout(function () {
                console.log("test_test: 3");
                $this.refreshToken();
            }, 60 * 1 * 1000);
        }
    };
    AuthenticationService.prototype.checkSession = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        if (sessionStorage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
                return currentUser;
            }
            catch (e) {
            }
        }
        return false;
    };
    AuthenticationService.prototype.getToken = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.token;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getUserRiverCode = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.river_pay_account_id;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.prototype.getRole = function () {
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                var currentUser = JSON.parse(this.storage.getItem('currentUser'));
                return currentUser.role_id;
            }
            catch (e) {
            }
        }
        return '';
    };
    AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.UserIdleService), i0.ɵɵinject(i4.UsersPointService), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i6.LoaderService)); }, token: AuthenticationService, providedIn: "root" });
    return AuthenticationService;
}(SettingsLoaderService));
export { AuthenticationService };
