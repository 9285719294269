<div class="page page--secondary long-content prizes" [class.page--blured]="popupConfirmInfo.show || popupDebitCardInfo.show || popupTransactions.show">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>
        <h1 class="page__title">PRIZES</h1>

        <div class="page__scrolled">
            <div class="prizes__col">
                <div class="prizes-heading">
                    <div class="prizes__row">
                        <div class="gl-title">Redeem</div>
                    </div>
                    <div class="gl-subtitle">{{redeemDescription}}</div>
                </div>
            </div>

            <div class="page__body">
                <div class="prizes__row">
                    <div class="gl-title c-accent">{{gameVendorName}}</div>
                    <div class="gl-text c-accent">{{gameBalance | currency : 'USD' : 'symbol'}}</div>
                </div>
                <div class="gl-subtitle">{{gameDescription}}</div>

                <div class="prizes__transfer-block">
                    <form [formGroup]="form" (ngSubmit)="showPopupConfirm(PRIZES_METHODS.games)" class="form-simple form-simple--primary">
                        <div class="prizes__transfer-block">
                            <div class="form-simple__item">
                                <mat-form-field>
                                    <mat-select #methodsSelect
                                                formControlName="method"
                                                (valueChange)="onProviderChange($event)"
                                                [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                                        <mat-select-trigger>
                                            {{methodsSelect?.value?.name}}
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let method of games" [value]="method">
                                            {{method.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <button class="button snake-btn">
                                Redeem
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                    </form>
                </div>

                <div class="prizes__transfer-block">
                    <div class="prizes__row">
                        <div class="gl-title" style="margin-right: 0.5rem;">Available Withdrawal Balance</div>
                        <div class="gl-text c-accent">{{availableBalance | currency : 'USD' : 'symbol'}}</div>
                    </div>
                    <div class="gl-subtitle">{{withdrawalDescription}}</div>
                    <div class="dropdown" style="padding-bottom: 0">
                        <div class="dropdown__head" style="margin-bottom: 0">
                            <div class="dropdown__title c-accent" style="text-transform: uppercase">
                                TRANSACTIONS
                            </div>
                        </div>

                        <app-senetpro-transactions [items]="transactionsList" [isStickyHeader]="false"></app-senetpro-transactions>

                        <button (click)="showTransactionsPopup()"
                                class="button snake-btn btn-show-transactions"
                                *ngIf="showMoreTransactionsBtn">Show more</button>

                        <div class="dropdown__head" style="margin: .25rem 0">
                            <div class="dropdown__title c-accent" style="text-transform: uppercase">
                                Note:
                            </div>
                        </div>
                        <div class="gl-subtitle" style="margin-bottom: 0">{{noteText}}</div>
                    </div>
                </div>
                <div class="prizes__transfer-block">
                    <div class="prizes__row">
                        <div class="gl-title">WithDraw</div>
                    </div>
                    <div class="gl-subtitle">{{withdrawText}}</div>
                </div>

                <div class="methods-list">
                    <div class="methods-list__item methods-list__header">
                        <div class="methods-list__left">Channel</div>
                        <div class="methods-list__right">Comments</div>
                    </div>
                    <div class="methods-list__item" *ngFor="let method of availableMethods">
                        <button class="methods-list__btn methods-list__left"
                                [class.is-limited]="method.limits"
                                [disabled]="method.disable && isDisableTimeActual(method.disableTime)"
                                (click)="onChannelClick(method)">{{method.name}}</button>
                        <div class="methods-list__description methods-list__right" *ngIf="!method.disable || !isDisableTimeActual(method.disableTime)" [innerHTML]="method.description"></div>
                        <div class="methods-list__description methods-list__right" *ngIf="method.disable && isDisableTimeActual(method.disableTime)" [innerHTML]="method.descriptionDisable"></div>

<!--                        <div class="methods-list__description methods-list__right" *ngIf="method.disableTime" [innerHTML]="method.description + ' <span>' + method.leftTime + '</span>'"></div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-popup-prizes-senetpro-confirm [popupInfo]="popupConfirmInfo"
                          (changeHandle)="changeHandlePopupConfirm($event)"
                          (confirm)="submitPopupConfirm($event)"
                          (close)="closePopupConfirm()">
</app-popup-prizes-senetpro-confirm>

<app-popup-prizes-debit-card [popupInfo]="popupDebitCardInfo"
                             (close)="closeDebitCardPopup()">
</app-popup-prizes-debit-card>

<app-popup-senetpro-transactions [popupInfo]="popupTransactions"
                                 (close)="closeTransactionsPopup()">
</app-popup-senetpro-transactions>
