import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {PAYPAL_CONFIG} from "../../config/api";
import {PaypalPaymentsSuccessComponent} from "../../pages/paypal/payments/success/paypal-payments-success.component";


@Injectable({
    providedIn: 'root'
})
export class PaypalService {
    public visible = new BehaviorSubject(null);
    private config = PAYPAL_CONFIG;

    constructor(private http: HttpClient) {

    }

    paymentSuccess(paymentId, payerId) {
        return this.http.post<any>(this.config.paymentSuccess, {paymentId,payerId});
    }

    paymentUnsuccess(paymentId) {
        return this.http.post<any>(this.config.paymentUnsuccess, {paymentId});
    }
}
