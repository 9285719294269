/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./support.component";
import * as i4 from "../../service/loader.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../service/support/support.service";
import * as i8 from "../../shared/popup/popup.component";
var styles_SupportComponent = [i0.styles];
var RenderType_SupportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupportComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_SupportComponent as RenderType_SupportComponent };
function View_SupportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "chat-frame"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["frameborder", "0"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 1, 0, currVal_0); }); }
export function View_SupportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "page page--primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Support"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupportComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.frameUrl; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_SupportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-support", [], [[40, "@slideToLeft", 0]], null, null, View_SupportComponent_0, RenderType_SupportComponent)), i1.ɵdid(1, 114688, null, 0, i3.SupportComponent, [i4.LoaderService, i5.ActivatedRoute, i5.Router, i6.DomSanitizer, i7.SupportService, i8.PopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var SupportComponentNgFactory = i1.ɵccf("app-support", i3.SupportComponent, View_SupportComponent_Host_0, {}, {}, []);
export { SupportComponentNgFactory as SupportComponentNgFactory };
