import {Component, OnInit} from '@angular/core';
import {slideToLeft, slideToRight} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {StylingService} from "../../../service/styling.service";
import {WeeklyReportService} from "../../../service/reports/weekly-report.service";
import {REPORT_DATA_COLS, REPORT_PERIOD} from "./report-weekly-senet.config";
import {ChartOptions, ChartType} from "chart.js";
import {formatCurrency} from "@angular/common";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-report-weekly-senet',
  templateUrl: './report-weekly-senet.component.html',
  styleUrls: ['./report-weekly-senet.component.scss'],
  animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
  host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class ReportWeeklySenetComponent implements OnInit {
  reportWeeksList = [];
  reportData;
  reportTotal;
  reportRTP = [];

  REPORT_DATA_COLS = REPORT_DATA_COLS;

  barChartData;
  barChartLabels: string[];
  barChartType: ChartType = 'bar';
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'series',
        // bounds: 'ticks',
        time: {
          displayFormats: {
            quarter: 'MM YYYY'
          },
        },
        ticks: {
          // autoSkip: true,
          maxTicksLimit: 5,
        },
        offset: true
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],

    },
    elements: {
      line: {
        fill: false
      }
    },
    tooltips: {
      callbacks: {
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatCurrency(+tooltipItem.value, 'en', '$');
        }
      }
    }
  };
  period: string|null = null;
  REPORT_PERIOD = REPORT_PERIOD;
  constructor(private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private route: ActivatedRoute,
              private weeklyReportService: WeeklyReportService,
              private stylingService: StylingService) {
  }

  ngOnInit() {
      this.getData();
  }

  getData() {
    if (this.period) {
      this.weeklyReportService.getSenetReport(this.period).subscribe(data => {
        this.setData(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    } else {
      this.weeklyReportService.getSenetReport().subscribe(data => {
        this.setData(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

  }

  setData(data) {
    if (data.status === true) {
      this.reportData = data;
      this.reportWeeksList = this.reportData.result;
      this.reportTotal = this.reportData.total;
      this.reportRTP = this.reportData.rtp;

      this.barChartData = this.getBarChartData();
      this.barChartLabels = this.getChartLabels();
    } else if (data.status === false) {
      this.popupComponent.showPopupError({text: data.message});
    } else if (data.status === undefined) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  getTotal(week, colName) {
    if (week[colName]) {
      return week[colName];
    }
    return '';
  }

  getActiveColor() {
    return this.stylingService.getPageColor();
  }

  getChartLabels() {
    let labels = [];
    this.reportWeeksList.forEach(week => {
      labels.push(week.date);
    });
    return labels;
  }

  getBarChartData() {
    let barChartData = [
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.net), label: 'NET', type: 'line'},
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.inflows), label: this.REPORT_DATA_COLS.inflows},
      // {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.freePlay), label: this.REPORT_DATA_COLS.freePlay},
      {data: this.getArrayOfWeeksTotal(this.REPORT_DATA_COLS.outflows), label: this.REPORT_DATA_COLS.outflows},
    ];

    return barChartData;
  }

  getArrayOfWeeksTotal(colName) {
    let totalData = [];
    this.reportWeeksList.forEach(week => {
      const total = this.getTotal(week, colName);
      totalData.push(total);
    });
    return totalData;
  }

  getReport(period: string) {
    if (period === this.REPORT_PERIOD.monthly) {
      this.period = period;
    } else {
      this.period = null;
    }
    this.getData();
  }
}
