import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StatesService} from "../../service/states/states.service";
import {StatesModel} from "../../models/states/states.model";
import {PopupComponent} from "../popup/popup.component";
import {PrizesService} from "../../service/prizes/prizes.service";
import {PROJECT_MESSAGES} from "../../config/project";

@Component({
    selector: 'app-popup-prizes-debit-card',
    templateUrl: './popup-prizes-debit-card.component.html',
    styleUrls: ['./popup-prizes-debit-card.component.scss']
})
export class PopupPrizesDebitCardComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();

    formCheckCard: FormGroup;
    formNew: FormGroup;
    formAdditionInfo: FormGroup;

    selectedState;
    states: Array<StatesModel> = [];

    activeMethod;
    activeCard;
    cards = [];
    birthdayDate;
    constructor(private loaderService: LoaderService,
                private statesService: StatesService,
                private prizesService: PrizesService,
                private popupComponent: PopupComponent,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.formCheckCard = this.formBuilder.group({
            name: [null],
        });
        this.formNew = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            card_number: [null, Validators.required],
            exp: [null, Validators.required],
            cvv: [null, Validators.required],
            city: [null, Validators.required],
            state: [null, Validators.required],
            billing_address: [null, Validators.required],
            billing_zip: [null, Validators.required],
            card_nickname: [null, Validators.required]
        });
        this.formAdditionInfo = this.formBuilder.group({
            legal_first_name: [null, Validators.required],
            legal_last_name: [null, Validators.required],
            address: [null, Validators.required],
            address_2: [null],
            city: [null, Validators.required],
            zip: [null, Validators.required],
            state: [null, Validators.required],
            birthday: [null, Validators.required],
            last_four: [null, Validators.required],
        });
    }

    getCards() {
        this.prizesService.getDebitCards().subscribe(data => {
            this.setCardsList(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setCardsList(data) {
        try {
            if (data.status) {
                if (data.result && data.result.length) {
                    this.cards = data.result;
                    this.cards.forEach(card => {
                        if (card.default == 1) {
                            this.activeCard = card;
                            this.setCheckedCard(card);
                        }
                    })
                } else {
                    this.cards = [];
                }
                if (data.states && data.states.length) {
                    this.states = data.states;
                    this.selectedState = this.states[0];
                    this.setStateSelect(this.selectedState);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setStateSelect(val) {
        if (this.formNew) {
            this.formNew.controls.state.setValue(val);
        }
        if (this.formAdditionInfo) {
            this.formAdditionInfo.controls.state.setValue(val);
        }
    }

    setCheckedCard(card) {
        this.activeMethod = card;
        if (card) {
            this.formCheckCard.controls.name.setValue(this.activeMethod.uuid);
        } else {
            this.formCheckCard.controls.name.setValue(null);
        }
    }

    ngOnChanges(changes) {
        if (changes.popupInfo.currentValue.show) {
            this.getCards();
            if (changes.popupInfo.currentValue.type === 2) {
              this.getDebitCardAdditionInfo();
            }
        }
    }

    getDebitCardAdditionInfo() {
        this.prizesService.getDebitCardAdditionInfo().subscribe(data => {
            this.setDebitCardAdditionInfo(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setDebitCardAdditionInfo(data) {
        try {
            if (data.status) {
                this.formAdditionInfo.reset();
                const info = data.result;
                if (info.firstName) {
                    this.formAdditionInfo.controls.legal_first_name.setValue(info.firstName);
                }
                if (info.lastName) {
                    this.formAdditionInfo.controls.legal_last_name.setValue(info.lastName);
                }
                if (info.address) {
                    this.formAdditionInfo.controls.address.setValue(info.address);
                }
                if (info.addressTwo) {
                    this.formAdditionInfo.controls.address_2.setValue(info.addressTwo);
                }
                if (info.city) {
                    this.formAdditionInfo.controls.city.setValue(info.city);
                }
                if (info.zip) {
                    this.formAdditionInfo.controls.zip.setValue(info.zip);
                }
                if (info.dob) {
                    this.birthdayDate = new Date(info.dob);
                }
                if (info.stateId) {
                    this.states.forEach(state => {
                        if (state.id === info.stateId) {
                            this.selectedState = state;
                        }
                    });
                    this.setStateSelect(this.selectedState);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup() {
        this.close.emit(true);
        this.formNew.reset();
        this.formAdditionInfo.reset();
        this.cards = [];
        this.activeCard = null;
        this.setCheckedCard(null);
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    onSelectCard() {
        const cardId = this.formCheckCard.controls.name.value;
        this.prizesService.changeActiveCard(cardId).subscribe(data => {
            this.selectCardResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    selectCardResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.activeCard = this.activeMethod;
            } else {
                this.popupComponent.showPopupError({text: data.message});
                this.setCheckedCard(this.activeCard);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitNewCardForm() {
        const number = this.getInputValue(this.formNew,'card_number');
        const exp = this.getInputValue(this.formNew,'exp');
        const cvv = this.getInputValue(this.formNew,'cvv');
        const nickname = this.getInputValue(this.formNew,'card_nickname');
        const firstName = this.getInputValue(this.formNew,'first_name');
        const lastName = this.getInputValue(this.formNew,'last_name');
        const billingAddress = this.getInputValue(this.formNew,'billing_address');
        const billingZip = this.getInputValue(this.formNew,'billing_zip');
        const city = this.getInputValue(this.formNew,'city');
        const stateId = this.formNew.controls.state.value.id;

        if (number && exp && cvv && nickname) {

            let expMonth = exp.substr(0, 2);
            let expYear = exp.substr(2, 3);

            if(expMonth != '' && expYear != '' && +expMonth && +expYear && billingAddress != '' && billingZip != '' && firstName != '' && lastName != ''){

                this.prizesService.addDebitCard(number, expMonth, expYear, cvv, nickname, firstName, lastName, billingAddress, billingZip, city, stateId).subscribe(data => {
                    this.checkSubmitNewCardResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            } else {
                this.popupComponent.showPopupError({text: PROJECT_MESSAGES.incorrectDataFormat});
            }
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    checkSubmitNewCardResult(data) {
        try {
            if (data.status === true) {
                if (data.statusLegalInfo) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                    this.formNew.reset();
                    this.getCards();
                    this.scrollToTopOfPopup();
                } else {
                    this.popupInfo.type = 2;
                    this.setStateSelect(this.selectedState);
                    this.scrollToTopOfPopup(false);
                    this.getDebitCardAdditionInfo();
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getInputValue(form, field) {
        if (form.controls[field]) {
            return form.controls[field].value
        }
        return null;
    }

    scrollToTopOfPopup(smooth = true) {
        setTimeout(() => {
            document.querySelector('#scrolled-wrapper').scroll({
                top: 0, left: 0, behavior: smooth ? 'smooth' : 'auto'
            });
        }, smooth ? 500 : 0);
    }

    onSubmitAdditionInfoForm() {
        const legalFirstName = this.getInputValue(this.formAdditionInfo,'legal_first_name');
        const legalLastName = this.getInputValue(this.formAdditionInfo,'legal_last_name');
        const address = this.getInputValue(this.formAdditionInfo,'address');
        const address_2 = this.getInputValue(this.formAdditionInfo,'address_2');
        const city = this.getInputValue(this.formAdditionInfo,'city');
        const zip = this.getInputValue(this.formAdditionInfo,'zip');
        const stateId = this.formAdditionInfo.controls.state.value.id;
        const birthday = this.getFilterBirhday();
        const last_four = this.getInputValue(this.formAdditionInfo,'last_four');

        if(legalFirstName != '' && legalLastName != '' && address != '' && city != '' && zip != '' && +stateId && birthday != '' && last_four != ''){
            this.prizesService.addDebitCardAdditionInfo(legalFirstName, legalLastName, address, address_2, city, zip, stateId, birthday, last_four).subscribe(data => {
                this.checkSubmitAdditionInfoResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    getFilterBirhday(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=birthday]')).value;
    }

    checkSubmitAdditionInfoResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.closePopup();

                // this.popupInfo.type = 1;
                // this.formNew.reset();
                // this.formAdditionInfo.reset();
                // this.getCards();
                // this.scrollToTopOfPopup();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askOfDeletingCard(card) {
        // const message = '<b class="js-popup-title">Please confirm deleting card</b>';
        const message = !card.last_four ? `<b class="js-popup-title">Please confirm deleting card</b>` : card.cardholder ?
        `<b class="js-popup-title">Please confirm deleting</b> <br><br> <span class="js-popup-subtitle">${card.cardholder} - ${card.last_four}</span>` :
        `<b class="js-popup-title">Please confirm deleting card</b> <br><br> <span class="js-popup-subtitle">ending in ${card.last_four}</span>`;
        this.popupComponent.showPopupAgreement({text: message}, (status) => {
            if (status) {
                this.removeDebitCard(card.uuid);
            }
        });
    }

    removeDebitCard(uuid) {
        this.prizesService.removeDebitCard(uuid).subscribe(data => {
            this.checkSubmitRemoveDebitCardResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    checkSubmitRemoveDebitCardResult(data) {
        try {
            if (data.status === true) {
                this.getCards();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
