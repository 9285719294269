import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {MassSmsService} from "../../service/mass-sms/mass-sms.service";

@Component({
    selector: 'app-mass-sms',
    templateUrl: './mass-sms.component.html',
    styleUrls: ['./mass-sms.component.scss'],
})
export class MassSmsComponent implements OnInit {

    @ViewChild('message', {static: false}) 'message': ElementRef;
    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private massSmsService: MassSmsService) {

    }

    ngOnInit() {}

    submit(message){
        if (!this.disableSubmit()) {
            this.massSmsService.submitSms(message).subscribe(response => {
               this.submitResult(response);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        } else {
            this.popupComponent.showPopupError({text: 'Please, enter the message first'});
        }
    }

    submitResult(response) {
        try {
            if (response.status === true) {
                this.popupComponent.showPopupSuccess({text: response.message});
                this.resetInputValue();
            } else if (response.status === false) {
                this.popupComponent.showPopupError({text: response.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetInputValue() {
        this.message.nativeElement.value = '';
    }

    disableSubmit() {
        if (this.message) {
            return !this.message.nativeElement.value || !this.message.nativeElement.value.trim()
        }
        return true;
    }
}

