<form *ngIf="!cashappVerified && !isPaypal && !isVenmo && !isZelle" [formGroup]="cashappForm" (ngSubmit)="onSubmitCashappForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="address"
               placeholder="Enter CashApp Address"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="Retype CashApp Address"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one cashapp address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="cashappForm.invalid || cashappForm.controls.address.value !== cashappForm.controls.retype.value">
            Verify Address
            <svg><rect></rect></svg>
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && isPaypal && !isVenmo && !isZelle" [formGroup]="paypalForm" (ngSubmit)="onSubmitPaypalForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="email"
               placeholder="Email"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="Retype Email"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="paypalForm.invalid || paypalForm.controls.email.value !== paypalForm.controls.retype.value">
            Verify Email
            <svg><rect></rect></svg>
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && !isPaypal && isVenmo && !isZelle" [formGroup]="venmoForm" (ngSubmit)="onSubmitVenmoForm()" class="form-simple form-simple--primary">
    <ng-container *ngIf="!isVenmoPhone">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="Enter Venmo Address"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="Retype Venmo Address"
                   [type]="'text'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>

    <ng-container *ngIf="isVenmoPhone">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="Phone Number"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="Retype Phone Number"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>


    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="venmoForm.invalid || venmoForm.controls.address.value !== venmoForm.controls.retype.value">
            Verify Address
            <svg><rect></rect></svg>
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && isZelle" [formGroup]="zelleForm" (ngSubmit)="onSubmitZelleForm()" class="form-simple form-simple--primary">
    <ng-container *ngIf="isZelle && (zelleType === 1)">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="Enter Email"
                   [type]="'text'"
                   [email]="true"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="Retype Email"
                   [type]="'text'"
                   [email]="true"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>

    <ng-container *ngIf="isZelle && (zelleType === 2)">
        <div class="form-simple__item">
            <input formControlName="address"
                   placeholder="Phone Number"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>

        <div class="form-simple__item">
            <input formControlName="retype"
                   placeholder="Retype Phone Number"
                   [mask]="phoneMask"
                   [type]="'tel'"
                   class="form-simple__input"
                   autocomplete="off">
        </div>
    </ng-container>


    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="zelleForm.invalid || zelleForm.controls.address.value !== zelleForm.controls.retype.value">
            <ng-container *ngIf="zelleType === 2">Verify Phone</ng-container>
            <ng-container *ngIf="zelleType === 1">Verify Email</ng-container>
            <svg><rect></rect></svg>
        </button>
    </div>
    <div class="form-simple__btns" style="margin-bottom: -1rem; margin-top: 2rem" *ngIf="isZelle">
        <button type="button"
                (click)="goBack()"
                style="border-color: #76D6FF"
                class="navigation__button button snake-btn is-notouched">
            Back
            <svg><rect></rect></svg>
        </button>
    </div>
</form>
