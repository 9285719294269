<div *ngIf="popupData" class="investments-popup" [class.is-active]="popupData" [class.is-blurred]="showFullAddress">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()">
        </button>


        <!--  CRYPTO  -->
        <ng-template [ngIf]="confirmType === 'crypto'">
            <div class="investments-popup__header">Transaction Details</div>
            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Address</div>
                <div class="js-crypto-address investments-popup__info-text investments-popup__info-text--elipsis js-check-address-size">
                    {{popupData.address}}
                </div>
                <button (click)="showFullAddress = true" class="investments-popup__show-full" *ngIf="needShowFullBtn">Show Full</button>
            </div>

            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Note</div>
                <div class="js-crypto-note investments-popup__info-text investments-popup__info-text--elipsis cut-overflow">
                    {{popupData.note}}
                </div>
            </div>

            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Amount</div>
                <div class="investments-popup__info-text">
                    <div class="js-crypto-amount js-flex-text">
                        {{popupData.amount | number: '1.2-12'}} {{popupData.currencyName}}
                    </div>
                </div>
            </div>

            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Fee</div>
                <div class="investments-popup__info-text ">
                    <div class="js-crypto-fee js-flex-text">
                        {{popupData.fee | number: '1.2-12'}} {{popupData.currencyName}}
                    </div>
                </div>
            </div>

            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Total</div>
                <div class="investments-popup__info-text">
                    <div class="js-crypto-total js-flex-text">
                        {{popupData.total | number: '1.2-12'}} {{popupData.currencyName}}
                    </div>
                </div>
            </div>
        </ng-template>



        <!--  TRADING  -->
        <ng-template [ngIf]="confirmType === 'trade' || confirmType === 'prizes'">
            <div class="investments-popup__header">Transaction Details</div>

            <div class="investments-popup__info">
                <div class="investments-popup__info-title">Amount</div>
                <div class="investments-popup__info-text">
                    <div *ngIf="confirmType === 'trade'" class="js-trade-amount js-flex-text">
                        {{popupData.amount | number: '1.2-12'}} {{popupData.from}}
                    </div>

                    <div *ngIf="confirmType === 'prizes' && isCryptoBuy()" class="js-trade-amount js-flex-text">
                        {{popupData.amount | currency : 'USD' : 'symbol'}}
                    </div>

                    <div *ngIf="confirmType === 'prizes' && !isCryptoBuy()" class="js-trade-amount js-flex-text">
                        {{popupData.amount | number: '1.2-12'}} {{popupData.currencyName}}
                    </div>

                </div>
            </div>

            <div *ngIf="confirmType === 'trade'" class="investments-popup__info">
                <div class="investments-popup__info-title">Fee</div>
                <div class="investments-popup__info-text">
                    <div class="js-trade-fee js-flex-text">
                        {{popupData.fee | number: '1.2-12'}} {{popupData.from}}
                    </div>
                </div>
            </div>

            <div *ngIf="confirmType === 'prizes'" class="investments-popup__info">
                <div class="investments-popup__info-title">{{isCryptoBuy() ? 'Crypto' : 'USD'}}</div>
                <div class="investments-popup__info-text">
                    <div *ngIf="isCryptoBuy()" class="js-trade-total js-flex-text">
                        {{popupData.currencyAmount | number: '1.2-12'}} {{popupData.currencyName}}
                    </div>
                    <div *ngIf="!isCryptoBuy()" class="js-trade-total js-flex-text">
                        {{popupData.currencyAmount | currency : 'USD' : 'symbol'}}
                    </div>
                </div>
            </div>

            <div *ngIf="confirmType === 'trade'" class="investments-popup__info">
                <div class="investments-popup__info-title">Total</div>
                <div class="investments-popup__info-text">
                    <div class="js-trade-total js-flex-text">
                        {{popupData.total | number: '1.2-12'}} {{popupData.to}}
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="form-simple__btns">

            <!--  CRYPTO  -->
            <ng-template [ngIf]="confirmType === 'crypto'">
                <button (click)="onConfirmCryptoSpend()" class="js-button-confirm button snake-btn is-notouched">
                    Confirm
                    <svg><rect></rect></svg>
                </button>
            </ng-template>


            <!--  TRADING  -->
            <ng-template [ngIf]="confirmType === 'trade'">
                <button (click)="onConfirmTrade()" class="js-button-confirm button snake-btn is-notouched">
                    Confirm
                    <svg><rect></rect></svg>
                </button>
            </ng-template>

            <!--  PRIZES  -->
            <ng-template [ngIf]="confirmType === 'prizes'">
                <button (click)="onConfirmPrizes()" class="js-button-confirm button snake-btn is-notouched">
                    Confirm
                    <svg><rect></rect></svg>
                </button>
            </ng-template>

            <button (click)="closePopup()" class="button snake-btn is-notouched">
                Back
                <svg><rect></rect></svg>
            </button>

        </div>
    </div>
</div>

<div *ngIf="showFullAddress" class="investments-popup__show-full-popup">
    <button class="js-close-show-full-address investments-popup__close" (click)="showFullAddress = false"></button>
    <div class="investments-popup__info-title">
        <span>Address</span>
        <svg (click)="showMoreAddress(addressText)" *ngIf="hasScroll(addressText)" class="investments-popup__show-full-arrow">
            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#arrow-long'"></use>
        </svg>
    </div>
    <div #addressText class="js-text-show-full-address investments-popup__info-text investments-popup__info-text--no-flex">
        {{popupData.address}}
    </div>
</div>
