import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {ScratchOffService} from "../../../../service/games/scratchOff.service";
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FundsResultItem} from "../../../../models/services/funds/funds.model";
import {ScrollToService} from "../../../../service/scrollTo.service";
import {SCRATCH_OFF_POPUP_TYPE, SCRATCH_OFF_RULES, SCRATCH_OFF_WELCOME_RULES} from "./scratch-off-rules.config";


@Component({
    selector: 'app-scratch-off-rules',
    templateUrl: './scratch-off-rules.component.html',
    styleUrls: ['./scratch-off-rules.component.scss']
})

export class ScratchOffRulesComponent implements OnInit {
    @Output() isElementReady = new EventEmitter();
    @Output() isPaymentForbidden = new EventEmitter();
    @Output() isPaymentSuccess = new EventEmitter();

    imageLoaded = false;
    backgroundImage;
    rules;

    private popupVisible = false;
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
      this._showPopup = value;
      if (value && !this.popupVisible) {
          this.popupVisible = true;
          this.getInfo();
      }
    }

    get showPopup() : boolean  {
      return this._showPopup;
    }

    @Input() popupType: string;

    constructor(private scratchOffService: ScratchOffService,
                private formBuilder: FormBuilder,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {

    }

    ngOnInit() {}

    ngOnDestroy() {
        this.popupVisible = false;
    }

    getInfo() {
        this.backgroundImage = './assets/images/scratchOff/game-placeholder.jpeg';
        setTimeout(() => {
          this.rules = this.isWelcomePopup() ? SCRATCH_OFF_WELCOME_RULES : SCRATCH_OFF_RULES;
        });
    }

    isWelcomePopup() {
        return this.popupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    }

    isScratchOffRulesPopup() {
      return this.popupType === SCRATCH_OFF_POPUP_TYPE.pigRules;
    }

    onActionBtnClick() {
        if (this.isScratchOffRulesPopup()) {
          this.buyGameRequest();
        } else if (this.isWelcomePopup()) {
          this.isPaymentSuccess.emit();
        }
    }

    buyGameRequest() {
        this.scratchOffService.getGamePaymentInfo().subscribe(data => {
            this.buyGameResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    buyGameResult(response) {
        if(response.status === true){
            if (response.payment) {
                this.isPaymentForbidden.emit(response);
            } else {
                this.isPaymentSuccess.emit(response);
            }
        }else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onBgLoaded() {
        this.imageLoaded = true;
        this.isElementReady.emit(true);
    }
}
