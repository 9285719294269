import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {TRANSACTIONS_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {timer} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TransactionsService extends SettingsLoaderService{
    private config = TRANSACTIONS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    confirmation(paymentIntent) {
        // return timer(2500).pipe();
        return this.http.post<any>(this.config.confirmation, {paymentIntent});
    }

    // onCloseProcessingPopup(val = null) {
    //     window.parent.postMessage(val, '*');
    // }
}
