<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
        <div class="investments-popup__scrolled">
            <div class="investments-popup__title-pay">
                <p *ngIf="popupInfo.type === PRIZES_METHODS.games">
                    Please select the game below where you would like to transfer the funds to.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && !popupInfo.cashappVerified">
                    Please enter your CashApp tag. Only ONE CashApp tag is allowed per active account.
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && popupInfo.cashappVerified">
                    Daily banking hours transfers are done via CashApp. To change your CashApp Tag, contact support.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                    Please enter the email account registered with your PayPal. Only ONE PayPal email is allowed per active account.
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress">
                    Afterhours transfers are done via PayPal. To change your PayPal email, contact support.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
                    Please enter the tag account registered with your Venmo. Only ONE Venmo tag is allowed per active account.
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
                    {{popupInfo.handle}} transfers are aggregated and sent out once per day by 5PM. To change your {{popupInfo.handle}} tag, please contact support.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.card">
                    Afterhours transfers are done via your Debit Card {{popupInfo.handle}}.
                </p>

                <p *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && (step === 1) && !popupInfo.zelleAddress">
                    Please enter either your email or phone number registered with Zelle.
                </p>

                <p *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && (step === 2) && !popupInfo.zelleAddress">
                    Please enter {{zelleTagType()}}
                </p>

                <p *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && popupInfo.zelleAddress">
                    Your {{zelleTagType()}} is now registered to receive payments with Zelle. If you need to change it in the future, please contact support.
                </p>

                <p *ngIf="isOneOfGiftCards()">
                    {{popupInfo.giftDescription}}
                </p>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && !popupInfo.cashappVerified">
                <app-verify-cashapp-tag [verifyStatus]="popupInfo.cashappVerified" (onVerify)="setCashappTagVerified($event)"></app-verify-cashapp-tag>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                <app-verify-cashapp-tag [isPaypal]="true"  [verifyStatus]="popupInfo.paypalAddress" (onVerify)="setPaypalVerified($event)"></app-verify-cashapp-tag>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
                <app-verify-cashapp-tag [isVenmo]="true"  [verifyStatus]="popupInfo.venmoAddress" (onVerify)="setVenmoVerified($event)"></app-verify-cashapp-tag>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && !popupInfo.zelleAddress && (step === 2)">
                <app-verify-cashapp-tag [isZelle]="true"
                                        [zelleType]="popupInfo.zelleType"
                                        [verifyStatus]="popupInfo.zelleAddress"
                                        (onBack)="step = 1"
                                        (onVerify)="setZelleVerified($event)"></app-verify-cashapp-tag>
            </div>

            <form [formGroup]="form"
                  (ngSubmit)="onSubmitForm()"
                  *ngIf="(popupInfo.type === PRIZES_METHODS.cashapp && popupInfo.cashappVerified) ||
                     (popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress) ||
                     (popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress) ||
                     popupInfo.type === PRIZES_METHODS.games ||
                     popupInfo.type === PRIZES_METHODS.zelle ||
                     (popupInfo.type === PRIZES_METHODS.card && popupInfo.handle) ||
                     (isOneOfGiftCards() && popupInfo.handle)"
                  class="form-simple form-simple--shrink form-simple--primary">
                <ng-container *ngIf="!((popupInfo.type === PRIZES_METHODS.zelle) && !popupInfo.zelleAddress)">
                    <div class="form-simple__item" *ngIf="(popupInfo.type !== PRIZES_METHODS.games) && (popupInfo.type !== PRIZES_METHODS.zelle)">
                        <input formControlName="handle"
                               placeholder="Handle"
                               disabled="true"
                               [type]="'text'"
                               [value]="popupInfo.handle"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && !isZellePhone()">
                        <input formControlName="handle"
                               placeholder="Handle"
                               disabled="true"
                               [type]="'text'"
                               [value]="popupInfo.handle"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && isZellePhone()">
                        <input formControlName="handle"
                               placeholder="Phone Number"
                               [mask]="phoneMask"
                               disabled="true"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.games">
                        <mat-form-field>
                            <mat-select #methodsSelect
                                        formControlName="handle"
                                        [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                                <mat-select-trigger>
                                    {{methodsSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let method of popupInfo.providers" [value]="method">
                                    {{method.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="form-simple__item form-simple__item--amount" *ngIf="(isOneOfGiftCards() && popupInfo.giftAmountType === 'range') || !isOneOfGiftCards()">
                        <input mask="separator.0"
                               thousandSeparator=","
                               (input)="onInputAmount()"
                               formControlName="amount"
                               placeholder="Amount"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                        <span>.00</span>
                        <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                            Available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                        </div>
                    </div>

                    <div>
                        <div class="amount-variants" *ngIf="isOneOfGiftCards() && popupInfo.giftAmountType === 'fixed' && popupInfo.giftAmountArray">
                            <button class="amount-variants__item"
                                    type="button"
                                    [class.is-active]="form.controls.amount.value === item"
                                    (click)="selectAmount(item)"
                                    *ngFor="let item of popupInfo.giftAmountArray">{{item}}</button>
                        </div>
                    </div>


                    <div class="form-simple__item"  *ngIf="isOneOfGiftCards()">
                        <input mask="separator.0"
                               (input)="onInputQuantity()"
                               formControlName="quantity"
                               placeholder="Quantity"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                        <div style="margin-top: 1rem">
                            Total Amount: {{totalAmount | currency : 'USD' : 'symbol'}}
                        </div>
                        <div class="page__form-note page__form-note--qty" [class.is-active]="!isTotalAmountCorrect && +form.controls.quantity.value">
                            Total Amount can't be more than available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                        </div>
                    </div>

                    <div class="form-simple__btns">
                        <button class="button snake-btn is-notouched"
                                [disabled]="+form.controls.amount.value <= 0 || (isOneOfGiftCards() && (!+form.controls.quantity.value || !isTotalAmountCorrect))">
                            Transfer
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </ng-container>

                <ng-container *ngIf="((popupInfo.type === PRIZES_METHODS.zelle) && !popupInfo.zelleAddress)">
                    <div class="form-simple__item" *ngIf="(popupInfo.type === PRIZES_METHODS.zelle) && (step === 1) && !popupInfo.zelleAddress">
                        <mat-form-field>
                            <mat-select #methodsSelect
                                        formControlName="zelleTagType"
                                        [(value)]="selectedZelleTagType"
                                        (valueChange)="onZelleTypeChange($event)"
                                        panelClass="my-select-panel-border-yellow two-rows">
                                <mat-select-trigger>
                                    {{methodsSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let method of zelleTagTypeList" [value]="method">
                                    {{method.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-simple__btns">
                        <button class="button snake-btn is-notouched"
                                type="button"
                                *ngIf="step === 1"
                                (click)="goToZelleStepTwo()">
                            Continue
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </ng-container>
            </form>
        </div>
    </div>
</div>
