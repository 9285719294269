import { ElementRef, EventEmitter, OnInit, QueryList } from '@angular/core';
import { FireworkService } from "../../../../service/fireworks/firework.service";
import { ParticleService } from "../../../../service/fireworks/particle.service";
import { PIG_SOUND_TYPE } from "./pig.config";
window['requestAnimFrame'] = (function () {
    return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window['mozRequestAnimationFrame'] ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        };
})();
var PigComponent = /** @class */ (function () {
    function PigComponent(scratchOffService, popupComponent, loaderService) {
        this.scratchOffService = scratchOffService;
        this.popupComponent = popupComponent;
        this.loaderService = loaderService;
        this.isElementReady = new EventEmitter();
        this.close = new EventEmitter();
        this.reTryUploadPigGame = new EventEmitter();
        this.closeAfterReTry = new EventEmitter();
        this.popupVisible = false;
        this.activeClass = false;
        this.imagesForLoading = 3;
        this.showGamePopup = false;
        this.showGamePopupNoLuck = false;
        this.showGamePopupWinning = false;
        this.popupPrizeImage = '';
        this.hidePopupAutoAfterSec = 1.5;
        this.winAmount = 0;
        this.buttons = [];
        this.PARTICLE_COUNT = 100;
        this.responseKeys = {
            getList: {
                buttonsList: 'elements',
            },
            getItem: {
                isWinning: 'winning',
                winningImage: 'result',
                prizeValue: 'prize',
                canPlay: 'canPlay',
                restImages: 'restImages'
            }
        };
        this.additionalBtnKeys = {
            winningImage: 'winningImage',
            animate: 'animate',
            noLuck: 'noLuck'
        };
        // Alpha level that canvas cleanup iteration removes existing trails.
        // Lower value increases trail duration.
        this.CANVAS_CLEANUP_ALPHA = 0.15;
        // Hue change per loop, used to rotate through different firework colors.
        this.HUE_STEP_INCREASE = 0.5;
        // Minimum number of ticks per manual firework launch.
        this.TICKS_PER_FIREWORK_MIN = 1;
        // Minimum number of ticks between each automatic firework launch.
        this.TICKS_PER_FIREWORK_AUTOMATED_MIN = 20;
        // Maximum number of ticks between each automatic firework launch.
        this.TICKS_PER_FIREWORK_AUTOMATED_MAX = 80;
        this.fireworks = [];
        this.particles = [];
        this.isMouseDown = false;
        // Initial hue.
        this.hue = 120;
        // Track number of ticks since automated firework.
        this.ticksSinceFireworkAutomated = 0;
        // Track number of ticks since manual firework.
        this.ticksSinceFirework = 0;
        this.reTryWasInit = false;
    }
    Object.defineProperty(PigComponent.prototype, "popupData", {
        get: function () {
            return this._popupData;
        },
        set: function (value) {
            var _this = this;
            this._popupData = value;
            if (value) {
                this.setButtons(value);
                setTimeout(function () {
                    if (_this.buttons.length === value.limit) {
                        _this.initCanvas();
                        setTimeout(function () {
                            _this.loop();
                        });
                    }
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    PigComponent.prototype.ngOnInit = function () {
        // this.getButtonsArray();
        // this.initCanvas();
        //
        // setTimeout(() => {
        //     this.loop();
        // });
    };
    PigComponent.prototype.ngOnDestroy = function () {
        this.popupVisible = false;
        this.buttons = [];
        this.reTryWasInit = false;
    };
    PigComponent.prototype.initCanvas = function () {
        this.canvas = document.getElementById('canvas');
        this.context = this.canvas.getContext('2d');
        this.canvas.width = window.innerWidth;
        this.canvas.height = window.innerHeight;
    };
    // getButtonsArray() {
    //     this.loaderService.show();
    //     this.scratchOffService.createRound().subscribe(data => {
    //         this.setButtons(data);
    //     }, () => {
    //         this.loaderService.hideAndShowTryAgain('bordered');
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }
    PigComponent.prototype.setButtons = function (response) {
        var _this = this;
        if (response.status === true) {
            response[this.responseKeys.getList.buttonsList].forEach(function (el) {
                _this.buttons.push({ hash: el });
            });
            this.buttons.forEach(function (btn) {
                response.openedElements.forEach(function (openedItem) {
                    if (btn.hash === openedItem.uuid) {
                        btn[_this.additionalBtnKeys.winningImage] = openedItem.image;
                    }
                });
            });
            if (this.buttons.length !== response.limit) {
                if (this.reTryWasInit) {
                    this.popupComponent.showPopupError({ text: response.errorMessage }, 'btn-ok');
                    this.reTryWasInit = false;
                    this.closeAfterReTry.emit();
                }
                else {
                    this.reTryWasInit = true;
                    this.reTryUploadPigGame.emit();
                }
                return;
            }
            this.roundUuid = response.roundUuid;
            this.defaultImage = response.defaultImage;
        }
        else if (response.status === false) {
            this.popupComponent.showPopupError({ text: response.message }, 'btn-ok');
            this.close.emit();
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
            this.close.emit();
        }
    };
    PigComponent.prototype.chooseItem = function (target, hash) {
        var _this = this;
        var item = target.querySelector('img');
        var rect = item.getBoundingClientRect();
        var x = rect.left + rect.width / 2;
        var y = rect.top + rect.height / 2;
        var itemWidthAnimation = item.width * .5;
        var itemHeightAnimation = item.height * .5;
        this.canvas.classList.add('is-animated');
        this.fireFirework(x, y, itemWidthAnimation, itemHeightAnimation);
        this.fireworkInterval = setInterval(function () {
            _this.fireFirework(x, y, itemWidthAnimation, itemHeightAnimation);
        }, 500);
        this.scratchOffService.chooseItem(this.roundUuid, hash).subscribe(function (data) {
            setTimeout(function () {
                _this.updateButton(data, hash);
            }, 500);
        }, function () {
            _this.loaderService.hideAndShowTryAgain('bordered');
        }, function () {
            clearInterval(_this.fireworkInterval);
            _this.isMouseDown = false;
            setTimeout(function () {
                _this.canvas.classList.remove('is-animated');
            }, 1000);
        });
    };
    PigComponent.prototype.fireFirework = function (x, y, itemWidthAnimation, itemHeightAnimation) {
        var rect = this.canvas.getBoundingClientRect();
        this.mouseX = ((x - rect.left) * this.canvas.width / rect.width) + this.random(-itemWidthAnimation, itemWidthAnimation);
        this.mouseY = ((y - rect.top) * this.canvas.height / rect.height) + this.random(-itemHeightAnimation, itemHeightAnimation);
        this.isMouseDown = true;
    };
    PigComponent.prototype.updateButton = function (response, hash) {
        var _this = this;
        if (response.status === true) {
            // image.setAttribute('src', response.result);
            this.buttons.forEach(function (el) {
                if (el.hash === hash) {
                    el[_this.additionalBtnKeys.winningImage] = response.element.image;
                }
            });
            this.winAmount = response.winAmount;
            this.showResultPopup(response);
        }
        else if (response.status === false) {
            this.popupComponent.showPopupError({ text: response.message }, 'btn-ok');
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    };
    PigComponent.prototype.showResultPopup = function (response) {
        var _this = this;
        this.popupPrizeImage = response.element.image;
        if (response.type) {
            this.showWinningPopup(response.winAmount);
        }
        else {
            this.showNoLuckPopup();
            this.hidePopupTimeout = setTimeout(function () {
                console.log("test_test: 25");
                _this.hideGamePopup();
            }, this.hidePopupAutoAfterSec * 1000);
        }
        this.checkGameIsOver(response);
    };
    PigComponent.prototype.hideGamePopup = function () {
        this.showGamePopup = false;
        this.showGamePopupNoLuck = false;
        this.showGamePopupWinning = false;
        clearTimeout(this.hidePopupTimeout);
    };
    PigComponent.prototype.showWinningPopup = function (prize) {
        this.showGamePopup = true;
        this.showGamePopupWinning = true;
        this.playWinSound();
    };
    PigComponent.prototype.showNoLuckPopup = function () {
        this.showGamePopup = true;
        this.showGamePopupNoLuck = true;
    };
    PigComponent.prototype.checkGameIsOver = function (response) {
        var _this = this;
        if (!response.attempts) {
            this.secretBtns.forEach(function (el) { return el.nativeElement.classList.add('no-events'); });
            var restImages_1 = response.elements;
            setTimeout(function () {
                if (restImages_1 && restImages_1.length) {
                    restImages_1.forEach(function (rest) {
                        _this.buttons.forEach(function (btn) {
                            if (rest.uuid === btn.hash) {
                                btn[_this.additionalBtnKeys.noLuck] = rest.image;
                                btn[_this.additionalBtnKeys.animate] = true;
                            }
                        });
                    });
                }
            }, 800);
        }
    };
    PigComponent.prototype.getResultImage = function (item) {
        if (item[this.additionalBtnKeys.winningImage]) {
            return item[this.additionalBtnKeys.winningImage];
        }
        else if (item[this.additionalBtnKeys.noLuck]) {
            return item[this.additionalBtnKeys.noLuck];
        }
        else {
            return this.defaultImage;
        }
    };
    PigComponent.prototype.createParticles = function (x, y) {
        // Set particle count.
        // Higher numbers may reduce performance.
        var particleCount = this.PARTICLE_COUNT;
        while (particleCount--) {
            // Create a new particle and add it to particles collection.
            this.particles.push(new ParticleService(x, y));
        }
    };
    PigComponent.prototype.cleanCanvas = function () {
        // Set 'destination-out' composite mode, so additional fill doesn't remove non-overlapping content.
        this.context.globalCompositeOperation = 'destination-out';
        // Set alpha level of content to remove.
        // Lower value means trails remain on screen longer.
        this.context.fillStyle = "rgba(0, 0, 0, " + this.CANVAS_CLEANUP_ALPHA + ")";
        // Fill entire canvas.
        this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
        // Reset composite mode to 'lighter', so overlapping particles brighten each other.
        this.context.globalCompositeOperation = 'lighter';
    };
    PigComponent.prototype.random = function (min, max) {
        return Math.random() * (max - min) + min;
    };
    PigComponent.prototype.launchAutomatedFirework = function () {
        // Determine if ticks since last automated launch is greater than random min/max values.
        if (this.ticksSinceFireworkAutomated >= this.random(this.TICKS_PER_FIREWORK_AUTOMATED_MIN, this.TICKS_PER_FIREWORK_AUTOMATED_MAX)) {
            // Check if mouse is not currently clicked.
            if (!this.isMouseDown) {
                // Set start position to bottom center.
                var startX = this.canvas.width / 2;
                var startY = this.canvas.height;
                // Set end position to random position, somewhere in the top half of screen.
                var endX = this.random(0, this.canvas.width);
                var endY = this.random(0, this.canvas.height / 2);
                // Create new firework and add to collection.
                this.fireworks.push(new FireworkService(startX, startY, endX, endY));
                // Reset tick counter.
                this.ticksSinceFireworkAutomated = 0;
            }
        }
        else {
            // Increment counter.
            this.ticksSinceFireworkAutomated++;
        }
    };
    PigComponent.prototype.launchManualFirework = function () {
        // Check if ticks since last firework launch is less than minimum value.
        if (this.ticksSinceFirework >= this.TICKS_PER_FIREWORK_MIN) {
            // Check if mouse is down.
            if (this.isMouseDown) {
                // Set start position to bottom center.
                var startX = this.canvas.width / 2;
                var startY = this.canvas.height;
                // // Set end position to current mouse position.
                var endX = this.mouseX;
                var endY = this.mouseY;
                // Create new firework and add to collection.
                this.fireworks.push(new FireworkService(startX, startY, endX, endY));
                // Reset tick counter.
                this.ticksSinceFirework = 0;
            }
        }
        else {
            // Increment counter.
            this.ticksSinceFirework++;
        }
    };
    // Update all active fireworks.
    PigComponent.prototype.updateFireworks = function () {
        // Loop backwards through all fireworks, drawing and updating each.
        for (var i = this.fireworks.length - 1; i >= 0; --i) {
            this.fireworks[i].draw(this.context, this.hue);
            this.fireworks[i].update(i, this.fireworks, this.createParticles.bind(this));
        }
    };
    // Update all active particles.
    PigComponent.prototype.updateParticles = function () {
        // Loop backwards through all particles, drawing and updating each.
        for (var i = this.particles.length - 1; i >= 0; --i) {
            this.particles[i].draw(this.context);
            this.particles[i].update(i, this.particles);
        }
    };
    // === END APP HELPERS ===
    // Primary loop.
    PigComponent.prototype.loop = function () {
        // Smoothly request animation frame for each loop iteration.
        var $this = this;
        if ($this) {
            window.requestAnimFrame(this.loop.bind(this));
            // Adjusts coloration of fireworks over time.
            this.hue += this.HUE_STEP_INCREASE;
            // Clean the canvas.
            this.cleanCanvas();
            // Update fireworks.
            this.updateFireworks();
            // Update particles.
            this.updateParticles();
            // Launch manual fireworks.
            this.launchManualFirework();
        }
    };
    PigComponent.prototype.btnClickHandler = function (e, hash) {
        this.chooseItem(e.currentTarget, hash);
    };
    PigComponent.prototype.buttonImage = function (index, button) {
        return button.image;
    };
    PigComponent.prototype.showGame = function () {
        var _this = this;
        this.isElementReady.emit(true);
        setTimeout(function () {
            _this.activeClass = true;
        }, 0);
    };
    PigComponent.prototype.onImageLoaded = function () {
        if (this.imagesForLoading) {
            this.imagesForLoading -= 1;
        }
        else {
            this.showGame();
        }
    };
    PigComponent.prototype.playWinSound = function () {
        var audio = new Audio(PIG_SOUND_TYPE.win.src);
        audio.play();
    };
    return PigComponent;
}());
export { PigComponent };
