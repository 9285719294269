import {LoaderService} from "./loader.service";

export class SettingsLoaderService {
    private _showLoaderBeforeRequest = true;
    private _hideLoaderAfterRequest = true;

    constructor(public loaderService: LoaderService) {
    }

    showLoader(){
        if(this.showLoaderBeforeRequest){
            this.loaderService.show();
        }
    }

    hideLoader(){
        if(this.hideLoaderAfterRequest){
            this.loaderService.hide();
        }
    }

    get showLoaderBeforeRequest(): boolean {
        return this._showLoaderBeforeRequest;
    }

    set showLoaderBeforeRequest(value: boolean) {
        this._showLoaderBeforeRequest = value;
    }

    get hideLoaderAfterRequest(): boolean {
        return this._hideLoaderAfterRequest;
    }

    set hideLoaderAfterRequest(value: boolean) {
        this._hideLoaderAfterRequest = value;
    }
}
