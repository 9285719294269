import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {TERMINAL_RESPONSE_KEYS, TERMINAL_RESPONSE_KEYS_MODEL} from "../../config/terminal/response_keys.config";
import {BehaviorSubject} from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class TerminalService {
    storage = sessionStorage;
    terminalInfoStorageKey = "terminalInfo";
    terminalInfoUpdating = new BehaviorSubject(null);
    info: TERMINAL_RESPONSE_KEYS_MODEL = {};

    constructor() {
        //   console.log("terminalInfoUpdating subscribe in TerminalService");
        //
        // this.terminalInfoUpdating.subscribe(val => {
        //   if (val) {
        //     const info = this.storage.getItem(this.terminalInfoStorageKey);
        //     this.info = JSON.parse(info);
        //   }
        // });
    }

    getTitle() {
        return this.getKey('title');
    }

    getDescription() {
        return this.getKey('description');
    }

    getShortName() {
        return this.getKey('shortName');
    }

    getLogo() {
        return this.getKey('logo');
    }

    isCrypto() {
        return this.getKey('isCrypto');
    }

    isGames() {
        return this.getKey('isGames');
    }

    isInfo() {
        if (this.info.shortName) {
          return true;
        }

        return false;
    }

    isRegistered() {
        if (this.storage.getItem(this.terminalInfoStorageKey)) {
          if(this.getShortName() !== ''){
              return true;
          }
        }

        return false;
    }

    getKey(key) {
        if (this.storage.getItem(this.terminalInfoStorageKey)) {
            const info = this.storage.getItem(this.terminalInfoStorageKey);
            this.info = JSON.parse(info);
        }

        return this.info && this.info[TERMINAL_RESPONSE_KEYS[key]] ? this.info[TERMINAL_RESPONSE_KEYS[key]] : '';
    }
}
