import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { PRIZES_SENETPRO_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var PrizesSenetProService = /** @class */ (function (_super) {
    tslib_1.__extends(PrizesSenetProService, _super);
    function PrizesSenetProService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = PRIZES_SENETPRO_CONFIG;
        return _this;
    }
    PrizesSenetProService.prototype.getPageInfo = function (page, singleHistoryPage) {
        this.showLoader();
        return this.http.post(this.config.info, { page: page, singleHistoryPage: singleHistoryPage });
    };
    PrizesSenetProService.prototype.redeem = function (amount) {
        this.showLoader();
        return this.http.post(this.config.redeem, { amount: amount });
    };
    PrizesSenetProService.prototype.withdraw = function (shortname, amount) {
        this.showLoader();
        return this.http.post(this.config.withdraw, { shortname: shortname, amount: amount });
    };
    PrizesSenetProService.prototype.sendFriendPhone = function (phone) {
        this.showLoader();
        return this.http.post(this.config.sendFriendPhone, { phone: phone });
    };
    PrizesSenetProService.prototype.sendToFrined = function (phone, amount) {
        this.showLoader();
        return this.http.post(this.config.sendToFriend, { phone: phone, amount: amount });
    };
    PrizesSenetProService.prototype.getPaypalInfo = function () {
        this.showLoader();
        return this.http.post(this.config.getPaypalInfo, {});
    };
    PrizesSenetProService.prototype.getVenmoInfo = function () {
        this.showLoader();
        return this.http.post(this.config.getVenmoInfo, {});
    };
    PrizesSenetProService.prototype.getDebitCardInfo = function () {
        this.showLoader();
        return this.http.post(this.config.getDebitCardInfo, {});
    };
    PrizesSenetProService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PrizesSenetProService_Factory() { return new PrizesSenetProService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: PrizesSenetProService, providedIn: "root" });
    return PrizesSenetProService;
}(SettingsLoaderService));
export { PrizesSenetProService };
