<div class="page page--primary commission">
    <div class="page__inner js-commission-parent" (scroll)="scroll($event, commissionData)">

        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">Commission</h1>

        <div class="commission__text-block">
            <h2 class="gl-title">EARN</h2>
            <div class="gl-subtitle">The commission is settled once per week, always on Mondays. Available Balance is your reward that you can claim. Running Balance is a live tally and can change throughout the week. Once your Available Balance is issued, it is recommended that you use it within a week to make sure your current Running Balance does not change it the following week.</div>
        </div>

        <div class="commission__text-block">
            <div class="commission__row">
                <h2 class="gl-title">Available Balance</h2>
                <div class="gl-text c-accent">{{availableBalance  | currency : 'USD' : 'symbol'}}</div>
            </div>
            <p class="gl-subtitle" *ngIf="availableBalance < 0">Since your last commission, there were more rewards sent than money spent. No worries. You are never responsible for it. We cover it all for you. The next commission will be sent as soon as your balance become positive.</p>
            <div class="gl-subtitle">You can use this balance now. Remember next week your Running Balance might increase or decrease it.</div>
        </div>

        <div class="page__form">
            <form [formGroup]="form" (ngSubmit)="onSubmitPayout()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <mat-form-field>
                        <!--                        <mat-label>GET</mat-label>-->
                        <mat-select #methodsSelect
                                    formControlName="method"
                                    (valueChange)="onMethodChange($event)"
                                    [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                            <mat-select-trigger>
                                {{methodsSelect?.value?.name}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let method of methods" [value]="method">
                                {{method.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           (input)="onInputAmount()"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>


                <div class="form-simple__item">
                    <div *ngIf="moreThenAvailablePayout" class="form-simple__note">
                        <p *ngIf="isAvailableBalance(); else isNegativeBalance">Available {{availableBalance | currency : 'USD' : 'symbol'}}</p>
                        <ng-template #isNegativeBalance>
                            <p>Available {{0 | currency : 'USD' : 'symbol'}}</p>
                        </ng-template>
                    </div>
                </div>

                <div class="form-simple__item" [class.is-hidden]="isNoMailCheckChosen">
                    <input formControlName="address"
                           placeholder="Address"
                           [type]="'text'"
                           (change)="onInputAddress($event)"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button
                            class="button snake-btn is-notouched"
                            [disabled]="noSubmitPayoutForm()">
                        SETTLE
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

        <div class="commission__text-block">
            <div class="commission__row">
                <h2 class="gl-title">Running balance</h2>
                <div class="gl-text c-accent">{{balance  | currency : 'USD' : 'symbol'}}</div>
            </div>
            <p class="gl-subtitle" *ngIf="!isPartnerTypeSingleBalance()">
                Thank you and welcome to our Reseller Program where you can earn up
                to {{commission}}% commission by sharing our link with your friends and family.
                When making a referral, please mention the website {{partnerDomain}}
                together with your personal PROMO Code. The table below shows your
                commission inflows and outflows. Your commission is paid out weekly on he Net of all earned income.
                <!--            The commission of {{commission}}% is paid out-->
                <!--            weekly on the Net of all earned income.-->
            </p>
            <p class="gl-subtitle" *ngIf="isPartnerTypeSingleBalance()">
                Thank you and welcome to our Referral Program where you can earn {{commission}}%
                commission by sharing our link with your friends and family.
                When making a referral, please mention the website {{partnerDomain}}
                together with your personal PROMO Code. The table below shows
                your commission inflows and outflows. Your commission is paid
                out weekly on the Net of all earned income.
            </p>
        </div>

        <div class="commission__text-block">
            <div>
                <div style="text-align: center">
                    <h2 class="commission__title">SITE</h2>
                    <span class="commission__text commission__text--accent commission__link">{{site}}</span>
                    <h2 class="commission__title">PROMO CODE</h2>
                    <span class="commission__text commission__text--accent">{{promoCode}}</span>
                </div>
<!--                <div class="commission__col">-->
<!--                    <h2 class="commission__title">PROMO CODE</h2>-->
<!--                    <span class="commission__text commission__text&#45;&#45;accent">{{promoCode}}</span>-->
<!--                </div>-->
            </div>
        </div>

        <p class="gl-subtitle" style="display: none">
            Easily refer anybody by simply entering their phone below and clicking the Refer button. A Text message will be sent on your behalf with the link and your personal Promo Code.
            <!--            You can easily refer anybody by simply entering their phone below and clicking the Refer button. A Text message will be sent on your behalf with the link and your personal Promo Code.-->
        </p>

        <div class="page__form" style="display: none">
            <form [formGroup]="formRefer" (ngSubmit)="onSubmit()" class="form-simple form-simple--primary">
                <div class="form-simple__item">
                    <input formControlName="firstLastName"
                           placeholder="First Last Name"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>
                <div class="form-simple__item">
                    <input [mask]="phoneMask"
                           formControlName="phone"
                           placeholder="Phone"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__btns">
                    <button
                            class="button snake-btn is-notouched"
                            [disabled]="formRefer.invalid">
                        REFER
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

<!--        <app-dropdown-item [dropdownClass]="'js-disputes-parent'"-->
<!--                           [title]="'DISPUTES'"-->
<!--                           [noBodyLeftPadding]="true"-->
<!--                           [hasPagination]="true">-->
<!--            <div class="dropdown__body" #scrolledEl (scroll)="scroll($event, disputesData)" #dropBody>-->
<!--                <table class="commission-history commission-history&#45;&#45;disputes">-->
<!--                    <tr class="commission-history__head">-->
<!--                        <th>DATE</th>-->
<!--                        <th>PHONE</th>-->
<!--                        <th>AMOUNT</th>-->
<!--                        <th>BAL</th>-->
<!--                    </tr>-->
<!--                    <tr *ngFor="let action of disputesData.items" class="commission-history__item js-disputes-item">-->
<!--                        <td>{{getDate(action.created) | date: 'MM/dd/yy'}}</td>-->
<!--                        <td>{{action.phone | mask: phoneMask}}</td>-->
<!--                        <td><app-financial-info-item [negativeIsNotDashed]="true" [value]="action.amount"></app-financial-info-item></td>-->
<!--                        <td><app-financial-info-item [negativeIsNotDashed]="true" [value]="action.balance"></app-financial-info-item></td>-->
<!--                    </tr>-->
<!--                </table>-->
<!--            </div>-->
<!--        </app-dropdown-item>-->

        <div class="commission__text-block">
            <h2 class="gl-title">Sales History</h2>
            <div class="gl-subtitle">The table below shows historical records of all your sales and all commissions ever issued to you.</div>
        </div>

        <app-dropdown-item [hideOpenArrow]="true"
                           [title]="'Transaction History'"
                           [noBodyLeftPadding]="true"
                           [hasPagination]="true">
            <div class="dropdown__body">
                <table class="commission-history">
                    <tr class="commission-history__head">
                        <th>DATE</th>
                        <th>CREDIT</th>
                        <th>DEBIT</th>
                        <th>BAL</th>
                        <th>COM {{commission}}%</th>
                    </tr>
                    <tr *ngFor="let action of commissionData.items" class="commission-history__item js-commission-item">
                        <td>{{getDate(action.created) | date: 'MM/dd/yy'}}</td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.credit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.debit)"></app-financial-info-item></td>
                        <td><app-financial-info-item [negativeWithoutBrackets]="true" [value]="action.balance"></app-financial-info-item></td>
                        <td class="accent"><app-financial-info-item [negativeWithoutBrackets]="true" [value]="getAmount(action.amount, action.type, COMMISSION_TYPE.settlement)"></app-financial-info-item></td>
                    </tr>
                </table>
            </div>
        </app-dropdown-item>
    </div>
</div>

