<div class="page page--primary">
    <div class="page__inner">
<!--        <app-logo-bg></app-logo-bg>-->

<!--        <a [routerLink]="getBackLink()" class="page__back page__back&#45;&#45;mobile page__back&#45;&#45;mobile-text">-->
<!--            <span class="visually-hidden">Back</span>-->
<!--        </a>-->
        <h1 class="page__title">Support</h1>

        <div class="page__body">
            <div class="chat-frame" *ngIf="frameUrl">
                <iframe [src]="frameUrl" frameborder="0"></iframe>
<!--                <a [routerLink]="['/home/rewards']" class="button page__back page__back&#45;&#45;desktop" >-->
<!--                    Back-->
<!--                    <svg><rect></rect></svg>-->
<!--                </a>-->
            </div>


        </div>
    </div>
</div>
