<div class="login">
    <div class="login__inner">
        <div class="login__header">
            <img class="login__logo" src="../../../../assets/images/activate_yourself.png" alt="">

        </div>
        <div class="login__title">{{partnerBusinessName}} Member Portal</div>

        <div class="login__text login__text--mb-sm">
            {{message}}
        </div>
        <br/><br/>
        <div class="login__forms">

            <div class="login-tab">
                <div class="text-center form-btn-submit">
                    <button class="button snake-btn is-notouched" (click)="closeWindow()">
                        Back
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
