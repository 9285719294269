<div class="page page--tertiary page--scrolled long-content" [class.is-blurred]="showPopup !== 'hide' || showNFTPopup">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">NFT & CRYPTO</h1>

<!--        <app-investments-header [data]="headerData"></app-investments-header>-->


        <div class="page__body" #scrolledEl>
            <div class="dropdown">
                <div class="dropdown__head">
                    <div class="dropdown__title">
                        NFT WALLET
                    </div>
                    <div class="dropdown__total">
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#polygonscan'"></use>
                        </svg>
                    </div>
                </div>
                <div class="dropdown__body">
                    <div class="dropdown__text gl-subtitle" *ngIf="isPartnerTypeSingleBalance()">
                        This is your private NFT Wallet on Polygon Block Chain. Keep it safe as all Minted NFT Artwork is stored on this Wallet
                    </div>
                    <div class="wallet" [class.wallet--flex]="!nftData.wallet">

                        <button class="button button--default button--middle"
                                *ngIf="!nftData.wallet"
                                (click)="generateWallet()">Generate</button>

                        <div class="wallet__info" *ngIf="nftData.wallet">
                            <img [src]="nftData.qrCode" alt="" *ngIf="nftData.qrCode" class="wallet__qr-code">

                            <div class="wallet__content">
                                <div class="wallet__address">{{nftData.wallet}}</div>

                                <div class="wallet__row">
<!--                                    <button class="button button&#45;&#45;default button&#45;&#45;small"-->
<!--                                            (click)="openMintPopup()">Mint Nft</button>-->
                                    <button class="button button--default button--small"
                                            (click)="copyToBuffer()">COPY</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="dropdown">
                <div class="dropdown__head">
                    <div class="dropdown__title">
                        NFT LIBRARY
                    </div>
                </div>
                <div class="dropdown__text gl-subtitle" *ngIf="isPartnerTypeSingleBalance()">
                    All minted artwork is stored in your library. The price of minting is flat ${{nftPrice}}.
                </div>
                <div class="dropdown__body" *ngIf="nftData.library.length">
                    <div class="library" *ngIf="!isPartnerTypeSingleBalance()">
                        <ngx-glide #librarySlider
                                   perView="3"
                                   [showBullets]="false"
                                   [showArrows]="false"
                                   [type]="nftData.library.length > NFTLibraryMaxSlides ? 'carousel' : 'slider'"
                                   [dragThreshold]="nftData.library.length > NFTLibraryMaxSlides"
                                   [swipeThreshold]="nftData.library.length > NFTLibraryMaxSlides">
                            <button [attr.data-id]="slide.uuid"
                                    *ngFor="let slide of nftData.library"
                                    class="slide library__slide js-library-slide">
                                <img class="slide library__slide-img" src="{{ slide.image_link }}" alt="" width="100%">
                                <p class="library__text">Ownership {{slide.ownership | number: '1.0-2'}}%</p>
                            </button>
                        </ngx-glide>

                        <div class="library__controls" *ngIf="nftData.library.length > NFTLibraryMaxSlides">
                            <button class="library__control library__control--prev" aria-label="Previous" (click)="librarySlider.go('<')" >
                                <
                            </button>
                            <button class="library__control library__control--next" aria-label="Next" (click)="librarySlider.go('>')">
                                >
                            </button>
                        </div>

                    </div>
                    <div class="library library--grid" *ngIf="isPartnerTypeSingleBalance()">
                        <button [attr.data-id]="slide.uuid"
                                *ngFor="let slide of nftData.library"
                                class="slide library__slide js-library-slide">
                            <img class="slide library__slide-img" src="{{ slide.image_link }}" alt="" width="100%">
<!--                            <img class="slide library__slide-img" src="../../../assets/partners/snekapek/icons/icon-512.png" alt="" width="100%">-->
                            <p class="library__text">Ownership {{slide.ownership | number: '1.0-2'}}%</p>
                        </button>
                    </div>
                </div>
            </div>

<!--            <div class="dropdown js-dropdown js-crypto-parent is-hidden">-->
<!--                <div id="cryptoOut" class="dropdown__head" (click)="toggleDropdown($event)">-->
            <div class="dropdown js-dropdown js-crypto-parent" style="display: none;">
                <div id="cryptoOut" class="dropdown__head">
                    <div class="dropdown__title">
                        CRYPTO OUT
                    </div>
                    <div class="dropdown__total">
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#bitcoin'"></use>
                        </svg>
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#litecoin'"></use>
                        </svg>
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#bitcoin_cash'"></use>
                        </svg>
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/payment_sprite.svg#ethereum'"></use>
                        </svg>
                    </div>
<!--                    <svg class="dropdown__arrow" width="21" height="12">-->
<!--                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>-->
<!--                    </svg>-->
                </div>
                <div class="dropdown__body">
                    <table class="transactions">
                        <tr *ngFor="let item of cryptoData?.items; let i = index" class="transactions__item js-crypto-item">
                            <td class="transactions__type transactions__type--fixed">
                                <div class="transactions__icon-wrapper transactions__icon-wrapper--left-align">
                                    <img *ngIf="item" class="transactions__icon"
                                         src="../../../../assets/images/currencies/{{item.icon}}.png"
                                         (error)="loadDefaultCurrencyIcon($event)">
                                </div>
                            </td>
                            <td class="transactions__middle">
                                <div class="js-flex-text">
                                    {{item.size | number: CRYPTO_AMOUNT_MASK}} {{item.name}}
                                </div>
                            </td>
                            <td class="transactions__btn-wrapper">
                                <button id="cryptoOut_{{i}}"
                                        (click)="openSendPopup(item)"
                                        [ngStyle]="{color: getCryptoColor(item.icon), borderColor: getCryptoColor(item.icon)}"
                                        class="transactions__btn snake-btn btn is-notouched">
                                    Send
                                    <svg><rect [ngStyle]="{stroke: getCryptoColor(item.icon)}"></rect></svg>
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="cryptoData.loading">
                            <td colspan="3" style="text-align: center">
                                <div class="pre-loader">
                                    <div class="box1"></div>
                                    <div class="box2"></div>
                                    <div class="box3"></div>
                                    <div class="box4"></div>
                                    <div class="box5"></div>
                                </div>
                            </td>

                        </tr>

                    </table>
                </div>
            </div>
        </div>

<!--        <img class="page__coins" src="../../../assets/images/bitcoins.png" alt="">-->
    </div>

    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>


</div>

<app-investments-popup-form [showPopupForm]="showPopup"
                            [data]="popupFormData"
                            [formType]="popupFormType"
                            [fromPage]="'trade'"
                            [confirmRequest]="popupConfirmRequest"
                            (close)="onPopupFormClose($event)"></app-investments-popup-form>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>
