import {Component, OnInit} from '@angular/core';
import {slideToLeft, slideToRight} from "../../../../animations/router.animations";


@Component({
    selector: 'app-snekapek-investments',
    templateUrl: './snekapek-investments.component.html',
    styleUrls: ['./snekapek-investments.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class SnekapekInvestmentsComponent implements OnInit {

    constructor() {

    }


    ngOnInit() {

    }
}
