import {Injectable} from '@angular/core';
import {PHONE_MASK} from "../config/country_phone_mask";

@Injectable({
    providedIn: 'root'
})
export class PhoneMaskService {
    PHONE_MASK_CONFIG = PHONE_MASK;
    country = localStorage.country ? localStorage.country : 'us';

    constructor() {
    }

    setPhoneMask() {
        return this.PHONE_MASK_CONFIG[this.country];
    }

}
