import {EventEmitter, Injectable} from '@angular/core';
import {YETI_RULES} from "../yeti.config";
import {YetiCommonService} from "./yetiCommon.service";
declare const PIXI: typeof import('pixi.js');

@Injectable({
  providedIn: 'root'
})
export class YetiRulesService {
  RULES = YETI_RULES;
  activeSlide = 1;

  TITLE_STYLE = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 80,
    lineHeight: 100,
    fontWeight: 'bold',
    align: 'center',
    fill: '#e0f8ff'
  });

  TITLE_SECONDARY_STYLE = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 60,
    lineHeight: 80,
    fontWeight: 'bold',
    align: 'center',
    fill: '#e3ffdc'
  });

  TEXT_STYLE = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 40,
    fill: ['#e0f7ff'], // gradient
  });



  CONTAINER_SETTINGS = {
    positionX: 726,
    positionY: 348,
    width: 1065,
    height: 600
  };

  PRIZES = [
    {
      containerPosX: 580,
      containerPosY: 370,
      imgName: 'prize1',
      imgHeight: 115,
      numImageName: '1',
      numHeight: 90,
    },
    {
      containerPosX: 50,
      containerPosY: 565,
      imgName: 'prize2',
      imgHeight: 135,
      numImageName: '2',
      numHeight: 94,
    },
    {
      containerPosX: 430,
      containerPosY: 565,
      imgName: 'prize3',
      imgHeight: 176,
      numImageName: '3',
      numHeight: 90,
    },
    {
      containerPosX: 180,
      containerPosY: 370,
      imgName: 'prize4',
      imgHeight: 155,
      numImageName: '4',
      numHeight: 98,
    },
    {
      containerPosX: 790,
      containerPosY: 565,
      imgName: 'prize5',
      imgHeight: 150,
      numImageName: '5',
      numHeight: 96,
    }
  ]

  rulesSlide1Container = new PIXI.Container();
  rulesSlide2Container = new PIXI.Container();
  rulesSlide3Container = new PIXI.Container();
  rulesCommonContainer = new PIXI.Container();

  playBtn;
  closeRulesBtn;
  
  nextSlideBtn;
  prevSlideBtn;

  isSlideChangeAnimation = false;

  constructor(private yetiCommonService: YetiCommonService) {}

  setFirstSlideActive() {
    const allRulesSlideContainersChildren = [...this.rulesSlide1Container.children, ...this.rulesSlide2Container.children, ...this.rulesSlide3Container.children];
    const dist = this.CONTAINER_SETTINGS.width / 2 - this.rulesSlide1Container.children[0].position.x;

    allRulesSlideContainersChildren.forEach(child => {
      if (!child.isMask) {
        child.position.x += dist;
      }
    });
    
    this.activeSlide = 1;
    this.prevSlideBtn.alpha = 0;
    this.nextSlideBtn.alpha = 1;
  }

  setRules(stage, resources, renderer) {
    this.setRulesView(stage, resources, renderer);
    this.setRulesControls(stage, renderer);
  }

  setRulesView(stage, resources, renderer) {
    this.setRulesFrame(stage, resources);
    this.setPlayBtn(stage, resources);
    this.setCloseBtn(stage, resources);
    this.setSlidesPosition();
    this.setSlide1(stage);
    this.setSlide2(stage, resources);
    this.setSlide3(stage, resources);
    this.setRulesFrameControls(stage, resources, renderer);
    stage.addChild(this.rulesCommonContainer);
  }

  setRulesControls(stage, renderer) {
    this.setSliderControls(renderer, stage);
    this.onPlayBtnClick();
    this.onCloseRulesBtnClick()
  }

  setSliderControls(renderer, stage) {
    this.onSlideNextBtnClick(renderer, stage);
    this.onSlidePrevBtnClick(renderer, stage);
  }

  onCloseRulesBtnClick() {
    this.yetiCommonService.addButtonBehavior(this.closeRulesBtn);

    this.closeRulesBtn.on('pointerdown', (event) => {
      this.yetiCommonService.showRules.emit(false);
    });
  }

  onPlayBtnClick() {
    this.yetiCommonService.addButtonBehavior(this.playBtn);
    this.playBtn.on('pointerdown', (event) => {
      this.yetiCommonService.startGame.emit(true);
    });
  }

  onSlideNextBtnClick(renderer, stage) {
    this.nextSlideBtn.on('pointerdown', (event) => {
      this.changeSlide('next', renderer, stage);
    });
  }

  onSlidePrevBtnClick(renderer, stage) {
    this.prevSlideBtn.on('pointerdown', (event) => {
      this.changeSlide('prev', renderer, stage);
    });
  }

  setRulesFrame(stage, resources) {
    const img = new PIXI.Sprite(resources.frameImg.texture);
    const imgMarkupHeight = 810;
    const scale = imgMarkupHeight / img.height;

    const posX = 670;
    const posY = 184;

    img.scale.set(scale, scale);
    img.position.set(posX, posY);
    this.rulesCommonContainer.addChild(img);
  }

  setPlayBtn(stage, resources) {
    this.playBtn = new PIXI.Sprite(resources.use.texture);
    const imgMarkupHeight = 110;
    const scale = imgMarkupHeight / this.playBtn.height;
    this.playBtn.scale.set(scale, scale);

    const posX = this.CONTAINER_SETTINGS.positionX + this.CONTAINER_SETTINGS.width / 2;
    const posY = 965;

    this.playBtn.anchor.set(.5, .5);
    this.playBtn.position.set(posX, posY);

    this.rulesCommonContainer.addChild(this.playBtn);
  }

  setCloseBtn(stage, resources) {
    this.closeRulesBtn = new PIXI.Sprite(resources.closeRules.texture);
    const imgMarkupHeight = 105;
    const scale = imgMarkupHeight / this.closeRulesBtn.height;
    this.closeRulesBtn.scale.set(scale, scale);

    const posX = this.CONTAINER_SETTINGS.positionX + this.CONTAINER_SETTINGS.width;
    const posY = 200;

    this.closeRulesBtn.anchor.set(.25, .5);
    this.closeRulesBtn.visible = false;
    this.closeRulesBtn.position.set(posX, posY);

    this.rulesCommonContainer.addChild(this.closeRulesBtn);
  }

  setSlidesPosition() {
    [this.rulesSlide1Container, this.rulesSlide2Container, this.rulesSlide3Container].forEach(container => {
      container.position.set(this.CONTAINER_SETTINGS.positionX, this.CONTAINER_SETTINGS.positionY);
    });
  }

  setSlideMask(container) {
    const mask = new PIXI.Sprite(PIXI.Texture.WHITE);
    mask.width = this.CONTAINER_SETTINGS.width;
    mask.height = this.CONTAINER_SETTINGS.height;
    container.addChild(mask);
    container.mask = mask;
  }

  setSlide1(stage) {
    let lastAddedElPos = 0;

    const title = new PIXI.Text('RULES:', this.TITLE_STYLE);
    title.anchor.set(0.5, 0);
    this.rulesSlide1Container.addChild(title);

    lastAddedElPos += title.height * 1.2;

    this.RULES.forEach(rule => {
      const item = new PIXI.Text(`• ${rule}`, this.TEXT_STYLE);
      item.y = lastAddedElPos;
      item.x = 30;
      lastAddedElPos += item.height * 1.2;
      this.rulesSlide1Container.addChild(item);
    });

    title.position.x = this.CONTAINER_SETTINGS.width / 2;

    this.setSlideMask(this.rulesSlide1Container);
    this.rulesCommonContainer.addChild(this.rulesSlide1Container);
  }

  setSlide2(stage, resources) {
    const hiddenOffset = this.CONTAINER_SETTINGS.width;

    const title = new PIXI.Text('Multiplier \n Winning Elements', this.TITLE_STYLE);
    title.anchor.set(0.5, 0);

    this.rulesSlide2Container.addChild(title);

    title.position.x = this.CONTAINER_SETTINGS.width / 2 + hiddenOffset;

    this.setSlide2Images(hiddenOffset, resources);

    this.setSlideMask(this.rulesSlide2Container);
    this.rulesCommonContainer.addChild(this.rulesSlide2Container);
  }

  setSlide2Images(hiddenOffset, resources) {
    this.PRIZES.forEach(prizeInfo => {
      let lastAddedElementXPosition = 0;
      const prizeContainer = new PIXI.Container();

      const prizeImage = new PIXI.Sprite(resources[prizeInfo.imgName].texture);
      const imgMarkupHeight = prizeInfo.imgHeight;
      const scale = imgMarkupHeight / prizeImage.height;
      this.yetiCommonService.scale(prizeImage, scale);
      prizeContainer.addChild(prizeImage);
      lastAddedElementXPosition = prizeImage.width;

      const xImage = new PIXI.Sprite(resources.x.texture);
      const xMarkupHeight = 40;
      const xScale = xMarkupHeight / xImage.height;
      this.yetiCommonService.scale(xImage, xScale);
      xImage.anchor.set(0, 1);
      xImage.position.set(lastAddedElementXPosition + 20, prizeImage.height - 20);
      prizeContainer.addChild(xImage);
      lastAddedElementXPosition += 20 + xImage.width

      const numImage = new PIXI.Sprite(resources[prizeInfo.numImageName].texture);
      const numMarkupHeight = prizeInfo.numHeight;
      const numScale = numMarkupHeight / numImage.height;
      this.yetiCommonService.scale(numImage, numScale);
      numImage.anchor.set(0, 1);
      numImage.position.set(lastAddedElementXPosition + 3, prizeImage.height - 20);
      prizeContainer.addChild(numImage);

      prizeContainer.position.set(prizeInfo.containerPosX + hiddenOffset, prizeInfo.containerPosY - prizeContainer.height);
      this.rulesSlide2Container.addChild(prizeContainer);
    });
  }

  setSlide3(stage, resources) {
    const hiddenOffset = this.CONTAINER_SETTINGS.width * 2;

    const title = new PIXI.Text('Lose All \n previous wins', this.TITLE_SECONDARY_STYLE);
    title.anchor.set(0.5, 0);

    this.rulesSlide3Container.addChild(title);

    title.position.x = this.CONTAINER_SETTINGS.width / 2 + 330 + hiddenOffset;
    title.position.y = 240;

    const posionBg = new PIXI.Sprite(resources.poisonBg.texture);
    const posionBgMarkupHeight = 750;
    const posionBgScale = posionBgMarkupHeight / posionBg.height;
    this.yetiCommonService.scale(posionBg, posionBgScale);
    posionBg.position.x = hiddenOffset;
    posionBg.position.y = -100;
    this.rulesSlide3Container.addChild(posionBg);

    const posionImage = new PIXI.Sprite(resources.poison.texture);
    const markupHeight = 345;
    const scale = markupHeight / posionImage.height;
    this.yetiCommonService.scale(posionImage, scale);
    posionImage.position.x = 230 + hiddenOffset;
    posionImage.position.y = 140;
    this.rulesSlide3Container.addChild(posionImage);

    this.setSlideMask(this.rulesSlide3Container);
    this.rulesCommonContainer.addChild(this.rulesSlide3Container);
  }

  changeSlide = (direction, renderer, stage) => {
    let animationSpeed;
    let requestAnimation;

    if (!this.isSlideChangeAnimation) {
      const isNext = direction === 'next';
      const isPrev = direction === 'prev';
      this.isSlideChangeAnimation = true;
      animationSpeed = 20;
      if ((isNext && this.activeSlide !== 3) || (isPrev && this.activeSlide !== 1)) {
        const play = () => {
          let speed = direction === 'next' ? -animationSpeed : animationSpeed;

          const allRulesSlideContainersChildren = [...this.rulesSlide1Container.children, ...this.rulesSlide2Container.children, ...this.rulesSlide3Container.children];

          allRulesSlideContainersChildren.forEach(child => {
            if (!child.isMask) {
              child.position.x += speed;
            }
          });

          animationSpeed = animationSpeed * 1.02;

          let nextActiveSlide;
          if (isNext) {
            nextActiveSlide = this.activeSlide === 1 ? 2 : 3;
          } else {
            nextActiveSlide = this.activeSlide === 2 ? 1 : 2;
          }

          const stopPoint = isNext ? this.CONTAINER_SETTINGS.width / 2 - this.CONTAINER_SETTINGS.width * this.activeSlide : this.CONTAINER_SETTINGS.width / 2 - this.CONTAINER_SETTINGS.width * (this.activeSlide - 2);

          let stopCondition;
          if (isNext) {
            stopCondition = stopPoint < this.rulesSlide1Container.children[0].position.x;
          } else {
            stopCondition = stopPoint > this.rulesSlide1Container.children[0].position.x + 30;
          }

          if (stopCondition) {
            requestAnimation = requestAnimationFrame(play);
          } else {
            this.activeSlide = nextActiveSlide;
            this.isSlideChangeAnimation = false;

            if (this.activeSlide === 1) {
              this.prevSlideBtn.alpha = 0;
            } else {
              this.prevSlideBtn.alpha = 1;
            }

            if (this.activeSlide === 3) {
              this.nextSlideBtn.alpha = 0;
            } else {
              this.nextSlideBtn.alpha = 1;
            }

            cancelAnimationFrame(requestAnimation);
          }
          renderer.render(stage);
        }
        requestAnimation = requestAnimationFrame(play);
      } else {
        this.isSlideChangeAnimation = false;
      }
    }
  }

  setRulesFrameControls(stage, resources, renderer) {
    this.nextSlideBtn = new PIXI.Sprite(resources.nextBtn.texture);
    this.prevSlideBtn = new PIXI.Sprite(resources.nextBtn.texture);

    const imgMarkupHeight = 110;
    const scale = imgMarkupHeight / this.nextSlideBtn.height;

    const nextSlideBtnPosX = 1810;
    const prevSlideBtnPosX = 705;
    const posY = 524;

    this.prevSlideBtn.rotation = 3.14159;

    this.yetiCommonService.scale(this.nextSlideBtn, scale);
    this.yetiCommonService.scale(this.prevSlideBtn, scale);

    this.nextSlideBtn.position.set(nextSlideBtnPosX, posY);
    this.prevSlideBtn.position.set(prevSlideBtnPosX, posY);

    this.yetiCommonService.setAnchorToCenter(this.nextSlideBtn);
    this.yetiCommonService.setAnchorToCenter(this.prevSlideBtn);

    this.yetiCommonService.addButtonBehavior(this.nextSlideBtn);
    this.yetiCommonService.addButtonBehavior(this.prevSlideBtn);

    this.prevSlideBtn.alpha = 0;

    this.rulesCommonContainer.addChild(this.nextSlideBtn);
    this.rulesCommonContainer.addChild(this.prevSlideBtn);
  }

  activateGamePlayBtn(state = false) {
    if (state) {
      this.playBtn.alpha = 1;
      this.playBtn.interactive = true;
      this.playBtn.buttonMode = true;
      this.playBtn.defaultCursor = 'pointer';
    } else {
      this.playBtn.alpha = 0;
      this.playBtn.interactive = false;
      this.playBtn.buttonMode = false;
      this.playBtn.defaultCursor = 'auto';
    }
  }
}
