<table class="transactions" [class.is-sticky]="isStickyHeader">
    <tr class="transactions__head js-prizes-item">
        <th class="transactions__date">DATE</th>
        <th style="text-align: center" class="transactions__amount">CREDIT</th>
        <th style="text-align: center" class="transactions__amount">DEBIT</th>
        <th style="text-align: center" class="transactions__amount">BALANCE</th>
        <th class="transactions__type">TYPE</th>
    </tr>
    <tr *ngFor="let transaction of items" class="transactions__item  js-prizes-item" [class.c-accent]="transaction.type === WITHDRAW_TRANSACTION_TYPES.bonuses">
        <td class="transactions__date">{{getDate(transaction.created) | date: 'MM/dd/yy'}}</td>

        <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">{{transaction.amount | currency : 'USD' : 'symbol'}}</td>
        <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.increase, transaction.type)">-</td>

        <td style="text-align: center" class="transactions__amount" *ngIf="isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">{{transaction.amount | currency : 'USD' : 'symbol'}}</td>
        <td style="text-align: center" class="transactions__amount" *ngIf="!isTransaction(WITHDRAW_TRANSACTION_TYPES.decrease, transaction.type)">-</td>

        <td style="text-align: center" class="transactions__amount">{{transaction.balance | currency : 'USD' : 'symbol'}}</td>

        <td class="transactions__type">{{transaction.method_name | uppercase}}</td>
    </tr>
</table>
