import {YETI_SNOWDRIFTS_POSITIONS} from "../yeti.config";
import {YetiCreateGameElementResponseModel} from "./yetiCreateGameResponse.model";

export class YetiSnowdriftModel {
  private _id: string;
  private _index: number;
  private _scale: number;
  private _position: {x: number, y: number};
  private _opened: YetiCreateGameElementResponseModel;
  constructor(id: string, index: number, opened: YetiCreateGameElementResponseModel) {
    this._id = id;
    this._position = YETI_SNOWDRIFTS_POSITIONS[index];
    this._scale = YETI_SNOWDRIFTS_POSITIONS[index].scale;
    this._index = index;
    this._opened = opened;
  }

  getPosition() {
    return this.position;
  }

  getId() {
    return this.id;
  }

  getIndex() {
    return this.index;
  }

  getScale() {
    return this.scale;
  }

  isOpened() {
    return !!this.opened;
  }

  getOpenedElementInfo() {
    return this.opened;
  }

  setOpenedElementInfo(value) {
    this.opened = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get index(): number {
    return this._index;
  }

  set index(value: number) {
    this._index = value;
  }

  get scale(): number {
    return this._scale;
  }

  set scale(value: number) {
    this._scale = value;
  }


  get position(): { x: number; y: number } {
    return this._position;
  }

  set position(value: { x: number; y: number }) {
    this._position = value;
  }


  get opened(): YetiCreateGameElementResponseModel {
    return this._opened;
  }

  set opened(value: YetiCreateGameElementResponseModel) {
    this._opened = value;
  }
}
