import * as tslib_1 from "tslib";
import { SENETPRO_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SenetproService = /** @class */ (function (_super) {
    tslib_1.__extends(SenetproService, _super);
    function SenetproService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = SENETPRO_CONFIG;
        return _this;
    }
    SenetproService.prototype.playNow = function (token) {
        this.loaderService.show();
        return this.http.post(this.config.playNow, { token: token });
    };
    SenetproService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SenetproService_Factory() { return new SenetproService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SenetproService, providedIn: "root" });
    return SenetproService;
}(SettingsLoaderService));
export { SenetproService };
