import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Promo777Service} from "../../../service/promo/promo777.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {UsersPointService} from "../../../service/users/users-point.service";
import {GamesProviderService} from "../../../service/games/gamesProvider.service";
import {PartnerService} from "../../../service/partner.service";
import {PartnerComponent} from "../../partners/partner.component";

@Component({
  selector: 'app-promo777',
  templateUrl: './promo777.component.html',
  styleUrls: ['./promo777.component.scss']
})
export class Promo777Component implements OnInit {
  form: FormGroup;
  pinForm: FormGroup;
  resendForm: FormGroup;

  phoneMask = '(000) 000-0000';
  benefits = {
    register: 7,
    cashapp: 7,
    deposit: 7
  };
  popupIsOpen = false;
  popupType = '';
  numOfDigits = 4;
  promoStep = 1;
  userInfo;

  // popupIsOpen = true;
  // popupType = 'address';

  @ViewChildren('inputs') inputs: QueryList<ElementRef>;
  @ViewChild('cashappTag', {static: false}) cashappTag: ElementRef;
  @ViewChild('cashappTagRetype', {static: false}) cashappTagRetype: ElementRef;

  constructor(private formBuilder: FormBuilder,
              private loaderService: LoaderService,
              private popupComponent: PopupComponent,
              private auth: AuthenticationService,
              private route: Router,
              private usersPointService: UsersPointService,
              private gamesProviderService: GamesProviderService,
              private partnerComponent: PartnerComponent,
              private promo777Service: Promo777Service) { }

  ngOnInit() {
    document.body.classList.add('promo');
    //this.loaderService.show();
    // (document.querySelector('.promo__bg') as HTMLImageElement).onload = () => {
      this.setFontSize();
      window.onresize = () => {
        this.setFontSize();
      // }
      //this.loaderService.hide();
    }
    this.form = this.formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, Validators.required],
      cashappTag: [null, Validators.required],
      deposit: ['1.00', Validators.required],
      skip: [true, Validators.required]
    });
    this.resendForm = this.formBuilder.group({
      cashappTag: [null, Validators.required],
      email: [null, Validators.required],
      cashappTagRetype: [null, Validators.required],
    });

    this.pinForm = this.formBuilder.group({
      digits: this.formBuilder.array([])
    });
    for (let i = 0; i < this.numOfDigits; i++) {
      (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
    }
  }

  ngOnDestroy() {
    document.body.classList.remove('promo');
  }

  setFontSize() {
    try {
      this.loaderService.show();
      const doc = document.documentElement;
      document.querySelector('.promo__bg').removeAttribute('style');
      // doc.style.setProperty('--promo-height', `${document.querySelector('.promo__inner').clientHeight}px`);
      // doc.style.setProperty('--promo-width', `${document.querySelector('.promo__inner').clientWidth}px`);
      const width = window.innerWidth > window.innerHeight ?  window.innerWidth : window.innerHeight;
      const height = window.innerWidth <= window.innerHeight ? window.innerWidth : window.innerHeight;

      doc.style.setProperty('--promo-width', `${width / 1.87 < height ? width : height / 0.5344}px`);

      setTimeout(() => {
        this.loaderService.hide();
      }, 200);
    } catch (e) {

    }
  }

  onPhoneSubmit() {
    const firstName = this.form.controls.firstName.value;
    const lastName = this.form.controls.lastName.value;
    const email = this.form.controls.email.value;
    const phone = this.form.controls.phone.value;

    this.promo777Service.register(firstName, lastName, phone, email).subscribe(data => {
      try {
        if (data.status) {
          if (!this.popupIsOpen) {
            this.showPinPopup();
          }
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
        this.loaderService.hideAndShowTryAgain();
      }
    }, () => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  onPopupClose() {
    this.popupIsOpen = false;
  }

  showPinPopup() {
    this.resetPinInput();
    this.popupIsOpen = true;
    this.popupType = 'pin';
    setTimeout(() => {
      this.inputs.toArray()[0].nativeElement.focus();
    }, 100);
  }

  resendSms() {
    this.onPhoneSubmit();
  }

  check(index, field, event) {
    if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
      event.preventDefault();
    }

    if (field.value && event.key !== 'Backspace') {
      if (index < this.inputs.toArray().length - 1) {
        this.inputs.toArray()[index + 1].nativeElement.focus();
      }
    } else if (event.key === 'Backspace') {
      if (index > 0) {
        field.setValue(null)
        this.inputs.toArray()[index - 1].nativeElement.focus();
      } else {
        console.log('first field');
      }
    }
  }

  resetPinInput() {
    (this.pinForm.get('digits') as FormArray).clear();
    for (let i = 0; i < this.numOfDigits; i++) {
      (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
    }
  }

  onPinSubmit() {
    if (this.pinForm.invalid) {
      return;
    }

    const phone = this.form.controls.phone.value;
    let pin = this.pinForm.controls.digits.value;
    pin = pin.join('');

    if (pin.length !== 4) {
      this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
      return
    }

    if (phone && pin && (pin.length === 4)) {
      this.promo777Service.verification(phone, pin).subscribe(data => {
        this.checkPinSubmit(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    } else {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  checkPinSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = false;
        this.promoStep = 2;
        setTimeout(() => {
          this.cashappTag.nativeElement.focus();
        }, 100);
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onEmailResendSubmit() {

  }
  onTagResendSubmit() {

  }

  onVerifyCashappSubmit() {
    this.popupIsOpen = true;
    this.popupType = 'tagRetype';
    setTimeout(() => {
      this.cashappTagRetype.nativeElement.focus();
    }, 100);
  }

  onTagRetypeSubmit() {
    const cashapp = this.form.controls.cashappTag.value;
    const cashappRetype = this.resendForm.controls.cashappTagRetype.value;

    if (cashapp !== cashappRetype) {
      return;
    }

    this.promo777Service.sendCashapp(cashapp).subscribe(data => {
      this.checkCashappSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkCashappSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = false;
        this.promoStep = 3;
      } else {
        this.popupIsOpen = false;
        this.resendForm.controls.cashappTagRetype.setValue('');
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onTextCashTag() {
    this.promo777Service.textCashTag().subscribe(data => {
      this.checkTextCashTagSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkTextCashTagSubmit(data) {
    try {
      if (data.status) {
        this.popupIsOpen = true;
        this.popupType = 'textCashTag';
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  onLogin() {
    this.popupIsOpen = false;
  }

  onPlayNow() {
    const firstName = this.form.controls.firstName.value;
    const lastName = this.form.controls.lastName.value;
    const phone = this.form.controls.phone.value;
    const email = this.form.controls.email.value;
    const cashappTag = this.form.controls.cashappTag.value;
    const skip = this.form.controls.skip.value;

    this.promo777Service.playNow(firstName, lastName, phone, email, cashappTag, skip).subscribe(data => {
      this.checkPlayNowSubmit(data);
    }, (error) => {
      this.loaderService.hideAndShowTryAgain();
    }, () => {
      this.loaderService.hide();
    });
  }

  checkPlayNowSubmit(data) {
    try {
      if (data.status) {
        this.popupComponent.showPopupErrorReturnEvent({text: data.message}, () => {
          this.userInfo = data;
          this.partnerComponent.detectPartner(this.loginUser.bind(this));
        });
      } else {
        this.popupComponent.showPopupError({text: data.message});
      }
    } catch (e) {
      this.loaderService.hideAndShowTryAgain();
    }
  }

  loginUser() {
    // this.userInfo.gameVendorShortName = "mascot";
    const userAccount = this.userInfo.accounts[0];

    this.auth.setUserLogged(this.userInfo.result);
    this.usersPointService.setAccount(userAccount);
    this.gamesProviderService.loginUser(this.userInfo.userPointId, this.userInfo.pointPlayersEarnIncome, this.userInfo.gameVendorShortName);
    this.route.navigate(['/home/rewards']);
  }

  onSkipChange($event) {
    this.form.controls.skip.setValue($event.target.checked);
  }
}
