import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { Validators } from "@angular/forms";
import { USER_STATUS } from "../../config/user_status";
import { RouterPaths } from "../../config/router.paths.model";
import { PROJECT_CONFIG, PROJECT_MESSAGES } from "../../config/project";
import { NFT_POPUP_TYPE } from "../investments/investments-nft-popup/investments-nft-popup.config";
var SettingsComponent = /** @class */ (function () {
    function SettingsComponent(formBuilder, eRef, loaderService, userStatusService, popupComponent, stylingService, partnerService, settingsService, fundsService, activateRoute, cashappPayService, gamesProviderService, router) {
        this.formBuilder = formBuilder;
        this.eRef = eRef;
        this.loaderService = loaderService;
        this.userStatusService = userStatusService;
        this.popupComponent = popupComponent;
        this.stylingService = stylingService;
        this.partnerService = partnerService;
        this.settingsService = settingsService;
        this.fundsService = fundsService;
        this.activateRoute = activateRoute;
        this.cashappPayService = cashappPayService;
        this.gamesProviderService = gamesProviderService;
        this.router = router;
        this.menu = false;
        this.navigation = [];
        this.navigationNoLimits = [];
        this.USER_STATUS = USER_STATUS;
        this.gameVendorName = this.gamesProviderService.getActiveProviderName();
        this.gameVendorShortName = this.gamesProviderService.getActiveProviderShortName();
        this.showSupportForm = false;
        this.cashappVerified = false;
        this.showCashApp = false;
        this.showCashAppMessage = '';
        // Add funds to settings
        this.showNFTPopup = false;
        this.resetNftForm = false;
        this.iframePopup = {
            show: false,
            url: null
        };
        this.isRiverPay = this.partnerService.isRiverPayTerminal();
        if (!this.isPartnerTypeSingleBalance()) {
            this.cashappTag = this.partnerService.storageGetCashapTag();
        }
        this.activateRoute.queryParams.subscribe(function (queryParams) {
            if (queryParams.back) {
                setTimeout(function () {
                    document.querySelector('.dropdown').querySelector('.dropdown__head').click();
                    setTimeout(function () {
                        document.querySelector('.page__scrolled').scrollIntoView({ behavior: 'smooth' });
                        document.querySelector('.page__scrolled').scroll({
                            top: document.querySelector('.dropdown').querySelector('.dropdown__head').offsetHeight + document.querySelector('.page__body').offsetTop - document.querySelector('.page__scrolled').offsetTop,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 500);
                }, 0);
            }
        });
    }
    SettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.cashAppPayForm = this.formBuilder.group({
            amount: [null, Validators.required]
        });
        this.getUserStatus();
        this.navigation = [
            {
                title: 'cash app card',
                link: RouterPaths.settings.cashapp.main,
                hidden: this.isRiverPay,
                id: 'cashapp',
            },
            {
                title: 'CREDIT/DEBIT CARD',
                link: RouterPaths.settings.card.main,
                hidden: this.isRiverPay,
                id: 'credit_card',
            },
            {
                title: 'PAPER CHECKS',
                link: RouterPaths.settings.addresses.main,
                hidden: this.isPartnerTypeSingleBalance(),
                id: 'mail_check',
            },
            {
                title: 'Contact info',
                link: RouterPaths.settings.contacts,
                id: 'contact_info',
            },
        ];
        this.setNavigationNoLimits();
        this.cashappPayService.destroyCashApp();
        this.cashappPayService.paymentResult.subscribe(function (tokenResult) {
            if (tokenResult) {
                try {
                    if (tokenResult.status === 'OK') {
                        _this.onCashAppPayConfirm(tokenResult.token);
                    }
                    else {
                        _this.popupComponent.showPopupError({ text: 'Payment is canceled' });
                        _this.onPopupNFTClose();
                    }
                    // this.cashappPayService.paymentResult.next(null);
                }
                catch (e) {
                    console.log(e);
                }
            }
        });
    };
    SettingsComponent.prototype.setNavigationNoLimits = function () {
        this.navigationNoLimits = [
            {
                title: 'Text me address',
                action: this.onTextMe.bind(this),
                hidden: !this.cashappVerified,
                id: 'text_me',
            },
            {
                title: 'Help',
                action: this.onHelpBtnClick.bind(this),
                hidden: !this.cashappVerified || this.isPartnerTypeSingleBalance(),
                id: 'help',
            },
            {
                title: 'Support',
                action: this.onShowSupportForm.bind(this),
                id: 'support',
            },
        ];
    };
    SettingsComponent.prototype.onHelpBtnClick = function () {
        this.router.navigate([RouterPaths.settings.help]);
    };
    SettingsComponent.prototype.onTextMe = function () {
        var _this = this;
        this.settingsService.textMe().subscribe(function (data) {
            try {
                if (data.status) {
                    _this.popupComponent.showPopupSuccess({ text: data.message });
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message });
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    SettingsComponent.prototype.onShowSupportForm = function () {
        this.router.navigate([RouterPaths.rewards.support]);
    };
    SettingsComponent.prototype.onSupportClose = function ($event) {
        this.showSupportForm = false;
    };
    SettingsComponent.prototype.getUserStatus = function () {
        var _this = this;
        this.userStatusService.getSettingsData().subscribe(function (data) {
            // const data = {
            //     "status": true,
            //     "userStatus": 0,
            //     "subjectLimitationTxt": "Avaliable 24/7. Instant deposits. Limited to three (3) deposits per 24 hours for each sweepstaks. Max deposit per transaction is $100",
            //     "cashappTagStatus": true,
            //     "cashappTagTo": "$iresjdl",
            //     "cashappShow": true,
            //     "cashapp": {
            //         "appId": "sandbox-sq0idb-vroAoylGDSnNU--Au1YG2w",
            //         "locationId": "LFHR510D04M1J",
            //         "mode": "sandbox"
            //     }
            // }
            _this.setUserStatus(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    SettingsComponent.prototype.setUserStatus = function (data) {
        // data.cashappTagStatus = true;
        // data.cashappTag = '$jdjdjd';
        // data.cashappShow = true;
        // data.cashappShowMessage = 'dkdkdk';
        if (data.status === true) {
            this.userStatus = data.userStatus;
            this.subjectLimitationTxt = data.subjectLimitationTxt;
            this.cashappVerified = data.cashappTagStatus;
            this.userCashappTag = data.cashappTag;
            this.showCashApp = data.cashappShow;
            this.showCashAppMessage = data.cashappShowMessage;
            this.setNavigationNoLimits();
            if (this.isPartnerTypeSingleBalance()) {
                this.cashappTag = data.cashappTagTo;
            }
            if (data.cashapp) {
                this.setCashAppSettings(data);
            }
        }
        else if (data.status === false) {
            this.popupComponent.showPopupError({ text: data.message });
        }
        else if (data.status === undefined) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    SettingsComponent.prototype.askFreezeUser = function () {
        var _this = this;
        this.popupComponent.showPopupAgreement({ text: "You are about to freeze your account. No data will be saved. Do you wish to proceed?" }, function (status) {
            if (status) {
                _this.freezeUser();
            }
        });
    };
    SettingsComponent.prototype.freezeUser = function () {
        var _this = this;
        this.userStatusService.freeze().subscribe(function (data) {
            _this.updateUserChangingStatus(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        });
    };
    SettingsComponent.prototype.askUnFreezeUser = function () {
        var _this = this;
        this.popupComponent.showPopupAgreement({ text: "Do you really want to freeze the user?" }, function (status) {
            if (status) {
                _this.unFreezeUser();
            }
        });
    };
    SettingsComponent.prototype.unFreezeUser = function () {
        var _this = this;
        this.userStatusService.activate().subscribe(function (data) {
            _this.updateUserChangingStatus(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        });
    };
    SettingsComponent.prototype.askDeleteUser = function () {
        var _this = this;
        this.popupComponent.showPopupAgreement({ text: "Do you really want to delete the user?",
            confirmButtonText: 'DELETE ACCOUNT<svg><rect></rect></svg>',
            style: 'swal-agreement--error' }, function (status) {
            if (status) {
                _this.deleteUser();
            }
        });
    };
    SettingsComponent.prototype.deleteUser = function () {
        var _this = this;
        this.userStatusService.delete().subscribe(function (data) {
            _this.updateUserChangingStatus(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        });
    };
    SettingsComponent.prototype.updateUserChangingStatus = function (data) {
        if (data.status === true || data.status === "true") {
            this.popupComponent.showPopupSuccess({ text: data.message });
            this.menu = false;
            this.getUserStatus();
        }
        else if (data.status === false || data.status === "false") {
            this.popupComponent.showPopupError({ text: data.message });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    SettingsComponent.prototype.background = function (id) {
        if (id == 'credit_card') {
            //return 'card_background';
        }
        return '';
    };
    SettingsComponent.prototype.isAvailble = function (id, link) {
        // if(id == 'credit_card' || id == 'cashapp'){
        //     this.popupComponent.showPopupError({
        //         text:'Performing maintenance. Temporarily unavailable. Please check back later.',
        //         confirmButtonText:'OK'
        //     });
        //
        //     return;
        // }
        this.router.navigate([link]);
    };
    SettingsComponent.prototype.getLinkToFunds = function () {
        return RouterPaths.settings.funds;
    };
    SettingsComponent.prototype.getLinkToCashApp = function () {
        return RouterPaths.settings.cashapp.main;
    };
    SettingsComponent.prototype.getActiveColor = function () {
        return this.stylingService.getPageColor();
    };
    SettingsComponent.prototype.setCashappVerified = function ($event) {
        if ($event) {
            if ($event.startsWith('$')) {
                this.userCashappTag = $event;
            }
            else {
                this.userCashappTag = '$' + $event;
            }
        }
        this.cashappVerified = true;
        this.setNavigationNoLimits();
    };
    // Add funds to settings
    SettingsComponent.prototype.showIframePopup = function (url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        };
    };
    SettingsComponent.prototype.hideIframePopup = function (result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        };
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            }
            else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({ text: result.message });
                    this.resetNftForm = true;
                }
                else {
                    this.popupComponent.showPopupError({ text: result.message, confirmButtonText: 'OK' });
                }
            }
        }
    };
    SettingsComponent.prototype.submit = function (type) {
        // let amount = this.getInputValue('amount') ? this.getInputValue('amount') : 0;
        // let usdc_amount = this.getInputValue('usdc_amount') ? this.getInputValue('usdc_amount') : 0;
        // const id = this.activeMethod ? this.activeMethod.id : undefined;
        // let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;
        var _this = this;
        var amount = this.NFTPopupFormData.amount;
        var usdc_amount = this.NFTPopupFormData.usdc_amount;
        var id = this.NFTPopupFormData.id;
        var paymentMethod = this.NFTPopupFormData.paymentMethod;
        if (type === 'sweeps') {
            usdc_amount = 0;
        }
        else if (type === 'crypto') {
            amount = 0;
        }
        if (id && paymentMethod) {
            this.fundsService.addFunds(id, paymentMethod, amount, usdc_amount).subscribe(function (data) {
                _this.checkSubmitResult(data);
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.notAllFieldsAreFilled });
        }
    };
    SettingsComponent.prototype.checkSubmitResult = function (data) {
        var paymentMethod = this.NFTPopupFormData.paymentMethod;
        // data.status = true;
        // data.threeDSecure = true;
        // data.threeDSecureUrl = 'http://192.168.0.102:4200/threedsecure?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        // data.threeDSecureUrl = 'http://192.168.0.102:8888/ebox-client/threedsecure.html?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        if (data.status === true) {
            if (data.threeDSecure) {
                if (data.threeDSecureUrl) {
                    this.showIframePopup(data.threeDSecureUrl);
                }
                else {
                    this.loaderService.hideAndShowTryAgain();
                }
            }
            else {
                if (this.isCard(paymentMethod)) {
                    this.resetNftForm = true;
                    this.popupComponent.showPopupSuccess({ text: data.message });
                }
                else if (this.isPaypal(paymentMethod)) {
                    this.resetNftForm = true;
                    var win = window.open(data.link, '_blank');
                    win.focus();
                }
            }
        }
        else if (data.status === false) {
            this.popupComponent.showPopupError({ text: data.message });
        }
        else {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    SettingsComponent.prototype.isCard = function (method) {
        if (PROJECT_CONFIG.paymentMethodCard == method) {
            return true;
        }
        return false;
    };
    SettingsComponent.prototype.isPaypal = function (method) {
        if (PROJECT_CONFIG.paymentMethodPaypal == method) {
            return true;
        }
        return false;
    };
    SettingsComponent.prototype.onPopupNFTClose = function ($event) {
        document.body.classList.remove('hide-menu');
        this.showNFTPopup = false;
        if ($event) {
            this.submit('sweeps');
        }
    };
    SettingsComponent.prototype.openNFTPopup = function ($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
        this.NFTPopupFormData = $event.form;
    };
    SettingsComponent.prototype.nftFormIsReseted = function () {
        this.resetNftForm = false;
    };
    SettingsComponent.prototype.isPartnerTypeSingleBalance = function () {
        return this.partnerService.isPartnerTypeSingleBalance();
    };
    SettingsComponent.prototype.getPartnerBusinessName = function () {
        return this.partnerService.getBusinessName();
    };
    SettingsComponent.prototype.scrollTo = function (part) {
        var element = part === 'addCard' ? document.getElementById('addCard') : document.getElementById('addFunds');
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    };
    SettingsComponent.prototype.onCashAppPayFormSubmit = function () {
        var _this = this;
        var amount = this.cashAppPayForm.controls.amount.value;
        if (amount) {
            this.fundsService.getCashAppInfo(amount).subscribe(function (data) {
                // console.log(amount);
                // const data = {
                //     "status": true,
                //     "image": "https://api.dev.eboxenterprises.com/images/nft/senetpro/images/amaterasu.jpg",
                //     "amount": "1",
                //     "sweeps": "1",
                //     "spend": 0,
                //     "btc": 0,
                //     "headerLabel": "NFT",
                //     "cartLabel": "You are minting NFT",
                //     "message": "skks"
                // }
                try {
                    if (data.status) {
                        _this.onShowNFTPopup(data);
                    }
                    else {
                        _this.popupComponent.showPopupError({
                            text: data.message,
                            confirmButtonText: 'OK'
                        });
                    }
                }
                catch (e) {
                    _this.loaderService.hideAndShowTryAgain();
                }
            }, function (error) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.unknownError });
        }
    };
    SettingsComponent.prototype.onShowNFTPopup = function (data) {
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.cashApp,
            data: {
                element: data
            }
        };
    };
    SettingsComponent.prototype.setCashAppSettings = function (data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var amount, e_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        if (!data.status) return [3 /*break*/, 3];
                        if (!!this.cashappPayService.isCashAppPayScripExist()) return [3 /*break*/, 2];
                        amount = '1.00';
                        return [4 /*yield*/, this.cashappPayService.createCashAppPayButton(data['cashapp'], amount)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [3 /*break*/, 4];
                    case 3:
                        this.popupComponent.showPopupError({ text: data.message });
                        _a.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_1 = _a.sent();
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    SettingsComponent.prototype.onCashAppPayConfirm = function (token) {
        var _this = this;
        var amount = this.cashAppPayForm.controls.amount.value;
        if (amount) {
            this.cashappPayService.onConfirmPayment(amount, token).subscribe(function (data) {
                // console.log(amount, token);
                // const data = {
                //     status: true,
                //     message: "Test message"
                // }
                _this.setCashAppPayConfirmResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    SettingsComponent.prototype.setCashAppPayConfirmResult = function (data) {
        try {
            if (data.status) {
                this.cashAppPayForm.reset();
                this.onPopupNFTClose();
                this.popupComponent.showPopupSuccess({ text: data.message });
            }
            else {
                this.popupComponent.showPopupError({ text: data.message });
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    return SettingsComponent;
}());
export { SettingsComponent };
