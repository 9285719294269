import {Component, EventEmitter, Input, OnInit, Output,Injectable} from '@angular/core';
import {SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from "sweetalert2";
import {MaskPipe} from "ngx-mask";
import {MaskApplierService} from "ngx-mask";
import {ScreenSaverService} from "../../service/screen-saver.service";

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})

@Injectable({providedIn: 'root'})
export class PopupComponent implements OnInit {

    isApp = +localStorage.getItem('isApp');
    closePopupAfter = this.isApp ? 5 * 60 * 1000 : null;

    constructor(private maskApplierService: MaskApplierService,
    ) {
    }

    ngOnInit() {

    }

    swalStyled = Swal.mixin({
        customClass: {
            container: 'popup',
            popup: 'popup__inner',
            image: 'popup__image',
            title: 'popup__title',
            content: 'popup__content',
            confirmButton: 'popup__btn popup__btn_confirm snake-btn is-notouched',
            cancelButton: 'popup__btn popup__btn_cancel',
            input: 'login-input',
        }
    });

    showPopupSuccess(message?, callBack?) {
        this.loadPopupImages();
        this.swalStyled.fire({
            showClass: {
                popup: 'swal-success'
            },
            text: message.text ? message.text : 'The action was successful!',
            confirmButtonText: 'OK, THANKS',
            timer: message.closePopupAfter ? message.closePopupAfter : this.closePopupAfter,
        }).then((result) => {
            if (result.isConfirmed && callBack) {
                callBack();
            }
        });
    }

    showPopupError(message?, btn?) {
        this.loadPopupImages();
        let text = message.text ? message.text : 'Oops! Something is not right.';
        // let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'BACK';
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'OK';
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;
        let popupTypeBtn = btn ? btn : '';

        this.swalStyled.fire({
            // title: 'Error',
            // imageUrl: '../assets/images/popup/error-logo.png',
            //className: "swal-back",
            showClass: {
                popup:  `swal-error ${popupTypeBtn}`
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
            timer: this.closePopupAfter,
        });
    }

    showPopupErrorReturnEvent(message, handler, btn?) {
        this.loadPopupImages();
        let text = message.text ? message.text : 'Oops! Something is not right.';
        let confirmButtonText = message.confirmButtonText ? message.confirmButtonText : 'OK';
        let closeBtn = message.closeBtn === false ? message.closeBtn : true;
        const closePopupAfter = message.closePopupAfter ? message.closePopupAfter : this.closePopupAfter;
        let popupTypeBtn = btn ? btn : '';

        this.swalStyled.fire({
            showClass: {
                popup:  `swal-error ${popupTypeBtn}`
            },
            text: text,
            showConfirmButton: closeBtn,
            allowOutsideClick: closeBtn,
            confirmButtonText: confirmButtonText,
            timer: closePopupAfter,
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        });
    }

    loadPopupImages() {
        const wrapperId = '#hidden-images';
        const bodyImagesLoadedClass = 'popup-images-loaded';
        const bodyMascotClass = 'is-mascot';

        const isMascotPage = document.body.classList.contains(bodyMascotClass);
        const isImagesNotLoaded = !document.body.classList.contains(bodyImagesLoadedClass);

        if (isImagesNotLoaded && isMascotPage) {

            const popupImages = [
                '/assets/images/popup/popup_border--sm.png',
                '/assets/images/popup/popup_close-btn.png',
                '/assets/images/popup/popup_login-btn.png',
                '/assets/images/popup/popup_ok-btn.png',
                '/assets/images/popup/popup_play-btn.png',
                '/assets/images/popup/popup_сontinue-btn.png'];

            let popupCounter = popupImages.length;

            popupImages.forEach((src, index) => {
                const image = new Image();
                image.src = src;
                image.addEventListener('load', function() {
                    popupCounter -= 1;

                    if (!popupCounter) {
                        document.body.classList.add(bodyImagesLoadedClass);
                    }
                });
            });
        }

    }

    showPopupAgreement(options, handler, timer = null) {
        this.loadPopupImages();
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let style = options.style ? options.style : 'swal-agreement';
        let closeBtn = options.closeBtn === false ? options.closeBtn : true;
        let showConfirmButton = options.showConfirmButton === false ? options.showConfirmButton : true;
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: closeBtn ? cancelButtonText : false,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            showConfirmButton: showConfirmButton,
            timer: timer

        }).then((result) => {
            const dismissReason: string = result.dismiss && result.dismiss.toString();

            if (dismissReason === 'timer') {
                return handler('timerOut');
            }

            if (result.isConfirmed) {
                return handler(true);
            } else {
                return handler(false);
            }
        })
    }

    showPopupConfirmCancel(options, handler, timer = null) {
        this.loadPopupImages();
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let style = options.style ? options.style : 'swal-confirm-cancel';
        let closeBtn = options.closeBtn === false ? options.closeBtn : true;
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'CANCEL<svg><rect></rect></svg>';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            allowOutsideClick: closeBtn,
            showCancelButton: true,
            cancelButtonText: cancelButtonText,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            timer: timer,
            customClass: {
                container: 'popup',
                popup: 'popup__inner',
                image: 'popup__image',
                title: 'popup__title',
                content: 'popup__content',
                confirmButton: 'popup__btn popup__btn_confirm snake-btn is-notouched',
                input: 'login-input',
                cancelButton: 'popup__btn snake-btn popup__btn_cancel is-notouched'
            }
        }).then((result) => {
            const dismissReason: string = result.dismiss && result.dismiss.toString();

            if (dismissReason === 'timer') {
                return handler('timerOut');
            }

            if (result.isConfirmed) {
                return handler(true);
            } else {
                return handler(false);
            }
        })
    }

    showPopupChoice(options, handler) {
        let text = options.text ? options.text : 'Something went wrong. Please try again.';
        let style = options.style ? options.style : 'swal-choice';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'Yes<svg><rect></rect></svg>';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'No<svg><rect></rect></svg>';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: true,

        }).then((result) => {
            if (result.isConfirmed) {
                return handler(true);
            }else{
                return handler(false);
            }
        })
    }

    enterAccountId(options, handler) {
        let text = options.text ? options.text : 'Please enter your account id';
        let style = options.style ? options.style : 'swal-choice';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : 'No<svg><rect></rect></svg>';

        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            allowOutsideClick: false,
            allowEscapeKey: false,
            showCancelButton: false,
            input: 'tel',
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to write something!';
                } else if (value.length !== 17) {
                    return 'Entered account id is not valid';
                }

            },
            onBeforeOpen: () => {
                this.swalStyled.getInput().addEventListener('input', (event) => {
                    if (!Number.isInteger(parseInt((event['data']))) && event['inputType'] === "insertText") {
                        setTimeout(() => {
                            this.swalStyled.getInput().value = this.swalStyled.getInput().value.slice(0, -1);
                        });
                        // return false;
                    } else {
                        this.swalStyled.getInput().maxLength = 17;
                        let maskedValue = this.maskApplierService.applyMask(this.swalStyled.getInput().value, '00-00-00-00-00-00');
                        this.swalStyled.getInput().value = maskedValue;
                    }

                });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(this.swalStyled.getInput().value);
            }else{
                return handler(false);
            }
        })
    }

    showPopupVariants(options, handler) {
        let text = options.text ? options.text : 'Select which account you would like to use:';
        let style = options.style ? options.style : 'swal-agreement';
        let confirmButtonText = options.confirmButtonText ? options.confirmButtonText : 'CONFIRM<svg><rect></rect></svg>';
        let accounts = options.accounts;
        let cancelButtonText = options.cancelButtonText ? options.cancelButtonText : '<svg class="close" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
            '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z"/>\n' +
            '</svg>';

        let $this = this;
        this.swalStyled.fire({
            showClass: {
                popup: style,

            },
            input: 'radio',
            inputOptions: accounts,
            html: text,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showCancelButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose something!'
                }else{
                    //return handler(value);
                    //return value;
                }
            }
        }).then((result) => {
            if (result.isConfirmed) {
                return handler(result.value);
            }else{
                return handler(false);
            }
        })
    }

    closePopup() {
       this.swalStyled.close();
    }

}
