import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MAT_DATE_FORMATS,
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {APP_DATE_FORMATS, SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";
import {PartnerComponent} from "./partners/partner.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";

import {PrizesComponent} from "./prizes/prizes.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {InstallComponent} from "./install/install.component";
import {RewardsComponent} from "./rewards/rewards.component";
import {PaypalComponent} from "./prizes/paypal/paypal.component";
import {MailCheckComponent} from "./prizes/mail-check/mail-check.component";
import {NewEmailComponent} from "./prizes/paypal/new-email/new-email.component";
import {NewAddressComponent} from "./prizes/mail-check/new-address/new-address.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {PaypalSettingsComponent} from "./settings/paypal-settings/paypal-settings.component";
import {AddAccountComponent} from "./settings/paypal-settings/add-account/add-account.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";
import {SnekapekModule} from "./partners/snekapek/snekapek.module";
import {EditAddressComponent} from "./settings/addresses-settings/edit-address/edit-address.component";
import {EditAccountComponent} from "./settings/paypal-settings/edit-account/edit-account.component";
import {USDCComponent} from "./prizes/usdc/usdc.component";
import {InvestmentsHeaderComponent} from "./investments/investments-header/investments-header.component";
import {LoginPhoneFormComponent} from "./login/login-phone-form/login-phone-form.component";
// import {PinComponent} from "./login2/pin/pin.component";

import {MatSelectModule} from '@angular/material/select';
import {InvestmentsPopupFormComponent} from "./investments/investments-popup-form/investments-popup-form.component";
import {InvestmentsPopupConfirmComponent} from "./investments/investments-popup-confirm/investments-popup-confirm.component";
// import {RewardsMascotComponent} from "./rewards/rewards-mascot/rewards-mascot.component";
import {TerminalConnectingComponent} from "./login/terminal-connecting/terminal-connecting.component";
import {TerminalComponent} from "./terminal/terminal.component";
import {ParticlesModule} from "angular-particle";
import {GamesSelectionComponent} from "./login/games-selection/games-selection.component";
import {BuyMenuComponent} from "./login/kiosk/buy-menu/buy-menu.component";
import {KioskPaymentComponent} from "./login/kiosk/kiosk-payment/kiosk-payment.component";
import {KioskBuyCryptoComponent} from "./login/kiosk/kiosk-buy-crypto/kiosk-buy-crypto.component";
import {KioskComponent} from "./login/kiosk/kiosk.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {MascotComponent} from "./gamesProviders/mascot/mascot.component";
import {MascotSlidersComponent} from "./gamesProviders/mascot-sliders/mascot-sliders.component";
// import {SwiperModule} from "swiper/angular";
// import {LoginPhoneFormComponent} from "./login/login-phone-form/login-phone-form.component";



import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {SliderTestComponent} from "./slider-test/slider-test.component";
import {SliderTest2Component} from "./slider-test2/slider-test2.component";
import {SliderTest3Component} from "./slider-test3/slider-test3.component";
import {SliderTest4Component} from "./slider-test4/slider-test4.component";
import {SlickCarouselModule} from "ngx-slick-carousel";

import { NgxGlideModule } from 'ngx-glide';
import {PigComponent} from "./games/scratch-off/pig/pig.component";
import {GamesPaymentComponent} from "./games/games-payment/games-payment.component";
import {SpinnerComponent} from "./games/spinner/spinner.component";
import {ScratchOffRulesComponent} from "./games/scratch-off/scratch-off-rules/scratch-off-rules.component";
import {PrizesCryptoComponent} from "./prizes/prizes-crypto/prizes-crypto.component";
import {InvestmentsNftPopupComponent} from "./investments/investments-nft-popup/investments-nft-popup.component";
import {MassSmsComponent} from "./mass-sms/mass-sms.component";
import {PrizesCashappComponent} from "./prizes/prizes-cashapp/prizes-cashapp.component";
import {YetiComponent} from "./games/yeti/yeti.component";
import {SharedGamesModule} from "../sharedGames/sharedGames.module";
import {CommissionComponent} from "./rewards/commission/commission.component";
import {MascotCardComponent} from "./gamesProviders/mascot-card/mascot-card.component";
import {PrizesVenmoComponent} from "./prizes/prizes-venmo/prizes-venmo.component";
import {PrizesGamesComponent} from "./prizes/prizes-games/prizes-games.component";
import {ReportWeeklyComponent} from "./reports/report-weekly/report-weekly.component";
import {ChartsModule} from "ng2-charts";
import {SlotsComponent} from "./games/slots/slots.component";
import {HelpSettingsComponent} from "./settings/help-settings/help-settings.component";
import { Promo777Component } from './promo/promo777/promo777.component';
import {TransactionProcessingComponent} from "./transactions/transaction-processing/transaction-processing.component";
import {SupportComponent} from "./support/support.component";
import {ReportWeeklySenetComponent} from "./reports/report-weekly-senet/report-weekly-senet.component";
import {PopupRewardsGameFormComponent} from "./rewards/popup-rewards-game-form/popup-rewards-game-form.component";
import {FundsFormComponent} from "./settings/funds-form/funds-form.component";
import {SenetproComponent} from "./promo/senetpro/senetpro.component";
import {PrizesParentComponent} from "./prizes-parent/prizes-parent.component";
import {PrizesSenetproComponent} from "./prizes-senetpro/prizes-senetpro.component";
import {PopupSenetproTransactionsComponent} from "./prizes-senetpro/popup-senetpro-transactions/popup-senetpro-transactions.component";
import {SenetproTransactionsComponent} from "./prizes-senetpro/senetpro-transactions/senetpro-transactions.component";
import {TestCashpayComponent} from "./test-cashpay/test-cashpay.component";

export const DEFAULT_SWIPER_CONFIG = {
    // a11y: true,
    direction: 'horizontal',
    slidesPerView: 'auto',
    loop: true,
     loopedSlides: 100,
    observer: true,
    observeParents: true,
    preventClicks: false,
    initialSlide: 0,
    // simulateTouch: touchCapable(),
    simulateTouch: true,
    // cssMode: {
    //     simulateTouch: touchCapable(),
    // },
    // shortSwipes: false
    resistanceRatio: 0.6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
    // on: {
    //     slideChange: function () {
    //         console.log('t', this);
    //         this.slideTo(0);
    //     }
    // }
};

// function touchCapable() {
//     return (
//       'ontouchstart' in window ||
//       ((window as any).DocumentTouch &&
//         document instanceof (window as any).DocumentTouch) ||
//       navigator.maxTouchPoints > 0 ||
//       (window as any).navigator.msMaxTouchPoints > 0
//     );
// }

@NgModule({
    declarations: [
        MainComponent,
        LoginComponent,
        PartnerComponent,
        BuyMenuComponent,
        KioskComponent,
        KioskPaymentComponent,
        KioskBuyCryptoComponent,
        // LoginPhoneFormComponent,
        TerminalConnectingComponent,
        // PinComponent,

        HomeComponent,

        PaypalPaymentsSuccessComponent,
        PaypalPaymentsUnsuccessComponent,

        RewardsComponent,
        RewardsFormComponent,
        // RewardsMascotComponent,
        PopupRewardsGameFormComponent,
        CommissionComponent,

        PrizesParentComponent,
        PrizesComponent,
        PrizesSenetproComponent,
        SenetproTransactionsComponent,
        PopupSenetproTransactionsComponent,
        PaypalComponent,
        NewEmailComponent,

        MailCheckComponent,
        NewAddressComponent,

        USDCComponent,
        PrizesCryptoComponent,
        PrizesCashappComponent,
        PrizesVenmoComponent,
        PrizesGamesComponent,

        SettingsComponent,
        ContactInfoComponent,

        InvestmentsComponent,
        InvestmentsHeaderComponent,
        InvestmentsPopupFormComponent,
        InvestmentsPopupConfirmComponent,
        InvestmentsNftPopupComponent,

        CardComponent,
        NewCardComponent,
        CardMenuComponent,
        EditCardComponent,
        VerifyCardComponent,

        PaypalSettingsComponent,
        AddAccountComponent,
        EditAccountComponent,

        AddressesSettingsComponent,
        AddAddressComponent,
        EditAddressComponent,

        FundsComponent,
        FundsFormComponent,

        HelpSettingsComponent,

        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent,

        TerminalComponent,
        GamesSelectionComponent,

        LoginPhoneFormComponent,
        TermsAndConditionsComponent,
        MascotComponent,
        MascotSlidersComponent,
        MascotCardComponent,

        SliderTestComponent,
      SliderTest2Component,
      SliderTest3Component,
        SliderTest4Component,

      PigComponent,
      ScratchOffRulesComponent,
      GamesPaymentComponent,
      SpinnerComponent,
      YetiComponent,
      SlotsComponent,

      MassSmsComponent,
      ReportWeeklyComponent,
      ReportWeeklySenetComponent,
      Promo777Component,
      SenetproComponent,
      TransactionProcessingComponent,
      SupportComponent,
      TestCashpayComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        ChartsModule,
        NgxCurrencyModule,
        // CarouselModule,
        // SwiperModule,
        // SlickCarouselModule
        DemoMaterialModule,
        SnekapekModule,
        MatSelectModule,
        ParticlesModule,
        SwiperModule,
        SwiperModule,
        SlickCarouselModule,
        NgxGlideModule,
        SharedGamesModule
    ],
    providers: [
        FocusMonitor,
        Platform,
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    exports: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent
    ],
    entryComponents: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
