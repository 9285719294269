<div id="goal-thermometer" style="height: 224px; opacity: 1;">
    <div id="therm-numbers">
        <div class="therm-number pos0">100%</div>
        <div class="therm-number pos1">75%</div>
        <div class="therm-number pos2">50%</div>
        <div class="therm-number pos3">25%</div>
    </div>
    <div id="therm-graphics" style="height: 174px; left: 50px;">
        <img id="therm-top" src="../../../assets/images/thermometer-loader/glassTop.png">
        <img id="therm-body-bg" src="../../../assets/images/thermometer-loader/glassBody.png" style="height: 160px;">
        <img id="therm-body-mercury" src="../../../assets/images/thermometer-loader/redVertical.png">
        <div id="therm-body-fore" style="height: 160px; background-image: url(&quot;images/tickShine.png&quot;);"></div>
        <img id="therm-bottom" src="../../../assets/images/thermometer-loader/glassBottom.png" style="top: 173px;">
        <div id="therm-tooltip">
            <img class="tip-left" src="../../../assets/images/thermometer-loader/tooltipPoint.png">
            <div class="tip-middle" style="background-image: url(./assets/images/thermometer-loader/tooltipMiddle.png);">
                <p>0%</p>
            </div>
        <img class="tip-right" src="../../../assets/images/thermometer-loader/tooltipButt.png">
        </div>
    </div>
</div>
