<form [formGroup]="sellForm" [class.is-settings]="isPartnerTypeSingleBalance()" class="form-simple form-simple--shrink form-simple--primary">
    <div class="saved">

        <div *ngIf="!isPartnerTypeSingleBalance()" class="saved__title">SAVED PAYMENT METHODS</div>

        <div class="saved__list" #scrolledEl>
            <div *ngFor="let method of methods" class="form-simple__item">
                <input *ngIf="isCard(getPaymentMethod(method))" formControlName="method"
                       [id]="'card-'+getPaymentMethod(method)+getMethodId(method)"
                       [type]="'radio'"
                       class="form-simple__radio"
                       [value]="method"
                       [attr.data-id]="getMethodId(method)"
                       name="email"
                       [checked]="activeMethod === method"
                       (change)="changeMethod(method)"
                       autocomplete="off">

                <label *ngIf="isCard(getPaymentMethod(method))" [for]="'card-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>

                <input *ngIf="isPaypal(getPaymentMethod(method))" formControlName="method"
                       [id]="'email-'+getPaymentMethod(method)+getMethodId(method)"
                       [type]="'radio'"
                       class="form-simple__radio"
                       [value]="method"
                       [attr.data-id]="getMethodId(method)"
                       name="email"
                       [checked]="activeMethod === method"
                       (change)="changeMethod(method)"
                       autocomplete="off">
                <label *ngIf="isPaypal(getPaymentMethod(method))" [for]="'email-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>
            </div>

        </div>
    </div>

    <div class="small-text" *ngIf="goldenDragonView">Before recharging, please logout from Golden Dragon Member Portal.</div>



    <div class="form-simple__item form-simple__item--amount">
        <input mask="separator.0"
               thousandSeparator=","
               formControlName="amount"
               placeholder="Amount"
               [type]="'tel'"
               class="form-simple__input"
               autocomplete="off">
        <span>.00</span>
    </div>

    <div class="form-simple__btns form-simple__btns--middle">
        <button id="funds_add_sweeps" class="js-submit-btn button snake-btn is-notouched"
                (click)="onSubmit('sweeps')"
                [disabled]="canSubmitSweeps()">
            Deposit
            <svg><rect></rect></svg>
        </button>
    </div>
    <div class="form-simple__btns form-simple__btns--middle" *ngIf="page === 'settings'">
        <button class="js-submit-btn button snake-btn is-notouched"
                id="addCardBtn"
                (click)="onAddNewCard()">
            Add New Card
            <svg><rect></rect></svg>
        </button>
    </div>

<!--                <div class="form-simple__item form-simple__item&#45;&#45;separator">-->
<!--&lt;!&ndash;                    <input mask="separator.0"&ndash;&gt;-->
<!--&lt;!&ndash;                           thousandSeparator=","&ndash;&gt;-->
<!--&lt;!&ndash;                           formControlName="usdc_amount"&ndash;&gt;-->
<!--&lt;!&ndash;                           placeholder="BTC Amount"&ndash;&gt;-->
<!--&lt;!&ndash;                           [type]="'tel'"&ndash;&gt;-->
<!--&lt;!&ndash;                           class="form-simple__input"&ndash;&gt;-->
<!--&lt;!&ndash;                           autocomplete="off">&ndash;&gt;-->
<!--&lt;!&ndash;                    <span>.00</span>&ndash;&gt;-->

<!--                    <mat-form-field>-->
<!--                        <mat-label>GET</mat-label>-->
<!--                        <mat-select #crypto formControlName="crypto" [(value)]="selectedCrypto" [disabled]="!cryptoList">-->
<!--                            <mat-select-trigger>-->
<!--                                <img *ngIf="crypto?.value" class="investments-form__icon js-currency-icon"-->
<!--                                     src="../../../../assets/images/currencies/{{crypto?.value?.icon}}.png"-->
<!--                                     alt="{{crypto?.value?.name}}"-->
<!--                                     (error)="loadDefaultCurrencyIcon($event)"-->
<!--                                >-->
<!--                                {{crypto?.value?.name}}-->
<!--                            </mat-select-trigger>-->
<!--                            <mat-option *ngFor="let option of cryptoList" [value]="option">-->
<!--                                <img *ngIf="option" class="investments-form__icon"-->
<!--                                     src="../../../../assets/images/currencies/{{option?.icon}}.png"-->
<!--                                     alt="{{option.name}}"-->
<!--                                     (error)="loadDefaultCurrencyIcon($event)">-->

<!--                                {{option.name}}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->

<!--                <div class="form-simple__item transaction-limit">Crypto up to {{cryptoLimit | currency : 'USD' : 'symbol'}} per day.</div>-->

<!--                <div class="form-simple__btns">-->
<!--                    <button id="funds_add"-->
<!--                            class="js-submit-btn button snake-btn is-notouched"-->
<!--                            (click)="onSubmit('crypto')"-->
<!--                            [disabled]="canSubmitCrypto()">-->
<!--                        Add Crypto-->
<!--                        <svg><rect></rect></svg>-->
<!--                    </button>-->
<!--                </div>-->
</form>


<!--<div *ngIf="frame" class="frame">-->
<!--    <iframe [src]="frameUrl" #iframe></iframe>-->
<!--</div>-->

<!--<button *ngIf="frame" (click)="closeFrame()" class="close">-->
<!--    <span class="visually-hidden">Close</span>-->
<!--    <svg class="close__icon" width="34" height="34">-->
<!--        <use xlink:href="../../../../assets/images/sprite.svg#close"></use>-->
<!--    </svg>-->
<!--</button>-->

<!--<app-investments-popup-form [showPopupForm]="showBuyCryptoPopup"-->
<!--                            [data]="popupFormData"-->
<!--                            [formType]="popupFormType"-->
<!--                            [fromPage]="'founds'"-->
<!--                            (close)="onBuyCryptoPopupClose()"></app-investments-popup-form>-->

<!--<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"-->
<!--                            [popupData]="NFTPopupData.data"-->
<!--                            [popupType]="NFTPopupData.type"-->
<!--                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>-->

<!--<app-iframe-popup *ngIf="iframePopup.show"-->
<!--                  [url]="iframePopup.url"-->
<!--                  (onClose)="hideIframePopup($event)"></app-iframe-popup>-->
