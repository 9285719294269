export const PRIZES_METHODS = {
  mailCheck: 'mailCheck',
  cashapp: 'cashapp',
  crypto: 'crypto',
  venmo: 'venmo',
  games: 'games',
  paypal: 'paypal',
  zelle: 'zelle',
  card: 'debitCard',
  giftCardAmazon: 'reloadlyGiftCardAmazon',
  giftCardVisa: 'reloadlyGiftCardVisa',
    mobileTopup: 'mobileTopup'
};

export const DEBIT_CARD_STATUS = {
  default: 1,
  pending: 2,
  error: 3,
  selected: 4,
  errorCard: 5,
};
