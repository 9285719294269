import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'app-thermometer-loader',
    templateUrl: './thermometer-loader.component.html',
    styleUrls: ['./thermometer-loader.component.scss']
})
export class ThermometerLoaderComponent implements OnInit {
    private _progressValue: number;

    @Input() set progressValue(value: number) {
        this._progressValue = value;

        if (value !== null) {
            this.updateState(value);
        }

    }

    get progressValue() : number  {
        return this._progressValue;
    }

    // @Input() progressValue;
    // @Output() openSettings: EventEmitter<any> = new EventEmitter();
    requestAnimationId;
    isAnimation = false;
    constructor() {}

    ngOnInit() {
        // document.addEventListener('click', () => {
        //     console.log('click');
        //     if (!this.isAnimation) {
        //         this.isAnimation = true;
        //         let delta = .5;
        //
        //         const progressAnimation = () => {
        //             delta *= 1.01;
        //             const top = Number.parseInt(document.getElementById('therm-body-mercury').style.top);
        //             const height = Number.parseInt(document.getElementById('therm-body-mercury').style.height);
        //
        //             document.getElementById('therm-body-mercury').style.height = String(Math.round( height + delta)) + 'px';
        //             document.getElementById('therm-body-mercury').style.top = String(Math.round( top - delta)) + 'px';
        //             document.getElementById('therm-tooltip').style.top = String(Math.round( top - 4 - delta)) + 'px';
        //             document.getElementById('therm-tooltip').querySelector('p').innerHTML = String(Math.round((176 - top + 6) / 176 * 100)) + '%';
        //
        //             if (top > 6) {
        //                 this.requestAnimationId = requestAnimationFrame(progressAnimation);
        //             } else {
        //                 cancelAnimationFrame(this.requestAnimationId);
        //                 //this.isAnimation = false;
        //             }
        //         }
        //         this.requestAnimationId = requestAnimationFrame(progressAnimation);
        //     }
        //
        // });
        this.updateState(0);
    }

    updateState(value) {
        const maxHeight = 172;
        const styleValue = String(Math.round( maxHeight * value / 100)) + 'px';

        document.getElementById('therm-body-mercury').style.height = styleValue;
        document.getElementById('therm-tooltip').style.bottom = styleValue;
        document.getElementById('therm-tooltip').querySelector('p').innerHTML = String(Math.round(value)) + '%';
    }
}
