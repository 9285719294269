<div class="kiosk-buy-crypto" [class.is-hidden]="!showPopup" [hidden]="cryptoData && cryptoData.items && cryptoData.items.length <= 1">
    <div class="kiosk-buy-crypto__inner">

        <div class="kiosk-payment-header">
            <div class="kiosk-payment-header__title">Buy Crypto Currency</div>

            <img src="../../../../../assets/images/kiosk/buy-crypto.png" alt="" class="kiosk-payment-header__head-img">

            <div class="kiosk-payment-header__wrapper">
                <button class="kiosk-payment-header__back" *ngIf="showBackBtn" (click)="goBack()">Back</button>
                <div class="kiosk-payment-header__description">
                    Buying your favorite cryptocurrencies has never been more exciting. Invest and watch your money grow!
                </div>
            </div>
        </div>

        <div class="kiosk-buy-crypto__centered">
            <div class="crypto-list" #scrolledEl>
            <div *ngFor="let item of cryptoData.items; let i = index" class="crypto-list__item">
                <img *ngIf="item" class="crypto-list__icon"
                     src="../../../../assets/images/currencies/{{item.iconTo}}.png"
                     (error)="loadDefaultCurrencyIcon($event)">

                <div class="crypto-list__wrapper">
                    <div class="crypto-list__rate">
                        <span>1 {{item.nameTo}} / </span>
                        {{item.amount | number: '1.2-12'}} {{item.nameFrom}}
                    </div>

                    <button id="cryptoOut_{{i}}"
                            (click)="buyCrypto(item)"
                            [ngStyle]="{color: getCryptoColor(item.iconTo),
                                        borderColor: getCryptoColor(item.iconTo)}"
                            class="crypto-list__btn">
                        Buy
                    </button>
                </div>
            </div>

            <div *ngIf="cryptoData.loading">
                <td colspan="3" style="text-align: center">
                    <div class="pre-loader">
                        <div class="box1"></div>
                        <div class="box2"></div>
                        <div class="box3"></div>
                        <div class="box4"></div>
                        <div class="box5"></div>
                    </div>
                </td>
            </div>
            <div class="scroll-btns scroll-btns--small">
                <svg [class.is-active]="canScrollTop()"
                     (click)="scrollTable(true)"
                     class="scroll-btns__arrow scroll-btns__arrow--top">
                    <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
                </svg>
                <svg [class.is-active]="canScrollBottom()"
                     (click)="scrollTable()"
                     class="scroll-btns__arrow scroll-btns__arrow--bottom">
                    <use xlink:href="../../../../assets/images/sprite.svg#arrow-up"></use>
                </svg>
            </div>
        </div>
        </div>
    </div>
</div>


