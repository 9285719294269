import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {GamesProviderService} from "../../../service/games/gamesProvider.service";
import {UsersPointService} from "../../../service/users/users-point.service";
import {KioskScrollService} from "../../../service/kiosk-scroll.service";
import {KioskService} from "../../../service/kiosk.service";
import {PROVIDERS_GAME_STATUS} from "../../../config/project";
import {TerminalService} from "../../../service/terminal/terminal.service";


@Component({
    selector: 'app-games-selection',
    templateUrl: './games-selection.component.html',
    styleUrls: ['./games-selection.component.scss'],
})
export class GamesSelectionComponent implements OnInit {

    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
        this._showPopup = value;
        if (value && this.isApp) {
            this.getGamesList(this.pointId);
        }

    }

    get showPopup() : boolean  {
        return this._showPopup;
    }

    private _pointId: number;

    @Input() set pointId(value: number) {
        this._pointId = value;

        if (value !== null && !this.isApp) {
            this.getGamesList(this._pointId);
        }

    }

    get pointId() : number  {
        return this._pointId;
    }


    @Output() close = new EventEmitter();
    @Output() back = new EventEmitter();
    @Output() gameSelected = new EventEmitter();
    @Output() isBackBtn = new EventEmitter();

    games = [];
    kioskTableGames = [];

    isApp = this.kioskService.isKiosk();
    isAppGamesAndCrypto = false;

    showPaymentPopup = false;
    buyingGameInfo;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;
    @HostBinding('class.is-kiosk-app') someField: boolean = !!this.isApp;

    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private gamesProviderService: GamesProviderService,
                private userPointService: UsersPointService,
                private kioskScrollService: KioskScrollService,
                private auth:AuthenticationService,
                private terminalService:TerminalService,
                private kioskService: KioskService) {

    }

    ngOnInit() {
        this.isAppGamesAndCrypto = this.terminalService.isCrypto();
    }

    onGameSelected(game?) {
        if (this.isApp) {
            this.gameSelected.emit(game);
        } else {
            this.gameSelected.emit();
        }
    }

    chooseGame(game) {
        // game.status = 2;
        // game['error_message'] = 'Errro';

        const gameStatus = game.status;
        const errorMessage = game.error_message;

        if (gameStatus === PROVIDERS_GAME_STATUS.notAvailable && this.isApp) {
            this.popupComponent.showPopupError({text: errorMessage});
            return;
        }

        // const gameId = game.id;
        // this.gamesProviderService.chooseGame(this.userPointService.getId(), gameId).subscribe(data => {
        //     this.checkSubmitGame(data, gameId);
        // }, () => {
        //     this.loaderService.hideAndShowTryAgain();
        // }, () => {
        //     // this.loaderService.hide();
        // });
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    }

    checkSubmitGame(data, gameId) {
        this.loaderService.hide();

        try {
            if(data.status === true){
                if (this.isApp) {
                    this.buyingGameInfo = this.games.find(el => el.id === gameId);
                    this.onGameSelected(this.buyingGameInfo);
                } else {
                    this.onGameSelected();
                    this.loaderService.hide();
                }
                // this.userPointService.setUserPointId(data.userPointId);
                // this.userPointService.setGameVendorShortName(data.gameVendorShortName);
                //
                // this.auth.userLoggedStatusChanged.next(true);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    closePopup() {
        this.close.emit();
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable(this.scrolledEl.nativeElement, top);
    }

    getGamesList(pointId) {
        if (pointId !== null) {
            this.gamesProviderService.getGamesList(pointId).subscribe(data => {
                this.getGamesListResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                //this.loaderService.hide();
            });
        }
    }

    getGamesListResult(data) {
        try {
            if(data.status === true) {
                this.games = data.result;
                // this.games.length = 1;
                this.gamesProviderService.setProviders(this.games);

                if (this.games.length === 1) {
                    this.chooseGame(this.games[0]);
                } else {
                    this.convertGamesList();
                    setTimeout(() => {
                        this.updateMaxScrollHeight();
                    });
                    this.loaderService.hide();
                    this.isBackBtn.emit(true);
                }

            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    convertGamesList() {
        this.kioskTableGames = this.games.map(this.convertGamesListItem);
    }

    convertGamesListItem(el) {
        return {
            img: el.image_link,
            title: el.name,
            description: el.description,
            id: el.id,
            status: el.status,
            error_message: el.error_message
        }
    }

    goBack() {
        this.back.emit();
    }

    updateMaxScrollHeight() {
        const firstChild = (<HTMLElement>document.querySelector('.games-selection .kiosk-table__item:first-child'));
        const secondChild = (<HTMLElement>document.querySelector('.games-selection .kiosk-table__item:nth-child(2)'));
        if (this.isApp && firstChild && secondChild) {
            const first = firstChild.scrollHeight;
            const second = secondChild.scrollHeight;
            const spicing = 70;

            const maxHeightInVH = this.vhToPixels(57);
            const maxHeightFirstTwoItems = first + second + spicing;

            (<HTMLElement>document.querySelector('.games-selection')).style.maxHeight = `${maxHeightFirstTwoItems < maxHeightInVH ? maxHeightFirstTwoItems : maxHeightInVH}px`;
        }
    }

    vhToPixels (vh) {
        return Math.round(window.innerHeight / (100 / vh));
    }
}

