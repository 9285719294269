import {Component, OnInit, ElementRef} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {UserStatusService} from "../../service/userStatus.service";
import {USER_STATUS} from "../../config/user_status";
import {RouterPaths} from "../../config/router.paths.model";
import {StylingService} from "../../service/styling.service";
import {PartnerService} from "../../service/partner.service";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {SettingsService} from "../../service/settings/settings.service";
import {PROJECT_CONFIG, PROJECT_MESSAGES} from "../../config/project";
import {FundsService} from "../../service/settings/funds.service";
import {NFT_POPUP_TYPE} from "../investments/investments-nft-popup/investments-nft-popup.config";
import {CashappPayService} from "../../service/cashapp-pay.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': '','[class.is-menu]': 'menu',} : {'[@slideToLeft]': '', '[class.is-menu]': 'menu',}
})
export class SettingsComponent implements OnInit {
    cashAppPayForm: FormGroup;
    userStatus;
    menu = false;
    isRiverPay: boolean;

    navigation = [];
    navigationNoLimits = [];

    USER_STATUS = USER_STATUS;
    gameVendorName = this.gamesProviderService.getActiveProviderName();
    gameVendorShortName = this.gamesProviderService.getActiveProviderShortName();
    showSupportForm = false;
    cashappTag: string;

    cashappVerified = false;
    userCashappTag: string;
    subjectLimitationTxt: string;

    showCashApp = false;
    showCashAppMessage = '';

    // Add funds to settings
    showNFTPopup = false;
    NFTPopupData;
    NFTPopupFormData;
    resetNftForm = false;
    iframePopup = {
        show: false,
        url: null
    }
    constructor(private formBuilder: FormBuilder,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private userStatusService: UserStatusService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private partnerService: PartnerService,
                private settingsService: SettingsService,
                private fundsService: FundsService,
                private activateRoute: ActivatedRoute,
                private cashappPayService: CashappPayService,
                private gamesProviderService: GamesProviderService,
                private router: Router) {

        this.isRiverPay = this.partnerService.isRiverPayTerminal();
        if (!this.isPartnerTypeSingleBalance()) {
            this.cashappTag = this.partnerService.storageGetCashapTag();
        }

      this.activateRoute.queryParams.subscribe(queryParams => {
        if (queryParams.back) {
          setTimeout(() => {
            (document.querySelector('.dropdown').querySelector('.dropdown__head') as HTMLElement).click();
            setTimeout(() => {
              (<HTMLElement>document.querySelector('.page__scrolled')).scrollIntoView({behavior: 'smooth'});
              (<HTMLElement>document.querySelector('.page__scrolled')).scroll({
                top: (document.querySelector('.dropdown').querySelector('.dropdown__head') as HTMLElement).offsetHeight + (document.querySelector('.page__body') as HTMLElement).offsetTop - (document.querySelector('.page__scrolled') as HTMLElement).offsetTop,
                left: 0,
                behavior: 'smooth'
              });
            }, 500);
          }, 0)
        }
      });
    }


    ngOnInit() {
        this.cashAppPayForm = this.formBuilder.group({
            amount: [null, Validators.required]
        });

        this.getUserStatus();

        this.navigation = [
            {
                title: 'cash app card',
                link: RouterPaths.settings.cashapp.main,
                hidden: this.isRiverPay,
                id: 'cashapp',
            },
            {
                title: 'CREDIT/DEBIT CARD',
                link: RouterPaths.settings.card.main,
                hidden: this.isRiverPay,
                id: 'credit_card',
            },
            {
                title: 'PAPER CHECKS',
                link: RouterPaths.settings.addresses.main,
                hidden: this.isPartnerTypeSingleBalance(),
                id: 'mail_check',
            },
            {
                title: 'Contact info',
                link: RouterPaths.settings.contacts,
                id: 'contact_info',
            },
        ];

        this.setNavigationNoLimits();

        this.cashappPayService.destroyCashApp();
        this.cashappPayService.paymentResult.subscribe(tokenResult => {
            if (tokenResult) {
                try {
                    if (tokenResult.status === 'OK') {
                        this.onCashAppPayConfirm(tokenResult.token);
                    } else {
                        this.popupComponent.showPopupError({text: 'Payment is canceled'});
                        this.onPopupNFTClose();
                    }
                    // this.cashappPayService.paymentResult.next(null);
                } catch (e) {
                    console.log(e);
                }

            }
        })
    }

    setNavigationNoLimits() {
        this.navigationNoLimits = [
            {
                title: 'Text me address',
                action: this.onTextMe.bind(this),
                hidden: !this.cashappVerified,
                id: 'text_me',
            },
            {
                title: 'Help',
                action: this.onHelpBtnClick.bind(this),
                hidden: !this.cashappVerified || this.isPartnerTypeSingleBalance(),
                id: 'help',
            },
            {
                title: 'Support',
                action: this.onShowSupportForm.bind(this),
                id: 'support',
            },
        ]
    }

    onHelpBtnClick() {
        this.router.navigate([RouterPaths.settings.help]);
    }

    onTextMe() {
        this.settingsService.textMe().subscribe(data => {
            try {
                if (data.status) {
                    this.popupComponent.showPopupSuccess({text: data.message});
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShowSupportForm() {
        this.router.navigate([RouterPaths.rewards.support]);
    }

    onSupportClose($event) {
        this.showSupportForm = false;
    }

    getUserStatus() {
        this.userStatusService.getSettingsData().subscribe(data => {
        // const data = {
        //     "status": true,
        //     "userStatus": 0,
        //     "subjectLimitationTxt": "Avaliable 24/7. Instant deposits. Limited to three (3) deposits per 24 hours for each sweepstaks. Max deposit per transaction is $100",
        //     "cashappTagStatus": true,
        //     "cashappTagTo": "$iresjdl",
        //     "cashappShow": true,
        //     "cashapp": {
        //         "appId": "sandbox-sq0idb-vroAoylGDSnNU--Au1YG2w",
        //         "locationId": "LFHR510D04M1J",
        //         "mode": "sandbox"
        //     }
        // }
            this.setUserStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setUserStatus(data) {
        // data.cashappTagStatus = true;
        // data.cashappTag = '$jdjdjd';
        // data.cashappShow = true;
        // data.cashappShowMessage = 'dkdkdk';
        if (data.status === true) {
            this.userStatus = data.userStatus;
            this.subjectLimitationTxt = data.subjectLimitationTxt;
            this.cashappVerified = data.cashappTagStatus;
            this.userCashappTag = data.cashappTag;

            this.showCashApp = data.cashappShow;
            this.showCashAppMessage = data.cashappShowMessage;

            this.setNavigationNoLimits();
            if (this.isPartnerTypeSingleBalance()) {
                this.cashappTag = data.cashappTagTo;
            }

            if (data.cashapp) {
                this.setCashAppSettings(data);
            }

        } else if (data.status === false){
            this.popupComponent.showPopupError({text: data.message});
        } else if (data.status === undefined){
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `You are about to freeze your account. No data will be saved. Do you wish to proceed?`}, (status) => {
            if (status) {
                this.freezeUser();
            }
        });
    }

    freezeUser() {
        this.userStatusService.freeze().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    askUnFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to freeze the user?`}, (status) => {
            if (status) {
                this.unFreezeUser();
            }
        });
    }

    unFreezeUser() {
        this.userStatusService.activate().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'DELETE ACCOUNT<svg><rect></rect></svg>',
            style: 'swal-agreement--error'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.userStatusService.delete().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    updateUserChangingStatus(data) {
        if(data.status === true || data.status === "true"){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.menu = false;
            this.getUserStatus();
        } else if (data.status === false || data.status === "false") {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    background(id){
        if(id == 'credit_card'){
            //return 'card_background';
        }

        return '';
    }

    isAvailble(id,link){
        // if(id == 'credit_card' || id == 'cashapp'){
        //     this.popupComponent.showPopupError({
        //         text:'Performing maintenance. Temporarily unavailable. Please check back later.',
        //         confirmButtonText:'OK'
        //     });
        //
        //     return;
        // }

        this.router.navigate([link]);
    }

    getLinkToFunds() {
        return RouterPaths.settings.funds;
    }

    getLinkToCashApp() {
        return RouterPaths.settings.cashapp.main;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    setCashappVerified($event?) {
        if ($event) {
            if (($event as string).startsWith('$')) {
                this.userCashappTag = $event;
            } else {
                this.userCashappTag = '$' + ($event as string);
            }
        }
        this.cashappVerified = true;
        this.setNavigationNoLimits();
    }


    // Add funds to settings
    showIframePopup(url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        }
    }

    hideIframePopup(result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        }
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            } else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({text: result.message});
                    this.resetNftForm = true;
                } else {
                    this.popupComponent.showPopupError({text: result.message, confirmButtonText: 'OK'})
                }
            }
        }
    }

    submit(type) {
        // let amount = this.getInputValue('amount') ? this.getInputValue('amount') : 0;
        // let usdc_amount = this.getInputValue('usdc_amount') ? this.getInputValue('usdc_amount') : 0;
        // const id = this.activeMethod ? this.activeMethod.id : undefined;
        // let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;

        let amount = this.NFTPopupFormData.amount;
        let usdc_amount = this.NFTPopupFormData.usdc_amount;
        const id = this.NFTPopupFormData.id;
        let paymentMethod = this.NFTPopupFormData.paymentMethod;

        if (type === 'sweeps') {
            usdc_amount = 0;
        } else if (type === 'crypto') {
            amount = 0;
        }

        if (id && paymentMethod) {
            this.fundsService.addFunds(id, paymentMethod, amount, usdc_amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }

    }

    checkSubmitResult(data) {
        let paymentMethod = this.NFTPopupFormData.paymentMethod;
        // data.status = true;
        // data.threeDSecure = true;
        // data.threeDSecureUrl = 'http://192.168.0.102:4200/threedsecure?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        // data.threeDSecureUrl = 'http://192.168.0.102:8888/ebox-client/threedsecure.html?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        if(data.status === true){
            if (data.threeDSecure) {
                if (data.threeDSecureUrl) {
                    this.showIframePopup(data.threeDSecureUrl);
                } else {
                    this.loaderService.hideAndShowTryAgain();
                }
            } else {
                if (this.isCard(paymentMethod)){
                    this.resetNftForm = true;
                    this.popupComponent.showPopupSuccess({text:data.message});
                } else if(this.isPaypal(paymentMethod)){
                    this.resetNftForm = true;

                    var win = window.open(data.link, '_blank');
                    win.focus();
                }
            }
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    isCard(method){
        if(PROJECT_CONFIG.paymentMethodCard == method){
            return true;
        }
        return false;
    }

    isPaypal(method){
        if(PROJECT_CONFIG.paymentMethodPaypal == method){
            return true;
        }
        return false;
    }

    onPopupNFTClose($event?) {
        document.body.classList.remove('hide-menu');
        this.showNFTPopup = false;
        if ($event) {
            this.submit('sweeps');
        }
    }

    openNFTPopup($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
        this.NFTPopupFormData = $event.form;
    }

    nftFormIsReseted() {
        this.resetNftForm = false;
    }

    isPartnerTypeSingleBalance(): boolean {
        return this.partnerService.isPartnerTypeSingleBalance();
    }

    getPartnerBusinessName() {
        return this.partnerService.getBusinessName();
    }

    scrollTo(part) {
        const element = part === 'addCard' ? document.getElementById('addCard') : document.getElementById('addFunds');
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    onCashAppPayFormSubmit() {
        const amount = this.cashAppPayForm.controls.amount.value;
        if (amount) {
            this.fundsService.getCashAppInfo(amount).subscribe(data => {
            // console.log(amount);
            // const data = {
            //     "status": true,
            //     "image": "https://api.dev.eboxenterprises.com/images/nft/senetpro/images/amaterasu.jpg",
            //     "amount": "1",
            //     "sweeps": "1",
            //     "spend": 0,
            //     "btc": 0,
            //     "headerLabel": "NFT",
            //     "cartLabel": "You are minting NFT",
            //     "message": "skks"
            // }
                try {
                    if (data.status) {
                        this.onShowNFTPopup(data);
                    } else {
                        this.popupComponent.showPopupError({
                            text: data.message,
                            confirmButtonText:'OK'
                        });
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });

        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
    }

    onShowNFTPopup(data) {
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.cashApp,
            data: {
                element: data
            }
        };
    }

    async setCashAppSettings(data) {
        try {
            if (data.status) {
                if (!this.cashappPayService.isCashAppPayScripExist()) {
                    const amount = '1.00';
                    await this.cashappPayService.createCashAppPayButton(data['cashapp'], amount);
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            //this.loaderService.hideAndShowTryAgain();
        }
    }

    onCashAppPayConfirm(token) {
        const amount = this.cashAppPayForm.controls.amount.value;
        if (amount) {
            this.cashappPayService.onConfirmPayment(amount, token).subscribe(data => {
                // console.log(amount, token);
                // const data = {
                //     status: true,
                //     message: "Test message"
                // }
                this.setCashAppPayConfirmResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    setCashAppPayConfirmResult(data) {
        try {
            if (data.status) {
                this.cashAppPayForm.reset();
                this.onPopupNFTClose();
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
