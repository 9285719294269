import {Component, OnInit, ElementRef, ViewChild,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PrizesService} from "../../../service/prizes/prizes.service";
import {PROJECT_CONFIG, PROJECT_MESSAGES} from "../../../config/project";
import {StylingService} from "../../../service/styling.service";
import {ScrollToService} from "../../../service/scrollTo.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";
import {KeyboardService} from "../../../service/keyboard.service";
import {CRYPTO_SETTINGS} from "../../../config/crypto_settings";
import {POPUP_TYPES, showPopupFormType} from "../../investments/investments-popup-form/investments-popup-form.config";

@Component({
    selector: 'app-prizes-crypto',
    templateUrl: './prizes-crypto.component.html',
    styleUrls: ['./prizes-crypto.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PrizesCryptoComponent implements OnInit {
    showPopup: showPopupFormType = 'hide';
    availableBalance = 0;
    popupFormType;
    popupFormData;

    cryptoData = {
        items: [],
        loading: false
    };

    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
    CRYPTO_SETTINGS = CRYPTO_SETTINGS;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private prizesService: PrizesService,
                private router: Router,
                private stylingService: StylingService,
                private scrollToService: ScrollToService,
                private kioskAccessorService: KioskAccessorService,
                private keyboardService: KeyboardService,
                private existingUserService: ExistingUserService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getBalance();
            }
        });
    }

    ngOnInit() {
        this.getBalance();
        this.getCryptoList();
    }

    getBalance(){
        this.prizesService.getUSDCoinsBalance().subscribe(data => {
            this.setRequestResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setRequestResult(data) {
        try {
            if (data && data.available_balance) {
                this.setBalance(data.available_balance);
            }

            if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else if (data.status === undefined) {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setBalance(balance: number) {
        const balanceNumber = +balance;
        if (balanceNumber || balanceNumber === 0) {
            this.availableBalance = balanceNumber;
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    openTradePopup(item) {
        this.showPopup = 'show';
        this.popupFormType = POPUP_TYPES.buy;
        this.popupFormData = item;
        this.popupFormData.availableAmount = this.availableBalance;
        // this.popupFormData.availableAmount = 7;

        let $this = this;
        setTimeout(() => {
            (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
            $this.keyboardService.setActiveInput();
        },0);
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = './assets/images/currencies/'+this.DEFAULT_CRYPTO_ICON;
    }

    getCryptoList() {
        this.cryptoData.loading = true;
        this.prizesService.getCryptoList().subscribe(data => {
            this.getServerResponse(data, this.setCryptoList.bind(this));
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.cryptoData.loading = false;
        });
    }

    getServerResponse(data, successHandler, additionalParams?) {
        try {
            // data.result.push(data.result[0]);
            // data.result.push(data.result[0]);
            // data.result.push(data.result[0]);
            // data.result.push(data.result[0]);
            // console.log(data);
            if (data.status === true) {
                successHandler(data, additionalParams);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setCryptoList(data)  {
        this.cryptoData.items = data.result;
    }

    onPopupFormClose(response) {
        this.showPopup = 'hide';

        if (response && response.status) {
            this.getBalance();
        }
    }

    getCryptoColor(icon) {
        if (this.CRYPTO_SETTINGS[icon]) {
            return this.CRYPTO_SETTINGS[icon].color;
        } else {
            return this.CRYPTO_SETTINGS.default.color;
        }
    }
}

