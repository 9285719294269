<div class="investments-popup investments-popup--balance" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
        <div class="balance-popup">
            <div class="balance-popup__content">
                <div class="balance-popup__text" *ngIf="popupInfo.gameVendor === GAME_VENDORS_GOLDENDRAGON">
                    Your mobile ID has been copied! Please paste it on the next screen to continue.
                </div>
                <div class="balance-popup__text" *ngIf="popupInfo.gameVendor === GAME_VENDORS_RIVER">
                    Your account number has been copied! Please paste it on the next screen to continue and downloads the app at
                    <a class="balance-popup__link" href="https://river777.com">https://river777.com</a>
                </div>
                <div class="balance-popup__balance">
                    Available Balance {{popupInfo.availableBalance | currency : 'USD' : 'symbol'}}
                </div>
                <div class="balance-popup__form">
                    <form [formGroup]="balanceForm"
                          (ngSubmit)="onSubmitBalanceForm()"
                          class="form-simple form-simple--shrink form-simple--primary">

                        <div class="form-simple__item form-simple__item--amount" *ngIf="popupInfo.availableBalance">
                            <input mask="separator.0"
                                   thousandSeparator=","
                                   (input)="onInputAmount()"
                                   formControlName="amount"
                                   placeholder="Amount"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                            <span>.00</span>
                            <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                                Available {{popupInfo.availableBalance | currency : 'USD' : 'symbol'}}
                            </div>
                        </div>

                        <div class="form-simple__btns" *ngIf="popupInfo.availableBalance">
                            <button class="button snake-btn is-notouched"
                                    [disabled]="+balanceForm.controls.amount.value <= 0">
                                Add
                                <svg><rect></rect></svg>
                            </button>
                        </div>
                        <div class="form-simple__btns" *ngIf="!popupInfo.availableBalance">
                            <a [routerLink]="ROUTER_PATHS.settings.home" class="button snake-btn is-notouched">
                                Add Funds
                                <svg><rect></rect></svg>
                            </a>
                        </div>
                        <div class="form-simple__btns" *ngIf="!popupInfo.availableBalance">
                            <a [routerLink]="ROUTER_PATHS.settings.home" [queryParams]="{part: 'addCard'}" class="button snake-btn is-notouched">
                                Add New Card
                                <svg><rect></rect></svg>
                            </a>
                        </div>
                    </form>
                </div>
                <div class="balance-popup__text">
                    You are being redirected to a third-party provider not associated with {{popupInfo.partnerName}}. Please confirm to proceed:
                </div>
                <div class="balance-popup__footer">
                    <button class="button snake-btn is-notouched balance-popup__sweep-btn" type="button" (click)="sweepGame()">
                        Sweep Rewards
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
