import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {PaypalService} from "../../../service/settings/paypal.service";
import {ServerResponse} from "../../../models/responses/response.model";
import {RouterPaths} from "../../../config/router.paths.model";
import {StylingService} from "../../../service/styling.service";
import {KioskService} from "../../../service/kiosk.service";
import {KioskScrollService} from "../../../service/kiosk-scroll.service";

@Component({
    selector: 'app-paypal-settings',
    templateUrl: './paypal-settings.component.html',
    styleUrls: ['./paypal-settings.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalSettingsComponent implements OnInit {
    accounts;

    @HostBinding('class') isAppClass = '';
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private stylingService: StylingService,
                private kioskService: KioskService,
                private kioskScrollService: KioskScrollService,
                private paypalService: PaypalService) {

    }


    ngOnInit() {
        this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';
        this.getAccountsList();
    }

    getAccountsList() {
        this.paypalService.getAccounts().subscribe(data => {
            this.checkAccountsList(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    checkAccountsList(response) {
        this.accounts = [];
        if(response.status === true){
            this.accounts = response.result;
        } else if (response.status === undefined){
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askDelete(account) {
        if (account.id && account.email && account.email.trim()) {
            this.popupComponent.showPopupAgreement({
                text: `<b class="js-popup-title">Please confirm deleting Email</b> 
                        </br></br> 
                        <span class="js-popup-subtitle">${account.email}</span>`},
              (status) => {
                if (status) {
                    this.delete(account.id);
                }
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    delete(id) {
        if (id && id != 'undefined') {
            this.paypalService.deleteAccount(id).subscribe(data => {
                this.checkDeleteRequestResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkDeleteRequestResult(response: ServerResponse) {
        if(response.status === true){
            this.popupComponent.showPopupSuccess({text: response.message});
            this.getAccountsList();
        }  else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    edit(email) {
        if (email.id) {
            this.router.navigate(['/home/settings/paypal/edit', email.id]);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getBackLink() {
        return RouterPaths.settings.home;
    }

    getAddAccountLink() {
        return RouterPaths.settings.paypal.add;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    canScrollTop() {
        return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    }

    canScrollBottom() {
        return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    }

    scrollTable(top?) {
        this.kioskScrollService.scrollTable('.cards', top);
    }

}
