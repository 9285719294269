import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ScratchOffService} from "../../../service/games/scratchOff.service";
import {LoaderService} from "../../../service/loader.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {SpinnerService} from "../../../service/games/spinner.service";
import {MascotService} from "../../../service/mascot/mascot.service";
import {SPINNER_SOUND_TYPE} from "./spinner.config";


@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})

export class SpinnerComponent implements OnInit {
    spinning = false;
    spinningFinish = false;
    spinnerInterval;

    tommorow = {
        id: 18,
        initialText: 'no luck',
        resultText: 'tomorrow'
    };

    private _data: any;

    @Input() set data(value: any) {
        this._data = value;
        if (value) {
            this.setSpinnerData(value);
        }
    }

    get data() : any  {
        return this._data;
    }

    @ViewChild('audio', {static: false}) audio: ElementRef;
    @ViewChildren('segment', {read: ElementRef}) slice: QueryList <ElementRef>;
    constructor(private scratchOffService: ScratchOffService,
                private spinnerService: SpinnerService,
                private popupComponent: PopupComponent,
                private mascotService: MascotService,
                private loaderService: LoaderService) {
    }

    transformValue = -8;

    ngOnInit() {
    }

    ngOnDestroy() {
    }

    getSpinResult() {
        this.spinning = true;
        this.transformValue += 12 * 30;
        this.spinnerInterval = setInterval(() => {
            this.transformValue += 12 * 30;
        }, 500);
        this.spinnerService.init().subscribe(data => {
            this.setSpinnerResult(data);
        }, () => {
            this.onSpin();
            this.loaderService.hideAndShowTryAgain('bordered');
        }, () => {
            this.loaderService.hide();
        });
    }

    setSpinnerResult(responce) {
        try {
            if (responce.status === true) {
                this.onSpin(responce.result, responce.message, responce.type);
            } else if (responce.status === false) {
                this.onSpin();
                this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
            } else {
                this.onSpin();
                this.loaderService.hideAndShowTryAgain('bordered');
                // window.location.reload();
            }
        } catch (e) {
            this.onSpin();
            this.loaderService.hideAndShowTryAgain('bordered');
            // window.location.reload();
        }
    }

    onSpin(id?, message?, type?) {
        this.playSound(SPINNER_SOUND_TYPE.wheel.name);

        clearInterval(this.spinnerInterval);
        this.spinningFinish = true;


        const activeSegmentPosition = this.slice.find(el => el.nativeElement.classList.contains('is-winning')).nativeElement.getAttribute('data-segment-position');

        if (id) {
            const newSegment = this.slice.find(el => +el.nativeElement.getAttribute('data-segment-id') === +id);
            const defaultSegment = this.slice.find(el => +el.nativeElement.getAttribute('data-segment-id') === +this.data[0].id);
            const newSegmentPosition = newSegment ? newSegment.nativeElement.getAttribute('data-segment-position') : defaultSegment.nativeElement.getAttribute('data-segment-position');

            const distToEl = activeSegmentPosition - newSegmentPosition;
            this.transformValue += (12 * this.getRndInteger(1, 2) + distToEl) * 30;
        } else {
            this.transformValue += (12 * this.getRndInteger(1, 2) + 6) * 30;
        }


        // setTimeout(() => {
        //     this.transformValue += 150;
        // }, 1750);

        this.slice.forEach(el => {
            el.nativeElement.classList.remove('is-winning');
            // el.nativeElement.querySelector('.segment__middle').innerHTML = '';
        });

        setTimeout(() => {
            console.log("test_test: 26");

            let distMin = 0;
            let distMinEl;

            const pointArrow = document.querySelector('.spinner__arrow .spinner__arrow-inner').getBoundingClientRect();
            const pointArrowTop = pointArrow.top;
            const pointArrowLeft = pointArrow.left;

            this.slice.forEach(el => {
                const start = el.nativeElement.querySelector('.segment__inner').getBoundingClientRect();
                const startX = start.x;
                const startY = start.y;

                const end = el.nativeElement.querySelector('.segment__end').getBoundingClientRect();
                const endX = end.x;
                const endY = end.y;

                const elemDistToArrow = this.dist(startX, startY, pointArrowTop, pointArrowLeft) + this.dist(endX, endY, pointArrowTop, pointArrowLeft);

                if (!distMin || distMin > elemDistToArrow) {
                    distMin = elemDistToArrow;
                    distMinEl = el;
                }

            });

            distMinEl.nativeElement.classList.add('is-winning');

            if (id && id === this.tommorow.id) {
                distMinEl.nativeElement.querySelector('.segment__middle').innerHTML = this.tommorow.resultText;
            }

            this.spinning = false;
            this.spinningFinish = false;

            if (message != '') {
                this.popupComponent.showPopupError({text: message}, 'btn-ok');
            }

            this.stopSound();

            if (type) {
                this.playSound(SPINNER_SOUND_TYPE.win.name);
                this.mascotService.updateBalance();
            }
        }, 5000);
    }

    getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min) ) + min;
    }

    dist (x1, y1, x2, y2) {
        var deltaX = this.diff(x1, x2);
        var deltaY = this.diff(y1, y2);
        var dist = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
        return (dist);
    }

    diff (num1, num2) {
        if (num1 > num2) {
            return (num1 - num2);
        } else {
            return (num2 - num1);
        }
    }

    setSpinnerData(value) {
        this.slice.forEach((el, index) => {
            const elDOM = el.nativeElement;
            const elMiddleDOM = el.nativeElement.querySelector('.segment__middle');

            if(this.data[index]) {
                elDOM.setAttribute('data-segment-id', this.data[index].id);
                if (this.data[index].id === this.tommorow.id) {
                    elMiddleDOM.innerHTML = this.tommorow.initialText;
                } else {
                    elMiddleDOM.innerHTML = this.data[index].name;
                }
            }
        });
    }

    playSound(type) {
        const audio: HTMLAudioElement = this.audio.nativeElement;
        if (type === SPINNER_SOUND_TYPE.wheel.name) {
            audio.src = SPINNER_SOUND_TYPE.wheel.src;
            audio.loop = true;
            audio.play();
        } else if (type === SPINNER_SOUND_TYPE.win.name) {
            const audioWin = new Audio(SPINNER_SOUND_TYPE.win.src);
            audioWin.play();
        }

    }

    stopSound() {
        this.audio.nativeElement.pause();
    }
}
