<div id="pixi" class="pixi-container" [class.is-blur]="infoPopup.show" #pixi></div>
<div class="action-loader" *ngIf="isMainGameLoaderShown">
    <app-thermometer-loader [progressValue]="gameMainLoaderProgress"></app-thermometer-loader>
</div>

<!--<div class="info-popup" *ngIf="infoPopup.show">-->
<!--    <div class="info-popup__inner">-->
<!--        <div class="info-popup__content">-->
<!--            {{infoPopup.message}}-->
<!--        </div>-->

<!--        <button class="info-popup__btn" (click)="hideInfoPopup()"></button>-->
<!--    </div>-->
<!--</div>-->

<app-info-popup
        *ngIf="infoPopup.show"
        [data]="infoPopup"
        (close)="hideInfoPopup()"></app-info-popup>
