import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {MascotService} from "../../../service/mascot/mascot.service";
import {LoaderService} from "../../../service/loader.service";
import {GameService} from "../../../service/games/gameService.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {DomSanitizer} from "@angular/platform-browser";
import {DateService} from "../../../service/date.service";
import {FullScreenService} from "../../../service/fullScreen.service";
import {NgxGlideComponent} from "ngx-glide";
import {POPUP_TYPE} from "./mascot-sliders.config";
import {ScratchOffService} from "../../../service/games/scratchOff.service";
import {SpinnerService} from "../../../service/games/spinner.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PHONE_MASK} from "../../../config/country_phone_mask";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {KeyboardService} from "../../../service/keyboard.service";
import {BehaviorSubject} from "rxjs";
import {PartnerService} from "../../../service/partner.service";
import {PROJECT_MESSAGES, PROVIDERS_TEMPLATES, TIMERS_CONFIG} from "../../../config/project";
import {RouterPaths} from "../../../config/router.paths.model";
import {AUTH_CODES} from "../../../config/auth_codes";
import {UsersPointService} from "../../../service/users/users-point.service";
import {DeviceDetectionService} from "../../../service/deviceDetection.service";
import {ERROR_CODE} from "../../../config/error_code";
import {KioskService} from "../../../service/kiosk.service";
import {KioskAccessorService} from "../../../service/kiosk/kiosk-accessor.service";
import {SCRATCH_OFF_POPUP_TYPE} from "../../games/scratch-off/scratch-off-rules/scratch-off-rules.config";
import {environment} from "../../../../environments/environment";
import {FrontendTestSettingsService} from "../../../service/testing/frontendTestSettings.service";

@Component({
    selector: 'app-mascot-sliders',
    templateUrl: './mascot-sliders.component.html',
    styleUrls: ['./mascot-sliders.component.scss']
})
export class MascotSlidersComponent implements OnInit {
    showPopup = false;
    isApp = +localStorage.getItem('isApp');
    isStationMascot;

    bodyAdditionalClass = 'is-mascot';

    singlePage = false;
    pinForm: FormGroup;
    phoneForm: FormGroup;
    showPhonePopup = false;
    showPinPopup = false;
    showRegistrationPopup = false;
    // showContactsPopup = false;
    country = 'us';
    phoneMask = PHONE_MASK[this.country];
    phoneLastFour;
    showContactsFormFirst;

    logoutTimeout;
    logoutAfterMin = 5;
    logoutAfterSec = this.logoutAfterMin * 60;
    logoutAutoConfirmAfterSec = 20;

    screenSaver = false;
    screenSaverTimeout;
    screenSaverAfterMin = 5;
    screenSaverAfterSec = this.screenSaverAfterMin * 60;

    popupGameImage;
    popupGameId;
    popupGameURL;
    popupType: number;
    popupLoading = false;
    POPUP_TYPE = POPUP_TYPE;

    showGameFindPrize = false;
    showGamePayment = false;
    showGamePaymentForm = false;
    paymentData: any;
    rulesPopupType: string;

    weekDay: string;
    games = [];
    slider1 = [];
    slider2 = [];

    slidesPerView = 2;
    slideWidth = 285;
    sliderAnimationDuration = 2000;
    sliderAnimationFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
    sliderAnimationDurationOnClick = 400;

    slideResolution = 1.458;
    slideHeightRate = .423;

    spinnerData;

    numOfDigits = 4;

    balance = {
        freeRounds: 0,
        win: 0,
        jackpot: 0,
        balance: 0
    };

    jackpotDynamicText = new BehaviorSubject(null);
    isTerminal = true;
    pigGameData = null;

    @ViewChild('newSwiper', {static: false}) newSwiper: NgxGlideComponent;
    @ViewChild('newSwiper2', {static: false}) newSwiper2: NgxGlideComponent;

    @ViewChild('textData', {static: false}) textData: ElementRef;
    @ViewChild('text', {static: false}) text: ElementRef;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    // @HostListener('window:resize', ['$event'])
    @HostListener('window:beforeunload', ['$event'])
    unloadHandler(event: Event) {
      // Your logic on beforeunload
      if ((this.isApp && this.isStationMascot) || this.singlePage) {
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: { notLogged: true },
            queryParamsHandling: 'merge'
          });
      }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setSlideWidth();

        setTimeout(() => {
            if (this.newSwiper) {
                this.newSwiper.update({perView: this.slidesPerView, autoplay: false});
            }
            if (this.newSwiper2) {
                this.newSwiper2.update({perView: this.slidesPerView, autoplay: false});
            }
        });
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event) {
        if (!this.isApp) {
            this.newSwiper.update({autoplay: false, animationDuration: this.sliderAnimationDurationOnClick});
            this.newSwiper2.update({autoplay: false, animationDuration: this.sliderAnimationDurationOnClick});
            this.onMouseMove = () => {};
        } else {
            this.onMouseMove = () => {};
        }
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {

        this.setSlideWidth();

        setTimeout(() => {
            if (this.newSwiper) {
                this.newSwiper.update({perView: this.slidesPerView, autoplay: false});
            }
            if (this.newSwiper2) {
                this.newSwiper2.update({perView: this.slidesPerView, autoplay: false});
            }
        });

    }

    constructor(
        private mascotService: MascotService,
        private scratchOffService: ScratchOffService,
        private loaderService: LoaderService,
        private gameService: GameService,
        private popupComponent: PopupComponent,
        private spinnerService: SpinnerService,
        public sanitizer: DomSanitizer,
        private dateService: DateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private cdRef : ChangeDetectorRef,
        private kioskService: KioskService,
        private kioskAccessorService: KioskAccessorService,
        private authenticationService: AuthenticationService,
        private keyboardService: KeyboardService,
        private partnerService: PartnerService,
        private fullScreenService: FullScreenService,
        private deviceDetectionService: DeviceDetectionService,
        private usersPointService: UsersPointService,
        private frontendTestSettingsService: FrontendTestSettingsService,
    ) {

        this.mascotService.balanceUpdated.subscribe(val => {
           if (val) {
               this.getBalance();
           }
        });

        this.kioskAccessorService.accessorStatusChanged.subscribe(value => {
            if (value) {
                if (this.isPopupForLogoutOpen()) {
                    this.closePopupAndUpdateTimer();
                } else {
                    this.setLogoutTimer();
                    this.setScreenSaver();
                }
            }
        });

        this.isStationMascot = this.partnerService.isMascotSingle();
        this.isTerminal = this.isStationMascot;

        if(this.partnerService.isMascotSingle()){
            this.singlePage = true;

            this.checkSinglePage();
            this.detectActivity();
        }

        if(this.partnerService.isMascotSingleTerminal()){
            this.logoutAfterMin = 2;
            this.logoutAfterSec = this.logoutAfterMin * 60;

            this.setLogoutTimer();
            this.setScreenSaver();

            this.kioskAccessorService.paymentAmountChanged.subscribe(val => {
                //if (val > 0) {
                    this.getBalance();
                //}
            });
        }

        this.jackpotDynamicText.subscribe(val => {
            if (this.text !== undefined && this.textData !== undefined) {
                setTimeout(() => {
                    this.text.nativeElement.innerHTML = this.textData.nativeElement.innerHTML;
                });
            }
        });

        // this.activatedRoute.queryParams.subscribe((queryParams:any) => {
        //     if (queryParams && queryParams.notLogged === 'true') {
        //         console.log(queryParams.notLogged);
        //
        //         this.logoutSinglePage();
        //     }
        // });

        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
    }


    ngOnInit() {
        // if (this.isIpad()) {
        //     const doc = document.documentElement;
        //     doc.style.setProperty('--ipad-height', `${window.innerHeight * .99}px`);
        //     doc.style.setProperty('--ipad-width', `${window.innerHeight * 1.4}px`);
        // }

        this.setBodyMascotClass();
        this.initGame();

        this.checkSinglePage();

        this.setAndroidSettings();
    }

    ngAfterContentChecked() : void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(){
        this.removeBodyMascotClass();
        if (this.logoutTimeout) {
            clearTimeout(this.logoutTimeout);
        }
        if (this.screenSaverTimeout) {
            clearTimeout(this.screenSaverTimeout);
        }
    }

    setBodyMascotClass() {
        document.body.classList.add(this.bodyAdditionalClass);
        setTimeout(() => {
            this.popupComponent.loadPopupImages();
        });
    }

    removeBodyMascotClass() {
        document.body.classList.remove(this.bodyAdditionalClass);
    }

    checkSinglePage() {
        if(this.partnerService.isMascotSingle()){
            this.showLoginPopup();
            document.body.classList.add('is-mascot-app');

            setTimeout(() => {
                if (this.jackpotDynamicText.value === null) {
                    this.setJackpot(0);

                }
            });
        }else{
            this.getBalance();
        }
    }

    setJackpot(value) {
        this.balance.jackpot = value;
        this.jackpotDynamicText.next(value);
    }

    // handleEventsForLogout() {
    //     if (this.singlePage) {
    //         setTimeout(() => {
    //             document.addEventListener('click', () => {
    //                 this.setLogoutTimer();
    //             });
    //             document.addEventListener('keydown', () => {
    //                 this.setLogoutTimer();
    //             });
    //         });
    //     }
    // }

    detectActivity() {
        setTimeout(() => {
            document.addEventListener('click', this.onUserActivity.bind(this));
            document.addEventListener('keydown', this.onUserActivity.bind(this));
        });
    }

    onUserActivity() {
        if (this.singlePage) {
            this.setLogoutTimer();
        }

        if (this.partnerService.isMascotSingleTerminal()) {
            this.setScreenSaver();
        }
    }

    isPopupForLogoutOpen() {
        return (document.querySelector('.swal-agreement .swal2-confirm') as HTMLButtonElement )
    }

    closePopupAndUpdateTimer() {
        if (this.isPopupForLogoutOpen()) {
            const popup = this.isPopupForLogoutOpen();
            popup.click();
        }
    }

    setScreenSaver() {
        this.screenSaver = false;
        if (this.screenSaverTimeout) {
            clearTimeout(this.screenSaverTimeout);
        }

        if (this.partnerService.isMascotSingleTerminal() && this.showPhonePopup && !this.phoneForm.controls.phone.value && !this.screenSaver) {
            this.screenSaverTimeout = setTimeout(() => {
               this.screenSaver = true;

           }, this.screenSaverAfterSec * 1000);
        }
    }

    setLogoutTimer() {
        if (this.singlePage) {
            if (this.logoutTimeout) {
                clearTimeout(this.logoutTimeout);
            }
            this.logoutTimeout = setTimeout(() => {
                if (this.showPhonePopup) {
                    if (this.phoneForm.controls.phone.value) {
                        this.phoneForm.controls.phone.setValue('');
                        this.setScreenSaver();
                    }
                    this.updateLogoutTimer();

                } else if (this.popupGameURL) {
                    this.mascotService.checkLastUpdates().subscribe(data => {
                        const now = data.now;
                        const last = data.last;
                        if ((now - last) > this.logoutAfterSec ) {
                            this.logout();
                        }
                    }, () => {
                        this.loaderService.hideAndShowTryAgain('bordered');
                    }, () => {
                        this.updateLogoutTimer();
                    });
                } else {
                    this.logout();
                }
            }, this.logoutAfterSec * 1000);
        }
    }

    updateLogoutTimer() {
        console.log('updateLogoutTimer : sliders');
        this.logoutTimeout = setTimeout(() => {
            this.setLogoutTimer();
        }, this.logoutAfterSec * 1000);
    }

    initGame() {
        this.getData();
        this.setSlideWidth();
    }

    showLoginPopup() {
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required]
        });

        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });

        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
        this.showPhonePopup = true;

        if ((<FormArray>this.pinForm.controls.digits).controls.length) {
            this.keyboardService.smallInputValueChanged.subscribe((settings) => {
                (<FormArray>this.pinForm.controls.digits).controls[settings.order].patchValue(settings.value);
            });
        }

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            this.keyboardService.setActiveInput();
        }, 0);
    }

    onPhoneSubmit() {
        if (this.phoneForm.invalid) {
            return;
        }

        let phone = this.phoneForm.controls.phone.value;
        let country = this.country;

        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(data => {
            try {
                if (data.status) {
                    this.phoneLastFour = phone.substr(-4);
                    this.showPhonePopup = false;
                    this.showPinPopup = true;

                    setTimeout(() => {
                        // this.inputs.toArray()[0].nativeElement.focus();
                        (document.querySelector('input') as HTMLInputElement).focus();
                        this.keyboardService.setActiveInput();
                    }, 0);
                } else {
                    if (data.code) {
                        if (data.code == AUTH_CODES.registerNewUser) {
                            this.errorHandlerRegisterNewUser();
                        } else {
                            this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                        }
                    } else {
                        this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                    }
                    // this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain('bordered');
            }

        }, (error) => {
            this.loaderService.hideAndShowTryAgain('bordered');
        }, () => {
            this.loaderService.hide();
        });
    }

    errorHandlerRegisterNewUser() {
        this.showRegistrationPopup = true;
        this.showPinPopup = true;
        this.showPhonePopup = false;

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            this.keyboardService.setActiveInput();
        }, 0);
    }

    onRegisterNewUser() {
        let phone = this.phoneForm.controls.phone.value;
        let country = this.country;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'}, 'btn-ok');
            return
        }

        this.loaderService.show();
        this.authenticationService.register(phone, pin, country).subscribe(data => {
            this.loaderService.hide();

            try {
                if (data.status) {
                    this.authenticationService.setData(data.result);

                    this.onUserLogged(data);
                } else {
                    this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                }
            } catch (e) {
                this.loaderService.hide();
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'}, 'btn-ok');
            }

        }, (error) => {
            this.loaderService.hide();
            this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'}, 'btn-ok');
        });
    }

    resendSms() {
        this.pinForm.reset();
        this.onPhoneSubmit();
    }

    setSlideWidth() {
        const docElement = document.documentElement;
        // const docElementWidth = document.documentElement.clientWidth;
        // const docElementHeight = document.documentElement.clientHeight;

        // New
        const docElementWidth = window.innerWidth;
        const docElementHeight = window.innerHeight;

        const isPortraitOrientation = docElementWidth < docElementHeight;
        const viewportHeight =  isPortraitOrientation ? docElementWidth : docElementHeight;
        const viewportWidth =  isPortraitOrientation ? docElementHeight : docElementWidth;
        const slideHeight = viewportHeight * this.slideHeightRate;

        if (slideHeight) {
            this.slideWidth = slideHeight / this.slideResolution;
        }

        this.slidesPerView = viewportWidth / this.slideWidth;
    }

    fullScreen() {
        if (!this.isAndroid()) {
            this.fullScreenService.toggleFullScreen();
        }
    }

    detectClickOnGame() {
        document.querySelectorAll('.mascot-slider__slide').forEach(el => {
            el.addEventListener('click', (e) => {
                const id = (<HTMLElement>e.currentTarget).getAttribute('data-id');
                this.openGame(id);
            })
        })
    }

    setDate() {
        this.weekDay = this.dateService.getWeekDayName();
    }

    getBalance() {
        if(this.authenticationService.isUserLogged()){
            this.mascotService.getBalance().subscribe(data => {
                this.setBalance(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    getData() {
        if (!this.games.length) {
            this.mascotService.getIcons().subscribe(data => {
                this.setData(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    setData(data) {
        try {
            this.games = data.result;
            const middle = Math.round(this.games.length / 2);

            this.slider1 = this.games.slice(0,middle);
            this.slider2 = this.games.slice(middle);

            this.slider1.push(...this.slider1);
            this.slider2.push(...this.slider2);

            this.spinnerData = data.wheel;

            this.setJackpot(data.jackpot);
            setTimeout(() => {
                this.detectClickOnGame();
            });
        } catch (e) {
        }

    }

    setBalance(data) {
        if (data && data.status) {
            this.balance.win = data.wins;
            this.balance.balance = data.balance;
            this.balance.freeRounds = data.freerounds;
            this.setJackpot(data.jackpot);
        } else {
            if (this.jackpotDynamicText.value === null) {
                this.setJackpot(0);
            }

            //this.popupComponent.showPopupError({text: data.message}, 'btn-ok')
        }
    }

    openGame(id?) {
        this.popupLoading = true;
        if (id) {
            const checkedGame = this.games.find(el => +el.id === +id);
            this.popupType = POPUP_TYPE.play;
            this.popupGameImage = checkedGame.image1600;
            this.popupGameId = checkedGame.id;
        } else {
            this.setPayment();
        }
        this.showPopup = true;
    }

    setPayment() {
        this.popupType = POPUP_TYPE.buy;
        this.popupLoading = true;
        this.showGamePayment = true;
        this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.pigRules;
    }

    isPaymentReady(value) {
        if (value) {
            this.popupLoading = false;
        } else {
            this.showGamePayment = false;
            this.showPopup = false;

        }
    }

    missedPayment(e) {
        this.showGamePaymentForm = true;
        this.paymentData = e;
    }

    closeGame() {
        this.popupGameURL = null;
        this.showPopup = false;
        this.popupGameImage = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.showGamePaymentForm = false;

        this.mascotService.updateBalance();
    }

    playGame(id) {
        if (id) {
            this.popupGameImage = null;
            this.popupLoading = true;
            this.gameService.getGameUrl(id).subscribe(data => {
                this.checkSubmitResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }

        // this.popupGameImage = null;
        // this.popupLoading = true;
        // setTimeout(() => {
        //     this.popupGameURL = this.getLink('https://kxys05e7341auuy.mascot.games');
        //     this.popupLoading = false;
        // }, 2000);
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    checkSubmitResult(responce) {
        if(responce.status === true){
            this.popupGameURL = this.getLink(responce.result);
            this.popupLoading = false;
        }else if (responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    imageLoaded() {
        this.popupLoading = false;
    }

    touchCapable() {
        return (
          'ontouchstart' in window ||
          ((window as any).DocumentTouch &&
            document instanceof (window as any).DocumentTouch) ||
          navigator.maxTouchPoints > 0 ||
          (window as any).navigator.msMaxTouchPoints > 0
        );
    }


    showGame(data) {
        this.showGamePayment = false;
        this.showGamePaymentForm = false;
        this.showGameFindPrize = true;
        // this.popupLoading = false;
        this.popupLoading = true;
        this.pigGameData = data;
    }

    onRulesActionBtnClick(data?) {
        if (this.isWelcomeRulesPopup()) {
            this.closeGame();
        } else {
            if (data.elements && data.limit) {
                if (data.elements.length !== data.limit) {
                    this.reTryUploadPigGame();
                } else {
                    this.showGame(data);
                }
            } else {
                this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
            }


            // this.showGame(data);
        }
    }

    reTryUploadPigGame() {
        this.scratchOffService.getGamePaymentInfo().subscribe(data => {
            this.buyGameResult(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    buyGameResult(response) {
        if(response.status === true){
            if (response.payment) {
                this.missedPayment(response);
            } else {
                if (response.elements && response.limit) {
                    if (response.elements.length !== response.limit) {
                        this.popupComponent.showPopupError({text: response.errorMessage}, 'btn-ok');
                        this.closeGame();
                    } else {
                        this.showGame(response);
                    }
                }
            }
        }else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    isWelcomeRulesPopup() {
        return this.rulesPopupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    }

    closeScratchOffGame() {
        this.showGamePayment = true;
        this.showGameFindPrize = false;
    }

    onSubmitPin() {
        if (this.pinForm.invalid) {
            return;
        }

        const country = this.country;
        const phone = this.phoneForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'}, 'btn-ok');
            return
        }

        if (phone && pin && (pin.length === 4) && country) {
            this.authenticationService.login(phone, pin, country).subscribe(data => {
                this.checkLoginSubmit(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                this.onUserLogged(data);
            } else {
                if (data.code) {
                    if (data.code == ERROR_CODE.blocked || data.code == ERROR_CODE.authOver) {
                        this.popupComponent.showPopupErrorReturnEvent({text: data.message, closePopupAfter: TIMERS_CONFIG.popupHideAfter}, () => {
                            // this.popupGoBack();
                            this.logoutSinglePage();
                        }, 'btn-ok');
                    } else {
                        this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                        this.popupGoBack();
                    }
                } else {
                    this.popupComponent.showPopupError({text: data.message}, 'btn-ok');
                    this.popupGoBack();
                }
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain('bordered');
            this.popupGoBack();
        }
    }

    onUserLogged(data) {
        console.log('onUserLogged : sliders');

        // data.result.first_name = '';
        // data.result.last_name = '';
        // data.result.email = '';
        if (this.frontendTestSettingsService.isHideForTesting()) {
            if(data.userPointId){
                if(data.userPointId == ''){
                    this.userPointIdEmpty();

                    return true;
                }else{
                    this.usersPointService.setUserPointId(data.userPointId);
                }
            }else{
                if(this.partnerService.isMascotSingle()){
                    this.userPointIdEmpty();

                    return true;
                }
            }
        }

        this.showRegistrationPopup = false;
        this.showPinPopup = false;
        this.authenticationService.userLoggedStatusChanged.next(true);
        // this.showContactsFormFirst = data.result.first_name == '' && data.result.last_name == '' && data.result.email == '';
        console.log("RouterPaths.mascot.main 2");
        //this.router.navigate([RouterPaths.mascot.main], {queryParams: {notLogged: false}});

        this.getBalance();
        this.keyboardService.keyboardVisible.next(false);
        this.showWelcomePopup();
        if (this.newSwiper) {
            this.newSwiper.update({perView: this.slidesPerView, autoplay: false});
        }
        if (this.newSwiper2) {
            this.newSwiper2.update({perView: this.slidesPerView, autoplay: false});
        }
    }

    showWelcomePopup() {
        if ((this.isApp && this.isStationMascot) || (!this.isTerminal && this.isStationMascot)) {
            this.popupLoading = true;
            this.popupType = POPUP_TYPE.buy;
            this.showGamePayment = true;
            this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.welcome;
            this.showPopup = true;
        }
    }

    userPointIdEmpty(){
        this.logoutSinglePage();

        this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'}, 'btn-ok');
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    popupGoBack() {
        this.showPhonePopup = true;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.resetPinInput();
        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            this.keyboardService.setActiveInput();
        }, 0);
    }

    hideContactForm() {
        this.showContactsFormFirst = false;
    }

    logout() {
        if (this.singlePage) {
            let counter = this.logoutAutoConfirmAfterSec;
            const counterInterval = setInterval(() => {
                if (counter) {
                    counter -= 1;
                    document.querySelector('#logoutTimer').innerHTML = String(counter) + '.';
                } else {
                    clearInterval(counterInterval)
                }
            }, 1000);

            const logoutTime = this.logoutAfterMin === 5 ? 'five (5)' : this.logoutAfterMin === 2 ? 'two (2)' : ''
            this.popupComponent.showPopupAgreement({text: `Are you still here? No activity is detected for ${logoutTime} minutes. We are about to log you out in <span id="logoutTimer">${counter}</span>`, style: 'swal-agreement no-close-btn btn-continue'}, (result) => {
                if (result === 'timerOut') {
                    this.logoutSinglePage();
                }
                clearInterval(counterInterval);
            }, this.logoutAutoConfirmAfterSec * 1000);
        } else {
            this.router.navigate(['/home/settings']);
        }
    }

    onLogout() {
        if (this.singlePage) {
            this.logoutSinglePage();
        } else {
            this.router.navigate(['/home/settings']);
        }
    }

    logoutSinglePage() {
        this.showPopup = false;
        this.showPinPopup = false;
        this.showRegistrationPopup = false;
        this.showContactsFormFirst = false;
        this.popupGameURL = false;
        this.popupType = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.popupComponent.closePopup();

        this.showPhonePopup = true;

        if (this.pinForm && this.phoneForm) {
            this.phoneForm.controls.phone.setValue('');
            this.resetPinInput();
        }


        this.authenticationService.logout();
        this.clearBalance();

        setTimeout(() => {
            // this.inputs.toArray()[0].nativeElement.focus();
            (document.querySelector('input') as HTMLInputElement).focus();
            this.keyboardService.setActiveInput();
            this.setScreenSaver();
        }, 0);

        // this.router.navigate(['/mascot'], {queryParams: {notLogged: true}});
        // window.location.reload();
    }

    resetPinInput() {
        (this.pinForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    frameClick() {
        // console.log('frameClick')
    }

    clearBalance() {
        this.balance.freeRounds = 0;
        this.balance.balance = 0;
        this.balance.win = 0;
    }

    getPartnerName() {
        return this.partnerService.getName();
        //return this.currentPartner.name;
    }

    isIphone() {
        return this.deviceDetectionService.isIphone();
    }

    isIpad() {
        return this.deviceDetectionService.isIpad();
    }

    isAndroid() {
        return this.deviceDetectionService.isAndroid();
    }

    setAndroidSettings() {
        if (this.isAndroid()) {
            document.addEventListener('click', this.fullScreenService.setFullScreen);
        }
    }

    goToPreviousSlide(slider) {
        slider.go('<');
    }

    goToNextSlide(slider) {
        slider.go('>');
    }

    onCloseGamePaymentForm() {
        this.showGamePaymentForm = false;
        this.pigGameData = null;
    }
}
