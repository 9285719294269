import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {ADDRESSES_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class AddressesService extends SettingsLoaderService {
    private config = ADDRESSES_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getAddresses() {
        this.showLoader();
        let post = this.http.post<any>(this.config.addresses, {});
        this.hideLoader();

        return post;
    }

    getList() {
        this.showLoader();
        let post = this.http.post<any>(this.config.list, {});
        this.hideLoader();
        return post;
    }

    find(id) {
        this.showLoader();
        let post = this.http.post<any>(this.config.find, {id:id});
        this.hideLoader();
        return post;
    }

    deleteAddress(id) {
        this.showLoader();
        let post = this.http.post<any>(this.config.delete, {id});
        this.hideLoader();

        return post;
    }

    addAddress(address) {
        this.showLoader();
        let post = this.http.post<any>(this.config.add, {address});
        this.hideLoader();

        return post;
    }

    editAddress(id, address) {
        this.showLoader();
        let post = this.http.post<any>(this.config.edit, {id, address});
        this.hideLoader();

        return post;
    }
}
