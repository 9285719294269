import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
import { PROJECT_MESSAGES } from "../../../config/project";
import { NFT_POPUP_TYPE } from "../../investments/investments-nft-popup/investments-nft-popup.config";
import { RouterPaths } from "../../../config/router.paths.model";
var GamesPaymentComponent = /** @class */ (function () {
    function GamesPaymentComponent(scratchOffService, formBuilder, scrollToService, popupComponent, fundsService, loaderService) {
        this.scratchOffService = scratchOffService;
        this.formBuilder = formBuilder;
        this.scrollToService = scrollToService;
        this.popupComponent = popupComponent;
        this.fundsService = fundsService;
        this.loaderService = loaderService;
        // @Output() isPaymentForbidden = new EventEmitter();
        this.isPaymentSuccess = new EventEmitter();
        this.closePopup = new EventEmitter();
        this.showNFTPopup = false;
        this.popupVisible = false;
        this.iframePopup = {
            show: false,
            url: null
        };
    }
    Object.defineProperty(GamesPaymentComponent.prototype, "showPopup", {
        get: function () {
            return this._showPopup;
        },
        set: function (value) {
            var _this = this;
            this._showPopup = value;
            if (value) {
                this.popupVisible = true;
                setTimeout(function () {
                    _this.setCheckedMethod();
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    GamesPaymentComponent.prototype.ngOnInit = function () {
        this.buyForm = this.formBuilder.group({
            paymentMethod: [null, Validators.required],
        });
    };
    GamesPaymentComponent.prototype.ngOnDestroy = function () {
        this.popupVisible = false;
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.remove('is-blur');
        }
        document.body.classList.remove('hide-menu');
    };
    GamesPaymentComponent.prototype.buyGame = function () {
        var method = this.buyForm.controls.paymentMethod;
        if (!this.data.result.length) {
            this.popupComponent.showPopupError({ text: 'Please enter your payment method' }, 'btn-ok');
        }
        else {
            if (this.data.result.length > 1 && method === null) {
                this.popupComponent.showPopupError({ text: 'Please select the payment method to use' }, 'btn-ok');
            }
            else {
                if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
                    this.showNoVerifyErrorMessage();
                }
                else {
                    this.showConfirmMessage();
                }
            }
        }
    };
    GamesPaymentComponent.prototype.showConfirmMessage = function () {
        var _this = this;
        var amount = this.data.amount;
        this.fundsService.getDepositInfo(amount).subscribe(function (data) {
            try {
                if (data.status) {
                    _this.onShowNFTPopup(data);
                }
                else {
                    _this.popupComponent.showPopupError({
                        text: data.message,
                        confirmButtonText: 'OK'
                    }, 'btn-ok');
                }
            }
            catch (e) {
                _this.loaderService.hideAndShowTryAgain();
            }
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    GamesPaymentComponent.prototype.onShowNFTPopup = function (data) {
        var description = this.activeMethod.description;
        var number = this.activeMethod.last_four;
        data.cardInfo = {
            description: description,
            number: number
        };
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.funds,
            data: {
                element: data
            }
        };
    };
    GamesPaymentComponent.prototype.showNoVerifyErrorMessage = function () {
        if (this.activeMethod && this.activeMethod.last_four) {
            var message = this.activeMethod.description ?
                "Please verify " + this.activeMethod.description + " - " + this.activeMethod.last_four + " first. Go to the Card settings page." :
                "Please verify card ending in " + this.activeMethod.last_four + ".";
            this.popupComponent.showPopupError({ text: message }, 'btn-ok');
        }
        else {
            this.popupComponent.showPopupError({ text: PROJECT_MESSAGES.unknownError }, 'btn-ok');
        }
        return;
    };
    GamesPaymentComponent.prototype.getPaymentMethod = function (method) {
        return method.payment_method;
    };
    GamesPaymentComponent.prototype.getMethodId = function (method) {
        return method.id;
    };
    GamesPaymentComponent.prototype.getPaymentName = function (data) {
        if (data) {
            return data.description + ' - ' + data.last_four;
        }
        return '';
    };
    GamesPaymentComponent.prototype.changeMethod = function (method) {
        if (method) {
            this.activeMethod = method;
        }
    };
    GamesPaymentComponent.prototype.setCheckedMethod = function () {
        if (!this.data.result.length) {
            this.buyForm.controls.paymentMethod.setValue(null);
            this.activeMethod = null;
        }
        else {
            var method = this.data.result[this.data.result.length - 1];
            this.buyForm.controls.paymentMethod.setValue(method);
            this.activeMethod = method;
        }
        this.scrollToLastMethod();
    };
    GamesPaymentComponent.prototype.scrollToLastMethod = function () {
        this.scrollToService.scrollToEnd('.saved__list');
    };
    GamesPaymentComponent.prototype.onPopupNFTClose = function (result) {
        var _this = this;
        this.closePopup.emit();
        this.showNFTPopup = false;
        document.getElementById('mascot-popup-pig').classList.add('is-blurred');
        if (result) {
            var cardId = this.activeMethod.id;
            this.scratchOffService.buyGame(cardId).subscribe(function (data) {
                if (data.status) {
                    if (data.threeDSecure) {
                        if (data.threeDSecureUrl) {
                            _this.showIframePopup(data.threeDSecureUrl);
                        }
                        else {
                            _this.loaderService.hideAndShowTryAgain();
                        }
                    }
                    else {
                        _this.isPaymentSuccess.emit(data);
                        _this.showNFTPopup = false;
                        document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
                    }
                }
                else {
                    _this.popupComponent.showPopupError({ text: data.message }, 'btn-ok');
                }
            }, function () {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.showNFTPopup = false;
            document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
        }
    };
    GamesPaymentComponent.prototype.onClose = function () {
        this.closePopup.emit();
    };
    GamesPaymentComponent.prototype.getAddLink = function () {
        return RouterPaths.settings.card.add;
    };
    GamesPaymentComponent.prototype.showIframePopup = function (url) {
        document.body.classList.add('hide-menu');
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.add('is-blur');
        }
        this.iframePopup = {
            show: true,
            url: url
        };
    };
    GamesPaymentComponent.prototype.hideIframePopup = function (result) {
        document.body.classList.remove('hide-menu');
        document.getElementById('mascot-popup-pig').classList.remove('is-blurred');
        if (document.body.querySelector('.investments-popup')) {
            document.body.querySelector('.investments-popup').classList.remove('is-blur');
        }
        this.iframePopup = {
            show: false,
            url: null
        };
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            }
            else {
                if (result.status) {
                    this.isPaymentSuccess.emit(result);
                    this.showNFTPopup = false;
                }
                else {
                    this.popupComponent.showPopupError({ text: result.message }, 'btn-ok');
                }
            }
        }
    };
    return GamesPaymentComponent;
}());
export { GamesPaymentComponent };
