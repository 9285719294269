export const REPORT_DATA_COLS = {
  inflows: "in",
  freePlay: "free",
  outflows: "out",
  net: "net",
    due: "due"
};

export const REPORT_PERIOD = {
  weekly: "weekly",
  monthly: "monthly",
};
