import * as tslib_1 from "tslib";
import { FUNDS_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var FundsService = /** @class */ (function (_super) {
    tslib_1.__extends(FundsService, _super);
    function FundsService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = FUNDS_CONFIG;
        return _this;
    }
    FundsService.prototype.getPayments = function () {
        this.showLoader();
        return this.http.post(this.config.payments, {});
    };
    FundsService.prototype.addFunds = function (id, method, amount, usdc_amount) {
        this.showLoader();
        return this.http.post(this.config.add, { id: id, method: method, amount: amount, usdc_amount: usdc_amount });
    };
    FundsService.prototype.getDepositInfo = function (amount) {
        this.showLoader();
        return this.http.post(this.config.getDepositInfo, { amount: amount });
    };
    FundsService.prototype.getCashAppInfo = function (amount) {
        this.showLoader();
        return this.http.post(this.config.getCashAppInfo, { amount: amount });
    };
    FundsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FundsService_Factory() { return new FundsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: FundsService, providedIn: "root" });
    return FundsService;
}(SettingsLoaderService));
export { FundsService };
