import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PAYMENT_TYPES, PAYMENT_TYPES_ICONS} from "../../config/payment_types";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PrizesService} from "../../service/prizes/prizes.service";
import {PrizesModel} from "../../models/services/prizes/prizes.model";
import {StylingService} from "../../service/styling.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BREAKPOINTS} from "../../config/breakpoints";
import {DOMManipulationsService} from "../../service/dom/DOMManipulations.service";
import {KioskAccessorService} from "../../service/kiosk/kiosk-accessor.service";
import {KioskScrollService} from "../../service/kiosk-scroll.service";
import {RouterPaths} from "../../config/router.paths.model";
import {DEBIT_CARD_STATUS, PRIZES_METHODS} from "./prizes.config";
import {PROJECT_MESSAGES} from "../../config/project";
import {formatCurrency, formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../config/crypto_settings";
import {PrizesMethodModel} from "../../models/prizes/prizesMethod.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {POPUP_TYPES, showPopupFormType} from "../investments/investments-popup-form/investments-popup-form.config";
import {SettingsService} from "../../service/settings/settings.service";
import {PopupConfirmInfoModel} from "../../shared/popup-prizes-confirm/popupConfirmInfo.model";
import {GAME_PROVIDER_ERRORS_CODE} from "../../service/games/gameProviderErrorsCode.config";

@Component({
    selector: 'app-prizes',
    templateUrl: './prizes.component.html',
    styleUrls: ['./prizes.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class PrizesComponent implements OnInit {
    dropdownClosed = true;

    prizesInfo: PrizesModel;

    PAYMENT_TYPES = PAYMENT_TYPES;
    PAYMENT_TYPES_ICONS = PAYMENT_TYPES_ICONS;
    ROUTER_PATHS = RouterPaths;

    menu = false;
    availableBalance = 0;

    prevChildTopOffset = 0;
    methodUnavailableMsg;
    availableMethods: Array<PrizesMethodModel> = [];
    prizesData: PaginationDataModel = new PaginationData('prizes', '.js-prizes-item', '.js-prizes-parent', 'credit');

    PRIZES_METHODS = PRIZES_METHODS;
    workingTimeMessage = 'Redemption Operating hours are Monday thru Friday from 9am to 5pm EST.';

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    @ViewChild('dropBody', {static: false}) dropBody: ElementRef;
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    // FORM
    form: FormGroup;

    selectedMethod;

    games = [];
    cashappVerified = {
        handle: '',
        status: false
    };
    paypalVerified = {
        handle: '',
        status: false
    };
    venmoVerified = {
        handle: '',
        status: false
    };
    zelleVerified = {
        handle: '',
        status: false,
        type: null
    };
    giftCards = {
        description: ''
    };
    mobileTopup = {
        description: '',
        phone: ''
    };

    activeProvider;
    gameVendorName = this.gamesProviderService.getActiveProviderName();

    // cryptoData = {
    //     items: [],
    //     loading: false
    // };

    showPopup: showPopupFormType = 'hide';
    popupFormType;
    popupFormData;

    popupConfirmInfo: PopupConfirmInfoModel = {show: false, totalDebit: 0};
    popupDebitCardInfo: any = {show: false};
    popupMobileTopupInfo = {show: false, handle: '', totalDebit: 0};

    dailyInfo = {
        list: [],
        total: 0
    };

    debitCardDescription = '';
    debitCardStatus;
    debitCardPendingText = '';
    debitCardErrorMessage = '';
    debitCardSelectedName = '';
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private paginationScrollService: PaginationScrollService,
                private DOMManipulationsService: DOMManipulationsService,
                private kioskAccessorService: KioskAccessorService,
                private kioskScrollService: KioskScrollService,
                private gamesProviderService: GamesProviderService,
                private settingsService: SettingsService,
                private prizesService: PrizesService) {
        this.kioskAccessorService.paymentComplete.subscribe((val) => {
            if (val !== null) {
                this.getPrizesInfo();
            }
        });
    }


    ngOnInit() {
        this.setForms();
        this.getPrizesInfo();
        // this.getCryptoList();
    }

    setForms() {
        this.form = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required]
        });
    }

    getPrizesInfo(noGameChange = false, onlyDebitCard = false){
        this.prizesService.getPrizesInfo(1).subscribe(data => {
            try {
                this.setPrizesInfo(data, noGameChange, onlyDebitCard)
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setGamesProviders(providers, noGameChange: boolean) {
        this.games = providers;
        this.gamesProviderService.setProviders(this.games);

        if (!noGameChange) {
            this.activeProvider = this.gamesProviderService.getActiveProvider(this.games);
            this.gameVendorName = this.gamesProviderService.getActiveProviderName();

            if(this.activeProvider === undefined){
                if(providers[0]){
                    this.onProviderChange(providers[0]);
                }
            }else{
                this.selectedMethod = this.activeProvider;
                this.form.controls.method.setValue(this.selectedMethod);
            }
        }
    }

    setPrizesInfo(data: PrizesModel, noGameChange: boolean, onlyDebitCard = false) {
        if (data.methodsDebitCard) {
            this.setDebitCard(data.methodsDebitCard);
        }
        if (onlyDebitCard) {
            return;
        }

        this.prizesInfo = data;
        this.availableBalance = this.prizesInfo.totalDebit;

        if (data.games.length) {
            this.setGamesProviders(data.games, noGameChange);

        }

        this.availableMethods = data.methods;
        //this.availableMethods = [{shortName: 'crypto', display: 1, disable: 1}, {shortName: 'mailCheck', display: 0, disable: 1}, {shortName: 'paypal', display: 1, disable: 0}, {shortName: 'venmo', display: 1, disable: 0}, {shortName: 'cashapp', display: 1, disable: 0}];
        this.methodUnavailableMsg = data.methodUnavailableMsg;
        this.setCashAppTagStatus(data);
        this.setPaypalStatus(data);
        this.setVenmoStatus(data);
        this.setZelleStatus(data);
        this.setDalyList(data);
        this.setGiftCards(data);
        this.setMobileTopupInfo(data);

        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: data.credit,
            scrollLimit: data.limit,
            total: data.total
        };

        this.initScrolledData(this.prizesData, prizesDataSettings);
    }

    setCashAppTagStatus(data) {
        // data.cashappTag = '$eboxjen';
        // data.cashappTagStatus = true;

        this.cashappVerified.status = data.cashappTagStatus;
        this.cashappVerified.handle = data.cashappTag;
    }

    setPaypalStatus(data) {
        // data.paypalEmail = 'test@gmal.com';
        // data.paypalEmailStatus = true;

        this.paypalVerified.status = data.paypalEmailStatus;
        this.paypalVerified.handle = data.paypalEmail;
    }

    setVenmoStatus(data) {
        this.venmoVerified.status = data.venmoTagStatus;
        this.venmoVerified.handle = data.venmoTag;
    }

    setZelleStatus(data) {
        this.zelleVerified.status = data.zelle.status;
        this.zelleVerified.handle = data.zelle.address;
        this.zelleVerified.type = data.zelle.type;
    }

    setGiftCards(data) {
        this.giftCards.description = data.giftCardDescription;
    }

    setMobileTopupInfo(data) {
        this.mobileTopup.description = data.mobileTopupDescription;
        this.mobileTopup.phone = data.mobileTopupPhone;
    }

    setDalyList(data) {
        // data.dailyList = [{name: "Mascot", amount: "20.00"}, {name: "Golden Dragon", amount: "1.00"}];
        // data.dailyTotal = 0;

        this.dailyInfo = {
            list: data.dailyList,
            total: data.dailyTotal
        }
    }

    setDebitCard(data) {
        this.debitCardDescription = data.description;
        this.debitCardStatus = data.status;

        if (this.isDebitCardStatusPending()) {
            this.debitCardPendingText = data.pendingTxt;
        }

        if (this.isDebitCardStatusError() || this.isDebitCardStatusErrorCard()) {
            this.debitCardErrorMessage = data.errorTxt;
        }

        if (this.isDebitCardStatusSelected()) {
            this.debitCardSelectedName = data.cardName;
        }
    }

    checkDebitCardStatus(status) {
        if (this.debitCardStatus || this.debitCardStatus === 0) {
            return this.debitCardStatus === status;
        }
        return false;
    }

    isDebitCardStatusDefault() {
        return this.checkDebitCardStatus(DEBIT_CARD_STATUS.default);
    }

    isDebitCardStatusPending() {
        return this.checkDebitCardStatus(DEBIT_CARD_STATUS.pending);
    }

    isDebitCardStatusError() {
        return this.checkDebitCardStatus(DEBIT_CARD_STATUS.error);
    }

    isDebitCardStatusSelected() {
        return this.checkDebitCardStatus(DEBIT_CARD_STATUS.selected);
    }

    isDebitCardStatusErrorCard() {
        return this.checkDebitCardStatus(DEBIT_CARD_STATUS.errorCard);
    }


    // getCryptoList() {
    //     this.cryptoData.loading = true;
    //     this.prizesService.getCryptoList().subscribe(data => {
    //         this.getServerResponse(data, this.setCryptoList.bind(this));
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.cryptoData.loading = false;
    //     });
    // }

    // setCryptoList(data)  {
    //     this.cryptoData.items = data.result;
    //     this.selectedCryptoMethod = this.cryptoData.items[0];
    //     this.cryptoForm.controls.method.setValue(this.selectedCryptoMethod);
    // }

    getServerResponse(data, successHandler, additionalParams?) {
        try {
            if (data.status === true) {
                successHandler(data, additionalParams);
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }


    getPaymentIcon(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => this.PAYMENT_TYPES[key] === id);
        let icon;

        if (type) {
            icon = this.PAYMENT_TYPES_ICONS[type];
        }
        return icon;
    }

    getDate(date) {
        if(date == null){
           return '';
        }

        return date.replace(/\s/g, 'T');
    }

    toggleDropdown() {
        this.dropdownClosed = !this.dropdownClosed;

        // SCROLL TO END OF PAGE
        if (!this.dropdownClosed && window.innerWidth < BREAKPOINTS.mobile_max) {

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page__inner')[0]).offsetHeight;

                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scroll({
                    top: pageScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)
        }
    }

    // isWorkingTime() {
    //     const today = new Date().toLocaleTimeString("en-US", {timeZone: "America/New_York", weekday: 'short', hour12: false, hour: 'numeric'});
    //     const todayArray = today.split(', ');
    //     const weekday = todayArray[0];
    //     const hours = +todayArray[1];
    //
    //     if ((weekday !== 'Sun' && weekday !== 'Sat') &&
    //       (9 <= hours && hours < 17)) {
    //         return true;
    //     }
    //     return false;
    // }

    showWorkingTimeMessage(ev, method) {
        // if(method == PRIZES_METHODS.games){
        //     return true;
        // }

        if (this.isUnavailableMethod(method)) {
            this.popupComponent.showPopupError({text: this.methodUnavailableMsg,confirmButtonText:'OK'});
            ev.preventDefault();
            return true;
        }

        if (method === PRIZES_METHODS.mobileTopup) {
            this.showPopupMobileTopup();
        } else {
            this.showPopupConfirm(method);
        }



        // if (this.isWorkingTime()) {
        //     return;
        // } else {
        //     this.popupComponent.showPopupErrorReturnEvent({text: this.workingTimeMessage}, () => {
        //         setTimeout(() => {
        //             document.querySelector('.page__scrolled').scrollTo({
        //                 top: 0,
        //                 behavior: 'smooth'
        //             });
        //         }, 100);
        //     });
        //     ev.preventDefault();
        //     return true;
        // }
    }

    isUnavailableMethod(method) {
        return this.checkMethodProperty(method, 'disable');
    }

    isDisplayMethod(method) {
        return this.checkMethodProperty(method, 'display');
    }

    checkMethodProperty(method, property) {
        if (this.availableMethods && this.availableMethods.length) {
            const checkMethod = this.availableMethods.find(methodItem => methodItem.shortName === method);

            if (checkMethod) {
                return checkMethod[property];
            }
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        if (settings.name ===  'prizes') {
            this.prizesService.getPrizesInfo(page).subscribe(data => {
                this.paginationScrollService.dataAddingLogic(page, step, data, settings)
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    onSubmitPayout(amount, handle) {
        const method = handle.short_name;
        const methodName = handle.name;

        if (!amount || !method) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.sendPayout(method, amount);
                }
            });
        }
    }

    sendPayout(method, amount,) {
        this.prizesService.redeemViaGames(method, amount).subscribe(data => {
            this.displaySubmitPayoutResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitPayoutResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
                this.closePopupConfirm();
            }else{
                this.popupComponent.showPopupError({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
                this.closePopupConfirm();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetForms() {
        this.form.controls.amount.setValue(null);
    }

    onSubmitCashappForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your CashApp tag ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaCashApp(amount, handle).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onSubmitVenmoForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your Venmo tag ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaVenmoTag(amount, handle).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onSubmitDebitCardForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your Debit Card ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaDebitCard(amount).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onSubmitZelleForm(amount, handle) {
        const handleFormated = document.querySelector('input[formcontrolname="handle"]')['value'];
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your Zelle account ${handleFormated}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaZelle(amount, this.zelleVerified.type).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onSubmitGiftCardVisaForm(amount, handle, qty) {
        if (amount && handle && qty) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount*qty, 'en','$')} to Visa Gift Card for ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaVisaGiftCard(amount, qty).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onSubmitGiftCardAmazonForm(amount, handle, qty) {
        if (amount && handle && qty) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount*qty, 'en','$')} to Amazon Gift Card for ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaAmazonGiftCard(amount, qty).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    getInputValue(form, field) {
        if (form.controls[field]) {
            return form.controls[field].value;
        }
    }

    onPopupFormClose(response) {
        this.showPopup = 'hide';

        if (response && response.status) {
            this.resetForms();
            this.getPrizesInfo();
        }
    }

    showPopupConfirm(type) {
        document.body.classList.add('hide-menu');
        const handle = type === PRIZES_METHODS.games ? this.form.controls.method.value.name :
                                type === PRIZES_METHODS.card ? this.debitCardSelectedName :
                                type === PRIZES_METHODS.paypal ? this.paypalVerified.handle :
                                type === PRIZES_METHODS.venmo ? this.venmoVerified.handle :
                                type === PRIZES_METHODS.zelle ? this.zelleVerified.handle : this.cashappVerified.handle;

        if (type === PRIZES_METHODS.giftCardVisa || type === PRIZES_METHODS.giftCardAmazon) {
            const giftCardType = type === PRIZES_METHODS.giftCardVisa ? 'visa' : 'amazon';
            this.prizesService.getGiftCardInfo(giftCardType).subscribe((data) => {
                try {
                    if (data.status) {
                        this.popupConfirmInfo = {
                            show: true,
                            totalDebit: this.prizesInfo.totalDebit,
                            handle: data.email,
                            type: type,
                            giftDescription: data.message,
                            giftAmountType: data.denominationType,
                            giftAmountArray: data.fixedDenominations
                        };
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                        document.body.classList.remove('hide-menu');
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                    document.body.classList.remove('hide-menu');
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
                document.body.classList.remove('hide-menu');
            }, () => {
                this.loaderService.hide();
            })
        } else {
            const cashappVerified = type === PRIZES_METHODS.cashapp ? this.cashappVerified.status : null;
            const providers = this.games.filter(game => game !== this.activeProvider);
            this.popupConfirmInfo = {
                show: true,
                totalDebit: this.prizesInfo.totalDebit,
                handle: handle,
                type: type,
                providers: providers,
                cashappVerified: cashappVerified,
                paypalAddress: this.paypalVerified.status,
                venmoAddress: this.venmoVerified.status,
                zelleAddress: this.zelleVerified.status,
                zelleType: this.zelleVerified.type
            };
        }
    }

    showPopupMobileTopup() {
        document.body.classList.add('hide-menu');
        const handle = this.mobileTopup.phone;

        this.popupMobileTopupInfo = {
            show: true,
            handle: handle,
            totalDebit: this.prizesInfo.totalDebit
        };
    }

    submitPopupConfirm(info) {
        if (info.type === PRIZES_METHODS.games) {
            this.onSubmitPayout(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.paypal) {
            this.onSubmitPaypalForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.cashapp) {
            this.onSubmitCashappForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.venmo) {
            this.onSubmitVenmoForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.card) {
            this.onSubmitDebitCardForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.giftCardVisa) {
            this.onSubmitGiftCardVisaForm(info.amount, this.popupConfirmInfo.handle, info.qty);
        } else if (info.type === PRIZES_METHODS.giftCardAmazon) {
            this.onSubmitGiftCardAmazonForm(info.amount, this.popupConfirmInfo.handle, info.qty);
        } else if (info.type === PRIZES_METHODS.zelle) {
            this.onSubmitZelleForm(info.amount, this.popupConfirmInfo.handle);
        }
    }

    closePopupConfirm() {
        document.body.classList.remove('hide-menu');
        this.popupConfirmInfo = {
            show: false,
            totalDebit: this.prizesInfo.totalDebit
        };
    }

    closePopupMobileTopup() {
        document.body.classList.remove('hide-menu');
        this.popupMobileTopupInfo = {
            show: false,
            handle: '',
            totalDebit: 0
        };
    }

    changeHandlePopupConfirm(info) {
        this.popupConfirmInfo.handle = info.handle;
        if (info.type === PRIZES_METHODS.cashapp) {
            this.cashappVerified.status = true;
            this.cashappVerified.handle = info.handle;
        } else if (info.type === PRIZES_METHODS.paypal) {
            this.paypalVerified.status = true;
            this.paypalVerified.handle = info.handle;
        } else if (info.type === PRIZES_METHODS.venmo) {
            this.venmoVerified.status = true;
            this.venmoVerified.handle = info.handle;
        } else if (info.type === PRIZES_METHODS.zelle) {
            if (!info.handle) {
                this.zelleVerified.type = info.zelleType;
                return;
            }
            this.zelleVerified.status = true;
            this.zelleVerified.handle = info.handle;
            this.zelleVerified.type = info.zelleType;
        }

    }

    onSubmitPaypalForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your PayPal account ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.paypalCreatePayout(null, handle, amount).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });

        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onProviderChange(game) {
        this.loaderService.show();
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    }

    checkSubmitGame(data, gameId) {
        try {
            if(data.status === true){
                if (data.code) {
                    if (data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                        setTimeout(() => {
                            this.getPrizesInfo(true);
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.getPrizesInfo();
                    });
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showDebitCardPopup() {
        document.body.classList.add('hide-menu');
        this.popupDebitCardInfo = {
            show: true,
            type: 1
        }
    }

    closeDebitCardPopup() {
        document.body.classList.remove('hide-menu');
        this.popupDebitCardInfo = {
            show: false,
        };
        this.getPrizesInfo(false, false);
    }

    showDebitCardAdditionInfo() {
        document.body.classList.add('hide-menu');
        this.popupDebitCardInfo = {
            show: true,
            type: 2
        }
    }
}
