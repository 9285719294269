import { PROJECT_CONFIG } from "../../config/project";
import * as i0 from "@angular/core";
var UsersPointService = /** @class */ (function () {
    function UsersPointService() {
        this.storage = sessionStorage;
        this.keyName = 'userPoint';
        this.groupIdName = 'userPointGroupId';
        this.pointName = 'pointName';
        this.keyPointHash = 'pointHash';
    }
    UsersPointService.prototype.saveHashInStorage = function (hash) {
        this.storage.setItem(this.keyPointHash, hash);
    };
    UsersPointService.prototype.getHash = function () {
        if (sessionStorage.getItem(this.keyPointHash)) {
            try {
                return sessionStorage.getItem(this.keyPointHash);
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.isPointSubdomain = function () {
        if (this.getSubdomain() != '') {
            if (PROJECT_CONFIG.pointSubdomainEbox != this.getSubdomain() && PROJECT_CONFIG.pointSubdomainEboxDemo != this.getSubdomain()) {
                return true;
            }
        }
        return false;
    };
    UsersPointService.prototype.getSubdomain = function () {
        var domain = window.location.hostname;
        var parts = domain.split('.');
        parts.pop();
        parts.pop();
        //alert(this.usersPointService.getSubdomain());
        return parts.join('.');
    };
    UsersPointService.prototype.setUserPointId = function (id) {
        this.storage.setItem('userPointId', id);
    };
    UsersPointService.prototype.getUserPointId = function () {
        return this.storage.getItem('userPointId');
    };
    UsersPointService.prototype.setAccount = function (data) {
        this.storage.setItem(this.keyName, JSON.stringify(data));
    };
    UsersPointService.prototype.setPointPlayersEarnIncome = function (id) {
        if (id) {
            this.storage.setItem('pointPlayersEarnIncome', id);
        }
        else {
            this.storage.removeItem('pointPlayersEarnIncome');
        }
    };
    UsersPointService.prototype.isPointPlayersEarnIncome = function () {
        if (sessionStorage.getItem('pointPlayersEarnIncome')) {
            try {
                var pointPlayersEarnIncome = sessionStorage.getItem('pointPlayersEarnIncome');
                if (pointPlayersEarnIncome === '0') {
                    return false;
                }
                else {
                    return true;
                }
            }
            catch (e) { }
        }
        return false;
    };
    UsersPointService.prototype.getGroupId = function () {
        return sessionStorage.getItem(this.groupIdName) ? sessionStorage.getItem(this.groupIdName) : '';
    };
    UsersPointService.prototype.setGroupId = function (value) {
        sessionStorage.setItem(this.groupIdName, String(value));
    };
    UsersPointService.prototype.setPointName = function (name) {
        if (name) {
            this.storage.setItem(this.pointName, name);
        }
        else {
            this.storage.removeItem(this.pointName);
        }
    };
    UsersPointService.prototype.getPointName = function () {
        if (sessionStorage.getItem(this.pointName)) {
            try {
                return sessionStorage.getItem(this.pointName);
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.getRiverPayNumber = function () {
        if (sessionStorage.getItem(this.keyName)) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem(this.keyName));
                return currentUser.river_pay_account_id;
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.setGameVendorShortName = function (name) {
        this.storage.setItem('gameVendorShortName', name);
    };
    UsersPointService.prototype.getGameVendor = function () {
        return this.storage.getItem('gameVendorShortName');
    };
    UsersPointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersPointService_Factory() { return new UsersPointService(); }, token: UsersPointService, providedIn: "root" });
    return UsersPointService;
}());
export { UsersPointService };
