/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./prizes-parent.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../prizes-senetpro/prizes-senetpro.component.ngfactory";
import * as i3 from "../prizes-senetpro/prizes-senetpro.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/router";
import * as i6 from "../../service/loader.service";
import * as i7 from "../../shared/popup/popup.component";
import * as i8 from "../../service/styling.service";
import * as i9 from "../../service/pageScroll/paginationScroll.service";
import * as i10 from "../../service/dom/DOMManipulations.service";
import * as i11 from "../../service/games/gamesProvider.service";
import * as i12 from "../../service/settings/settings.service";
import * as i13 from "../../service/date.service";
import * as i14 from "../../service/prizes/prizesSenetPro.service";
import * as i15 from "../../service/prizes/prizes.service";
import * as i16 from "../prizes/prizes.component.ngfactory";
import * as i17 from "../prizes/prizes.component";
import * as i18 from "../../service/kiosk/kiosk-accessor.service";
import * as i19 from "../../service/kiosk-scroll.service";
import * as i20 from "@angular/common";
import * as i21 from "./prizes-parent.component";
import * as i22 from "../../service/partner.service";
var styles_PrizesParentComponent = [i0.styles];
var RenderType_PrizesParentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrizesParentComponent, data: {} });
export { RenderType_PrizesParentComponent as RenderType_PrizesParentComponent };
function View_PrizesParentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prizes-senetpro", [], [[40, "@slideToRight", 0]], null, null, i2.View_PrizesSenetproComponent_0, i2.RenderType_PrizesSenetproComponent)), i1.ɵdid(1, 114688, null, 0, i3.PrizesSenetproComponent, [i4.FormBuilder, i5.ActivatedRoute, i1.ElementRef, i6.LoaderService, i7.PopupComponent, i8.StylingService, i9.PaginationScrollService, i10.DOMManipulationsService, i11.GamesProviderService, i12.SettingsService, i13.DateService, i14.PrizesSenetProService, i15.PrizesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_PrizesParentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prizes", [], [[40, "@slideToRight", 0]], null, null, i16.View_PrizesComponent_0, i16.RenderType_PrizesComponent)), i1.ɵdid(1, 114688, null, 0, i17.PrizesComponent, [i4.FormBuilder, i5.ActivatedRoute, i1.ElementRef, i6.LoaderService, i7.PopupComponent, i8.StylingService, i9.PaginationScrollService, i10.DOMManipulationsService, i18.KioskAccessorService, i19.KioskScrollService, i11.GamesProviderService, i12.SettingsService, i15.PrizesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_PrizesParentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PrizesParentComponent_1)), i1.ɵdid(1, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["prizesInitial", 2]], null, 0, null, View_PrizesParentComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPartnerTypeSingleBalance(); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PrizesParentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-prizes-parent", [], null, null, null, View_PrizesParentComponent_0, RenderType_PrizesParentComponent)), i1.ɵdid(1, 114688, null, 0, i21.PrizesParentComponent, [i22.PartnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrizesParentComponentNgFactory = i1.ɵccf("app-prizes-parent", i21.PrizesParentComponent, View_PrizesParentComponent_Host_0, {}, {}, []);
export { PrizesParentComponentNgFactory as PrizesParentComponentNgFactory };
