import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../../modules/auth/_services/authentication.service";
import {GamesProviderService} from "../../../../service/games/gamesProvider.service";
import {UsersPointService} from "../../../../service/users/users-point.service";
import {KioskScrollService} from "../../../../service/kiosk-scroll.service";
import {KioskService} from "../../../../service/kiosk.service";
import {KioskPaymentService} from "../../../../service/kiosk/kiosk-payment.service";
import {PROJECT_CONFIG} from "../../../../config/project";
import {KioskAccessorService} from "../../../../service/kiosk/kiosk-accessor.service";
import {BehaviorSubject} from "rxjs/index";
import {KIOSK_CRYPTO} from "../../../../config/kiosk_config";
import {GAME_VENDORS} from "../../../../config/gameVendors";
import {PartnerService} from "../../../../service/partner.service";
import {TerminalService} from "../../../../service/terminal/terminal.service";


@Component({
    selector: 'app-kiosk-payment',
    templateUrl: './kiosk-payment.component.html',
    styleUrls: ['./kiosk-payment.component.scss'],
})
export class KioskPaymentComponent implements OnInit {
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
        this._showPopup = value;
        if (value) {
            if (this.isRiverPay) {
                this.gamesProviderService.connectRiver().subscribe(val => {
                    if (val && val.status && val.userPointId) {
                        this.usersPointService.setUserPointId(val.userPointId);
                        this.usersPointService.setGameVendorShortName(val.gameVendorShortName);

                        this.getBalance();
                    } else if (val.status === false) {
                        this.popupComponent.showPopupError({text: val.message})
                    } else {
                        this.loaderService.hideAndShowTryAgain();
                    }
                });
            }else{
                this.getBalance();
            }
        }
    }

    @Input() cryptoToBuy?;

    get showPopup() : boolean  {
        return this._showPopup;
    }

    @Input() type;
    @Input() data;

    // private _isBackBtnToTypeList: boolean;
    @Input() set isBackBtnToTypeList(value: boolean) {
        this.isBackBtn = value || (this.terminalService.isGames() && this.terminalService.isCrypto());
        console.log('payment', 'is Back btn', this.isBackBtn, this.isBackBtnToTypeList);
    }

    @Output() close = new EventEmitter();
    @Output() buySuccess = new EventEmitter();

    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;
    KIOSK_CRYPTO = KIOSK_CRYPTO;
    GAME_VENDORS = GAME_VENDORS;

    amount = 0;
    progressRiverExecs = 0;
    progressRiverExecsMax = 0;

    paymentTypes = PROJECT_CONFIG.terminalPaymentType;
    isRiverPay: boolean;
    isBackBtn = false;

    constructor(private kioskService: KioskService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private kioskAccessorService: KioskAccessorService,
                private partnerService: PartnerService,
                private gamesProviderService: GamesProviderService,
                private usersPointService: UsersPointService,
                private terminalService: TerminalService,
                private kioskPaymentService: KioskPaymentService) {
        this.kioskAccessorService.paymentAmountChanged.subscribe(val => {
            if (val) {
                // this.amount = val;
                this.getBalance();
            }
        });
        this.partnerService.isRiverPayTerminalPartner.subscribe(val => {
            this.isRiverPay = val;
        });
    }

    ngOnInit() {

    }

    closePopup() {
        // if (!this.isRiverPay) {
            this.close.emit(this.type);
        // }
        this.kioskService.deactivateBilling();
    }

    getBalance() {
        this.kioskPaymentService.getBalance().subscribe(data => {
            this.getBalanceResult(data);
        }, () => {
            this.showPopup = false;
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getBalanceResult(data) {
        this.kioskService.addPaymentType(this.type);
        try {
            if(data.status === true){
                this.amount = data.result;

                this.progressRiverExecs = data.river_execs_current;
                this.progressRiverExecsMax = data.river_execs_max;
            }
        } catch (e) {}

        this.kioskService.activateBilling();
    }

    onBuyCrypto() {
        this.kioskAccessorService.completeCryptoBuy(this.data.currencyIdTo, this.closePopup.bind(this));
    }

    onBuySweeps() {
        this.kioskAccessorService.completeSweepsBuy(this.closePopup.bind(this));
    }

    loadDefaultCurrencyIcon($event) {
        const el = $event.target;
        el.src = '../../../../assets/images/currencies/'+this.DEFAULT_CRYPTO_ICON;
    }

    isSweepsType() {
        return this.type === this.paymentTypes.sweeps;
    }

    isCryptoType() {
        return this.type === this.paymentTypes.crypto;
    }

    setPrimaryStyle() {
        return this.isCryptoType() && this.data.nameTo.toLowerCase() === 'eth' ||
          this.isSweepsType() && this.data && this.data.short_name === this.GAME_VENDORS.mascotCasino;
    }

    setSecondaryStyle() {
        return this.isCryptoType() && this.data.nameTo.toLowerCase() === 'btc';
    }

    getProgressWidth(current, max) {
        return current/max * 100 + '%';
    }
}

