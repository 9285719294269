export const SCRATCH_OFF_RULES = [
  'We give away three no loss bonus entries per day. Each entry is 5.00 credits',
  'Wins range from 10.00 to 100.00',
  'All wins are applied to any game of your choosing in the from of Free Rounds',
  'Regardless of your win or loss, 5.00 is always deposited to your balance',
  'Every game ticket has a winning symbol - Find the piggy!',
  'There is only one shot to find it and to win',
  'Good Luck!'
  ]

export const SCRATCH_OFF_WELCOME_RULES = [
  'All rewards are claimed online only',
  'NO Cash Payouts at this location',
  'To Claim a rewards, install the app',
  'The installation link has already been texted to you',
  'Or, you can scan the QR code on this page to install the app'
]

export const SCRATCH_OFF_POPUP_TYPE = {
  welcome: 'welcome',
  pigRules: 'pigRules'
}
