import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {PaypalService} from "../../../../service/settings/paypal.service";
import {AddressesService} from "../../../../service/settings/addresses.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {StylingService} from "../../../../service/styling.service";

@Component({
    selector: 'app-edit-account',
    templateUrl: './edit-account.component.html',
    styleUrls: ['./edit-account.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class EditAccountComponent implements OnInit {
    editAccountForm: FormGroup;
    email;
    emailId;
    constructor(private formBuilder: FormBuilder,
                private paypalService: PaypalService,
                private loaderService: LoaderService,
                private router: Router,
                private route: ActivatedRoute,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {
        this.editAccountForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.pattern('^[0-9A-Za-z._%+-]+@[0-9A-Za-z.-]+\\.[a-zA-Z]{2,4}$')]],
        });
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.emailId = params.id;

            this.getAccountsList(this.emailId);
        });
    }

    getAccountsList(id) {
        if (id) {
            this.paypalService.find(id).subscribe(data => {
                this.setEditingEmail(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            })
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setEditingEmail(data) {
        if (data.status === true && data.result && data.result.email) {
            this.setAccount(data.result.email)
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text: data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        const email = this.getAccount();

        console.log(this.emailId, email.trim())


        if (this.emailId && email.trim()) {
            this.paypalService.editAccount(this.emailId, email).subscribe(data => {
                this.onSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitResult(data) {
        if(data.status === true){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.editAccountForm.controls.email.setValue('');
            this.router.navigate([this.getBackLink()]);
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onInput($event) {
        this.editAccountForm.get('email').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getBackLink() {
        return RouterPaths.settings.paypal.main;
    }

    goBack() {
        this.editAccountForm.controls.address.setValue('');
        this.router.navigate([RouterPaths.settings.paypal.main]);
    }

    setAccount(email) {
        if (email && email.trim()) {
            this.editAccountForm.controls.email.setValue(email);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getAccount() {
        return this.editAccountForm.controls.email.value;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
