import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    constructor() {
    }

    getWeekDayName(date?) {
        let d;
        if (date) {
            d = new Date(date);
        } else {
            d = new Date();
        }
        return  this.days[d.getDay()];
    }

    getDateSafariSupport(date) {
        if (date) {
            return date.replace(/\s/g, 'T');
        } else {
            return '';
        }
    }

    msToTime(duration: number): string {
        let seconds: string|number = parseInt(String((duration/1000)%60));
        let minutes: string|number = parseInt(String((duration/(1000*60))%60));
        let hours: string|number = parseInt(String((duration/(1000*60*60))%24));
        const days: string|number = parseInt(String(duration/(1000*60*60*24)));
        const daysTitle = days === 1 ? 'day' : "days";
        hours = (hours < 10) ? "0" + String(hours) : hours;
        minutes = (minutes < 10) ? "0" + String(minutes) : minutes;
        seconds = (seconds < 10) ? "0" + String(seconds) : seconds;

        return days?(days + " " + daysTitle + " " + hours + ":" + minutes + ":" + seconds):(hours + ":" + minutes + ":" + seconds);
    }
}
