<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner  js-prizes-parent" *ngIf="popupInfo.show">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
<!--        <div class="investments-popup__content">-->
            <div class="dropdown" style="padding-bottom: 0">
<!--                <div class="dropdown__head" style="margin-bottom: 0">-->
<!--                    <div class="dropdown__title c-accent" style="text-transform: uppercase; text-align: center">-->
<!--                        TRANSACTIONS-->
<!--                    </div>-->
<!--                </div>-->
                <div class="dropdown__body dropdown__body--prizes" #scrolledEl (scroll)="scroll($event, prizesData)">
                    <app-senetpro-transactions [items]="prizesData.items" [isStickyHeader]="false"></app-senetpro-transactions>
                </div>
            </div>
<!--        </div>-->

    </div>
</div>
