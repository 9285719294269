import { OnInit } from '@angular/core';
import { RouterPaths } from "../../config/router.paths.model";
import * as i0 from "@angular/core";
import * as i1 from "../../service/loader.service";
import * as i2 from "../../service/partner.service";
import * as i3 from "../../modules/auth/_services/authentication.service";
import * as i4 from "@angular/router";
var PartnerComponent = /** @class */ (function () {
    function PartnerComponent(loaderService, partnerService, auth, router) {
        this.loaderService = loaderService;
        this.partnerService = partnerService;
        this.auth = auth;
        this.router = router;
    }
    PartnerComponent.prototype.ngOnInit = function () {
        this.loaderService.show();
        this.detectPartner();
    };
    PartnerComponent.prototype.detectPartner = function (callback) {
        var _this = this;
        if (!this.auth.isUserLogged()) {
            this.auth.clearStorage();
            //alert('getPartner');
            this.partnerService.getPartner().subscribe(function (data) {
                if (data.status) {
                    _this.partnerService.savePartner(data);
                    if (_this.partnerService.isMascotSingle()) {
                        _this.router.navigate([RouterPaths.mascot.main], { queryParams: { notLogged: true } });
                    }
                    else {
                        if (callback) {
                            callback();
                        }
                        else {
                            //this.router.navigate([RouterPaths.login]);
                        }
                    }
                }
                else {
                    _this.router.navigate(['/']);
                }
            }, function (data) {
                _this.loaderService.hideAndShowTryAgain();
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    PartnerComponent.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerComponent_Factory() { return new PartnerComponent(i0.ɵɵinject(i1.LoaderService), i0.ɵɵinject(i2.PartnerService), i0.ɵɵinject(i3.AuthenticationService), i0.ɵɵinject(i4.Router)); }, token: PartnerComponent, providedIn: "root" });
    return PartnerComponent;
}());
export { PartnerComponent };
