import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SLOTS_RESPONSES_CONFIG} from "../../../../config/api";
import {timer} from "rxjs";
import {mergeMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SlotsResponsesService {
  private config = SLOTS_RESPONSES_CONFIG;

  constructor(private http: HttpClient) {
  }

  initGame() {
    // return this.http.post<any>(this.config.initGame, {});
    // return timer(500).pipe(
    //   mergeMap(() => this.http.post<any>(this.config.initGame, {}))
    // );

      return timer(500).pipe();
  }
}
