import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "./../settingsLoader.service";
import {LoaderService} from "./../loader.service";
import {COINS_TRADE_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class CoinsTradeService extends SettingsLoaderService {
    private config = COINS_TRADE_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getData() {
        //this.showLoader();
        return this.http.post<any>(this.config.info, {});
    }
}
