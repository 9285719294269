<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
        <div class="investments-popup__scrolled">
            <div class="investments-popup__title-pay">

                <p *ngIf="popupInfo.type !== PRIZES_METHODS.paypal && popupInfo.type !== PRIZES_METHODS.venmo &&
                          popupInfo.type !== PRIZES_METHODS.card && !isSendToFriendPopup() && !isOneOfGiftCards()">
                    {{popupInfo.popupTitle}}
                </p>

                <p *ngIf="isSendToFriendPopup() && this.sendToFriendStep === 1">
                    Please enter your friend phone number
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                    Please enter the email account registered with your PayPal. Only ONE PayPal email is allowed per active account.
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress">
                    Afterhours transfers are done via PayPal. To change your PayPal email, contact support.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
                    Please enter the tag account registered with your Venmo. Only ONE Venmo tag is allowed per active account.
                </p>
                <p *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
                    {{popupInfo.handle | mask: phoneMask}} transfers are aggregated and sent out once per day by 5PM. To change your phone {{popupInfo.handle | mask: phoneMask}}, please contact support.
                </p>

                <p *ngIf="popupInfo.type === PRIZES_METHODS.card">
                    Afterhours transfers are done via your Debit Card {{popupInfo.handle}}.
                </p>

                <p *ngIf="isOneOfGiftCards()">
                    {{popupInfo.giftDescription}}
                </p>

                <div *ngIf="popupInfo.type === PRIZES_METHODS.card">
                    <div class="c-accent"  style="opacity: 1; display: flex; justify-content: space-between; margin: 1rem 0">
                        <div>{{popupInfo.handle}}</div>
                        <button class="edit-card-btn" id="editPopupBtn">
                            edit
                            <svg>
                                <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#pencil'"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                <app-verify-cashapp-tag [isPaypal]="true"  [verifyStatus]="popupInfo.paypalAddress" (onVerify)="setPaypalVerified($event)"></app-verify-cashapp-tag>
            </div>

            <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.venmo && !popupInfo.venmoAddress">
                <app-verify-cashapp-tag [isVenmo]="true" [isVenmoPhone]="true"  [verifyStatus]="popupInfo.venmoAddress" (onVerify)="setVenmoVerified($event)"></app-verify-cashapp-tag>
            </div>

            <form [formGroup]="form"
                  (ngSubmit)="!isSendToFriendPopup() ? onSubmitForm() : sendToFriendStep === 1 ? onSubmitSentToFriendPhone() : onSubmitSentToFriendForm()"
                  *ngIf="(popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress) ||
                     (popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress) ||
                     (popupInfo.type !== PRIZES_METHODS.paypal && popupInfo.type !== PRIZES_METHODS.venmo)"
                  class="form-simple form-simple--shrink form-simple--primary">

                <div *ngIf="isSendToFriendPopup() && sendToFriendStep === 2" style="margin-bottom: 1rem">
                    {{sendFriendInfo.firstName}} {{sendFriendInfo.lastName}}
                </div>

                <div class="form-simple__item"
                     *ngIf="!isSendToFriendPopup() && (popupInfo.type !== PRIZES_METHODS.venmo) && (popupInfo.type !== PRIZES_METHODS.card)">
                    <input formControlName="handle"
                           placeholder="Handle"
                           disabled="true"
                           [type]="'text'"
                           [value]="popupInfo.handle"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.venmo && popupInfo.venmoAddress">
                    <input formControlName="phoneVenmo"
                           placeholder="Phone Number"
                           [mask]="phoneMask"
                           [disabled]="true"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__item" *ngIf="isSendToFriendPopup()">
                    <input formControlName="phone"
                           placeholder="Phone Number"
                           [mask]="phoneMask"
                           [disabled]="sendToFriendStep === 2"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

<!--                <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.games">-->
<!--                    <mat-form-field>-->
<!--                        <mat-select #methodsSelect-->
<!--                                    formControlName="handle"-->
<!--                                    [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">-->
<!--                            <mat-select-trigger>-->
<!--                                {{methodsSelect?.value?.name}}-->
<!--                            </mat-select-trigger>-->
<!--                            <mat-option *ngFor="let method of popupInfo.providers" [value]="method">-->
<!--                                {{method.name}}-->
<!--                            </mat-option>-->
<!--                        </mat-select>-->
<!--                    </mat-form-field>-->
<!--                </div>-->

                <div class="form-simple__item form-simple__item--amount"
                     *ngIf="(isOneOfGiftCards() && popupInfo.giftAmountType === 'range') ||
                     (!isOneOfGiftCards() && (this.sendToFriendStep === 2 || !isSendToFriendPopup()))">
                    <input mask="separator.0"
                           thousandSeparator=","
                           (input)="onInputAmount()"
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                    <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                        Available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                    </div>
                </div>

                <div>
                    <div class="amount-variants" *ngIf="isOneOfGiftCards() && popupInfo.giftAmountType === 'fixed' && popupInfo.giftAmountArray">
                        <button class="amount-variants__item"
                                type="button"
                                [class.is-active]="form.controls.amount.value === item"
                                (click)="selectAmount(item)"
                                *ngFor="let item of popupInfo.giftAmountArray">{{item}}</button>
                    </div>
                </div>


                <div class="form-simple__item"  *ngIf="isOneOfGiftCards()">
                    <input mask="separator.0"
                           (input)="onInputQuantity()"
                           formControlName="quantity"
                           placeholder="Quantity"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <div style="margin-top: 1rem">
                        Total Amount: {{totalAmount | currency : 'USD' : 'symbol'}}
                    </div>
                    <div class="page__form-note page__form-note--qty" [class.is-active]="!isTotalAmountCorrect && +form.controls.quantity.value">
                        Total Amount can't be more than available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                    </div>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched"
                            *ngIf="!isSendToFriendPopup()"
                            [disabled]="+form.controls.amount.value <= 0 || (isOneOfGiftCards() && (!+form.controls.quantity.value || !isTotalAmountCorrect))">
                        Transfer
                        <svg><rect></rect></svg>
                    </button>

                    <button class="button snake-btn is-notouched"
                            *ngIf="isSendToFriendPopup() && sendToFriendStep === 1"
                            [disabled]="!form.controls.phone.value || form.controls.phone.invalid">
                        Continue
                        <svg><rect></rect></svg>
                    </button>

                    <button class="button snake-btn is-notouched"
                            *ngIf="isSendToFriendPopup() && sendToFriendStep === 2"
                            [disabled]="!form.controls.phone.value || form.controls.phone.invalid || !form.controls.amount.value || form.controls.amount.value <= 0">
                        Send
                        <svg><rect></rect></svg>
                    </button>


                </div>
                <div class="form-simple__btns" *ngIf="isSendToFriendPopup() && sendToFriendStep === 2">
                    <button class="button snake-btn is-notouched"
                            type="button"
                            (click)="goToFirstStepSendFriend()">
                        Back
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
