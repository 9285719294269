import {Inject, Injectable, NgZone} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SCRATCH_OFF_CONFIG} from "../../config/api";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class ScratchOffService {
    private config = SCRATCH_OFF_CONFIG;

    constructor(private http: HttpClient, private loaderService: LoaderService) {}

    getGamePaymentInfo() {
        this.loaderService.show();
        return this.http.post<any>(this.config.getGamePaymentInfo, {});
    }

    buyGame(cardId) {
        this.loaderService.show();
        return this.http.post<any>(this.config.buyGame, {cardId});
    }

    createRound() {
        return this.http.post<any>(this.config.createRound, {});
    }

    chooseItem(roundUuid, uuid) {
        // return this.http.post<any>(this.config.item, {hash});

        return this.http.post<any>(this.config.chooseItem, {roundUuid, uuid});

        // return timer(2000).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.chooseItem, {roundUuid, uuid}))
        // );
    }
}
