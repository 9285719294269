import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HomeArticle} from "../../models/home-articles";
import {ActivityItem} from "../../models/activityItem";
import {PROJECT_CONFIG} from "../../config/project";
import {
    SwiperComponent,
    SwiperDirective,
    SwiperConfigInterface,
    SwiperScrollbarInterface,
    SwiperPaginationInterface
} from 'ngx-swiper-wrapper';
import Swiper from "swiper";
import {MascotService} from "../../service/mascot/mascot.service";
import {LoaderService} from "../../service/loader.service";

@Component({
    selector: 'app-slider-test2',
    templateUrl: './slider-test2.component.html',
    styleUrls: ['./slider-test2.component.scss']
})
export class SliderTest2Component implements OnInit {
    slidesDynamic = [];

    slides = [
        'https://picsum.photos/700/250/?image=27',
        'https://picsum.photos/700/250/?image=22',
        'https://picsum.photos/700/250/?image=61',
        'https://picsum.photos/700/250/?image=23',
        'https://picsum.photos/700/250/?image=24',
        'https://picsum.photos/700/250/?image=26',
        'https://picsum.photos/700/250/?image=41',
        'https://picsum.photos/700/250/?image=28',
        'https://picsum.photos/700/250/?image=21',
        'https://picsum.photos/700/250/?image=20',
        'https://picsum.photos/400/250/?image=75'
    ];

    config4 = {
        a11y: true,
        effect: 'slide',
        loop: true,
        initialSlide: 0,
        // thumbs: {
        //     swiper: this.slides
        // },
        spaceBetween: 0,
        simulateTouch: true,
        preloadImages: false,
        observer: true,
        lazy: {
            loadPrevNext: false,
            loadOnTransitionStart: true,
        },
        zoom: {
            maxRatio: 5
        }
    };

    config: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    config2: SwiperConfigInterface = {
        a11y: true,
        direction: 'horizontal',
        loop: true,
        slidesPerView: "auto",
        keyboard: true,
        mousewheel: true,
        scrollbar: false,
        navigation: true,
        pagination: false
    };

    config3: SwiperConfigInterface = {
        // a11y: true,
        direction: 'horizontal',
        slidesPerView: 'auto',
        loop: true,
        // loopedSlides: 17,
        observer: true,
        observeParents: true,
        preventClicks: false,
        initialSlide: 0,
        containerModifierClass: 'looped-',
        // simulateTouch: touchCapable(),
        simulateTouch: true,
        // cssMode: {
        //     simulateTouch: touchCapable(),
        // },
        // shortSwipes: false
        resistanceRatio: 0.6,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        watchOverflow: true,
    }

    constructor(private mascotService: MascotService,
                private loaderService: LoaderService) {
    }

    ngOnInit() {
        this.mascotService.getIcons().subscribe(data => {
            this.setData(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setData(data) {
        console.log(data);
        this.slidesDynamic = data.result;
        // const middle = Math.round(this.games.length / 2);
        //
        // this.slider1 = this.games.slice(0,middle);
        // this.slider2 = this.games.slice(middle);

        //console.log(this.games, this.slider1, )

        // const i = Swiper.prototype.init;
        // (Swiper as any).prototype.init = function () {
        //     this.touchEventsData.formElements = '*';
        //     i.call(this);
        // };

        setTimeout(() => {
            // document.addEventListener( "DOMContentLoaded", function() {
                // it's a div, that holds your news
                // it holds ul with news in li elements
                var divs = document.querySelectorAll('.container');
                divs.forEach((div) => {

                    var ul = div.getElementsByTagName( "ul" )[ 0 ];
                    var li = div.querySelector( "li:last-child" );

                    var new_li = li.cloneNode( true );
                    ul.prepend( new_li );
                    div.scrollLeft = li.clientWidth;

                    div.addEventListener( "scroll", function() {
                        var max_scroll = this.scrollHeight - this.clientHeight;
                        var current_scroll = this.scrollTop;
                        var bottom = 100;
                        if ( current_scroll + bottom >= max_scroll ) {
                            var ul = this.getElementsByTagName( "ul" )[ 0 ];
                            var current = parseInt( ul.dataset.current, 10 );
                            var li = this.getElementsByTagName( "li" )[ current ];
                            var new_li = li.cloneNode( true );
                            ul.appendChild( new_li );
                            ul.dataset.current = `${current + 1 }`;
                        }
                    } );
                });



            });
        // });
    }

    public onIndexChange(index: number): void {
        console.log('Swiper index: ', index);
    }

    public onSwiperEvent(event: string): void {
        console.log('Swiper event: ', event);
    }

}
