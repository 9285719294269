<div class="games-payment mascot-popup"
     [class.is-active]="showPopup"
     [class.is-blur]="showNFTPopup"
     *ngIf="showPopup">

    <button (click)="onClose()"
            class="mascot-popup__close"></button>

    <button (click)="buyGame()"
            class="games-payment__btn"></button>

    <div class="games-payment__inner">
        <div class="page__centered">
            <a id="add_credit_card" [routerLink]="getAddLink()" class="button snake-btn is-notouched">
                ADD NEW CARD
                <svg><rect></rect></svg>
            </a>
        </div>

        <form [formGroup]="buyForm" class="form-simple form-simple--shrink form-simple--primary">
            <div class="saved">
                <div class="saved__title">SAVED PAYMENT METHODS</div>
                <div class="saved__list">
                    <div *ngFor="let method of data.result" class="form-simple__item">
                        <input formControlName="paymentMethod"
                               [id]="'card-'+getPaymentMethod(method)+getMethodId(method)"
                               [type]="'radio'"
                               class="form-simple__radio"
                               [value]="method"
                               [attr.data-id]="getMethodId(method)"
                               name="email"
                               [checked]="activeMethod === method"
                               (change)="changeMethod(method)"
                               autocomplete="off">

                        <label [for]="'card-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>
                    </div>
                </div>

            </div>
        </form>

    </div>

</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [isMascot]="true"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>

<app-iframe-popup *ngIf="iframePopup.show"
                  [url]="iframePopup.url"
                  [isMascot]="true"
                  (onClose)="hideIframePopup($event)"></app-iframe-popup>
