import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SETTINGS_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class SettingsService extends SettingsLoaderService {
    private config = SETTINGS_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    textMe() {
        this.showLoader();
        return this.http.post<any>(this.config.textMe, {});
    }

    verifyCashapp(address, retype) {
        this.showLoader();
        return this.http.post<any>(this.config.verifyCashapp, {address, retype});
    }

    verifyPaypal(email, retype) {
        this.showLoader();
        return this.http.post<any>(this.config.verifyPaypal, {email, retype});
    }

    verifyZelle(address, retype, type) {
        this.showLoader();
        return this.http.post<any>(this.config.verifyZelle, {address, retype, type});
    }

    verifyVenmo(address, retype) {
        this.showLoader();
        return this.http.post<any>(this.config.verifyVenmo, {address, retype});
    }
}
