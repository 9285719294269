import {Component, OnInit, ElementRef, ViewChild, HostBinding,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {FundsService} from "../../../service/settings/funds.service";
import {DomSanitizer} from "@angular/platform-browser";
import {PROJECT_CONFIG, PROJECT_MESSAGES} from "../../../config/project";
import {FundsResultItem} from "../../../models/services/funds/funds.model";
import {RouterPaths} from "../../../config/router.paths.model";
import {StylingService} from "../../../service/styling.service";
import {POPUP_TYPES, showPopupFormType} from "../../investments/investments-popup-form/investments-popup-form.config";

@Component({
    selector: 'app-funds',
    templateUrl: './funds.component.html',
    styleUrls: ['./funds.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': '',
        '[class.is-menu]': 'frame',}
})
export class FundsComponent implements OnInit {
    sellForm: FormGroup;
    methods: Array<FundsResultItem> = [];
    frame = false;
    frameUrl;
    activeMethod;

    cryptoLimit = 900;
    cryptoList;
    showBuyCryptoPopup: showPopupFormType = 'hide';
    popupFormType = POPUP_TYPES.buy;
    popupFormData;

    showNFTPopup = false;
    NFTPopupData;
    NFTPopupFormData;
    resetNftForm = false;

    DEFAULT_CRYPTO_ICON = PROJECT_CONFIG.defaultCryptoIcon;

    @ViewChild('iframe', {static: false}) iframe: ElementRef;

    @HostBinding('class') isAppClass = '';
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    iframePopup = {
        show: false,
        url: null
    }

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private fundsService: FundsService,
                private stylingService: StylingService,
                public sanitizer: DomSanitizer) {
        this.sanitizer = sanitizer;
    }

    ngOnInit() {
        // this.setPageView();
        // this.isAppClass = this.kioskService.isKiosk() ? 'is-app-kiosk' : '';

        // this.getPaymentMethods();

        // this.sellForm = this.formBuilder.group({
        //     amount: [null],
        //     usdc_amount: [null],
        //     method: [null, Validators.required],
        //     crypto: [null, Validators.required]
        // });
        //
        // this.route.queryParams.subscribe(params => {
        //     this.setCryptoActiveOption();
        // });
    }

    showIframePopup(url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        }
    }

    hideIframePopup(result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        }
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            } else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({text: result.message});
                    this.resetNftForm = true;
                } else {
                    this.popupComponent.showPopupError({text: result.message, confirmButtonText: 'OK'})
                }
            }
        }
    }

    submit(type) {
        // let amount = this.getInputValue('amount') ? this.getInputValue('amount') : 0;
        // let usdc_amount = this.getInputValue('usdc_amount') ? this.getInputValue('usdc_amount') : 0;
        // const id = this.activeMethod ? this.activeMethod.id : undefined;
        // let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;

        let amount = this.NFTPopupFormData.amount;
        let usdc_amount = this.NFTPopupFormData.usdc_amount;
        const id = this.NFTPopupFormData.id;
        let paymentMethod = this.NFTPopupFormData.paymentMethod;

        if (type === 'sweeps') {
            usdc_amount = 0;
        } else if (type === 'crypto') {
            amount = 0;
        }

        if (id && paymentMethod) {
            this.fundsService.addFunds(id, paymentMethod, amount, usdc_amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }

    }

    checkSubmitResult(data) {
        let paymentMethod = this.NFTPopupFormData.paymentMethod;
        // data.status = true;
        // data.threeDSecure = true;
        // data.threeDSecureUrl = 'http://192.168.0.102:4200/threedsecure?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        // data.threeDSecureUrl = 'http://192.168.0.102:8888/ebox-client/threedsecure.html?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        if(data.status === true){
            if (data.threeDSecure) {
                if (data.threeDSecureUrl) {
                    this.showIframePopup(data.threeDSecureUrl);
                } else {
                    this.loaderService.hideAndShowTryAgain();
                }
            } else {
                if (this.isCard(paymentMethod)){
                    this.resetNftForm = true;
                    this.popupComponent.showPopupSuccess({text:data.message});
                } else if(this.isPaypal(paymentMethod)){
                    this.resetNftForm = true;

                    var win = window.open(data.link, '_blank');
                    win.focus();
                }
            }
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    isCard(method){
        if(PROJECT_CONFIG.paymentMethodCard == method){
            return true;
        }
        return false;
    }

    isPaypal(method){
        if(PROJECT_CONFIG.paymentMethodPaypal == method){
            return true;
        }
        return false;
    }

    onPopupNFTClose($event) {
        this.showNFTPopup = false;
        if ($event) {
            this.submit('sweeps');
        }
    }

    openNFTPopup($event) {
        this.showNFTPopup = $event.showNFTPopup;
        this.NFTPopupData = $event.data;
        this.NFTPopupFormData = $event.form;
    }

    nftFormIsReseted() {
        this.resetNftForm = false;
    }

    getBackLink() {
        return RouterPaths.settings.home;
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    // scrollToLastMethod() {
    //     this.scrollToService.scrollToEnd('.saved__list');
    // }

    // getPaymentMethods(){
    //     this.fundsService.getPayments().subscribe(data => {
    //         this.setMethods(data);
    //     }, (error) => {
    //         this.loaderService.hideAndShowTryAgain();
    //     }, () => {
    //         this.loaderService.hide();
    //     });
    // }

    // setMethods(data) {
    //     try {
    //         this.sweepsStatus.status = data.game_status;
    //         this.sweepsStatus.error = data.game_error_message;
    //     } catch (e) {
    //
    //     }
    //
    //     if (data.status === true) {
    //         this.methods = data.result;
    //         if(data.crypto) {
    //             this.setCryptoSelect(data.crypto);
    //         }
    //
    //         if(data.cryptoLimit){
    //             this.cryptoLimit = data.cryptoLimit;
    //         }
    //
    //         this.setCheckedMethod();
    //     } else if (data.status === undefined) {
    //         this.loaderService.hideAndShowTryAgain();
    //     }
    // }

    // setCryptoSelect(list) {
    //     this.cryptoList = list;
    //     setTimeout(() => {
    //         this.setCryptoActiveOption();
    //     }, 100);
    // }
    //
    // setCryptoActiveOption() {
    //     if (this.cryptoList) {
    //         const cryptoIdFromTradeTable = this.route.snapshot.queryParams.cryptoId;
    //         const cryptoFromTradeTable = this.cryptoList.find(el => +el.id === +cryptoIdFromTradeTable);
    //         if (cryptoIdFromTradeTable && cryptoFromTradeTable) {
    //             this.selectedCrypto = cryptoFromTradeTable;
    //             this.sellForm.controls.crypto.setValue(cryptoFromTradeTable);
    //         }  else {
    //             this.selectedCrypto = this.cryptoList[0];
    //             this.sellForm.controls.crypto.setValue(this.cryptoList[0]);
    //         }
    //     }
    // }

    // setCheckedMethod(method?) {
    //     if (!this.methods.length) {
    //         this.sellForm.controls.method.setValue(null);
    //         this.activeMethod = null;
    //     } else if (method && this.methods.find(el => el === method)) {
    //         this.sellForm.controls.method.setValue(method);
    //         this.activeMethod = method;
    //     } else {
    //         let method = this.methods[this.methods.length-1];
    //         this.sellForm.controls.method.setValue(method);
    //         this.activeMethod = method;
    //     }
    //     this.scrollToLastMethod();
    // }

    // getInputValue(field) {
    //     if (this.sellForm.controls[field]) {
    //         return this.sellForm.controls[field].value;
    //     }
    // }

    // onSubmit(type) {
    //     let method = this.getInputValue('method');
    //
    //     if (type === 'sweeps') {
    //         if (this.sweepsStatus.status === PROVIDERS_GAME_STATUS.notAvailable) {
    //             this.popupComponent.showPopupError({text: this.sweepsStatus.error});
    //             return;
    //         }
    //     }
    //
    //     if (!this.methods.length) {
    //         this.popupComponent.showPopupError({text: 'Please enter your payment method'});
    //     } else {
    //         if (this.methods.length > 1 && method === null) {
    //             this.popupComponent.showPopupError({text: 'Please select the payment method to use'});
    //         } else {
    //             if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
    //                this.showNoVerifyErrorMessage();
    //             } else {
    //                 this.showConfirmMessage(type);
    //             }
    //         }
    //     }
    // }
    //
    //
    // showNoVerifyErrorMessage() {
    //     if (this.activeMethod && this.activeMethod.last_four) {
    //         const message = this.activeMethod.description ?
    //           `Please verify ${this.activeMethod.description} - ${this.activeMethod.last_four} first.` :
    //           `Please verify card ending in ${this.activeMethod.last_four}.`;
    //
    //         this.popupComponent.showPopupError({text: message});
    //     } else {
    //         this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
    //     }
    //     return;
    // }

    // showConfirmMessage(type) {
    //     const name = this.getPaymentName(this.activeMethod);
    //     const amount = this.getInputValue('amount');
    //     // const usdc_amount = this.getInputValue('usdc_amount');
    //     const crypto = this.getInputValue('crypto');
    //     const gameVendorName = this.gamesProviderService.getActiveProviderName();
    //
    //     if (type === 'sweeps' && name && amount) {
    //         // this.popupComponent.showPopupAgreement({
    //         //       text: `${name} <br><br>NFT / <span style="text-transform: capitalize">${gameVendorName}</span>: ${formatCurrency(amount, 'en','$')}`},
    //         //   (status) => {
    //         //     if (status) {
    //         //         this.submit(type);
    //         //     }
    //         // });
    //
    //         this.fundsService.getDepositInfo(amount).subscribe(data => {
    //             try {
    //                 if (data.status) {
    //                     this.onShowNFTPopup(data);
    //                 } else {
    //                     this.popupComponent.showPopupError({
    //                         text: data.message,
    //                         confirmButtonText:'OK'
    //                     });
    //                 }
    //             } catch (e) {
    //                 this.loaderService.hideAndShowTryAgain();
    //             }
    //         }, (error) => {
    //             this.loaderService.hideAndShowTryAgain();
    //         }, () => {
    //             this.loaderService.hide();
    //         });
    //
    //     } else if (type === 'crypto' && name && crypto) {
    //         const id = this.activeMethod ? this.activeMethod.id : undefined;
    //         let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;
    //
    //         this.showBuyCryptoPopup = 'show';
    //         this.popupFormData = crypto;
    //         this.popupFormData.availableAmount = this.cryptoLimit;
    //         this.popupFormData.paymentMethod = paymentMethod;
    //         this.popupFormData.paymentMethodId = id;
    //
    //         let $this = this;
    //         setTimeout(() => {
    //             (<HTMLElement>document.querySelectorAll('input:not([type=radio]):not([type=checkbox]):not([disabled])')[0]).focus();
    //             $this.keyboardService.setActiveInput();
    //         },0);
    //     } else {
    //         this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
    //     }
    // }

    // getPaymentName(data){
    //     if(data && this.isCard(data.payment_method)){
    //         return data.description+' - '+data.last_four;
    //     }else if(data && this.isPaypal(data.payment_method)){
    //         return 'PayPal - '+data.email;
    //     }
    //
    //     return '';
    // }

    // changeMethod(method){
    //     if (method) {
    //         this.activeMethod = method;
    //     }
    // }

    // showFrame() {
    //     this.frame = true;
    // }

    // getLink(url){
    //     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // }

    // closeFrame() {
    //     this.frame = false;
    // }

    // getPaymentMethod(method) {
    //     const el = new FundsResultItem(method);
    //     return el.payment_method;
    // }

    // getMethodId(method) {
    //     const el = new FundsResultItem(method);
    //     return el.id;
    // }
    //
    // canSubmitSweeps() {
    //     return !+this.sellForm.controls.amount.value;
    // }
    //
    // canSubmitCrypto() {
    //     return !this.sellForm.controls.crypto.value;
    // }

    // canScrollTop() {
    //     return  this.kioskScrollService.canScrollTop(this.scrolledEl);
    // }
    //
    // canScrollBottom() {
    //     return  this.kioskScrollService.canScrollBottom(this.scrolledEl);
    // }
    //
    // scrollTable(top?) {
    //     this.kioskScrollService.scrollTable('.saved__list', top);
    // }

    // setPageView() {
    //     const userGameVendor = this.usersPointService.getGameVendor();
    //
    //     switch (userGameVendor) {
    //         case this.GAME_VENDORS_GOLDENDRAGON: {
    //             this.goldenDragonView = true;
    //             break;
    //         }
    //     }
    // }

    // loadDefaultCurrencyIcon($event) {
    //     const el = $event.target;
    //     el.src = './assets/images/currencies/' + this.DEFAULT_CRYPTO_ICON;
    // }
    //
    // onBuyCryptoPopupClose() {
    //     this.showBuyCryptoPopup = 'hide';
    // }

    // onShowNFTPopup(data) {
    //     const description = this.activeMethod.description;
    //     const number = this.activeMethod.last_four;
    //
    //     data.cardInfo = {
    //         description,
    //         number
    //     }
    //
    //     this.showNFTPopup = true;
    //     this.NFTPopupData = {
    //         type: NFT_POPUP_TYPE.funds,
    //         data: {
    //             element: data
    //         }
    //     }
    // }
}

