<div class="login page-wrapper" [class.is-blurred]="showGamesSelection">
    <div class="hero-wrapper">
        <app-hero-v2></app-hero-v2>
    </div>

    <div class="login__inner">
        <div class="login__col login__top">
            <div class="login__header">
                <img class="login__logo" src="{{getPartnerLogo()}}" alt="">
            </div>

            <div class="login__title-wrapper">
                <div class="login__title">{{getPartnerName()}} <span class="login__title--inner"><span class="capital">M</span>arketplace</span></div>
                <div class="login__slogan">{{getPartnerDescription()}}</div>
            </div>

        </div>
    </div>
</div>

<app-buy-menu [showPopup]="showBuyMenu"
              [pointId]="pointId"></app-buy-menu>


