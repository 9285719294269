import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {PaypalService} from "../../../../service/paypal/paypal.service";
import {LoaderService} from "../../../../service/loader.service";
import {PartnerService} from "../../../../service/partner.service";

@Component({
    selector: 'app-paypal-payments-success',
    templateUrl: './paypal-payments-success.component.html',
    styleUrls: ['./paypal-payments-success.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class PaypalPaymentsSuccessComponent implements OnInit {
    message = 'Loading...';
    partnerBusinessName = '';

    constructor(private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private partnerService: PartnerService,
                private paypalService: PaypalService) {

    }

    ngOnInit() {
        this.partnerBusinessName = this.partnerService.getBusinessName();
        var url = new URL(window.location.href);

        try {
            var paymentId = url.searchParams.get("paymentId");
            var payerId = url.searchParams.get("PayerID");

            if(paymentId && payerId){
                this.loaderService.show();
                this.paypalService.paymentSuccess(paymentId, payerId).subscribe(data => {
                    this.loaderService.hide();

                    try {
                        this.message = data.message;
                    } catch (e) {
                        this.loaderService.hide();
                        this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
                    }
                });
            }else{
                this.message = 'Sorry there was an error try again later.';
            }

        }catch (e) {
            this.message = 'Sorry there was an error try again later.';
        }

    }

    closeWindow(){
        window.open('','_self').close();
    }
}

