<div class="dropdown {{dropdownClass}}" [class.is-hidden]="dropdownClosed"
                      [class.no-body-left-padding]="noBodyLeftPadding">
    <div class="dropdown__head"
         *ngIf="!headTwoColumns; else headTwoColumns"
         (click)="toggleDropdown()">
        <div class="dropdown__title">
            {{title}}
        </div>
        <svg *ngIf="!hideOpenArrow" class="dropdown__arrow" width="21" height="12">
            <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
        </svg>
    </div>
    <ng-template #headTwoColumns>
        <div class="dropdown__head dropdown__head--two-columns"
             (click)="toggleDropdown()">
            <div class="dropdown__title">
                {{title}}
            </div>
            <div>
                <div class="dropdown__title">
                    {{titleRight}}
                </div>
                <svg *ngIf="!hideOpenArrow" class="dropdown__arrow" width="21" height="12">
                    <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                </svg>
            </div>
        </div>
    </ng-template>
    <div class="dropdown__body" *ngIf="!hasPagination; else withPagination">
        <ng-content></ng-content>
    </div>
    <ng-template #withPagination>
        <ng-content></ng-content>
    </ng-template>
</div>
