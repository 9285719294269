import {Inject, Injectable, NgZone} from '@angular/core';


// @Injectable({
//   providedIn: 'root'
// })
export class FireworkService {
  // === CONFIGURATION ===
// Higher number increases rate firework accelerates over time.
  FIREWORK_ACCELERATION = 1.05;
// Minimum firework brightness.
  FIREWORK_BRIGHTNESS_MIN = 50;
// Maximum firework brightness.
  FIREWORK_BRIGHTNESS_MAX = 70;
// Base speed of fireworks.
  FIREWORK_SPEED = 5;
// Base length of firework trails.
  FIREWORK_TRAIL_LENGTH = 3;
// Determine if target position indicator is enabled.
  FIREWORK_TARGET_INDICATOR_ENABLED = true;

  x;
  y;
  startX;
  startY;
  endX;
  endY;
  distanceToEnd;
  distanceTraveled;
  trail;
  trailLength;
  angle;
  speed;
  acceleration;
  brightness;
  targetRadius;

  constructor(startX, startY, endX, endY) {
    // Set current coordinates.
    this.x = startX;
    this.y = startY;
    // Set starting coordinates.
    this.startX = startX;
    this.startY = startY;
    // Set end coordinates.
    this.endX = endX;
    this.endY = endY;
    // Get the distance to the end point.
    this.distanceToEnd = this.calculateDistance(startX, startY, endX, endY);
    this.distanceTraveled = 0;
    // Create an array to track current trail particles.
    this.trail = [];
    // Trail length determines how many trailing particles are active at once.
    this.trailLength = this.FIREWORK_TRAIL_LENGTH;
    // While the trail length remains, add current point to trail list.
    while(this.trailLength--) {
      this.trail.push([this.x, this.y]);
    }
    // Calculate the angle to travel from start to end point.
    this.angle = Math.atan2(endY - startY, endX - startX);
    // Set the speed.
    this.speed = this.FIREWORK_SPEED;
    // Set the acceleration.
    this.acceleration = this.FIREWORK_ACCELERATION;
    // Set the brightness.
    this.brightness = this.random(this.FIREWORK_BRIGHTNESS_MIN, this.FIREWORK_BRIGHTNESS_MAX);
    // Set the radius of click-target location.
    this.targetRadius = 5;
  }

  update(index, fireworks, createParticles) {
    // Remove the oldest trail particle.
    this.trail.pop();
    // Add the current position to the start of trail.
    this.trail.unshift([this.x, this.y]);

    // Animate the target radius indicator.
    if (this.FIREWORK_TARGET_INDICATOR_ENABLED) {
      if(this.targetRadius < 8) {
        this.targetRadius += 0.3;
      } else {
        this.targetRadius = 1;
      }
    }

    // Increase speed based on acceleration rate.
    this.speed *= this.acceleration;

    // Calculate current velocity for both x and y axes.
    let xVelocity = Math.cos(this.angle) * this.speed;
    let yVelocity = Math.sin(this.angle) * this.speed;
    // Calculate the current distance travelled based on starting position, current position, and velocity.
    // This can be used to determine if firework has reached final position.
    this.distanceTraveled = this.calculateDistance(this.startX, this.startY, this.x + xVelocity, this.y + yVelocity);

    // Check if final position has been reached (or exceeded).
    // if(this.distanceTraveled >= this.distanceToEnd) {
      // Destroy firework by removing it from collection.
      fireworks.splice(index, 1);
      // Create particle explosion at end point.  Important not to use this.x and this.y,
      // since that position is always one animation loop behind.
      createParticles(this.endX, this.endY);
    // } else {
    //   // End position hasn't been reached, so continue along current trajectory by updating current coordinates.
    //   this.x += xVelocity;
    //   this.y += yVelocity;
    // }
  }

  draw(context, hue) {
    // Begin a new path for firework trail.
    // context.beginPath();
    // // Get the coordinates for the oldest trail position.
    // let trailEndX = this.trail[this.trail.length - 1][0];
    // let trailEndY = this.trail[this.trail.length - 1][1];
    // // Create a trail stroke from trail end position to current firework position.
    // context.moveTo(trailEndX, trailEndY);
    // context.lineTo(this.x, this.y);
    // // Set stroke coloration and style.
    // // Use hue, saturation, and light values instead of RGB.
    // context.strokeStyle = 'transparent';
    // // context.strokeStyle = 'red';
    // // context.strokeStyle = `hsl(${hue}, 100%, ${this.brightness}%)`;
    // // Draw stroke.
    // context.stroke();

    if (this.FIREWORK_TARGET_INDICATOR_ENABLED) {
      // Begin a new path for end position animation.
      context.strokeStyle = `hsl(${hue}, 100%, ${this.brightness}%)`;
      // context.beginPath();
      // // Create an pulsing circle at the end point with targetRadius.
      //
      // // context.arc(this.endX, this.endY, this.targetRadius, 0, Math.PI * 2);
      // // Draw stroke.
      // context.stroke();
    }
  }

  calculateDistance(aX, aY, bX, bY) {
    let xDistance = aX - bX;
    let yDistance = aY - bY;
    return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
  }

  random(min, max) {
    return Math.random() * (max - min) + min;
  }
}
