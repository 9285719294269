import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {RouterPaths} from "../../config/router.paths.model";
import {TransactionsService} from "../../service/transactions/transactions.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";

@Component({
    selector: 'app-iframe-popup',
    templateUrl: './iframe-popup.component.html',
    styleUrls: ['./iframe-popup.component.scss']
})
export class IframePopupComponent implements OnInit {

    @Input() url;
    @Input() isMascot = false;
    @Output('onClose')
    emitClose: EventEmitter<any> = new EventEmitter();

    safeUrl;
    @ViewChild('iframeParent', {static: false}) iframeParent: ElementRef;
    constructor(private sanitizer: DomSanitizer,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private transactionsService: TransactionsService) {

    }

    ngOnInit() {
        this.safeUrl = this.getUrl();
        this.handleIframeMessage();
    }

    ngAfterViewInit() {
        var iframe = document.createElement('iframe');
        iframe.src = this.url;
        this.iframeParent.nativeElement.appendChild(iframe);
    }

    handleIframeMessage() {
        var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
        var eventer = window[eventMethod];
        var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
        eventer(messageEvent, (e) => {
            if (e.origin === window.location.origin) {

                console.log('gggggggggg');
                console.log(e.data);
                console.log(e.data.substring(0, 3));
                if(e.data.substring(0, 3) == 'pi_'){
                    this.transactionsService.confirmation(e.data).subscribe(data => {
                        try {
                            if (this.isMascot) {
                                this.emitClose.emit(data);
                                return;
                            }

                            const status = data.status;
                            const message = data.message;

                            if ((status || status === false) && message) {
                                this.emitClose.emit({status: status, message: message});
                            } else {
                                this.loaderService.hideAndShowTryAgain();
                            }
                        } catch (e) {
                            this.loaderService.hideAndShowTryAgain();
                        }
                    }, () => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    })
                }
            }
        }, false);
    }

    getUrl() {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    closePopup(result?) {
        this.emitClose.emit(result);
    }
}
