import {Injectable} from '@angular/core';
import {PROJECT_CONFIG} from "../../config/project";

@Injectable({
    providedIn: 'root'
})
export class UsersPointService {
    private storage = sessionStorage;
    private keyName = 'userPoint';
    private groupIdName = 'userPointGroupId';
    private pointName = 'pointName';
    private keyPointHash = 'pointHash';

    constructor() {

    }

    saveHashInStorage(hash){
        this.storage.setItem(this.keyPointHash, hash);
    }

    getHash(){

        if (sessionStorage.getItem(this.keyPointHash)) {
            try {
                return sessionStorage.getItem(this.keyPointHash);
            }catch (e) {

            }
        }

        return '';
    }

    isPointSubdomain(){
        if(this.getSubdomain() != ''){
            if(PROJECT_CONFIG.pointSubdomainEbox != this.getSubdomain() && PROJECT_CONFIG.pointSubdomainEboxDemo != this.getSubdomain()){
                return true;
            }
        }

        return false;
    }

    getSubdomain(){
        var domain = window.location.hostname;
        var parts = domain.split('.');
        parts.pop();
        parts.pop();

        //alert(this.usersPointService.getSubdomain());

        return parts.join('.');
    }

    setUserPointId(id){
        this.storage.setItem('userPointId', id);
    }

    getUserPointId(){
        return this.storage.getItem('userPointId');
    }

    setAccount(data){
        this.storage.setItem(this.keyName, JSON.stringify(data));
    }

    setPointPlayersEarnIncome(id){
      if (id) {
        this.storage.setItem('pointPlayersEarnIncome', id);
      } else {
        this.storage.removeItem('pointPlayersEarnIncome');
      }
    }

    isPointPlayersEarnIncome(){
        if (sessionStorage.getItem('pointPlayersEarnIncome')) {
            try {
                const pointPlayersEarnIncome = sessionStorage.getItem('pointPlayersEarnIncome');
                if(pointPlayersEarnIncome === '0'){
                    return false;
                } else {
                  return true;
                }
            }catch (e) {}
        }

        return false;
    }

    getGroupId(): string {
        return sessionStorage.getItem(this.groupIdName) ? sessionStorage.getItem(this.groupIdName) : '';
    }

    setGroupId(value: number): void {
        sessionStorage.setItem(this.groupIdName, String(value));
    }


    setPointName(name):void {
      if (name) {
        this.storage.setItem(this.pointName, name);
      } else {
        this.storage.removeItem(this.pointName);
      }
    }

    getPointName():string {
        if (sessionStorage.getItem(this.pointName)) {
            try {
                return sessionStorage.getItem(this.pointName);
            }catch (e) {

            }
        }

        return '';
    }

    getRiverPayNumber(){
        if (sessionStorage.getItem(this.keyName)) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem(this.keyName));

                return currentUser.river_pay_account_id;
            }catch (e) {

            }
        }

        return '';
    }

    setGameVendorShortName(name){
        this.storage.setItem('gameVendorShortName', name);
    }
    getGameVendor(){
        return this.storage.getItem('gameVendorShortName');
    }


}
