import {Component, OnInit, ElementRef} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PrizesService} from "../../service/prizes/prizes.service";
import {StylingService} from "../../service/styling.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {DOMManipulationsService} from "../../service/dom/DOMManipulations.service";
import {DEBIT_CARD_STATUS} from "../prizes/prizes.config";
import {PROJECT_MESSAGES} from "../../config/project";
import {formatCurrency, formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../config/crypto_settings";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {SettingsService} from "../../service/settings/settings.service";
import {GAME_PROVIDER_ERRORS_CODE} from "../../service/games/gameProviderErrorsCode.config";
import {PrizesSenetProService} from "../../service/prizes/prizesSenetPro.service";
import {PRIZES_SENETPRO_METHODS} from "./prizesSenetPro.config";
import {WITHDRAW_TRANSACTION_TYPES} from "../../config/withdraw/transactionsTypes.config";
import {PrizesSenetproMethodsModel} from "./prizes-senetpro-methods.model";
import {PrizesSenetproDataModel} from "./prizes-senetpro-data.model";
import {PrizesSenetproHistoryModel} from "./prizes-senetpro-history.model";
import {DateService} from "../../service/date.service";

@Component({
    selector: 'app-prizes-senetpro',
    templateUrl: './prizes-senetpro.component.html',
    styleUrls: ['./prizes-senetpro.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class PrizesSenetproComponent implements OnInit {
    availableBalance = 0;
    gameBalance = 0;

    availableMethods: PrizesSenetproMethodsModel[] = [];

    PRIZES_METHODS = PRIZES_SENETPRO_METHODS;
    WITHDRAW_TRANSACTION_TYPES = WITHDRAW_TRANSACTION_TYPES;
    DEBIT_CARD_POPUP_STEPS = {main: 1, additionInfo: 2};

    // FORM
    form: FormGroup;
    games = [];
    gameVendorName = this.gamesProviderService.getActiveProviderName();
    selectedMethod;
    activeProvider;

    paypalVerified = { handle: '', status: false };
    venmoVerified = { handle: '', status: false };
    debitCardSelectedName = '';

    popupConfirmInfo: any = {show: false, totalDebit: 0};
    popupDebitCardInfo: any = {show: false};
    popupTransactions = {show: false};

    redeemDescription = '';
    gameDescriptionTemplate = '';
    gameDescription = '';
    withdrawalDescription = '';
    withdrawText = '';
    noteText = '';
    descriptionMethodNotAvailable = '';

    intervalTimer = null;

    editCardBtnId = 'editPopupBtn';
    showMoreTransactionsBtn = false;
    transactionsList: PrizesSenetproHistoryModel[] = [];
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private paginationScrollService: PaginationScrollService,
                private DOMManipulationsService: DOMManipulationsService,
                private gamesProviderService: GamesProviderService,
                private settingsService: SettingsService,
                private dateService: DateService,
                private prizesSenetProService: PrizesSenetProService,
                private prizesService: PrizesService) {}

    ngOnInit() {
        this.setForms();
        this.setEditCardEventHandler();
        this.getPrizesInfo();
    }

    setForms() {
      this.form = this.formBuilder.group({
        method: [null, Validators.required]
      });
    }

    setEditCardEventHandler(): void {
      document.body.addEventListener( 'click', ( event ) => {
        if( event.target && event.target['id'] == this.editCardBtnId ) {
          this.popupComponent.closePopup();
          this.closePopupConfirm();
          this.showDebitCardPopup(this.DEBIT_CARD_POPUP_STEPS.main);
        }
      });
    }
    getPrizesInfo(noGameChange = false, onlyDebitCard = false){
        this.prizesSenetProService.getPageInfo(1, false).subscribe(data => {
            this.setPrizesInfo(data, noGameChange, onlyDebitCard);
        },
        () => { this.loaderService.hideAndShowTryAgain(); },
        () => { this.loaderService.hide(); }
        );
    }

    setGamesProviders(providers, noGameChange: boolean): void {
        if (!noGameChange) {
            this.games = providers;
            this.gamesProviderService.setProviders(this.games);

            this.activeProvider = this.gamesProviderService.getActiveProvider(this.games);
            this.gameVendorName = this.gamesProviderService.getActiveProviderName();

            if(this.activeProvider === undefined){
                if(providers[0]){
                    this.onProviderChange(providers[0]);
                }
            }else{
                this.selectedMethod = this.activeProvider;
                this.form.controls.method.setValue(this.selectedMethod);
            }
            this.gameDescription = this.gameDescriptionTemplate.replace('{game}', this.activeProvider.name);
        }
    }

    setPrizesInfo(data: PrizesSenetproDataModel, noGameChange: boolean, onlyDebitCard = false): void {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
            this.intervalTimer = null;
        }

        this.redeemDescription = data.description;
        this.gameDescriptionTemplate = data.descriptionGame;
        this.withdrawalDescription = data.descriptionBalance;
        this.withdrawText = data.descriptionWithdraw;
        this.noteText = data.descriptionNote;
        this.descriptionMethodNotAvailable = data.descriptionMethodNotAvailable;

        this.gameBalance = data.gameBalance;
        this.availableBalance = data.availableBalance;

        if (onlyDebitCard) {
            return;
        }

        if (data.games.length) {
            this.setGamesProviders(data.games, noGameChange);
        }

        this.setMethods(data.methods);
        this.setTransactionsList(data.history, data.limit, data.total);
    }

    setTransactionsList(history: PrizesSenetproHistoryModel[], limit: number, total: number): void {
      this.showMoreTransactionsBtn = total > limit;
      this.transactionsList = history;
    }

    setMethods(methods: PrizesSenetproMethodsModel[]): void {
      this.availableMethods = methods;
      this.availableMethods.forEach(method => {
        if (method.disableTime && method.disable && this.isDisableTimeActual(method.disableTime)) {
          if (this.intervalTimer === null) {
            this.intervalTimer = setInterval(() => {
              this.availableMethods.forEach((item) => {
                if (item.disableTime && item.disable && this.isDisableTimeActual(item.disableTime)) {
                  // const timeValue = ' <span>' + this.getChannelComment(item) + '</span> ';
                  // item.description = this.descriptionMethodNotAvailable.replace('{time}', timeValue);

                  item.descriptionDisable = this.getChannelComment(item);
                  // item.leftTime = this.getChannelComment(item);
                }
              });
              // console.log(Math.random(), this.intervalTimer);
            }, 1000);
          }
        } else if (method.disableTime && method.disable && !this.isDisableTimeActual(method.disableTime)) {
            method.descriptionDisable = this.getChannelComment(method);
            method.disable = false;
            method.disableTime = "";
        }
      });
    }

    setPaypalStatus(data) {
        // data.paypalEmail = 'test@gmal.com';
        // data.paypalEmailStatus = true;

        this.paypalVerified.status = data.status;
        this.paypalVerified.handle = data.email;
    }

    setVenmoStatus(data) {
        this.venmoVerified.status = data.status;
        this.venmoVerified.handle = data.phone;
    }

    getDate(date) {
        if(date == null){
           return '';
        }
        return date.replace(/\s/g, 'T');
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    onSubmitRedeem(amount) {
        const method = this.form.controls.method.value.short_name;
        const methodName = this.form.controls.method.value.name;

        if (!amount || !method) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `Redeem $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00 from ${methodName}?`;
            this.showPopupConfirmCancel(text, this.sendRedeem.bind(this, amount));
        }
    }

    sendRedeem(amount){
        this.prizesSenetProService.redeem(amount).subscribe(data => {
            this.displaySubmitPayoutResult(data);
          },
          () => { this.loaderService.hideAndShowTryAgain();},
          () => {this.loaderService.hide()}
        );
    }

    displaySubmitPayoutResult(data) {
        try {
            if (data.status) {
                this.popupComponent.showPopupSuccess({text: data.message});
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
            this.getPrizesInfo(true);
            this.closePopupConfirm();
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmitVenmoForm(amount, handle: string): void {
        if (amount && handle) {
            const text = `You are about to redeem ${formatCurrency(amount, 'en','$')} to your Venmo tag ${handle}. Please Confirm`;
            this.showPopupConfirmCancel(text, this.submitRedeemViaVenmoTag.bind(this, amount, handle));
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    submitRedeemViaVenmoTag(amount, handle): void {
      this.prizesService.redeemViaVenmoTag(amount, handle).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide(); }
      );
    }

    onSubmitDebitCardForm(amount, handle: string): void {
        if (amount && handle) {
            const text = `You are about to redeem ${formatCurrency(amount, 'en','$')} to your Debit Card ${handle}. Please Confirm`;
            this.showPopupConfirmCancel(text, this.submitRedeemViaDebitCard.bind(this, amount));
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitRedeemViaDebitCard(amount): void {
      this.prizesService.redeemViaDebitCard(amount).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitWithdrawForm(amount, handle: string, shortName: string): void {
        if (amount && handle && shortName) {
            const text = `You are about to withdraw ${formatCurrency(amount, 'en','$')} to ${handle}. Please Confirm`;
            this.showPopupConfirmCancel(text, this.submitWithdrawForm.bind(this, shortName, amount));
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitWithdrawForm(shortName: string, amount): void {
      this.prizesSenetProService.withdraw(shortName, amount).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitGiftCardVisaForm(amount, handle, qty): void {
        if (amount && handle && qty) {
          const text = `You are about to redeem ${formatCurrency(amount*qty, 'en','$')} to Visa Gift Card for ${handle}. Please Confirm`;
          this.showPopupConfirmCancel(text, this.submitRedeemViaVisaGiftCard.bind(this, amount, qty));
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitRedeemViaVisaGiftCard(amount, qty): void {
      this.prizesService.redeemViaVisaGiftCard(amount, qty).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    onSubmitGiftCardAmazonForm(amount, handle, qty) {
        if (amount && handle && qty) {
            const text = `You are about to redeem ${formatCurrency(amount*qty, 'en','$')} to Amazon Gift Card for ${handle}. Please Confirm`;
            this.showPopupConfirmCancel(text, this.submitRedeemViaAmazonGiftCard.bind(this, amount, qty));
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitRedeemViaAmazonGiftCard(amount, qty) {
      this.prizesService.redeemViaAmazonGiftCard(amount, qty).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      },
        () => { this.loaderService.hideAndShowTryAgain() },
        () => { this.loaderService.hide() }
      );
    }

    showPopupConfirm(type, name = '') {
        document.body.classList.add('hide-menu');
        const handle = type === this.PRIZES_METHODS.games ? this.form.controls.method.value.name :
                                type === this.PRIZES_METHODS.card ? this.debitCardSelectedName :
                                type === this.PRIZES_METHODS.paypal ? this.paypalVerified.handle :
                                type === this.PRIZES_METHODS.venmo ? this.venmoVerified.handle :
                                  name ? name : type;

        const maxAmountAvailable = type === this.PRIZES_METHODS.games ? this.gameBalance : this.availableBalance;
        const popupTitle = type === this.PRIZES_METHODS.games ? this.gameDescription :
                           this.isDefaultPopup(type) ? this.gameDescriptionTemplate.replace('{game}', handle) : null;

        if (type === this.PRIZES_METHODS.giftCardVisa || type === this.PRIZES_METHODS.giftCardAmazon) {
            const giftCardType = type === this.PRIZES_METHODS.giftCardVisa ? 'visa' : 'amazon';
            this.prizesService.getGiftCardInfo(giftCardType).subscribe((data) => {
                try {
                    if (data.status) {
                        this.popupConfirmInfo = {
                            show: true,
                            totalDebit: maxAmountAvailable,
                            handle: data.email,
                            type: type,
                            giftDescription: data.message,
                            giftAmountType: data.denominationType,
                            giftAmountArray: data.fixedDenominations
                        };
                    } else {
                        this.popupComponent.showPopupError({text: data.message});
                        document.body.classList.remove('hide-menu');
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                    document.body.classList.remove('hide-menu');
                }
            }, () => {
                this.loaderService.hideAndShowTryAgain();
                document.body.classList.remove('hide-menu');
            }, () => {
                this.loaderService.hide();
            })
        } else {
            const providers = this.games.filter(game => game !== this.activeProvider);
            this.popupConfirmInfo = {
                show: true,
                totalDebit: maxAmountAvailable,
                handle: handle,
                type: type,
                providers: providers,
                popupTitle: popupTitle,
                paypalAddress: this.paypalVerified.status,
                venmoAddress: this.venmoVerified.status
            };
        }
    }

    submitPopupConfirm(info) {
        if (info.type === this.PRIZES_METHODS.games) {
            this.onSubmitRedeem(info.amount);
        } else if (info.type === this.PRIZES_METHODS.paypal) {
            this.onSubmitPaypalForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === this.PRIZES_METHODS.venmo) {
            this.onSubmitVenmoForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === this.PRIZES_METHODS.card) {
            this.onSubmitDebitCardForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === this.PRIZES_METHODS.giftCardVisa) {
            this.onSubmitGiftCardVisaForm(info.amount, this.popupConfirmInfo.handle, info.qty);
        } else if (info.type === this.PRIZES_METHODS.giftCardAmazon) {
            this.onSubmitGiftCardAmazonForm(info.amount, this.popupConfirmInfo.handle, info.qty);
        }  else if (info.type === this.PRIZES_METHODS.sendToFriend) {
            this.getPrizesInfo(true);
        } else {
            this.onSubmitWithdrawForm(info.amount, this.popupConfirmInfo.handle, this.popupConfirmInfo.type);
        }
    }

    closePopupConfirm() {
        document.body.classList.remove('hide-menu');
        this.popupConfirmInfo = {
            show: false, totalDebit: 0
        };
    }

    changeHandlePopupConfirm(info) {
        this.popupConfirmInfo.handle = info.handle;
        if (info.type === this.PRIZES_METHODS.paypal) {
            this.paypalVerified.status = true;
            this.paypalVerified.handle = info.handle;
        } else if (info.type === this.PRIZES_METHODS.venmo) {
            this.venmoVerified.status = true;
            this.venmoVerified.handle = info.handle;
        }
    }

    onSubmitPaypalForm(amount, handle) {
        if (amount && handle) {
            const text = `You are about to redeem ${formatCurrency(amount, 'en','$')} to your PayPal account ${handle}. Please Confirm`;
            this.showPopupConfirmCancel(text, this.submitPaypalCreatePayout.bind(this, null, handle, amount));
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    submitPaypalCreatePayout(id, handle, amount):void {
      this.prizesService.paypalCreatePayout(id, handle, amount).subscribe(data => {
        this.displaySubmitPayoutResult(data);
      }, (error) => {
        this.loaderService.hideAndShowTryAgain();
      }, () => {
        this.loaderService.hide();
      });
    }

    onProviderChange(game) {
        this.loaderService.show();
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    }

    checkSubmitGame(data, gameId) {
        try {
            if(data.status === true){
                if (data.code) {
                    if (data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                        setTimeout(() => {
                            this.getPrizesInfo(true);
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.getPrizesInfo();
                    });
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showDebitCardPopup(step: number): void {
        document.body.classList.add('hide-menu');
        this.popupDebitCardInfo = {
            show: true,
            type: step
        }
    }

    closeDebitCardPopup() {
      document.body.classList.remove('hide-menu');
      this.popupDebitCardInfo = {
        show: false,
      };
      this.getPrizesInfo(false, false);
    }

    getChannelComment(method) {
        if (method.disableTime) {
            const sub = this.getDisableTimeDist(method.disableTime);

            if (this.isDisableTimeActual(method.disableTime)) {
                const timeValue = ' <span>' + this.dateService.msToTime(sub) + '</span> ';
                return this.descriptionMethodNotAvailable.replace('{time}', timeValue);
            } else {
                clearInterval(this.intervalTimer);
                this.intervalTimer = null;
                method.disable = false;
                // this.getPrizesInfo();
                return method.description;
            }
        }
    }

    getDisableTimeDist(disableTime) {
        const now = new Date();
        const utc = new Date(now.getTime() + now.getTimezoneOffset() * 60000);
        const disableDate = new Date(disableTime);

        const sub = disableDate.getTime() - utc.getTime();
        return sub;
    }

    isDisableTimeActual(disableTime) {
        const dist = this.getDisableTimeDist(disableTime);
        return dist > 0;
    }

    onChannelClick(method) {
        const type: string = method.shortName;
        switch (type) {
            case PRIZES_SENETPRO_METHODS.paypal:
                this.prizesSenetProService.getPaypalInfo().subscribe(data => {
                    this.setAdditionInfo(data, type);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
                break;
          case PRIZES_SENETPRO_METHODS.venmo:
              this.prizesSenetProService.getVenmoInfo().subscribe(data => {
                this.setAdditionInfo(data, type);
              }, () => { this.loaderService.hideAndShowTryAgain();
              }, () => { this.loaderService.hide(); });
              break;
          case PRIZES_SENETPRO_METHODS.card:
            this.prizesSenetProService.getDebitCardInfo().subscribe(data => {
              this.setAdditionInfo(data, type, method);
            }, () => {
              this.loaderService.hideAndShowTryAgain();
            }, () => {
              this.loaderService.hide();
            });
            break;
          default:
            this.showPopupConfirm(type, method.name.toUpperCase());
        }
    }

    setAdditionInfo(data, type, method?): void {
        try {
          switch (type) {
            case PRIZES_SENETPRO_METHODS.paypal:
              this.setPaypalStatus(data);
              this.showPopupConfirm(type);
              break;
            case PRIZES_SENETPRO_METHODS.venmo:
              this.setVenmoStatus(data);
              this.showPopupConfirm(type);
              break;
            case PRIZES_SENETPRO_METHODS.card:
              this.setDebitCardStatus(data, method);
              break;
          }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setDebitCardStatus(data, method): void {
      try {
        if (data.status) {
          this.debitCardSelectedName = '';
          const debitCardStatus = data.result.status;
          const debitCardName = data.result.cardName ? data.result.cardName.toUpperCase() : '';

          const errorText = '<b class="accent-link-popup">Debit Card Setup Failed. Error:</b> ' + String(data.result.errorTxt);

          switch (debitCardStatus) {
            case DEBIT_CARD_STATUS.default:
              this.showDebitCardPopup(this.DEBIT_CARD_POPUP_STEPS.main);
              break;
            case DEBIT_CARD_STATUS.pending:
              this.popupComponent.showPopupError({text: data.result.pendingTxt});
              break;
            case DEBIT_CARD_STATUS.error:
              this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, this.DEBIT_CARD_POPUP_STEPS.additionInfo));
              break;
            case DEBIT_CARD_STATUS.errorCard:
              this.showPopupAgreement(errorText, 'CORRECT', this.showDebitCardPopup.bind(this, this.DEBIT_CARD_POPUP_STEPS.main));
              break;
            case DEBIT_CARD_STATUS.selected:
              this.debitCardSelectedName = debitCardName;
              // const text = `<div style="display: flex; width: 100%; justify-content: space-between">
              //                       <span class="accent-link-popup">${debitCardName}</span>
              //                       <button style="display: flex; text-transform: uppercase"
              //                               class="accent-link-popup"
              //                               id="editPopupBtn">
              //                           Edit
              //                           <img style="width: 1em; height: 1em; margin-left: .6em; pointer-events: none"
              //                                src="../../../assets/images/pencil-icon.svg"
              //                                alt="Edit">
              //                       </button>
              //                   </div>`;
              // this.showPopupAgreement(text, 'SELECT', this.showPopupConfirm.bind(this, method.shortName));
              this.showPopupConfirm(method.shortName);
              break;
          }
        } else {
          this.popupComponent.showPopupError({text: data.message});
        }
      } catch (e) {
          this.loaderService.hideAndShowTryAgain()
      }
    }

    isDefaultPopup(type: string):boolean {
        return type !== this.PRIZES_METHODS.card && type !== this.PRIZES_METHODS.paypal &&
               type !== this.PRIZES_METHODS.topUpBalance && type !== this.PRIZES_METHODS.venmo &&
               type !== this.PRIZES_METHODS.giftCardAmazon && type !== this.PRIZES_METHODS.giftCardVisa;
    }

    showPopupConfirmCancel(text: string, successCallback: () => void): void {
      this.popupComponent.showPopupConfirmCancel({text}, (status) => {
        if (status) { successCallback(); }
      });
    }

    showPopupAgreement(text: string, confirmButtonText: string, successCallback: () => void): void {
      this.popupComponent.showPopupAgreement(
        {text: text, confirmButtonText: confirmButtonText + '<svg><rect></rect></svg>'},
        (status) => {
          if (status) { successCallback(); }
        });
    }

    showTransactionsPopup(): void {
        document.body.classList.add('hide-menu');
        this.popupTransactions = {
            show: true
        }
    }

    closeTransactionsPopup(): void {
        document.body.classList.remove('hide-menu');
        this.popupTransactions = {
            show: false
        }
    }
}
