import * as tslib_1 from "tslib";
import { SETTINGS_CONFIG } from "../../config/api";
import { SettingsLoaderService } from "../settingsLoader.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var SettingsService = /** @class */ (function (_super) {
    tslib_1.__extends(SettingsService, _super);
    function SettingsService(http, loaderService) {
        var _this = _super.call(this, loaderService) || this;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.config = SETTINGS_CONFIG;
        return _this;
    }
    SettingsService.prototype.textMe = function () {
        this.showLoader();
        return this.http.post(this.config.textMe, {});
    };
    SettingsService.prototype.verifyCashapp = function (address, retype) {
        this.showLoader();
        return this.http.post(this.config.verifyCashapp, { address: address, retype: retype });
    };
    SettingsService.prototype.verifyPaypal = function (email, retype) {
        this.showLoader();
        return this.http.post(this.config.verifyPaypal, { email: email, retype: retype });
    };
    SettingsService.prototype.verifyZelle = function (address, retype, type) {
        this.showLoader();
        return this.http.post(this.config.verifyZelle, { address: address, retype: retype, type: type });
    };
    SettingsService.prototype.verifyVenmo = function (address, retype) {
        this.showLoader();
        return this.http.post(this.config.verifyVenmo, { address: address, retype: retype });
    };
    SettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingsService_Factory() { return new SettingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: SettingsService, providedIn: "root" });
    return SettingsService;
}(SettingsLoaderService));
export { SettingsService };
