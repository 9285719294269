<table class="kiosk-table">
    <tr class="kiosk-table__item" *ngFor="let item of items; let odd = odd">

        <td *ngIf="odd; else titleTemplate" class="kiosk-table__content">
            <div class="kiosk-table__description" [innerHTML]="item.description"></div>
            <button class="kiosk-table__btn button" (click)="onBuy(item)">
                SELECT
            </button>
        </td>

        <ng-template #titleTemplate>
            <td class="kiosk-table__title" [innerHTML]="item.title"></td>
        </ng-template>

        <td>
            <img class="kiosk-table__image" [src]="item.img" alt="">
        </td>

        <td *ngIf="odd; else btnTemplate" class="kiosk-table__title" [innerHTML]="item.title"></td>

        <ng-template #btnTemplate>
            <td class="kiosk-table__content">
                <div class="kiosk-table__description" [innerHTML]="item.description"></div>
                <button class="kiosk-table__btn button" (click)="onBuy(item)">
                    SELECT
                </button>
            </td>
        </ng-template>

    </tr>
</table>
