<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner" *ngIf="popupInfo.show">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->
        <div class="investments-popup__content" id="scrolled-wrapper">
            <ng-container *ngIf="popupInfo.type === 1">
                <div class="investments-popup__title-pay">
                    <p>
                        Below are the cards eligible for instant transfers
                    </p>
                </div>
                <ng-container *ngIf="cards.length">
                    <form [formGroup]="formCheckCard">
                        <div class="card-selection">
                            <div class="saved">
                                <div class="saved__list">
                                    <div *ngFor="let card of cards" class="form-simple__item card-selection__row">
                                        <input formControlName="name"
                                               [id]="card.uuid"
                                               [type]="'radio'"
                                               class="form-simple__radio"
                                               [value]="card.uuid"
                                               name="email"
                                               [checked]="activeMethod === card"
                                               (change)="changeMethod(card)"
                                               autocomplete="off">
                                        <label [for]="card.uuid">{{card.cardholder}} - {{card.last_four}}</label>
                                        <button class="card-selection__remove" [class.is-hidden]="card === activeCard" (click)="askOfDeletingCard(card)">remove</button>
                                    </div>

                                </div>
                            </div>

                            <div class="form-simple__btns">
                                <button class="button snake-btn is-notouched" (click)="onSelectCard()">
                                    Select
                                    <svg><rect></rect></svg>
                                </button>
                            </div>
                        </div>
                    </form>

                    <div class="investments-popup__title-pay">
                        <p>
                            Enter a different card
                        </p>
                    </div>
                </ng-container>
                <form [formGroup]="formNew"
                      (ngSubmit)="onSubmitNewCardForm()"
                      class="form-simple form-simple--shrink form-simple--primary">
                    <div class="form-simple__item">
                        <input formControlName="card_nickname"
                               placeholder="Card Nickname"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="first_name"
                               placeholder="First Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="last_name"
                               placeholder="Last Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input mask="0000 0000 0000 0000"
                               formControlName="card_number"
                               placeholder="Card Number"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__row">
                        <div class="form-simple__item">
                            <input formControlName="exp"
                                   mask="00/00"
                                   placeholder="Exp MM/YY"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                        </div>
                        <div class="form-simple__item">
                            <input formControlName="cvv"
                                   mask="000"
                                   placeholder="CVV"
                                   [type]="'tel'"
                                   class="form-simple__input"
                                   autocomplete="off">
                        </div>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="city"
                               placeholder="City"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="states.length">
                        <mat-form-field>
                            <!-- <mat-label>GET</mat-label> -->
                            <!--                        [(value)]="selectedMethod"-->
                            <mat-select #stateSelect
                                        formControlName="state"
                                        [(value)]="selectedState"
                                        panelClass="my-select-panel-border-yellow">
                                <mat-select-trigger>
                                    {{stateSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let state of states" [value]="state">
                                    {{state.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="billing_address"
                               placeholder="Billing Address"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="billing_zip"
                               mask="00000"
                               placeholder="Billing Zip"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button class="button snake-btn is-notouched"
                                [disabled]="formNew.invalid">
                            Setup
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="popupInfo.type === 2">
                <div class="investments-popup__title-pay">
                    <p>
                        Final step. Your financial institution requires the following to allow instant deposits directly to your debit card.
                    </p>
                </div>
                <form [formGroup]="formAdditionInfo"
                      (ngSubmit)="onSubmitAdditionInfoForm()"
                      class="form-simple form-simple--shrink form-simple--primary">
                    <div class="form-simple__item">
                        <input formControlName="legal_first_name"
                               placeholder="Legal First Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="legal_last_name"
                               placeholder="Legal Last Name"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="address"
                               placeholder="Address"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="address_2"
                               placeholder="Address 2"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="city"
                               placeholder="City"
                               [type]="'text'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="zip"
                               mask="00000"
                               placeholder="Zip"
                               [type]="'tel'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__item" *ngIf="states.length">
                        <mat-form-field>
                            <mat-select #stateSelect
                                        formControlName="state"
                                        [(value)]="selectedState"
                                        panelClass="my-select-panel-border-yellow">
                                <mat-select-trigger>
                                    {{stateSelect?.value?.name}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let state of states" [value]="state">
                                    {{state.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-simple__item form-simple__item--date">
                        <mat-form-field appearance="outline">
                            <input formControlName="birthday" [(ngModel)]="birthdayDate" readonly placeholder="BIRTHDAY" matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                    <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                    <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                    <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                    <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                    <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                    <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div class="form-simple__item">
                        <input formControlName="last_four"
                               mask="0000"
                               placeholder="Last 4 of social"
                               [type]="'password'"
                               class="form-simple__input"
                               autocomplete="off">
                    </div>

                    <div class="form-simple__btns">
                        <button class="button snake-btn is-notouched"
                                [disabled]="formAdditionInfo.invalid">
                            SETUP
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </form>
            </ng-container>

        </div>

    </div>
</div>
