<div class="find-prize" [class.is-active]="activeClass" *ngIf="showPopup">
    <div class="find-prize__inner">
        <button #secretBtn
                (click)="btnClickHandler($event, button.hash)"
                *ngFor="let button of buttons; trackBy:buttonImage"
                [class.animate]="button[additionalBtnKeys.animate]"
                [class.no-events]="button[additionalBtnKeys.noLuck] || button[additionalBtnKeys.winningImage]"
                class="secret-btn">
            <img (load)="onImageLoaded()" class="secret-btn__img" [src]="getResultImage(button)" alt="">
        </button>

        <img (load)="onImageLoaded()" class="find-prize__decor find-prize__decor--pig" src="../../../../../assets/images/scratchOff/piggy_2.png" alt="">
        <img (load)="onImageLoaded()" class="find-prize__decor find-prize__decor--money" src="../../../../../assets/images/scratchOff/bags_2.png" alt="">
    </div>
    <canvas id="canvas">Your browser does not support the 'canvas' element.</canvas>
</div>

<div class="game-popup popup"
     *ngIf="showGamePopup"
     [class.is-active]="showGamePopup"
     [class.no-luck]="showGamePopupNoLuck"
     [class.winning]="showGamePopupWinning"
     (click)="hideGamePopup()">

    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/popup_winning_text.png" alt="" class="game-popup__text">
    <img *ngIf="showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/noLuck/popup_no_luck_text.png" alt="" class="game-popup__text">

    <img *ngIf="showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/noLuck/popup_no_luck_bg.png" alt="" class="game-popup__prize-bg">
    <div class="game-popup__prize-wrapper">
        <img [src]="popupPrizeImage" alt="" class="game-popup__prize">
    </div>

<!--    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/100$.png" class="game-popup__prize-value" alt="">-->
    <div class="game-popup__winAmount">${{winAmount | number}}</div>

    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/rocket_1.png" class="game-popup__rocket game-popup__rocket--1" alt="">
    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/rocket_2.png" class="game-popup__rocket game-popup__rocket--2" alt="">
    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/rocket_3.png" class="game-popup__rocket game-popup__rocket--3" alt="">


    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/firework_1.png" class="game-popup__firework game-popup__firework--1" alt="">
    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/firework_2.png" class="game-popup__firework game-popup__firework--2" alt="">
    <img *ngIf="!showGamePopupNoLuck" src="../../../../../assets/images/scratchOff/popups/winning/firework_3.png" class="game-popup__firework game-popup__firework--3" alt="">

    <div *ngIf="showGamePopup && !showGamePopupNoLuck" class="rays">
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
        <div class="rays__ray"></div>
    </div>
</div>

<audio #audio style="visibility: hidden; position: absolute"></audio>
