import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {SPINNER_GAME_CONFIG} from "../../config/api";
import {timer} from "rxjs";
import {mergeMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class SpinnerService extends SettingsLoaderService{
    private config = SPINNER_GAME_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    init() {
        // this.showLoader();
        let post = this.http.post<any>(this.config.init, {});
        return post;

        // return timer(1500).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.init, {}))
        // );
    }
}
