import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-promo-popup',
  templateUrl: './promo-popup.component.html',
  styleUrls: ['./promo-popup.component.scss']
})
export class PromoPopupComponent implements OnInit {
  @Input() active;
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closePopup() {
    this.close.emit();
  }
}
